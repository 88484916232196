import {
  Badge,
  Button,
  Card,
  Icon,
  Input,
  notification,
  Switch,
  Table,
  Tooltip
} from 'antd'
import _ from 'lodash'
import moment from 'moment'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
  ArticleStatus,
  Countries,
  DefaultTablePagination,
  displayDateWithtime
} from '../../../components/_utils/appUtils'
import Request from '../../../request'
import { getPushPathWrapper, getUrlParams } from '../../../routes'
import { getArticleActivities } from '../actions'
import EditAdminUser from '../editAdmin'

const regExpFilters = [
  'name',
  'local.email',
  'address',
  'designation',
  'mobileNo',
  'userName'
]

class allAdminUsers extends Component {
  state = {
    open: false,
    authorData: [],
    editVal: {},
    indexVal1: '',
    activityListOpen: false,
    adminUserId: '',
    journalNameList: [],
    total: 0
  }

  constructor(props) {
    super(props)
    this.getJournalList()
    this.table = React.createRef()
  }


  apiRequest = params => {
    let { dispatch } = this.props
    const regExPFilters = ['message', 'articleNo']
    return new Promise(async resolve => {
      let obj = {
        ...params,
        sortField: '_id',
        sortOrder: 'descend'
      }
      let data = await dispatch(
        getArticleActivities({
          ...obj,
          operatedByUserId: this.props.currentUser._id,
          regExFilters: regExPFilters
        })
      )
      this.setState({ total: data.total })
      resolve(data)
    })
  }


  onClose = () => {
    this.setState({
      open: false
    })
  }
  getJournalList = async () => {
    let { data } = await Request.journalName()
    let journalList = []
    _.each(data, item => {
      journalList.push({
        _id: item._id,
        value: item.slugJournal,
        display: item.name
      })
    })
    console.log(journalList)

    this.setState({ journalNameList: journalList })
  }

  componentDidMount() {
  }

  render() {
    const { dispatch } = this.props
    const { journalNameList, total } = this.state
    const columns = [
      {
        key: 'articleNo',
        title: 'Article No',

        searchTextName: 'articleNo',
        filterByRegex: true,
        render: (val, record) => {
          return (
            <Tooltip title='View Article'>
              <a href={`/articleView/${record.articleId}`}>{val}</a>
            </Tooltip>
          )
        }
      },
      {
        title: 'Article Status',
        key: 'status',
        dataIndex: 'status',
        filters: ArticleStatus.map(x => ({ text: x.status, value: x.status })),
        width: 150
      },
      {
        title: 'Time',
        key: 'time',
        dataIndex: 'time',

        render: item => {
          return item ? displayDateWithtime(item) : null
        }
      },
      {
        title: 'Journal',
        key: 'journalId',
        dataIndex: 'journalId',
        filters: journalNameList.map(x => ({ text: x.display, value: x._id })),
        render: item => {
          return item && item.name ? item.name : null
        }

      },
      {
        title: '  Message',
        key: 'message',
        dataIndex: 'message',
        searchTextName: 'message',
        filterByRegex: true
      }

      // {
      //   title: 'Action',
      //   key: 'actions',
      //   width: 280,
      //   render: (val, record) => (
      //     <React.Fragment>
      //       {(record.selectedReviewer.remarks || record.selectedReviewer.comments || record.selectedReviewer.receiveDate) ?
      //         <Button className={'roundSmallBtn'} onClick={() => events.showDrawer(record.selectedReviewer)}>Change
      //           History</Button> : null}
      //     </React.Fragment>)

      // }
    ]

    return (
      <PageHeaderWrapper title={`My Activities (Total :${total})`}>
        <Card bordered={true}>
          <TableComp
            ref={this.table}
            columns={columns}
            apiRequest={this.apiRequest}
            pagination={DefaultTablePagination({}, 50)}
          />
        </Card>
      </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global }) => ({
  categories: global.categories,
  currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(allAdminUsers)
