import React, { useEffect, useRef, useState } from 'react'
import { Drawer, Tooltip, Card, Button, Form, Row, Col, Input, Popconfirm, notification, message } from 'antd'
import { useDispatch } from 'react-redux'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import ReactHtmlParser from 'react-html-parser'
import { GetWebPage, UpdateWebPage, DeleteWebPage, addWebPageFxn } from '../actions'
import moment from 'moment'
import { CkEditor2013 } from '../../../components/_utils/appUtils'
import MentionInfo from '../../email/mentionInfo'
import EditJournalEthics from '../drawers/editJournalEthics'

const WebPagesDrawer = (props) => {
  let {
    form: { getFieldDecorator, getFieldValue, setFieldsValue },
    visible,
    onClose,
    page,
    callback
  } = props
  const dispatch = useDispatch()
  let [description, setDescription] = useState(null)

  const [pEthics, setPEthics] = useState({
    title: '',
    menuHeading: ''
  })
  const [state, setState] = useState({})
  const [open, setOpen] = useState(false)
  const [propsData, setPropsData] = useState({})

  const setFormData = (data) => {
    setTimeout(() => {
      props.form.setFieldsValue({
        description: data.description
      })
    }, 100)

  }

  useEffect(() => {
    getGuideline()

  }, [])

  const getGuideline = async () => {
    let { data, success } = await dispatch(GetWebPage({ page }))
    if (success) {
      setState(data)
    }
  }

  const handleChange = (data) => {
    setPEthics({
      ...pEthics,
      ...data
    })
  }
  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!pEthics.title) {
      notification.warning({
        message: 'Please enter values in Title field'
      })
      return
    }
    if (!pEthics.menuHeading) {
      notification.warning({
        message: 'Please enter values in Menu Heading field'
      })
      return
    }
    if (!description) {
      notification.warning({
        message: 'Please enter values in Description field'
      })
      return
    }
    let obj = {
      ...pEthics,
      description,
      page: page
    }
    let resp = await dispatch(addWebPageFxn(obj))
    let { success, data } = resp
    if (success) {
      dispatch({ type: 'START_LOAD_ARTICLE' })
      setDescription('')
      setPEthics({
        title: '',
        menuHeading: ''
      })
      // onClose()
      getGuideline()
      notification.success({
        message: `Journal saved successfully`

      })
    }
  }
  const { data } = state
  const { companyId } = state
  const handleDelete = async (item) => {
    let obj = {
      item,
      page,
      companyId
    }
    let resp = await dispatch(DeleteWebPage(obj))
    let { success, data } = resp
    if (success) {
      dispatch({ type: 'START_LOAD_ARTICLE' })
      getGuideline()
      notification.success({
        message: `Journal deleted successfully`

      })
    }

    getGuideline()
  }
  const showDrawer = (item) => {
    setOpen(true)
    setPropsData(item)
  }
  const onCloseDrawer = () => {
    setOpen(false)
  }
  return (
    <>
      <Drawer
        title={`${page}`}
        width={'70%'}
        visible={visible}
        maskClosable={true}
        placement='right'
        onClose={onClose}>
        <Card className={'GuideLineDrawer'}>
          <Form hideRequiredMark className={'inputForm'}>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label='Enter Title' name='title' rules={[{
                  required: true,
                  message: 'Please input your title!'
                }
                ]}>
                  <Input placeholder='Enter Title' name='title' value={pEthics.title}
                         onChange={({ target }) => handleChange({ title: target.value })} />
                </Form.Item>
                <Form.Item label='Menu Heading' name='menuHeading' rules={[{
                  required: true,
                  message: 'Please input your menu heading!'
                }
                ]}>
                  <Input placeholder='Menu Heading' name='menuHeading' value={pEthics.menuHeading}
                         onChange={({ target }) => handleChange({ menuHeading: target.value })} />
                </Form.Item>
                <Form.Item label='Description' name='description' rules={[{
                  required: true,
                  message: 'Please input your menu heading!'
                }
                ]}>
                  <CkEditor2013 html={description}
                                name='description'
                                basic={true}
                                customCls={'lgCk'}
                                onChange={(data) => {
                                  setDescription(data)
                                }}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item style={{ marginTop: 40 }}>
                  <Button type='primary' onClick={handleSubmit}>
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>

          <Col
            span={24}
            style={{ marginTop: '18px' }}
            className={'custom_card'}>
            {
              data &&
              data.map((item, i) => {
                return (
                  <>
                    <div
                      key={i}
                      style={{ marginTop: '18px' }}
                      className='custom_card'>
                      <Card
                        title={`${item.title} (${item.menuHeading})`}
                        extra={
                          <>
                            <Tooltip
                              title='Edit  information'
                              style={{ marginLeft: '20px' }}>
                              <Button
                                style={{ border: 20 }}
                                shape='circle'
                                onClick={() => {
                                  showDrawer(item, state.companyId)
                                }}
                                icon={'edit'}
                              />
                            </Tooltip>

                            <Popconfirm
                              placement='topLeft'
                              title={`Sure to delete?`}
                              onConfirm={() => handleDelete(item)}
                              okText='Yes'
                              cancelText='No'>
                              <Button
                                style={{ border: 20, marginLeft: 10 }}
                                shape='circle'
                                icon={'delete'}
                              />
                            </Popconfirm>
                          </>
                        }>
                        <h3>{ReactHtmlParser(item.description)}</h3>
                      </Card>
                    </div>
                  </>
                )
              })
            }
          </Col>
        </Card>
      </Drawer>
      {/* Edit drawer */}
      {open ?
        <EditJournalEthics onClose={onCloseDrawer} visible={open} data={propsData} page={page}
                           companyId={state.companyId} func={getGuideline} /> : null}
    </>
  )
}


const WrappedForm = Form.create()(WebPagesDrawer)

export default WrappedForm
