import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Icon,
  Input,
  notification,
  Row,
  Select,
  Tag
} from 'antd'
import _ from 'lodash'
import moment from 'moment'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { hideLoader, showLoader } from '../../../modules/actions'
import Request from '../../../request'
import { getUrlParams } from '../../../routes'
import { apiUrl as apiurl } from '../../../settings'
import AddKeyword from '../../editArticle/addKeywordDrawer'

import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import {
  formItemLayout,
  getSlugUrl,
  InputBox
} from '../../../components/_utils/appUtils'
import { AddJournal, GetJournal, UpdateJournal } from '../actions'
import { searchKeyword } from '../../editArticle/actions'

const { Option } = Select

@Form.create()
class AddJournals extends PureComponent {
  state = {
    edit: false,
    fileKey: moment(),
    thumbFile: {},
    oldJournalFile: {},
    oldThumbFile: {},
    chiefEditorList: [],
    subjectCovered: [],
    newkeywords: [],
    keywordList: []
  }

  constructor(props) {
    super(props)
  }

  keywordEvents = {
    getKeyword: async value => {
      console.log(value)
      if (value) {
        let { data } = await searchKeyword({ keyword: value })
        console.log(data)
        if (data) {
          this.setState({ keywordList: data })
        }
      }
    },
    chooseKeyword: value => {
      let clData = _.clone(this.state.subjectCovered)
      let clDataList = _.clone(this.state.keywordList)
      let findData = _.find(clDataList, item => {
        return item._id == value.key
      })
      clData.push({
        keywordId: findData._id,
        keyword: findData.keyword,
        slugKeyword: findData.slugKeyword
      })
      this.setState({
        subjectCovered: clData,
        keywordList: []
      })
    },

    removeKeyword: id => {
      let clData = _.clone(this.state.subjectCovered)
      clData = _.reject(clData, item => {
        return item.keywordId == id
      })
      this.setState({
        subjectCovered: clData
      })
    },
    showKeywordDrawer: () => {
      this.setState({
        open4: true
      })
    },
    onCloseKeyword: () => {
      this.setState({
        open4: false
      })
    }
  }

  handleSubmit = e => {
    const { dispatch, form } = this.props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        let { id: _id, journalFile, thumbFile, subjectCovered } = this.state
        let x = {}
        let fd = new FormData()
        if (!!_id) {
          valData._id = _id
        }
        valData.subjectCovered = subjectCovered
        fd.append('obj', JSON.stringify(valData))

        if (journalFile && journalFile.name) {
          fd.append('journalFile', journalFile)
        }
        if (thumbFile && thumbFile.name) {
          fd.append('thumbFile', thumbFile)
        }
        if (!!_id) {
          x = await dispatch(UpdateJournal(fd))
        } else {
          x = await dispatch(AddJournal(fd))
        }

        let { error, message } = x

        if (!error) {
          notification.success({
            message: message
          })

          if (!_id) {
            form.resetFields()
            this.setState({ fileKey: moment() })
          }
        } else {
          notification.error({
            message: message,
            description: message
          })
        }
      }
    })
  }

  setFormValues = async id => {
    const { dispatch, form } = this.props
    dispatch(showLoader())
    let { error, data } = await dispatch(GetJournal({ id }))
    if (error) {
      notification.error({
        message: 'Error Loading Data'
      })
    } else {
      setTimeout(() => {
        form.setFieldsValue(data)
        this.getChiefEditorId(data.name)
        this.setState({
          subjectCovered: data.subjectCovered
        })
        if (data.journalFile && data.journalFile.path) {
          this.setState({ oldJournalFile: data.journalFile })
        }
        if (data.thumbFile && data.thumbFile.path) {
          this.setState({ oldThumbFile: data.thumbFile })
        }
      }, 500)
    }
    dispatch(hideLoader())
  }

  handleChange = e => {
    let { name, files } = e.target
    if (files && files.length) {
      this.setState({ [name]: files[0] })
    }
  }

  chooseDocument = e => {
    let { name, files } = e.target
    if (files && files.length) {
      this.setState({ [name]: files[0] })
    }
  }

  getChiefEditorId = async journalName => {
    let { data } = await Request.getChiefEditor({
      journalName
    })
    this.setState({ chiefEditorList: data })
  }

  componentDidMount() {
    let data = getUrlParams('journals.edit', this.props.pathname)
    if (!!data) {
      let { id } = data
      this.setState({
        edit: true,
        id: id
      })
      this.setFormValues(id)
    }
    // this.loadKeywords();
  }

  render() {
    const { edit, oldJournalFile, chiefEditorList, oldThumbFile } = this.state
    const {
      form: { getFieldDecorator, getFieldValue, setFieldsValue, loading }
    } = this.props
    const { dispatch } = this.props
    // console.log(this.state.thumbFileKey);
    const formProps = {
      getFieldDecorator,
      getFieldValue,
      apiurl,
      loading
    }

    // this.loadKeywords();

    const inputSchma = {
      fields: [
        {
          label: 'Journal Name ',
          key: 'name',
          required: true,
          placeholder: 'Journal Name',
          onChange: ({ target }) => {
            let { value } = target
            setFieldsValue({
              name: value,
              slugJournal: getSlugUrl(value)
            })
          }
        },
        // Journal Short Name
        {
          label: 'Journal Short Name ',
          key: 'journalShortName',
          required: true,
          placeholder: 'Journal Short Name'
        },
        {
          label: 'Tag Line',
          key: 'tagLine',
          placeholder: 'Tag Line',
          span: 24
        },
        // { label: 'Journal Tag', key: 'journalTag', placeholder: 'Journal Tag' },
        {
          label: 'Slug Journal ',
          key: 'slugJournal',
          required: true,
          placeholder: 'Slug Journal'
        },
        // {
        //   label: 'Chief Editor',
        //   key: 'chiefEditor.name',
        //   placeholder: 'Chief Editor name'
        // },
        { label: 'Print Issn', key: 'printIssn', placeholder: 'Print Issn' },
        { label: 'Online Issn', key: 'onlineIssn', placeholder: 'Online Issn' },
        // { label: 'Title', key: 'title', placeholder: 'Title' },
        //{ label: 'Order Number', key: 'orderNumber', type: 'number', placeholder: 'Order Number' },

        {
          label: 'Chief Editor',
          key: 'chiefEditorId',
          required: true,
          placeholder: 'Select',
          options: chiefEditorList,
          keyAccessor: x => x._id,
          valueAccessor: x => x.name,
          type: 'select',
          showSearch: true,
          onChange: val => {
            // console.log(val)
            setFieldsValue({ chiefEditorId: val })
          }
        },
        {
          label: 'Journal File ',
          key: 'journalFile',
          type: 'file',
          customField: (
            <InputBox title={`Journal File`} className={'rowFlex'}>
              <Input
                type={'file'}
                name={'journalFile'}
                id={'journalFile'}
                className={'form-control'}
                key={this.state.fileKey}
                onChange={e => {
                  this.chooseDocument(e)
                }}
              />
              {oldJournalFile && oldJournalFile.path ? (
                <a
                  className={'linkBtn'}
                  target={'_blank'}
                  href={oldJournalFile.path}>
                  <Icon type={'eye'} />
                </a>
              ) : null}
            </InputBox>
          )
        },
        {
          label: 'Thumb Journal Image',
          key: 'thumbFile',
          type: 'file',
          customField: (
            <InputBox title={`Thumb Journal Image`} className={'rowFlex'}>
              <Input
                type={'file'}
                name={'thumbFile'}
                id={'thumbFile'}
                className={'form-control'}
                key={this.state.thumbFile}
                onChange={e => {
                  this.handleChange(e)
                }}
              />
              {oldThumbFile && oldThumbFile.path ? (
                <a
                  className={'linkBtn'}
                  target={'_blank'}
                  href={oldThumbFile.path}>
                  <Icon type={'eye'} />
                </a>
              ) : null}
            </InputBox>
          )
        },
        {
          label: 'Month(s) of publication',
          key: 'publication',
          placeholder: 'Month(s) of publication'
          // span: 24
        },
        {
          label: 'Scientifical Journal Ranking (scopus)',
          key: 'ranking',
          placeholder: 'Scientifical Journal Ranking (scopus)'
        },
        {
          label: 'National Academy of Agricultural Services (NAAS)',
          key: 'rating',
          placeholder: 'National Academy of Agricultural Services (NAAS) '
        },
        {
          label: 'Impact Factor (ISI Thomson Reuters)',
          key: 'factor',
          placeholder: 'Impact Factor (ISI Thomson Reuters)'
        },
        {
          label: 'Frequency',
          key: 'frequency',
          placeholder: 'Frequency'
        },
        { label: 'Type', key: 'type', placeholder: 'Type' },
        {
          label: 'Related Subjects',
          key: 'subjects',
          type: 'textArea',
          placeholder: 'Related Subjects',
          span: 24
        },
        {
          label: 'Indexing services',
          key: 'indexingServices',
          type: 'textArea',
          placeholder: 'Indexing services',
          span: 24
        },
        {
          label: 'Short Description',
          key: 'shortDescription',
          placeholder: 'Short Description',
          type: 'textArea',
          rows: 6,
          span: 24
        },
        {
          label: 'Description',
          key: 'description',
          placeholder: 'Description',
          type: 'ckeditor4',
          rows: 8,
          height: 100,
          span: 24
        },
        {
          label: 'Aim and Scope',
          key: 'aimAndScope',
          placeholder: 'Aim and Scope',
          type: 'ckeditor4',
          rows: 8,
          height: 100,
          span: 24
        },
        {
          label: 'Open Access',
          key: 'openAccess',
          placeholder: 'Open Access',
          type: 'ckeditor4',
          rows: 8,
          height: 100,
          span: 24
        },
        {
          label: 'Copyright',
          key: 'copyright',
          placeholder: 'Copyright',
          type: 'ckeditor4',
          rows: 6,
          height: 50,
          span: 24
        },
        // {
        //   label: 'Subject Covered',
        //   key: 'subjectCovered',
        //   placeholder: 'Subject Covered',
        //   showSearch: true,
        //   filterOption: false,
        //   mode: 'multiple',
        //   type: 'select',
        //   // value: this.state.keywords,
        //   options: this.state.keywordList.map((item, key) => item.keyword),
        //   rows: 8,
        //   height: 100,
        //   span: 24,
        //   onSearch: async value => {
        //     const { dispatch, form } = this.props
        //     console.log('djhjdhjdh', value)
        //     if (value) {
        //       let { data } = await dispatch(searchKeyword({ keyword: value }))
        //       console.log(data)
        //       if (data) {
        //         this.setState({ keywordList: data })
        //       }
        //     }
        //   },
        //   onChange: (val, index) => {
        //     console.log(val, index, 'index')
        //     let clData = _.clone(this.state.keywords)
        //     console.log(this.state.keywordList, 'keywordList')
        //     let clDataList = _.clone(this.state.keywordList)
        //     console.log(clDataList, 'jhdfjsd')
        //     let findData = _.find(clDataList, item => {
        //       return item.keyword == val.index
        //     })
        //     console.log(findData, 'find')
        //     return
        //     clData.push({
        //       keywordId: findData._id,
        //       keyword: findData.keyword,
        //       slugKeyword: findData.slugKeyword
        //     })
        //     console.log(clData, 'clData')
        //     this.setState({
        //       keywords: clData,
        //       keywordList: []
        //     })
        //     // setFieldsValue({subjectCovered:clData})
        //   }
        // },
        {
          label: 'Disclaimer',
          key: 'disclaimer',
          placeholder: 'Disclaimer',
          type: 'ckeditor4',
          rows: 6,
          height: 50,
          span: 24
        },
        {
          label: 'Article Copyright',
          key: 'articleCopyright',
          placeholder: 'Article Copyright',
          type: 'ckeditor4',
          rows: 6,
          height: 50,
          span: 24
        }
      ]
    }

    return (
      <PageHeaderWrapper title={edit ? 'Edit Journal' : 'Add Journal'}>
        <Card bordered={true}>
          <Form
            onSubmit={this.handleSubmit}
            hideRequiredMark
            className={'inputForm'}>
            <Row gutter={16}>
              {inputSchma.fields.map((item, key) => {
                return (
                  <Col
                    span={item.span ? item.span : 12}
                    md={item.span ? item.span : 12}
                    sm={12}
                    xs={24}
                    key={key}>
                    {item.customField ? (
                      item.customField
                    ) : (
                      <GetEachFormFields
                        item={item}
                        getFieldDecorator={getFieldDecorator}
                        formItemLayout={formItemLayout}
                      />
                    )}
                  </Col>
                )
              })}
            </Row>

            <Card title={'Subject Covered'}>
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item>
                    <Select
                      value={this.state.keywordList}
                      showSearch={true}
                      filterOption={false}
                      labelInValue
                      onChange={this.keywordEvents.chooseKeyword}
                      onSearch={this.keywordEvents.getKeyword}
                      name='subjectCovered'
                      placeholder=' Search subject Covered'>
                      {this.state.keywordList &&
                      this.state.keywordList.map(item => (
                        <Option key={item._id}>{item.keyword}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={2}>
                  <Button
                    style={{ marginTop: 3 }}
                    onClick={() => {
                      this.keywordEvents.showKeywordDrawer()
                    }}
                    className='ant-btn ant-btn-primary'>
                    Add Subject Covered
                  </Button>
                </Col>
                <Col span={24}>
                  {this.state.subjectCovered &&
                  this.state.subjectCovered.map((item, i) => {
                    return (
                      <>
                        <Tag>
                          {item.keyword}
                          <a
                            onClick={() => {
                              this.keywordEvents.removeKeyword(item.keywordId)
                            }}>
                            <Icon type={'close'} />
                          </a>
                        </Tag>
                      </>
                    )
                  })}
                </Col>
              </Row>
            </Card>

            {/*  <FormUtils inputSchema={inputSchma}
                       {...formProps}
            />*/}
            <Form.Item style={{ marginTop: 32 }}>
              <Button
                type='primary'
                htmlType='submit'
                loading={this.props.loading}>
                {edit ? 'UPDATE' : 'SAVE'}
              </Button>
            </Form.Item>
          </Form>
        </Card>
        {this.state.open4 ? (
          <AddKeyword
            visible={this.state.open4}
            onClose={() => {
              this.keywordEvents.onCloseKeyword()
            }}
          />
        ) : null}
      </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading,
  categories: global.categories,
  search: router.location.search,
  pathname: router.location.pathname
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddJournals)
