import { apiUrl } from '../../settings'

export const subscriptionUrl = () => {
  return apiUrl + '/subscription'
}
export const journalsAjaxListUrl = () => {
  return apiUrl + '/journalsAjax'
}
export const allSubscriptionUrl = () => {
  return apiUrl + '/allSubscription'
}
export const addSubscriptionUrl = () => {
  return apiUrl + '/addSubscription'
}
export const getGroupedSubscriptionUrl = () => {
  return apiUrl + '/getGroupedSubscription'
}
export const editSubscriptionUrl = () => {
  return apiUrl + '/editSubscription'
}
export const removeSubscriptionUrl = (id) => {
  return apiUrl + `/removeSubscription/${id}`
}