import React from 'react'
import { Button, Drawer, Icon, Select, Upload, Row, Col, Table } from 'antd'
import styles from './styles.less'

class PackageInfo extends React.Component {
  constructor(props) {
    super()
  }

  render() {
    let { visible, onClose, packageInfo } = this.props
    let subColumns = [
      {
        title: 'Year',
        key: 'period',
        dataIndex: 'period',
        render: (item) => {
          return (
            <div>
              {item} (Rs.)
            </div>
          )
        }
      },
      {
        title: 'Type',
        key: 'type',
        dataIndex: 'type'
      },
      {
        title: 'Amount',
        key: 'amount',
        dataIndex: 'amount'
      }
    ]
    let columns = [
      {
        title: 'Journal Name',
        key: 'journalName',
        dataIndex: 'journalName'
      },
      {
        title: 'Sub Total',
        key: 'subTotal',
        dataIndex: 'subTotal'
      },
      {
        title: 'Prices',
        key: 'package',
        dataIndex: 'package',
        render: (item, record) => {
          return (
            <Table dataSource={item} size={'small'}
                   pagination={false} bordered={false}
                   className={'priceTable'}
                   columns={subColumns}>
            </Table>
          )
        }
      }
    ]
    return (
      <Drawer
        title="Package Info"
        placement="right"
        width={1000}
        closable={true}
        onClose={() => {
          onClose()
        }}
        visible={visible}>
        <div className={styles.packageInfo}>
          <Row className={styles.background1}>
            <Col span={12}>
              <b> Affiliation :</b>
            </Col>
            <Col span={12}>
              {packageInfo.affiliation}
            </Col>
          </Row>
          <Row className={styles.background2}>
            <Col span={12}>
              <b>Department :</b>
            </Col>
            <Col span={12}>
              {packageInfo.department}
            </Col>
          </Row>
          <Row className={styles.background1}>
            <Col span={12}>
              <b>Institute :</b>
            </Col>
            <Col span={12}>
              {packageInfo.institute}
            </Col>
          </Row>
          <Row className={styles.background2}>
            <Col span={12}>
              <b>Country :</b>
            </Col>
            <Col span={12}>
              {packageInfo.country}
            </Col>
          </Row>
          <Row className={styles.background1}>
            <Col span={12}>
              <b> Address :</b>
            </Col>
            <Col span={12}>
              {packageInfo.address}
            </Col>
          </Row>
        </div>

        <Table dataSource={packageInfo.subDetails}
               className={'priceTable'}
               size={'small'}
               pagination={false}
               columns={columns}>

        </Table>


      </Drawer>
    )
  }
}

export default PackageInfo

