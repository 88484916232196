import {
  Button,
  Card,
  Col,
  Collapse,
  Divider,
  Drawer,
  Form,
  Icon,
  Input,
  notification,
  Popconfirm,
  Row,
  Table,
  Tooltip
} from 'antd'
import CollapsePanel from 'antd/lib/collapse/CollapsePanel'
import React, { useEffect, useRef, useState } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { connect, useDispatch } from 'react-redux'
import { Redirect } from 'react-router'
import { FormUtils, TableComp } from 'sz-react-utils-lite'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { DisplayDate } from '../../../components/_utils/appUtils'
import { getUrlParams } from '../../../routes'
import { AddDoiArticleFxn, singleArticle } from '../actions'

const { Panel } = Collapse
const Viewdetaildrawer = props => {
  const dispatch = useDispatch()
  const table = useRef()

  const formItemLayout = {
    labelCol: {
      xs: { span: 2 },
      sm: { span: 3 },
      md: { span: 7 }
    },
    wrapperCol: {
      xs: { span: 22 },
      sm: { span: 22 },
      md: { span: 22 }
    }
  }
  let [articleData, setArticleData] = useState({})
  const RowTable = props => {
    let { title, value } = props
    return (
      <div className={'tableRow'}>
        <div className={'title'}>{title}:</div>
        <div>{value}</div>
      </div>
    )
  }

  const loadArticleData = async id => {
    const { dispatch, form } = props
    let { error, data } = await dispatch(singleArticle(props.sendId))
    if (error) {
      notification.error({
        message: 'Error Loading Data'
      })
    } else {
      console.log(data)
      setArticleData(data)
      console.log(articleData, 'shdhs')
    }
  }

  let tableColumn = [
    {
      title: ' Image ',
      name: 'image',
      key: 'image',
      dataIndex: 'image',
      width: 400,
      render: image => {
        return <img src={image.path} style={{ height: 120 }} />
      }
    },
    {
      title: 'Description',
      name: 'description',
      key: 'description',
      dataIndex: 'description'
    }
  ]

  useEffect(
    () => {
      loadArticleData()
      console.log(props)
    },
    [props.visible]
  )

  // const apiRequest = params => {
  //   return new Promise(async resolve => {
  //     let regExpFilters = ['keyword']
  //     let result = await dispatch(
  //       keywordList({ ...params, regExFilters: regExpFilters })
  //     )
  //     resolve(result)
  //   })
  // }

  let authColumns = [
    {
      title: 'Sr.',
      dataIndex: 'index',
      key: 'index',
      render: (item, record, key) => {
        return key + 1
      }
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'Super Script',
      dataIndex: 'superScript',
      key: 'superScript'
    }
  ]

  return (
    <>
      <Drawer
        visible={props.visible}
        title='Doi Details'
        onClose={props.onClose}
        width='80%'>
        <Card bordered={true}>
          <PageHeaderWrapper>
            <div className={'tableBox striped'}>
              <RowTable
                title={'Date'}
                value={
                  articleData.doiProcessDate
                    ? DisplayDate(articleData.doiProcessDate)
                    : null
                }
              />
              <RowTable
                title={'Article Title'}
                value={
                  articleData.manuScriptTitle
                    ? ReactHtmlParser(articleData.manuScriptTitle)
                    : null
                }
              />


              <RowTable
                title={'Journal Name'}
                value={
                  articleData.composeJournalName
                    ? articleData.composeJournalName
                    : null
                }
              />

              <RowTable
                title={'Abstract'}
                value={
                  articleData.doiAbstract ? (
                    <div style={{ marginLeft: '10.8%' }}>
                      {ReactHtmlParser(articleData.doiAbstract)}
                    </div>
                  ) : null
                }
              />

              <RowTable
                title={'Reference'}
                value={
                  articleData.doiReferences ? (
                    <div>{ReactHtmlParser(articleData.doiReferences)}</div>
                  ) : null
                }
              />
              <RowTable
                title={'Keyword List'}
                value={
                  articleData.doiKeywordList
                    ? articleData.doiKeywordList.map((item, i) => {
                      return (
                        <>
                          <div
                            key={i}
                            style={{
                              width: '100%'
                            }}>
                            {i + 1}. {item.keyword}
                          </div>
                        </>
                      )
                    })
                    : null
                }
              />

              <RowTable
                title={'Affiliation Data'}
                value={
                  articleData.affiliationData
                    ? articleData.affiliationData.map((item, i) => {
                      return (
                        <>
                          <div key={i} style={{}}>
                            {i + 1} {item.affiliate}
                          </div>
                        </>
                      )
                    })
                    : null
                }
              />
              <div className={'doi-form'}>
                <Card title={'Authors'}>
                  <Table pagination={false} columns={authColumns} dataSource={articleData.doiAuthors} size={'small'}
                         bordered={true} />
                </Card>
                <Card title='Information' className='p10'>
                  {articleData.infoData
                    ? articleData.infoData.map((item, i) => {
                      return (
                        <>
                          <div style={{ marginTop: '8px' }}>
                            <Collapse>
                              <Panel
                                header={`${item.title} (${
                                  item.menuHeading
                                })`}>
                                <p>{ReactHtmlParser(item.description)}</p>
                              </Panel>
                            </Collapse>
                          </div>
                        </>
                      )
                    })
                    : null}
                </Card>
                <Card title='Table'>
                  {articleData.table ? (
                    <Table
                      className='figureTable'
                      bordered={true}
                      dataSource={articleData.table}
                      columns={tableColumn}
                      width={'100%'}
                      pagination={false}
                    />
                  ) : null}
                </Card>

                <Card title={'Figures '}>
                  {articleData.figure ? (
                    <Table
                      className='figureTable'
                      bordered={true}
                      dataSource={articleData.figure}
                      columns={tableColumn}
                      width={'100%'}
                      pagination={false}
                    />
                  ) : null}
                </Card>
                <Card title={'Supplementary Tables'}>
                  {articleData.supplementaryTable ? (
                    <Table
                      className='figureTable'
                      bordered={true}
                      dataSource={articleData.supplementaryTable}
                      columns={tableColumn}
                      width={'100%'}
                      pagination={false}
                    />
                  ) : null}
                </Card>
                <Card title={'Annexure'}>
                  {articleData.annexure ? (
                    <Table
                      className='figureTable'
                      bordered={true}
                      dataSource={articleData.annexure}
                      columns={tableColumn}
                      width={'100%'}
                      pagination={false}
                    />
                  ) : null}
                </Card>
                <Card title={'Anova '}>
                  {articleData.anova ? (
                    <Table
                      className='figureTable'
                      bordered={true}
                      dataSource={articleData.anova}
                      columns={tableColumn}
                      width={'100%'}
                      pagination={false}
                    />
                  ) : null}
                </Card>
                <Card title={'Plate '}>
                  {articleData.plate ? (
                    <Table
                      className='figureTable'
                      bordered={true}
                      dataSource={articleData.plate}
                      columns={tableColumn}
                      width={'100%'}
                      pagination={false}
                    />
                  ) : null}
                </Card>
                <Card title={'Flowchart '}>
                  {articleData.flowChart ? (
                    <Table
                      className='figureTable'
                      bordered={true}
                      dataSource={articleData.flowChart}
                      columns={tableColumn}
                      width={'100%'}
                      pagination={false}
                    />
                  ) : null}
                </Card>

                <Card title={'Diagram '}>
                  {articleData.diagram ? (
                    <Table
                      className='figureTable'
                      bordered={true}
                      dataSource={articleData.diagram}
                      columns={tableColumn}
                      width={'100%'}
                      pagination={false}
                    />
                  ) : null}
                </Card>
              </div>
            </div>
          </PageHeaderWrapper>
        </Card>
      </Drawer>
    </>
  )
}
const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading,
  categories: global.categories,
  search: router.location.search,
  pathname: router.location.pathname
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
// export default keyword;
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Viewdetaildrawer)
