import { Button, Card, Col, Drawer, Form, Icon, Row, Tooltip } from 'antd'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import Request from '../../../request'
import { getPushPathWrapper } from '../../../routes'
import AuthorGuidelineDrawer from '../../authorGuideLine/AuthorGuidelineDrawer'
import JournalWebPagerDrawer from '../drawers/journalWebPageDrawer'
import ManageScheduleForm from './ManageSchedule'
import ManageJournalForm from './ManageVolume'
import styles from './styles.less'
import WebPagesDrawer from './webPagesDrawer'
import PageHeader from '../../../components/PageHeader'

@Form.create()
class AllJournals extends Component {
  events = {
    showDrawer: data => {
      this.setState({
        visibleSchedule: true,
        selectedJournal: data
      })
    },
    hideDrawer: () => {
      this.setState({
        visibleSchedule: false,
        selectedJournal: {}
      })
      this.table.current.reload()
    },
    showWebPageDrawer: page => {
      this.setState({
        visibleWebPageDrawer: true,
        page
      })
    },
    hideWebPageDrawer: page => {
      this.setState({
        visibleWebPageDrawer: false,
        page
      })
    },
    showWebDrawer: data => {
      this.setState({
        webState: {
          visible: true,
          journalId: data.journalId,
          page: data.page,
          slugJournal: data.slugJournal,
          name: data.name
        }
      })
    },
    hideWebDrawer: () => {
      this.setState({
        webState: {
          visible: false,
          journalId: null,
          page: null,
          slugJournal: null,
          name: null
        }
      })
      this.table.current.reload()
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      visibleSchedule: false,
      selectedJournal: {},
      visibleWebPageDrawer: false,
      page: '',
      visibleWebDrawer: false,
      webPage: '',
      webState: {
        visible: false,
        page: '',
        journalId: '',
        journalName: '',
        journalSlug: ''
      }
    }
    this.table = React.createRef()
  }

  showDrawer = () => {
    this.setState({ visible: true })
  }
  onClose = () => {
    this.setState({ visible: false })
    this.table.current.reload()
  }

  componentDidMount() {
  }

  apiRequest = params => {
    return new Promise(async resolve => {
      let data = await Request.getAllJournals(params)
      resolve(data)
    })
  }

  render() {
    const { dispatch } = this.props
    const { visibleSchedule, selectedJournal, webState } = this.state
    const webButtons = [
      { page: 'APC', icon: 'file-text' },
      { page: 'Copyright', icon: 'file-text' },
      { page: 'Advertisement', icon: 'file-text' },
      { page: 'Open Access', icon: 'file-text' },
      { page: 'Publication Ethics', icon: 'file-text' },
      { page: 'Peer Review Process Article', icon: 'file-text' },
      { page: 'Role of Editor', icon: 'file-text' },
      { page: 'Role of reviewers', icon: 'file-text' },
      { page: 'Role Of Author', icon: 'file-text' },
      { page: 'For Readers', icon: 'file-text' },
      { page: 'Terms & conditions', icon: 'file-text' },
      { page: 'Author Guidelines', icon: 'file-text' },
      { page: 'About Us', icon: 'file-text' }
    ]
    const journalWebButtons = [
      { page: 'APC', icon: 'file-text' },
      { page: 'Peer Review Article Process', icon: 'file-text' },
      { page: 'Publication Ethics', icon: 'file-text' },
      { page: 'Editorial Policy', icon: 'file-text' },
      { page: 'Role of Reviewers', icon: 'file-text' },
      { page: 'Role of Authors', icon: 'file-text' },
      { page: 'Author Guidelines', icon: 'file-text' },
      { page: 'For Readers', icon: 'file-text' },
      { page: 'Terms and Conditions', icon: 'file-text' }
    ]

    const columns = [
      {
        title: 'Sr No.',
        dataIndex: 'orderNo',
        //align: 'center',
        key: 'index',
        width: 50,
        render: (text, record, index) => {
          return <div className={'alignCenter'}>{index + 1}</div>
        }
      },
      {
        title: 'Name',
        key: 'name',
        sorter: true,
        dataIndex: 'name',
        searchTextName: 'name',
        width: 250,
        render: (val, record) => (
          <div>
            <Tooltip title='Edit Details'>
              {val}
              <a
                className={'linkIcon'}
                onClick={() => {
                  dispatch(
                    getPushPathWrapper('journals.edit', { id: record._id })
                  )
                }}>
                {' '}
                <Icon type={'edit'} />
              </a>
            </Tooltip>
            {record.journalShortName ? (
              <h5>(Short Name: {record.journalShortName})</h5>
            ) : null}
          </div>
        )
      },
      {
        key: 'printIssn',
        render: (val, record) => {
          return <div>{val} </div>
        }
      },
      { key: 'onlineIssn' },
      {
        key: 'chiefEditor',
        render: val => {
          return <div>{val ? val.name : null}</div>
        }
      },
      {
        title: 'Journal File',
        key: 'thumbFile',
        render: (val, record) => {
          return (
            <div>
              {val && val.path ? (
                <img className={styles.img} src={val.path} />
              ) : null}
            </div>
          )
        }
      },
      {
        key: 'action',
        width: 440,
        render: (val, record, key) => {
          return (
            <div className={'btnWrapGroup'}>
              <ManageJournalForm
                className={'roundSmallBtn'}
                val={record}
                onClose={() => this.onClose()}
              />
              <Button
                type='primary'
                className={'roundSmallBtn'}
                onClick={() => this.events.showDrawer(record)}>
                2. Manage Schedule
              </Button>
              {journalWebButtons.map((item, key) => {
                return (
                  <>
                    <Button
                      className={'roundSmallBtn'}
                      key={key}
                      onClick={() =>
                        this.events.showWebDrawer({
                          page: item.page,
                          journalId: record._id,
                          ...record
                        })
                      }>
                      {key + 3}. {item.page}
                    </Button>
                  </>
                )
              })}
            </div>
          )
        }
      }
    ]

    const content = (
      <>
        <Row gutter={16}>
          <Col span={24} className={'btnGroup'}>
            {webButtons.map((item, key) => {
              return (
                <Button
                  type='primary'
                  key={key}
                  onClick={() => {
                    this.events.showWebPageDrawer(item.page)
                  }}>
                  <Icon type={item.icon} />
                  {item.page}
                </Button>
              )
            })}

            {/*<Button
              key='1'
              type='primary'
              onClick={() => {
                this.showDrawer()
              }}>
              <Icon type={'file-text'} />
              Author GuideLines
            </Button>*/}
            <Button
              key='1'
              type='primary'
              onClick={() => {
                dispatch(getPushPathWrapper('journals.add'))
              }}>
              <Icon type={'plus'} />
              Add Journal
            </Button>
          </Col>
        </Row>
      </>
    )

    const journalHeader = (
      <div className={'journalHeader'}>
        <Row gutter={16}>
          <Col span={6} className={'title'}>
            Manage Journals
          </Col>
          <Col span={18} className={'rightBox'}>
            {content}
          </Col>
        </Row>
      </div>
    )

    return (
      <>
        <PageHeaderWrapper title={journalHeader}>
          <div>
            <Card bordered={true}>
              <TableComp
                columns={columns}
                ref={this.table}
                apiRequest={this.apiRequest}
                pagination={false}
              />
            </Card>
            {this.state.visible ? (
              <AuthorGuidelineDrawer
                visible={this.state.visible}
                onClose={() => this.onClose()}
              />
            ) : null}

            {this.state.visibleWebPageDrawer ? (
              <WebPagesDrawer
                visible={this.state.visibleWebPageDrawer}
                page={this.state.page}
                onClose={() => this.events.hideWebPageDrawer()}
              />
            ) : null}

            {visibleSchedule ? (
              <ManageScheduleForm
                visible={visibleSchedule}
                val={selectedJournal}
                onClose={() => this.events.hideDrawer()}
              />
            ) : null}

            {webState.visible ? (
              <JournalWebPagerDrawer
                {...webState}
                onClose={() => this.events.hideWebDrawer()}
              />
            ) : null}
          </div>
        </PageHeaderWrapper>
      </>
    )
  }
}

const mapStateToProps = ({ global }) => ({
  categories: global.categories
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllJournals)
