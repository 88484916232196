import React, { useEffect, useState } from 'react'
import { Row, Col, Card, Drawer, Form, Button, Icon, notification } from 'antd'
import {
  chooseEmailModalFxn,
  currencyList,
  DisplayDate,
  formItemLayout,
  GetDownloadLink
} from '../../components/_utils/appUtils'
import ReactHtmlParser from 'react-html-parser'
import { RowTable, CustomCardTitle } from '../article/articleView/RowTable'
import moment from 'moment'
import GetEachFormFields from '../../components/_utils/appFormUtils'

import { connect } from 'react-redux'
import PageHeaderWrapper from '../../components/PageHeaderWrapper'
import { getSingleMember, changePassword } from './actions/index'
import { useDispatch } from 'react-redux'
import { ApproveArticle } from '../article/actions'

const ChangePassword = (props) => {

  let [memberInfo, setMemberInfo] = useState(false)
  let dispatch = useDispatch()

  let {
    form: { getFieldDecorator, setFieldsValue }
  } = props

  const inputSchema = {
    fields: [
      {
        label: 'Current Password',
        key: 'currentPassword',
        required: true,
        type: 'password'
      },
      {
        label: 'New Password',
        key: 'password',
        required: true,
        type: 'password'
      },
      {
        label: 'Confirm Password',
        key: 'confirmPassword',
        type: 'password'
      }
    ]
  }
  const handleSubmit = e => {
    const { form } = props
    let user = JSON.parse(localStorage.getItem('user'))
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (valData.password !== valData.confirmPassword) {
        notification.error({
          message: 'Please check your password'
        })
      } else {
        valData._id = user._id
        let { success } = await dispatch(changePassword(valData))
        if (success) {
          dispatch({ type: 'START_LOAD_ARTICLE' })
        }
      }
    })
  }

  return (
    <PageHeaderWrapper
      title={`Reviewer Id - ${memberInfo.reviewerId}`}>
      <Card>
        <Form onSubmit={handleSubmit} hideRequiredMark className={'inputForm'}>
          <Row gutter={16}>
            {inputSchema.fields.map((item, key) => {
              return (
                <Col span={item.span ? item.span : 8} key={key}>
                  <GetEachFormFields getFieldDecorator={getFieldDecorator}
                                     item={item} formItemLayout={formItemLayout} />
                </Col>
              )
            })}
            <Col span={24}>
              <Form.Item style={{ marginTop: 40 }}>
                <Button type='primary' htmlType='submit'>
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
    </PageHeaderWrapper>
  )
}
const WrappedForm = Form.create()(ChangePassword)

export default WrappedForm
