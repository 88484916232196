import { Button, Card, Drawer, Form, notification, Row, Col, Input, Icon } from 'antd'
import React, { Component } from 'react'
import Request from '../../../request'
import { FormUtils, TableComp } from 'sz-react-utils-lite'
import IssueJournalForm from './ManageIssue'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import {
  AuthorTitle,
  CustomCkEditor,
  DefaultTablePagination, DesignationList,
  formItemLayout, InputBox,
  mentionsFeeds
} from '../../../components/_utils/appUtils'
import { UpdateJournal } from '../actions'
import { connect } from 'react-redux'
import moment from 'moment'

@Form.create()
class ManageScheduleForm extends Component {
  volumeTable = React.createRef()

  inputSchema = {
    fields: [
      {
        label: 'Indian Schedule',
        key: 'manageIndianSchedule',
        placeholder: 'Complete description',
        type: 'ckeditor',
        // editorKey: this.state.manageIndianScheduleKey,
        span: 24
      },
      {
        label: 'Foreign Schedule',
        key: 'manageForeignSchedule',
        placeholder: 'Complete description',
        type: 'ckeditor',
        // editorKey: this.state.manageForeignScheduleKey,
        span: 24
      }
    ]

  }

  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      visibleIssueDrawer: false,
      total: 0,
      message: '',
      selectedVolume: {},
      manageIndianScheduleKey: moment(),
      manageForeignScheduleKey: moment() + 1

    }
  }

  componentDidMount() {
    let { val, form: { setFieldsValue } } = this.props
    console.log(val.manageForeignSchedule)
    console.log(val.manageIndianSchedule)
    setTimeout(() => {
      setFieldsValue({
        manageForeignSchedule: val.manageForeignSchedule ? val.manageForeignSchedule : '',
        manageIndianSchedule: val.manageIndianSchedule ? val.manageIndianSchedule : ''
      })
      this.setState({
        manageIndianScheduleKey: moment(),
        manageForeignScheduleKey: moment() + 1
      })
    }, 500)
  }

  handleSubmitVolume = (e) => {
    const { dispatch, form, val, onClose } = this.props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {

      if (valData.manageIndianSchedule !== undefined || valData.manageForeignSchedule !== undefined) {
        let fd = new FormData()
        let obj = {}
        if (valData.manageIndianSchedule !== undefined) {
          obj.manageIndianSchedule = valData.manageIndianSchedule
        }
        if (valData.manageForeignSchedule !== undefined) {
          obj.manageForeignSchedule = valData.manageForeignSchedule
        }
        obj._id = val._id
        fd.append('obj', JSON.stringify(obj))

        let data = await dispatch(UpdateJournal(fd))
        console.log(data)
        if (data.success) {
          notification.success({
            message: data.message
          })
          onClose()
        } else {
          notification.error({
            message: data.message
          })
        }
      } else {
        console.log(valData)
        notification.error({
          message: 'Fill Schedule field',
          description: 'Fill Schedule field'
        })
      }
    })
  }

  render() {
    const { form: { getFieldDecorator, getFieldValue, setFieldsValue }, loading, val } = this.props
    const formProps = {
      getFieldDecorator, getFieldValue, loading
    }

    let { selectedVolume, visibleIssueDrawer } = this.state
    let { visible, onClose } = this.props
    return (
      <Drawer
        title={`Manage Schedule - ${val.name}`}
        width={'55%'}
        placement='right'
        closable={true}
        onClose={onClose}
        visible={visible}>
        <div style={{ padding: 10 }}>
          <Card className={'scheduleDrawer'}>
            <Form onSubmit={this.handleSubmitVolume} hideRequiredMark className={'inputForm'}>
              <Row gutter={16}>
                {this.inputSchema.fields.map((item, key) => {
                  return (
                    !item.hidden &&
                    <Col span={item.span ? item.span : 12} md={item.span ? item.span : 12} sm={12} xs={24}
                         key={key}>
                      {item.customField ? item.customField :
                        <GetEachFormFields
                          item={item}
                          getFieldDecorator={getFieldDecorator}
                          formItemLayout={formItemLayout}/>}
                    </Col>
                  )
                })}
                <Col span={8}>
                  <Form.Item style={{ marginTop: 38 }}>
                    <Button type='primary' htmlType='submit' loading={this.props.loading}>
                      Save
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </div>
      </Drawer>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  null,
  mapDispatchToProps
)(ManageScheduleForm)
