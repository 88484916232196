import { hidePageLoad, showPageLoad } from '../../../modules/actions'
import { customAxios as axios, getToken } from '../../../request'
import { blogListUrl, deleteBlogUrl, updateBlogUrl, singleBlogUrl} from '../api'



export const blogListFxn = filters => async dispatch => {
  dispatch(showPageLoad())
  let config = {
    params: { ...filters },
    ...getToken()
  }
  let { data } = await axios.get(blogListUrl(), config)
  dispatch(hidePageLoad())
  return data
}

export const removeBlog = blogData => async dispatch => {
  try {
    dispatch(showPageLoad())

    const { data } = await axios.put(deleteBlogUrl(), { id: blogData._id }, getToken())

    dispatch(hidePageLoad())
    return data
  } catch (error) {
    dispatch(hidePageLoad())
    console.error("Error deleting blog:", error)
    return { success: false, message: "Error deleting blog" }
  }
}

export const singleBlog = blogId => async dispatch => {
  dispatch(showPageLoad())
  try {
    let config = {
      ...getToken()
    }
    let { data } = await axios.get(singleBlogUrl(blogId), config)
    dispatch(hidePageLoad())
    return data
  } catch (error) {
    dispatch(hidePageLoad())
    console.error("Error fetching single blog:", error)
    return { success: false, message: "Error fetching blog details" }
  }
}

export const updateBlog = blogData => async dispatch => {
  dispatch(showPageLoad())
  try {
    const { data } = await axios.put(updateBlogUrl(), blogData, getToken())
    dispatch(hidePageLoad())
    return data
  } catch (error) {
    dispatch(hidePageLoad())
    console.error("Error updating blog:", error)
    return { success: false, message: "Error updating blog" }
  }
}