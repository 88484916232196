import React from 'react'
import { Collapse, Row, Col, Card, Button, Tooltip, Popconfirm } from 'antd'
import { chooseEmailModalFxn, DisplayDate, GetDownloadLink } from '../../../components/_utils/appUtils'
import ReactHtmlParser from 'react-html-parser'
import { RowTable, CustomCardTitle } from './RowTable'
import { SendTemplateEmail } from '../../email/actions'
import { useDispatch } from 'react-redux'
import { getPushPathWrapper } from '../../../routes'
const { Panel } = Collapse


const ArticleDetails = (props) => {
  let { article } = props
  const dispatch = useDispatch()

  let sendMailFxn = (article) => {
    chooseEmailModalFxn({
      callback: async (email) => {
        let obj = {
          id: article._id,
          fromEmail: email,
          emailType: 'acknowledgement',
          template: article.processingCharges > 0 ? 'acknowledgementWithCharges' : 'acknowledgementWithoutCharges',
          type: 'article'
        }
        let resp = await dispatch(SendTemplateEmail({
          email: obj.fromEmail,
          id: article._id,
          obj,
          template: obj.template
        }))
        if (resp && resp.success) {
          dispatch({ type: 'START_LOAD_ARTICLE' })
        }
      }
    })
  }

  let BtnAction = (event) => {
    // events.show()
    event.stopPropagation()
    sendMailFxn(article)
  }


  let getExtraButton = () => {
    return (
      <React.Fragment>
        {!article.acknowledgmentDate && !article.paymentDate ?
          // <Popconfirm placement='topLeft' title={`Are you sure you want to Resend Acknowledgment?`}
          //             onConfirm={() => sendMailFxn(article)} okText='Yes' cancelText='No'>
          <Button type={'primary'} size={'small'} onClick={BtnAction}>Resend Ack.</Button>
          // </Popconfirm>
          : null}
        {' '}
        <Button type={'primary'} size={'small'} onClick={() => {
          dispatch(getPushPathWrapper('article.edit', { id: article._id }))
        }}>Edit</Button>
        {/*<Button type={'primary'} size={'small'} onClick={''}>Resend Ack.</Button>*/}
      </React.Fragment>
    )
  }
  return (
    article && article._id ? <Collapse defaultActiveKey={['1']} expandIconPosition={'right'}>
      <Panel header='Article Details' key='1' extra={getExtraButton()}>
        <div className={'tableBox striped'}>
          <RowTable title={'Article No'} value={article.articleNo} />
          <RowTable title={'Manuscript Title'} value={ReactHtmlParser(article.manuScriptTitle)} />
          <RowTable title={'Submitted Date'} value={DisplayDate(article.submittedDate)} />
          <RowTable title={'Article Origin'} value={article.country} />
          <RowTable title={'Article Type'} value={article.articleType} />
          <RowTable title={'Area Of Research'} value={article.areaOfResearch} />
          <RowTable title={'Journals Name'} value={article.journal} />
          <RowTable title={'Processing Charges'} value={`${article.currency} ${article.processingCharges}`} />
          <RowTable title={'Publication Charges'} value={`${article.currency} ${article.publicationCharges}`} />
          <RowTable title={'Total No. of Authors'} value={article.totalAuthors} />
          <RowTable title={'Abstract'} value={<div
            style={{ width: '75%', marginLeft: '19%' }}> {ReactHtmlParser(article.abstract)} </div>} />
          <RowTable title={'Reminder Date'} value={DisplayDate(article.reminderDate)} />
          <RowTable title={'Country'} value={article.country} />
          <RowTable title={'Process'} value={article.processType} />
          <RowTable title={'Special Note'} value={article.specialNote} />
        </div>
        <div className={'customBox'}>
          <CustomCardTitle title={'Corresponding Author'} />

          <div className={'tableBox striped'}>
            <RowTable title={'Total Author'} value={article.totalAuthors} />
            <RowTable title={'Corresponding Author'} value={article.correspondingAuthor} />
            <RowTable title={'Email'} value={article.correspondingAuthorsEmail} />
            <RowTable title={'Secondary Email'} value={article.correspondingAuthorEmail2} />
            <RowTable title={'Address'} value={article.correspondingAuthorAddress} />
            <RowTable title={'Contact Number'} value={article.correspondingAuthorContact} />
            <RowTable title={'Institute Affiliated with'} value={article.correspondingAuthorAffiliation} />
            <RowTable title={'Author Designation'} value={article.correspondingAuthorDesignation} />
          </div>
        </div>
        <div className={'customBox'}>
          <CustomCardTitle title={'Reviewer Attachments'} />

          <div className={'tableBox striped'}>
            <RowTable title={'Article Attachment'}
                      value={
                        article.reviewerArticleAttachment && article.reviewerArticleAttachment.path ?
                          <a download className='downloadLink' target={'_blank'}
                             href={article.reviewerArticleAttachment.path}>Download</a> : 'No Attachment'
                      }
            />
            <RowTable title={'Other Attachment'}
                      value={
                        article.reviewerOtherAttachment && article.reviewerOtherAttachment.path ?
                          <a download target={'_blank'}
                             className='downloadLink'
                             href={article.reviewerOtherAttachment.path}>Download</a> : 'No Attachment'
                      }
            />
          </div>
        </div>
      </Panel>
    </Collapse> : null
  )
}

export default ArticleDetails
