import React, { PureComponent } from 'react'
import {
  Form,
  Input,
  DatePicker,
  Select,
  Button,
  notification,
  Card,
  Icon,
  Table, Row, Col
} from 'antd'
import _ from 'lodash'
import moment from 'moment'

import { hideLoader, showLoader } from '../../../modules/actions'
import { apiUrl as apiurl } from '../../../settings'
import Request from '../../../request'
import { connect } from 'react-redux'
import { createMatchSelector } from 'connected-react-router'
import { getUrlParams } from '../../../routes'
import styles from './styles.less'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { FormUtils } from 'sz-react-utils-lite'
import { updateSubscription } from '../actions'
import GetEachFormFields from '../../../components/_utils/appFormUtils'

const timePeriodName = {
  oneYear: 'One Year',
  threeYear: 'Three Year',
  lifeTime: 'Life Time'

}

@Form.create()
class AddSubscription extends PureComponent {

  state = {
    edit: false,
    journalList: [],
    nationalityArr: [{ value: 'Indian', display: 'Indian', _id: 'Indian' }, {
      value: 'Foreign',
      display: 'Foreign',
      _id: 'Foreign'
    }],
    yearArr: [],
    lastYearPackagePrice: []

  }

  constructor(props) {
    super(props)
  }

  handleSubmit = e => {
    const { dispatch, form } = this.props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {

      if (!err) {
        let { id } = this.state
        valData._id = id
        dispatch(showLoader())
        let x = {}
        x = await dispatch(updateSubscription(valData))
        let { error, message } = x
        dispatch(hideLoader())
        if (!error) {
          notification.success({
            message: message
          })
        } else {
          notification.error({
            message: message,
            description: message
          })
        }


      }
    })
  }

  setFormValues = async (id) => {

    const { dispatch, form } = this.props

    dispatch(showLoader())

    let { error, data } = await Request.getSubscription({ id })

    if (error) {
      notification.error({
        message: 'Error Loading Data'
      })
    } else {
      form.setFieldsValue(data)
    }

    dispatch(hideLoader())

  }

  journalAjax = async () => {
    let journalListArr = await Request.getJournalAjax()
    let journalList = []
    _.each(journalListArr, (item, key) => {
      journalList.push({ value: item, display: item.name, _id: item._id })
    })

    this.setState({ journalList })
  }


  setYears = () => {
    let yearArr = []
    // let i = moment().year();
    let i = 2018
    while (i < 2026) {
      yearArr.push({ value: i, display: i, _id: i })
      i++
    }
    this.setState({ yearArr })
  }

  componentDidMount() {
    this.journalAjax()
    this.setYears()
    let data = getUrlParams('subscription.edit', this.props.pathname)
    if (!!data) {
      let { id } = data
      this.setState({
        edit: true,
        id: id
      })
      this.setFormValues(id)

    }
  }

  getLastYearSubscriptionInfo(type) {
    let obj = {
      year: this.props.form.getFieldValue('year'),
      nationality: this.props.form.getFieldValue('nationality'),
      journalId: this.props.form.getFieldValue('journalId')
    }
    if (type !== 'year') {
      this.setYears()
      this.props.form.setFieldsValue({ year: null })

    }
    Request.getLastYearPackageInfo(obj).then((resp) => {
      if (resp) {
        console.log(resp)
        let arr = []
        _.each(resp.subscriptionPack, (item1) => {
          let listObj = {
            nationality: item1.nationality,
            year: item1.year,
            price: []
          }
          _.each(item1.price, (item, key) => {
            listObj.price.push({
              currency: item1.nationality == 'Indian' ? 'Rs' : 'USD',
              key: key,
              name: key,
              print: item.print,
              online: item.online,
              printOnline: item.printOnline
            })
          })
          arr.push(listObj)
        })
        let cloneYearArr = Object.assign([], this.state.yearArr)
        _.each(resp.prevYearPack, (item1) => {
          cloneYearArr = _.reject(cloneYearArr, (item) => {
            return item1.year == item.value
          })
        })

        setTimeout(() => {
          this.setState({
            lastYearPackagePrice: arr,
            yearArr: cloneYearArr
          })
        }, 500)
      }
    })
  }

  render() {

    const { form: { getFieldDecorator, getFieldValue }, loading } = this.props
    const { edit, journalList, yearArr, nationalityArr } = this.state

    const formProps = {
      getFieldDecorator, getFieldValue, apiurl, loading
    }
    const lastYearPackagePrice = this.state.lastYearPackagePrice
    const styleForm = {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 }
    }

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 12 },
        md: { span: 4 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 }
      }

    }

    const columns = [
      {
        title: 'Year',
        key: 'name',
        dataIndex: 'name',
        render: (val, record) => {
          return <div>{timePeriodName[val]} ({record.currency})</div>
        }
      },

      {
        title: 'Print',
        key: 'print',
        dataIndex: 'print'
      },

      {
        title: 'Online',
        key: 'online',
        dataIndex: 'online'
      },

      {
        title: 'Print+Online',
        key: 'printOnline',
        dataIndex: 'printOnline'
      }

    ]
    const inputSchma = {
      fields: [
        {
          label: 'Journal',
          key: 'journalId',
          required: true,
          placeholder: 'Journal',
          type: 'select',
          options: journalList,
          onChange: (val) => {
            this.props.form.setFieldsValue({ journalId: val })
            this.getLastYearSubscriptionInfo('journalId')

          }
        },
        {
          label: 'Nationality',
          key: 'nationality',
          required: true,
          placeholder: 'Nationality',
          type: 'select',
          options: nationalityArr,
          onChange: (val) => {
            this.props.form.setFieldsValue({ nationality: val })
            this.getLastYearSubscriptionInfo('nationality')
          }
        },
        {
          label: 'Year', key: 'year', required: true, placeholder: 'Year', type: 'select', options: yearArr,
          onChange: (val) => {
            this.props.form.setFieldsValue({ year: val })
            this.getLastYearSubscriptionInfo('year')
          }
        }
      ]

    }
    const journalAmount = {
      fields: [

        {
          prefixComp: <h4><b>One Year</b></h4>,
          label: 'Print ',
          key: 'price.oneYear.print',
          placeholder: 'Print',
          required: true,
          type: 'number'
        },
        {
          label: 'Online',
          key: 'price.oneYear.online',
          placeholder: 'Online',
          required: true,
          type: 'number'
        },
        {
          label: 'Print + OnLine',
          key: 'price.oneYear.printOnline',
          placeholder: 'Print + OnLine',
          required: true,
          type: 'number'
        },

        {
          prefixComp: <h4><b>Three Year </b></h4>,
          label: 'Print',
          key: 'price.threeYear.print',
          placeholder: 'Print',
          required: true,
          type: 'number'
        },
        {
          label: 'Online',
          key: 'price.threeYear.online',
          placeholder: 'Online',
          required: true,
          type: 'number'
        },
        {
          label: 'Print + OnLine',
          key: 'price.threeYear.printOnline',
          placeholder: 'Print + OnLine',
          required: true,
          type: 'number'
        },

        {
          prefixComp: <h4><b>Life Time</b></h4>,
          label: 'Print',
          key: 'price.lifeTime.print',
          placeholder: 'Print',
          required: true,
          type: 'number'
        },
        {
          label: 'Online',
          key: 'price.lifeTime.online',
          placeholder: 'Online',
          required: true,
          type: 'number'
        },
        {
          label: 'Print + OnLine',
          key: 'price.lifeTime.printOnline',
          placeholder: 'Print + OnLine',
          required: true,
          type: 'number'
        }
      ]

    }

    return (
      <PageHeaderWrapper
        title={'Edit Subscription'}>


        <Card bordered={true}>
          <Row gutter={16}>
            {inputSchma.fields.map((item, key) => {
              return (
                <Col span={item.span ? item.span : 8} md={item.span ? item.span : 8} sm={12} xs={24}
                  key={key}>
                  <GetEachFormFields
                    item={item}
                    getFieldDecorator={getFieldDecorator}
                    formItemLayout={formItemLayout} />
                </Col>
              )
            })}
          </Row>
          <Form onSubmit={this.handleSubmit} hideRequiredMark style={{ marginTop: 8 }}>

            {/*<FormUtils inputSchema={inputSchma} {...formProps} />*/}

            <div className={styles.priceList}>
              <FormUtils inputSchema={journalAmount} refresh={this.refreshFxn}
                getFieldDecorator={getFieldDecorator} />

            </div>


            <Form.Item style={{ marginTop: 32 }}>
              <Button type="primary" htmlType="submit" loading={this.props.loading}>
                {edit ? 'UPDATE' : 'SAVE'}
              </Button>
            </Form.Item>

          </Form>
        </Card>
        {lastYearPackagePrice && lastYearPackagePrice.length ?
          <Card>
            {this.state.lastYearPackagePrice.map((item, key) => {
              return (
                <div key={key} style={{ marginBottom: 10 }}
                  className={key % 2 == 0 ? styles.background1 : styles.background2}>
                  <h3>Last Year {item.year} ({item.nationality})</h3>
                  <div className={styles.priceTable}>
                    <Table columns={columns}
                      pagination={false}
                      dataSource={item.price}
                    />
                  </div>

                </div>
              )
            })}

          </Card> : null}


      </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading,
  categories: global.categories,
  search: router.location.search,
  pathname: router.location.pathname

})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddSubscription)
