import {
  Button,
  Card,
  Col,
  Drawer,
  Form,
  Input,
  notification,
  Popconfirm,
  Row,
  Tooltip
} from 'antd'
import moment from 'moment'
import { Component, default as React } from 'react'
import { connect } from 'react-redux'
import { FormUtils, TableComp } from 'sz-react-utils-lite'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { default as GetEachFormFields } from '../../../components/_utils/appFormUtils'
import {
  DefaultTablePagination,
  DisplayDate,
  formItemLayout,
  InputBox
} from '../../../components/_utils/appUtils'
import { hideLoader, showLoader } from '../../../modules/actions'
import { default as Request } from '../../../request'
import { AddIssue } from '../actions'
import EditIssue from '../drawers/editIssue'
import styles from './styles.less'

@Form.create()
class IssueJournalForm extends Component {
  issueTable = React.createRef()

  editEvents = {
    showEditDrawer: data => {
      this.setState({ issueDoc: data, editVisible: true })
    },
    hideEditDrawer: () => {
      this.setState({ issueDoc: {}, editVisible: false }, () => {
        this.issueTable.current.reload()
      })
    }
  }

  showDrawer = () => {
    this.setState({ visible: true })
  }
  onClose = () => {
    this.setState({ visible: false })
  }

  getJournalsIssueData = params => {
    return new Promise(async resolve => {
      let {
        volumeData: { journalId, volumeId }
      } = this.props
      let resp = await Request.getJournalIssue({
        journalId: journalId,
        volumeId: volumeId,
        ...params
      })
      console.log('google.com', resp)
      this.setState({ total: resp.total })
      resolve(resp)
    })
  }

  loadVolume = () => {
    this.issueTable.current.reload()
  }

  handleChange = e => {
    let { name, files } = e.target
    if (files && files.length) {
      this.setState({ [name]: files[0] })
    }
  }

  handleSubmitIssue = e => {
    const { dispatch, form, volumeData } = this.props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        let { journalId, volumeId } = volumeData
        let { coverImage, issueZip } = this.state
        valData.volumeId = volumeId
        valData.journalId = journalId
        let fd = new FormData()
        fd.append('obj', JSON.stringify(valData))
        if (coverImage && coverImage.name) {
          fd.append('coverImage', coverImage)
        }
        if (issueZip && issueZip.name) {
          fd.append('issueZip', issueZip)
        }
        let x = await dispatch(AddIssue(fd))
        let { error, message } = x
        this.setState({ fileKey: moment() })
        if (!error) {
          notification.success({
            message: message
          })
          this.loadVolume()
          form.resetFields()
        } else {
          notification.error({
            message: message,
            description: message
          })
        }
      }
    })
  }

  handleRemoveIssue = async id => {
    console.log('record', id)
    let x = await Request.removeJournalIssue({ _id: id })
    let { error, msg } = x
    if (!error) {
      notification.success({
        message: msg
      })
      this.loadVolume()
    } else {
      notification.error({
        message: msg,
        description: msg
      })
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      total: 0,
      coverImage: {},
      issueZip: {},
      fileKey: moment(),
      issueDoc: {},
      editVisible: false
    }
  }

  render() {
    let { volumeData } = this.props
    let { editVisible, issueDoc } = this.state
    const columnsData = [
      {
        title: 'Serial No.',
        dataIndex: 'issueNumber',
        key: 'issueNumber',
        width: 100
        // render: text => <a>{text}</a>
      },
      {
        title: 'Name',
        dataIndex: 'issueName',
        key: 'issueName',
        width: 300
      },
      {
        title: 'Publish Date',
        dataIndex: 'publishDate',
        key: 'publishDate',
        width: 150,
        render: item => {
          return item ? DisplayDate(item) : ''
        }
      },
      {
        title: 'Page Range',
        dataIndex: 'pageRange',
        key: 'pageRange',
        width: 150
      },
      {
        title: 'Issue Zip',
        key: 'issueZip',
        render: (val, record) => {
          return (
            <div>
              {val && val.path ? (
                <a href={val.path} download={val.fileName}>Download Zip</a>
              ) : null}
            </div>
          )
        }
      },
      {
        title: 'Cover Image',
        key: 'coverImage',
        width: 150,
        render: (val, record) => {
          return (
            <div className={'alignCenter'}>
              {val && val.path ? (
                <img className={styles.img} src={val.path} />
              ) : null}
            </div>
          )
        }
      },
      {
        title: 'Action',
        key: 'action',
        width: 180,
        render: (val, record) => {
          return (
            <div>
              <Tooltip title={'Remove'}>
                <Button
                  icon={'edit'}
                  size={'small'}
                  onClick={() => {
                    this.editEvents.showEditDrawer(record)
                  }}>
                  Edit
                </Button>
              </Tooltip>{' '}
              <Tooltip title={'Remove'}>
                <Popconfirm
                  title={'Are sure, you want to delete issue?'}
                  onConfirm={() => {
                    this.handleRemoveIssue(record._id)
                  }}>
                  <Button icon={'close'} size={'small'}>
                    Remove
                  </Button>
                </Popconfirm>
              </Tooltip>
            </div>
          )
        }
      }
    ]
    const {
      form: { getFieldDecorator, getFieldValue, setFieldsValue },
      loading,
      visible,
      onClose
    } = this.props
    const inputSchema = {
      fields: [
        {
          label: 'Issue name',
          key: 'issueName',
          required: true,
          placeholder: 'Issue Name',
          onChange: e => {
            setFieldsValue({ issueName: e.target.value })
          }
        },
        {
          label: 'Issue Number',
          key: 'issueNumber',
          required: true,
          placeholder: 'Issue Number',
          type: 'number'
        },
        {
          label: 'Page Range',
          key: 'pageRange',
          required: true,
          placeholder: 'pageRange'
        },
        {
          label: 'Issue Zip',
          key: 'issueZip',
          customField: (
            <InputBox title={`Issue Zip`}>
              <Input
                type={'file'}
                name={'issueZip'}
                id={'issueZip'}
                className={'form-control'}
                key={this.state.fileKey}
                onChange={e => {
                  this.handleChange(e)
                }}
              />
            </InputBox>
          )
        },
        {
          label: 'Cover Image',
          key: 'coverImage',
          customField: (
            <InputBox title={`Cover Image`}>
              <Input
                type={'file'}
                name={'coverImage'}
                id={'coverImage'}
                className={'form-control'}
                key={this.state.fileKey}
                onChange={e => {
                  this.handleChange(e)
                }}
              />
            </InputBox>
          )
        },
        {
          label: 'Publish Date',
          key: 'publishDate',
          required: true,
          placeholder: 'Publish Date',
          type: 'date'
        }
      ]
    }
    return (
      <Drawer
        title={`Manage Issue - ${volumeData.journalName} (Volume - ${
          volumeData.volumeName
        })`}
        width={'75%'}
        placement='right'
        closable={true}
        onClose={onClose}
        visible={visible}>
        <div style={{ padding: 10 }}>
          <Card bordered={true}>
            <Form
              onSubmit={this.handleSubmitIssue}
              hideRequiredMark
              className={'inputForm'}>
              <Row gutter={10}>
                {inputSchema.fields.map((item, key) => {
                  return (
                    <Col md={4} sm={4} xs={18} key={key}>
                      {item.customField ? (
                        item.customField
                      ) : (
                        <GetEachFormFields
                          item={item}
                          getFieldDecorator={getFieldDecorator}
                          formItemLayout={formItemLayout}
                        />
                      )}
                    </Col>
                  )
                })}
                <Col span={4}>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={this.props.loading}
                      style={{ marginTop: 43 }}>
                      ADD
                    </Button>
                  </Form.Item>
                </Col>
              </Row>

              {/* <FormUtils inputSchema={inputSchema} {...formProps} />

              <Form.Item style={{ marginTop: 32 }}>
                <Button type='primary' htmlType='submit' loading={this.props.loading}>
                  ADD
                </Button>
              </Form.Item>*/}
            </Form>
            <h7>Total Volumes: {this.total}</h7>
            <TableComp
              columns={columnsData}
              ref={this.issueTable}
              apiRequest={this.getJournalsIssueData}
              pagination={DefaultTablePagination()}
            />
          </Card>
        </div>
        {editVisible ? (
          <EditIssue
            visible={editVisible}
            issue={issueDoc}
            onClose={this.editEvents.hideEditDrawer}
          />
        ) : null}
      </Drawer>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  null,
  mapDispatchToProps
)(IssueJournalForm)
