import {
  Button,
  Card,
  Col,
  Collapse,
  Divider,
  Form,
  Icon,
  Input,
  Modal,
  notification,
  Popconfirm,
  Row,
  Select,
  Table,
  Tag,
  Tooltip
} from 'antd'
import CollapsePanel from 'antd/lib/collapse/CollapsePanel'
import _ from 'lodash'
import { FlowRight } from 'lodash-decorators'
import moment from 'moment'
import React, { PureComponent } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { connect } from 'react-redux'
import PageHeaderWrapper from '../../components/PageHeaderWrapper'
import GetEachFormFields from '../../components/_utils/appFormUtils'
import {
  AuthorTitle,
  chooseEmailModalFxn,
  CkEditor2013,
  Countries,
  CustomCkEditor,
  DesignationList,
  Discipline,
  Genders,
  InputBox,
  MemberPositions,
  MemberRatings,
  MemberTypes
} from '../../components/_utils/appUtils'
import { getPushPathWrapper, getUrlParams } from '../../routes'
import { apiUrl as apiurl } from '../../settings'

import { addMember, checkMemberEmails } from '../members/actions'
import { singleReviewer } from '../reviewer/actions'
import { searchKeyword } from './actions'
import AddAffilate from './addAffilate'
import {
  default as AddInformation,
  default as AddReference
} from './addInformation'
import AddKeyword from './addKeyword'
import AddTable from './addTable'
import EditInformation from './editInformation'
const { Option } = Select
@Form.create()
class EditArticle extends PureComponent {
  state = {
    edit: false,
    fileKey: moment(),
    ckeditorKey: moment() + 1,
    infoData: [],
    tableData: [],
    figureData: [],
    anexureData: [],
    annovaData: [],
    suplementData: [],
    platesData: [],
    flowChartData: [],
    diagramData: [],
    affilateData: [],
    Keywords: [],
    featuredImage: '',
    inputVisible: false,
    inputValue: '',
    loop: null,
    authors: [],
    noOfAuthors: 0,
    page: 1,
    valGet: false,
    editInfo: {},
    keywordList: []
  }

  constructor(props) {
    super(props)
  }

  // select keyword

  getKeyword = async value => {
    if (value) {
      let { error, data } = await searchKeyword({ keyword: value })
      if (data) {
        this.setState({ keywordList: data })
      }
    }
  }

  handleChange1 = value => {
    let clData = _.clone(this.state.Keywords)
    clData.push(value)
    this.setState({
      Keywords: clData,
      keywordList: []
    })
  }
  onCloseKeyword = () => {
    this.setState({
      open4: false
    })
  }

  showKeywordDrawer = () => {
    this.setState({
      open4: true
    })
  }

  // Select
  handleChange = item => {}
  chooseDocument = e => {
    console.log(e.target)
    let { name, files } = e.target
    if (files && files.length) {
      this.setState({ [name]: files[0] })
    }
  }
  loopVal = noOfAuthors => {
    let authors = []
    for (let i = 0; i < noOfAuthors; i++) {
      console.log(i)
      authors.push({
        name: '',
        email: '',
        script: ''
      })
    }

    this.setState({ authors, noOfAuthors })
  }
  //edit infodrawer

  showDrawerEditInfo = (item, index) => {
    this.setState({
      editInfo: { ...item, index },
      ['open2']: true
    })
  }

  onClose2 = () => {
    this.setState({ open2: false })
  }

  updateInfo = row => {
    let cloneData = _.clone(this.state.infoData)
    cloneData[row.index] = row
    this.setState({ infoData: cloneData, open2: false })
  }
  // table drawer
  showDrawer3 = () => {
    this.setState({
      ['open3']: true
    })
  }
  onClose3 = () => {
    this.setState({
      ['open3']: false
    })
  }

  addTable = data => {
    let cloneData = _.clone(this.state.tableData)
    cloneData.push(data)
    this.setState({
      tableData: cloneData
    })
  }
  // suplement drawer
  showDrawerSuplement = () => {
    this.setState({
      openS: true
    })
  }
  onCloseS = () => {
    this.setState({
      openS: false
    })
  }

  addSuplement = data => {
    let cloneData = _.clone(this.state.suplementData)
    cloneData.push(data)
    this.setState({
      suplementData: cloneData
    })
  }
  // figure drawer
  showDrawerFigure = () => {
    this.setState({
      openf: true
    })
  }
  onCloseF = () => {
    this.setState({
      openf: false
    })
  }

  addFigure = data => {
    let cloneData = _.clone(this.state.figureData)
    cloneData.push(data)
    this.setState({
      figureData: cloneData
    })
  }
  // ANexure
  showDrawerAnexure = () => {
    this.setState({
      openAnxr: true
    })
  }
  onCloseAnxr = () => {
    this.setState({
      openAnxr: false
    })
  }

  addAnexure = data => {
    console.log(data)
    let cloneData = _.clone(this.state.anexureData)
    cloneData.push(data)
    this.setState({
      anexureData: cloneData
    })
  }
  // Anova data
  showDrawerAnova = () => {
    this.setState({
      openAnv: true
    })
  }
  onCloseAnv = () => {
    this.setState({
      openAnv: false
    })
  }

  addAnova = data => {
    console.log(data)
    let cloneData = _.clone(this.state.annovaData)
    cloneData.push(data)
    this.setState({
      annovaData: cloneData
    })
  }
  // PLates
  showDrawerPlates = () => {
    this.setState({
      openPlt: true
    })
  }
  onClosePlt = () => {
    this.setState({
      openPlt: false
    })
  }

  addPlates = data => {
    console.log(data)
    let cloneData = _.clone(this.state.platesData)
    cloneData.push(data)
    this.setState({
      platesData: cloneData
    })
  }

  // FlowChart Functions
  showDrawerFlowChart = () => {
    this.setState({
      openChrt: true
    })
  }
  onCloseChrt = () => {
    this.setState({
      openChrt: false
    })
  }

  addFlowChart = data => {
    console.log(data)
    let cloneData = _.clone(this.state.flowChartData)
    cloneData.push(data)
    this.setState({
      flowChartData: cloneData
    })
  }
  // Diagram Functions
  showDrawerDiagram = () => {
    this.setState({
      openDgrm: true
    })
  }
  onCloseDgrm = () => {
    this.setState({
      openDgrm: false
    })
  }

  addDiagram = data => {
    console.log(data)
    let cloneData = _.clone(this.state.diagramData)
    cloneData.push(data)
    this.setState({
      diagramData: cloneData
    })
  }
  //Drawer functions
  onClose = () => {
    this.setState({
      ['open']: false
    })
  }

  handleDelete = key => {
    const newData = this.state.infoData.filter(item => item.menuHeading !== key)
    this.setState({ infoData: newData })
  }

  addDetails = data => {
    let cloneData = _.clone(this.state.infoData)
    cloneData.push(data)
    this.setState({
      infoData: cloneData
    })
  }
  addAffilateData = data => {
    let cloneData = _.clone(this.state.affilateData)
    cloneData.push(data)
    this.setState({
      affilateData: cloneData
    })
  }

  onClose1 = () => {
    this.setState({
      ['open1']: false
    })
  }

  resetFormData = () => {
    const { dispatch, form } = this.props
    form.setFieldsValue({
      enterDate: moment()
    })
  }

  handleSubmit = async e => {
    const { dispatch, form } = this.props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      console.log(valData, 'asjhdajfsd')
      if (!err) {
        let fd = await new FormData()
        fd.append('obj', JSON.stringify(valData))
        console.log(e, fd, 'asdjasdauwoeiawejri')
        // valData.status = 'accepted'
        valData.infoData = this.state.infoData

        return
        let resp = await dispatch(checkMemberEmails(valData))
        if (resp && resp.success) {
          fd.append('obj', JSON.stringify(valData))
          console.log(fd, 'asdasdasj')
          return
          let { success, message } = await dispatch(addMember(fd))

          if (success) {
            notification.success({
              message: message
            })
            this.resetFormData()
            this.setState({
              infoData: ''
            })
            setTimeout(() => {
              this.setState({
                fileKey: moment(),
                ckeditorKey: moment() + 1
              })
              dispatch(getPushPathWrapper('member.addMember'))
            }, 300)

            this.loadReviewerId()
          }
        }
      }
    })
  }

  showDrawer = () => {
    this.setState({
      ['open']: true
    })
  }

  showDrawer1 = (item, index) => {
    this.setState({
      ['open1']: true
    })
  }

  //   [setFormValuesFxn = async id => {
  //     const { dispatch, form } = this.props
  //     let { error, data } = await dispatch(singleReviewer(id))
  //     if (data.reviewerFile != null && data.cvAndBrief != null) {
  //       this.setState({
  //         memberImage: data.reviewerFile,
  //         memberCV: data.cvAndBrief
  //       })
  //     }
  //     // if (data.memberImage != null && data.memberCV != null) {
  //     //   this.setState({ memberImage: data.memberImage, memberCV: data.memberCV })
  //     // }
  //     if (error) {
  //       notification.error({
  //         message: 'Error Loading Data'
  //       })
  //     } else {
  //       if (data.status && data.status !== 'Accepted') {
  //         form.setFieldsValue({
  //           ...data,
  //           instituteAffiliatedWith: data.institute
  //         })
  //         this.setState({ becomeReviewerId: data._id })
  //       } else {
  //         Modal.error({
  //           content: (
  //             <div>
  //               <p>Reviewer already Approved</p>
  //             </div>
  //           ),
  //           onOk: () => {
  //             dispatch(getPushPathWrapper('member.addMember'))
  //           },
  //           onCancel: () => {
  //             dispatch(getPushPathWrapper('member.addMember'))
  //           }
  //         })
  //       }
  //     }
  //   }
  // ]
  componentDidMount() {}

  render() {
    // }

    const {
      form: { getFieldDecorator, getFieldValue, setFieldsValue, featuredImage },
      loading
    } = this.props
    const {
      Keywords,
      tableData,
      figureData,
      authors,
      page,
      suplementData,
      anexureData,
      annovaData,
      platesData,
      diagramData,
      flowChartData,
      affilateData
    } = this.state

    const formProps = {
      getFieldDecorator,
      getFieldValue,
      apiurl,
      loading
    }
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 18 },
        md: { span: 14 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 }
      }
    }

    const inputSchema = {
      fields: [
        {
          label: 'Date',
          key: 'enterDate',
          required: true,
          placeholder: 'Date',
          type: 'date',
          span: 8
        },
        {
          label: 'Featured Image',
          key: 'featuredImage',
          span: 8,
          type: 'file',
          customField: (
            <InputBox title={`Featured Image`} className={'rowFlex'}>
              <Input
                width="100%"
                type={'file'}
                name={'featuredImage'}
                id={'featuredImage'}
                className={'form-control'}
                key={this.state.fileKey}
                onChange={e => {
                  this.chooseDocument(e)
                }}
              />

              {/* <a
                className={'linkBtn'}
                target={'_blank'}
                href={oldJournalFile.path}>
                <Icon type={'eye'} />
              </a> */}
            </InputBox>
          )
        },
        {
          label: 'Article Title',
          key: 'articleTitle',
          type: 'ckeditor',

          span: 24
        },
        {
          label: 'Abstract',
          key: 'abstract',
          type: 'ckeditor',
          height: '200px',
          span: 24
        }
      ]
    }
    const columns = [
      {
        title: 'Serial no',
        name: 'serial',
        key: 'serial',
        render: (text, record, index) => (index == 0 ? '*' : index),
        width: 100
      },

      {
        title: ' Affilation ',
        name: 'affilate',
        key: 'affilate',
        dataIndex: 'affilate'
      }
    ]

    return (
      <PageHeaderWrapper title={'Add Doi Article'}>
        <Card bordered={true}>
          <Form
            onSubmit={this.handleSubmit}
            hideRequiredMark
            className={'inputForm'}
            noValidate={true}>
            <Row gutter={16}>
              {inputSchema.fields.map((item, key) => {
                return (
                  !item.hidden && (
                    <Col
                      span={item.span ? item.span : 12}
                      md={item.span ? item.span : 12}
                      sm={12}
                      xs={24}
                      key={key}>
                      {item.customField ? (
                        item.customField
                      ) : (
                        <GetEachFormFields
                          item={item}
                          getFieldDecorator={getFieldDecorator}
                          formItemLayout={formItemLayout}
                        />
                      )}
                    </Col>
                  )
                )
              })}
            </Row>

            {this.state.open4 ? (
              <AddKeyword
                visible={this.state.open4}
                onClose={() => {
                  this.onCloseKeyword()
                }}
              />
            ) : null}

            <Card
              title={'Keywords'}
              extra={
                <Form.Item
                  name="Add keyword"
                  style={{
                    marginTop: '4'
                  }}>
                  <Button
                    size="small"
                    onClick={() => {
                      this.showKeywordDrawer()
                    }}
                    className="ant-btn ant-btn-primary">
                    Add Keyword
                  </Button>
                </Form.Item>
              }>
              <Form.Item>
                <Select
                  style={{ width: '34%' }}
                  value={this.state.keywordList}
                  showSearch={true}
                  filterOption={false}
                  labelInValue
                  onChange={this.handleChange1}
                  onSearch={this.getKeyword}
                  name="keywords"
                  placeholder=" Search Keywords">
                  {this.state.keywordList &&
                    this.state.keywordList.map(item => (
                      <Option key={item._id}>{item.keyword}</Option>
                    ))}
                </Select>
              </Form.Item>

              {this.state.Keywords &&
                this.state.Keywords.map((item, i) => {
                  return (
                    <>
                      <Tag closable={true}>{item.label}</Tag>
                    </>
                  )
                })}
            </Card>
            <Divider type="vertical" />

            <Card
              title={'Affilation'}
              extra={
                <Form.Item name="add" style={{ textAlign: 'right' }}>
                  <Button
                    size="small"
                    onClick={this.showDrawer1}
                    className="ant-btn ant-btn-primary">
                    Add Affilation
                  </Button>
                </Form.Item>
              }>
              {' '}
              <Table
                size="small"
                columns={columns}
                dataSource={this.state.affilateData}
                pagination={false}
                style={{ border: '1px solid #454545' }}
              />
            </Card>

            <Divider type="vertical" />
            <Card title="Authors">
              <Form.Item label="Add number of authors">
                <Input
                  style={{ width: '34%' }}
                  value={this.state.noOfAuthors}
                  type={'number'}
                  onChange={e => {
                    this.loopVal(e.target.value)
                  }}
                />
              </Form.Item>

              {authors && authors.length ? (
                <>
                  {authors.map((item, key) => {
                    return (
                      <>
                        <Row key={key} gutter={16}>
                          <Col span={8}>
                            <Form.Item label="Name">
                              <Input
                                placeholder=" Enter Name"
                                onChange={e => {
                                  authors[key].name = e.target.value
                                }}
                              />
                            </Form.Item>
                          </Col>

                          <Col span={8}>
                            <Form.Item label="Email Adress">
                              <Input
                                placeholder="Enter email adress"
                                onChange={e => {
                                  authors[key].email = e.target.value
                                }}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Form.Item label="Super Script ">
                              <Select
                                onChange={e => {
                                  authors[key].script = e
                                }}
                                name="category"
                                placeholder="Please select SuperScript">
                                {affilateData &&
                                  affilateData.map(item => {
                                    console.log(item, key, 'hbdhbhhdh')
                                    return (
                                      <Option value={key}>
                                        {affilateData.length == 0
                                          ? '*'
                                          : affilateData.length + 1}
                                      </Option>
                                    )
                                  })}

                                {/* <Option value="1">1</Option>
                                <Option value="3">3</Option>
                                <Option value="4">4</Option> */}
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                      </>
                    )
                  })}
                </>
              ) : null}
            </Card>
            <Divider type="vertical" />
            <Card
              title={'Information'}
              extra={
                <Form.Item
                  name="add"
                  style={{ marginLeft: '10%', marginTop: '8px' }}>
                  <Button
                    size="small"
                    onClick={this.showDrawer}
                    className="ant-btn ant-btn-primary">
                    Add Information
                  </Button>
                </Form.Item>
              }>
              {/* <Col
                span={24}
                style={{ marginTop: 8 }}
                className={'custom_card'}>
                {this.state.infoData &&
                  this.state.infoData.map((item, i) => {
                    return (
                      <>
                        <div
                          key={i}
                          style={{ marginTop: '18px' }}
                          className="custom_card">
                          <Card
                            title={`${item.menuHeading} (${item.title})`}
                            extra={
                              <>
                                <Tooltip
                                  title="Edit  information"
                                  style={{ marginLeft: '20px' }}>
                                  <Button
                                    style={{ border: 20 }}
                                    shape="circle"
                                    onClick={() => showDrawer(item)}
                                    icon={'edit'}
                                  />
                                </Tooltip>

                                <Popconfirm
                                  placement="topLeft"
                                  title={`Sure to delete?`}
                                  onConfirm={() => handleDelete(item)}
                                  okText="Yes"
                                  cancelText="No">
                                  <Button
                                    style={{ border: 20, marginLeft: 10 }}
                                    shape="circle"
                                    icon={'delete'}
                                  />
                                </Popconfirm>
                              </>
                            }
                          >
                            <h3>{ReactHtmlParser(item.description)}</h3>
                          </Card>
                        </div>
                      </>
                    )
                  })}
              </Col>  */}

              <Col style={{ marginTop: '8px' }} className={'white_collapse'}>
                {this.state.infoData &&
                  this.state.infoData.map((item, i) => {
                    return (
                      <>
                        <div key={i} style={{ marginTop: '18px' }}>
                          <Collapse style={{ backgroundColor: 'white' }}>
                            <CollapsePanel
                              header={`${item.title} (${item.menuHeading})`}
                              extra={
                                <h1>
                                  <Tooltip
                                    title="Edit  information"
                                    style={{ marginLeft: '20px' }}>
                                    <Button
                                      style={{ border: 20 }}
                                      shape="circle"
                                      onClick={() =>
                                        this.showDrawerEditInfo(item, i)
                                      }
                                      icon={'edit'}
                                    />
                                  </Tooltip>
                                  <Popconfirm
                                    placement="topLeft"
                                    title={`Are you Sure, You want to delete?`}
                                    onConfirm={() =>
                                      this.handleDelete(item.menuHeading)
                                    }
                                    okText="Yes"
                                    cancelText="No">
                                    <Button
                                      style={{ border: 20, marginLeft: 10 }}
                                      shape="circle"
                                      icon={'delete'}
                                    />
                                  </Popconfirm>
                                </h1>
                              }>
                              <p>{ReactHtmlParser(item.description)}</p>
                            </CollapsePanel>
                          </Collapse>
                        </div>
                      </>
                    )
                  })}
              </Col>
            </Card>
            <Divider type="vertical" />

            {/* information Drawer */}
            <AddInformation
              addDetails={this.addDetails}
              visible={this.state.open}
              onClose={() => {
                this.onClose()
              }}
              arrayData={this.state.infoData}
            />
            {this.state.editInfo && this.state.open2 ? (
              <EditInformation
                arrayData={this.state.infoData}
                visible={this.state.open2}
                editInfo={this.state.editInfo}
                updateInfo={valData => {
                  this.updateInfo(valData)
                }}
                onClose={() => {
                  this.onClose2()
                }}
              />
            ) : null}
            {/* Affilate Drawer */}
            <AddAffilate
              addAffilateData={this.addAffilateData}
              visible={this.state.open1}
              onClose={() => {
                this.onClose1()
              }}
            />
            {/* keyword */}
            {/* Table Drawer */}
            <Divider type="vertical" />

            <Card
              title={'Table Data '}
              extra={
                <Form.Item
                  name="addTable"
                  style={{ textAlign: 'right', marginTop: 8 }}>
                  <Button
                    size="small"
                    onClick={this.showDrawer3}
                    className="ant-btn ant-btn-primary">
                    Add Table
                  </Button>
                </Form.Item>
              }>
              <Col
                span={24}
                style={{ marginTop: '8px' }}
                className={'custom_card'}>
                {tableData && tableData.length
                  ? tableData.map((item, i) => {
                      console.log(item)
                      return (
                        <>
                          {/* <Row>
                            <Col /> */}
                          {/* <div
                            key={i}
                            style={{ marginTop: '8px' }}
                            className="custom_card">
                            <h3 style={{ alignItems: 'flex-end' }}> */}
                          {/* <Col>
                              <h3> {item.description}</h3>
                            </Col>
                          </Row> */}
                          <div style={{ display: 'flex' }}>
                            <p>{item.tableImage.name}</p>
                            <p
                              style={{
                                marginLeft: '6%',
                                textAlign: 'center'
                              }}>
                              {item.description}
                            </p>
                          </div>
                          {/* // </div> */}
                        </>
                      )
                    })
                  : null}
              </Col>
            </Card>

            <Divider type="vertical" />

            <Card
              title={'Figure Information '}
              extra={
                <Form.Item
                  name="addFigure"
                  style={{ textAlign: 'right', marginTop: 8 }}>
                  <Button
                    size="small"
                    onClick={this.showDrawerFigure}
                    className="ant-btn ant-btn-primary">
                    Add Figure
                  </Button>
                </Form.Item>
              }>
              <Col
                span={24}
                style={{ marginTop: '8px' }}
                className={'custom_card'}>
                {this.state.figureData &&
                  this.state.figureData.map((item, i) => {
                    return (
                      <>
                        <div style={{ display: 'flex' }}>
                          <p>{item.tableImage.name}</p>
                          <p
                            style={{
                              marginLeft: '6%',
                              textAlign: 'center'
                            }}>
                            {item.description}
                          </p>
                        </div>
                      </>
                    )
                  })}
              </Col>
            </Card>

            <Divider type="vertical" />

            <Card
              title={'Anexure Data  '}
              extra={
                <Form.Item
                  name="addAnexure"
                  style={{ textAlign: 'right', marginTop: 8 }}>
                  <Button
                    size="small"
                    onClick={this.showDrawerAnexure}
                    className="ant-btn ant-btn-primary">
                    Add Anexure
                  </Button>
                </Form.Item>
              }>
              <Col span={24} style={{ marginTop: 8 }} className={'custom_card'}>
                {anexureData && anexureData.length
                  ? anexureData.map((item, i) => {
                      return (
                        <>
                          <div style={{ display: 'flex' }}>
                            <p>{item.tableImage.name}</p>
                            <p
                              style={{
                                marginLeft: '6%',
                                textAlign: 'center'
                              }}>
                              {item.description}
                            </p>
                          </div>
                        </>
                      )
                    })
                  : null}
              </Col>
            </Card>

            <Divider type="vertical" />

            <Card
              title={'Anova Data'}
              extra={
                <Form.Item
                  name="addAnova"
                  style={{ textAlign: 'right', marginTop: 8 }}>
                  <Button
                    size="small"
                    onClick={this.showDrawerAnova}
                    className="ant-btn ant-btn-primary">
                    Add Anova
                  </Button>
                </Form.Item>
              }>
              <Col span={24} style={{ marginTop: 8 }} className={'custom_card'}>
                {annovaData && annovaData.length
                  ? annovaData.map((item, i) => {
                      return (
                        <>
                          <div style={{ display: 'flex' }}>
                            <p>{item.tableImage.name}</p>
                            <p
                              style={{
                                marginLeft: '6%',
                                textAlign: 'center'
                              }}>
                              {item.description}
                            </p>
                          </div>
                        </>
                      )
                    })
                  : null}
              </Col>
            </Card>

            <Divider type="vertical" />

            <Card
              title={'Plate Data'}
              extra={
                <Form.Item
                  name="addPlates"
                  style={{ textAlign: 'right', marginTop: 8 }}>
                  <Button
                    size="small"
                    onClick={this.showDrawerPlates}
                    className="ant-btn ant-btn-primary">
                    Add Plates
                  </Button>
                </Form.Item>
              }>
              <Col span={24} style={{ marginTop: 8 }} className={'custom_card'}>
                {platesData && platesData.length
                  ? platesData.map((item, i) => {
                      return (
                        <>
                          <div style={{ display: 'flex' }}>
                            <p>{item.tableImage.name}</p>
                            <p
                              style={{
                                marginLeft: '6%',
                                textAlign: 'center'
                              }}>
                              {item.description}
                            </p>
                          </div>
                        </>
                      )
                    })
                  : null}
              </Col>
            </Card>

            <Divider type="vertical" />

            <Card
              title={'FlowChart Data'}
              extra={
                <Form.Item
                  name="addFlowCharts"
                  style={{ textAlign: 'right', marginTop: 8 }}>
                  <Button
                    size="small"
                    onClick={this.showDrawerFlowChart}
                    className="ant-btn ant-btn-primary">
                    Add FlowChart
                  </Button>
                </Form.Item>
              }>
              <Col span={24} style={{ marginTop: 8 }} className={'custom_card'}>
                {flowChartData && flowChartData.length
                  ? flowChartData.map((item, i) => {
                      return (
                        <>
                          <div style={{ display: 'flex' }}>
                            <p>{item.tableImage.name}</p>
                            <p
                              style={{
                                marginLeft: '6%',
                                textAlign: 'center'
                              }}>
                              {item.description}
                            </p>
                          </div>
                        </>
                      )
                    })
                  : null}
              </Col>
            </Card>

            <Divider type="vertical" />

            <Card
              title={'Diagram Data'}
              extra={
                <Form.Item
                  name="addDiagram"
                  style={{ textAlign: 'right', marginTop: 8 }}>
                  <Button
                    size="small"
                    onClick={this.showDrawerDiagram}
                    className="ant-btn ant-btn-primary">
                    Add Diagram
                  </Button>
                </Form.Item>
              }>
              <Col span={24} style={{ marginTop: 8 }} className={'custom_card'}>
                {diagramData && diagramData.length
                  ? diagramData.map((item, i) => {
                      return (
                        <>
                          <div
                            key={i}
                            style={{ marginTop: 8 }}
                            className="custom_card">
                            <Card
                              hoverable
                              style={{
                                width: 240
                              }}
                              cover={
                                <img alt="example" src={`{item.tableImage}`} />
                              }>
                              <>sd</>
                            </Card>
                            <h3>{item.description}</h3>
                          </div>
                        </>
                      )
                    })
                  : null}
              </Col>
            </Card>

            {/* Add Table */}
            {this.state.open3 ? (
              <AddTable
                visible={this.state.open3}
                onClose={() => {
                  this.onClose3()
                }}
                addTable={this.addTable}
              />
            ) : null}
            {/* figure table */}

            {this.state.openf ? (
              <AddTable
                visible={this.state.openf}
                onClose={() => {
                  this.onCloseF()
                }}
                addFigure={this.addFigure}
              />
            ) : null}
            {/* suplemtry */}
            {this.state.openS ? (
              <AddTable
                visible={this.state.openS}
                onClose={() => {
                  this.onCloseS()
                }}
                addSuplement={this.addSuplement}
              />
            ) : null}

            {/* anexure */}
            {this.state.openAnxr ? (
              <AddTable
                visible={this.state.openAnxr}
                onClose={() => {
                  this.onCloseAnxr()
                }}
                addAnexure={this.addAnexure}
              />
            ) : null}

            {/* anova */}
            {this.state.openAnv ? (
              <AddTable
                visible={this.state.openAnv}
                onClose={() => {
                  this.onCloseAnv()
                }}
                addAnova={this.addAnova}
              />
            ) : null}
            {/* Plates */}
            {this.state.openPlt ? (
              <AddTable
                visible={this.state.openPlt}
                onClose={() => {
                  this.onClosePlt()
                }}
                addPlates={this.addPlates}
              />
            ) : null}
            {/* FlowChart */}
            {this.state.openChrt ? (
              <AddTable
                visible={this.state.openChrt}
                onClose={() => {
                  this.onCloseChrt()
                }}
                addFlowChart={this.addFlowChart}
              />
            ) : null}
            {/* Diagram */}
            {this.state.openDgrm ? (
              <AddTable
                visible={this.state.openDgrm}
                onClose={() => {
                  this.onCloseDgrm()
                }}
                addDiagram={this.addDiagram}
              />
            ) : null}

            <Form.Item style={{ marginTop: 32 }}>
              <Button type="primary" htmlType="submit">
                SUBMIT
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading,
  categories: global.categories,
  search: router.location.search,
  pathname: router.location.pathname
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditArticle)

// Tags functions
// handleClose = removedTag => {
//   const tags = this.state.tags.filter(tag => tag !== removedTag)
//   console.log(tags)
//   this.setState({ tags })
// }

// showInput = () => {
//   this.setState({ inputVisible: true }, () => this.input.focus())
// }

// handleInputChange = e => {
//   this.setState({ inputValue: e.target.value })
// }

// handleInputConfirm = () => {
//   const { inputValue } = this.state
//   let { tags } = this.state
//   if (inputValue && tags.indexOf(inputValue) === -1) {
//     tags = [...tags, inputValue]
//   }
//   console.log(tags)
//   this.setState({
//     tags,
//     inputVisible: false,
//     inputValue: ''
//   })
// }
// saveInputRef = input => (this.input = input)
