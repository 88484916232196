import React from 'react'
import styles from './login.less'
import {
  Form, Icon, Input, Button, Checkbox, notification
} from 'antd'
import Request from '../../request'
import { showLoader, hideLoader } from '../../modules/actions'

import { push } from 'connected-react-router'
import { connect } from 'react-redux'

class ResetPasswordForm extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      uid: ''
    }
  }

  componentDidMount() {
    this.checkLink()
  }

  checkLink = async (props) => {
    const { dispatch } = this.props
    const urlParams = new URLSearchParams(window.location.search)
    const key = urlParams.get('key')
    console.log(key)
    let companyId = '6089603e9087340b9bdcbbb1'
    let x = await Request.checkResetLink({ key, companyId })
    if (x.error) {
      notification.error({
        message: 'Link Expire',
        description: x.message
      })
      // dispatch(push('/login'))
    } else {
      this.setState({ uid: x.uuidkey })
    }
    console.log(x)
  }

  handleSubmit = (e) => {
    const { dispatch } = this.props
    e.preventDefault()

    this.props.form.validateFields(async (err, values) => {
      if (values.password.length < 8 && values.confirmPassword.length < 8) {
        notification.error({
          message: 'Error Updating Password',
          description: 'Password must contain 8 characters'
        })
        return
      }
      if (!err) {
        dispatch(showLoader())
        let obj = {
          password: values.password,
          confirmPassword: values.confirmPassword,
          uuidkey: this.state.uid
        }

        let x = await Request.ResetPassword({ obj })

        dispatch(hideLoader())

        if (!x.error) {
          setTimeout(() => {
            dispatch(push('/'))
          }, 300)
        } else {
          notification.error({
            message: 'Error Updating Password',
            description: x.message
          })
        }
      }
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form
    const { loading } = this.props
    return (
      <Form onSubmit={this.handleSubmit} className={styles.main}>
        <Form.Item>
          {getFieldDecorator('password', {
            rules: [{ required: true, message: 'required' }]
          })(
            <Input.Password prefix={<Icon type='key' style={{ color: 'rgba(0,0,0,.25)' }} />}
                            placeholder='New Password' />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('confirmPassword', {
            rules: [{ required: true, message: 'required' }]
          })(
            <Input.Password prefix={<Icon type='key' style={{ color: 'rgba(0,0,0,.25)' }} />}
                            placeholder='Confirm Password' />
          )}
        </Form.Item>
        <Form.Item>
          <Button type='primary' loading={loading} htmlType='submit' className={styles.button}>
            Reset Password
          </Button>
        </Form.Item>
      </Form>
    )
  }
}

const WrappedResetPasswordForm = Form.create()(ResetPasswordForm)


const mapStateToProps = ({ counter, global }) => ({
  count: counter.count,
  loading: global.buttonLoading,
  pathname: global.pathname,
  search: global.search

})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedResetPasswordForm)
