import { notification } from 'antd'
import { emailTemplateUrl, singleTemplateUrl, sendTemplateEmailUrl } from '../apis'
import { getToken, customAxios as axios } from '../../../request'
import { showPageLoad, hidePageLoad } from '../../../modules/actions'

export const addEmailTemplate = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(emailTemplateUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const getAllEmailTemplate = (filters) => async (dispatch) => {
  // dispatch(showPageLoad())
  let config = {
    params: { ...filters },
    ...getToken()
  }
  let { data } = await axios.get(emailTemplateUrl(), config)
  // dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}


export const getSingleEmailTemplate = (id) => async (dispatch) => {
  console.log(id)
  let { data } = await axios.post(singleTemplateUrl(id), {}, getToken())
  console.log(data)
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data

}

export const updateTemplate = (valData, id) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.put(singleTemplateUrl(id), valData, getToken())
  console.log(data)
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  dispatch(hidePageLoad())
  return data

}

export const SendTemplateEmail = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(sendTemplateEmailUrl(), valData, getToken())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    notification.success({
      message: data.message || 'Success'
    })
  }
  dispatch(hidePageLoad())
  return data
}
