import React from 'react'
import PageHeaderWrapper from '../../components/PageHeaderWrapper'
import { FormUtils, TableComp } from 'sz-react-utils-lite'
import KeywordComponent from './keywordComponent'
import { useDispatch } from 'react-redux'
import { DefaultTablePagination } from '../../components/_utils/appUtils'

const Keyword = props => {
  return (
    <>
      <PageHeaderWrapper title={'Add Keyword'}>
        <KeywordComponent />
      </PageHeaderWrapper>
    </>
  )
}
export default Keyword
