import { combineReducers } from 'redux'
import counter from './counter'
import theme from './theme'
import global from './global'
import ArticleReducer from '../containers/article/reducers/article'

import { connectRouter } from 'connected-react-router'
import { createBrowserHistory as createHistory } from 'history'

export const history = createHistory()
export default combineReducers({
  theme,
  counter,
  global,
  ArticleReducer,
  router: connectRouter(history)
})
