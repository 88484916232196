import { apiUrl } from '../../../settings'

export const memberUrl = () => {
  return apiUrl + '/member'
}
export const singleMemberUrl = id => {
  return apiUrl + `/member/${id}`
}

export const getNextReviewerIdUrl = () => {
  return apiUrl + `/getNextReviewerId`
}

export const checkMemberEmailsUrl = () => {
  return apiUrl + `/checkMemberEmails`
}

export const getSingleMemberURL = id => {
  return apiUrl + `/getSingleMember/${id}`
}

export const updateMemberUrl = id => {
  return apiUrl + `/editMember/${id}`
}

export const checkMemberEmailsUpdateUrl = () => {
  return apiUrl + `/checkMemberEmailsUpdate`
}
