import { notification } from 'antd'
import { hidePageLoad, showPageLoad } from '../../modules/actions'
import { customAxios as axios, getToken } from '../../request'

import { searchKeywordUrl } from './api/api'

export const searchKeyword = async key => {
  let config = {
    params: key,
    ...getToken()
  }

  let { data } = await axios.get(searchKeywordUrl(), config)
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
