import {
  Badge,
  Button,
  Card,
  Icon,
  Input,
  notification,
  Switch,
  Table,
  Tooltip
} from 'antd'
import _ from 'lodash'
import moment from 'moment'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { Countries } from '../../../components/_utils/appUtils'
import Request from '../../../request'
import { getPushPathWrapper, getUrlParams } from '../../../routes'
import { getAllUsersFxn } from '../actions'
import EditAdminUser from '../editAdmin'
import ActivityListAdmin from './articleActivityDrawer'

const regExpFilters = [
  'name',
  'local.email',
  'address',
  'designation',
  'mobileNo',
  'userName'
]

class allAdminUsers extends Component {
  state = {
    open: false,
    authorData: [],
    editVal: {},
    indexVal1: '',
    activityListOpen: false,
    adminUserId: ''
  }

  constructor(props) {
    super(props)

    this.table = React.createRef()
  }

  editSuccessfull = () => {
    this.setState({ open: false })

    this.table.current.reload()
  }

  loadAuthors = params => {
    let { dispatch } = this.props
    return new Promise(async resolve => {
      let data = await dispatch(
        getAllUsersFxn({
          ...params,
          userType: 'Admin',
          regExFilters: regExpFilters
        })
      )
      resolve(data)
    })
  }
  showEditAdmin = (val, index) => {
    this.setState({ open: true, editVal: val, indexVal1: index })
  }
  showActivityList = (val) => {
    this.setState({ activityListOpen: true, adminUserId: val._id, name: val.name })
  }

  onClose = () => {
    this.setState({
      open: false
    })
  }

  componentDidMount() {
    // let data = getUrlParams('users.editAdmin', this.props.pathname)
    // if (!!data) {
    //   let { id } = data
    //   this.setState({
    //     edit: true,
    //     id: id
    //   })
    //   this.setFormValues(id)
    // }
  }

  render() {
    const { dispatch } = this.props

    const columns = [
      {
        title: 'Name',
        key: 'name',
        dataIndex: 'name',
        filterRegex: true,
        searchTextName: 'name',
        width: '120px'
      },

      {
        title: 'Email',
        dataIndex: 'local.email',
        key: 'local.email',
        searchTextName: 'email',
        filterByRegex: true,
        width: '120px'
      },

      {
        title: 'Address',
        dataIndex: 'address',
        key: 'address',
        searchTextName: 'address',
        filterByRegex: true,
        width: '120px'
      },

      {
        title: 'Designation',
        dataIndex: 'designation',
        key: 'designation',
        searchTextName: 'designation',
        filterByRegex: true,
        width: '120px'
      },
      {
        title: 'Mobile No',
        dataIndex: 'mobileNo',
        key: 'mobileNo',
        searchTextName: 'mobileNo',
        filterByRegex: true,
        width: '120px'
      },
      {
        title: 'Action',
        name: 'action',
        key: 'action',
        dataIndex: 'action',
        width: '120px',
        render: (item, record, indexValue) => {
          return (
            <>
              <Tooltip title='Edit  Admin-User' style={{ marginRight: '20px' }}>
                <Button
                  shape='circle'
                  className='roundSmallBtn'
                  onClick={e => {
                    this.showEditAdmin(record, indexValue)
                  }}>
                  Edit
                </Button>
              </Tooltip>
              <Button
                onClick={() => {
                  this.showActivityList(record)
                }}
                className='roundSmallBtn'>
                Article Activities
              </Button>
            </>
          )
        }
      }
    ]
    return (
      <PageHeaderWrapper
        title={'All Admin Users'}
        content={[
          <Button
            key='1'
            type='primary'
            onClick={() => {
              dispatch(getPushPathWrapper('users.adminUser'))
            }}>
            <Icon type={'plus'} />
            Add Admin User
          </Button>
        ]}>
        <Card bordered={true}>
          <TableComp
            ref={this.table}
            extraProps={{ scroll: { x: 1350 } }}
            columns={columns}
            apiRequest={this.loadAuthors}
          />
        </Card>
        {this.state.open ? (
          <EditAdminUser
            visible={this.state.open}
            editVal={this.state.editVal}
            indexVal={this.state.indexVal1}
            onClose={this.onClose}
            editSuccessfull={() => {
              this.editSuccessfull()
            }}
          />
        ) : null}
        {this.state.activityListOpen && this.state.adminUserId ? (
          <ActivityListAdmin
            visible={this.state.activityListOpen}
            onClose={() => {
              this.setState({ activityListOpen: false })
            }}
            operatedByUserId={this.state.adminUserId}
            name={this.state.name}
          />
        ) : null}
      </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global }) => ({
  categories: global.categories
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(allAdminUsers)
