import { Button, Card, Form, notification } from 'antd'
import _ from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormUtils, TableComp } from 'sz-react-utils-lite'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
  ChooseEmailModal,
  chooseEmailModalFxn,
  DisplayDate
} from '../../../components/_utils/appUtils'
import Request from '../../../request'
import { SendTemplateEmail } from '../../email/actions'
import { getAllArticle } from '../actions'
import ArticleActivityList from '../articleView/ArticleActivityList'
import { ArticleFilters } from '../articleView/articlePages'
import CommentsToAuthorDrawer from '../drawers/commentsToAuthorDrawer'
import styles from './styles.less'

const publishArr = [
  { value: true, display: 'Publish', _id: 'publish' },
  { value: false, display: 'Un-Publish', _id: 'unpublish' }
]

@Form.create()
class AllArticle extends Component {
  state = {
    edit: false,
    journalList: [],
    volumeList: [],
    issueList: [],
    isPublished: null,
    slugJournal: null,
    slugVolume: null,
    slugIssue: null,
    articleId: null,
    filters: {},
    visibleDrawer: false,
    article: {},
    total: '',
    visibleActivityList: false
  }

  constructor(props) {
    super(props)
    this.table = React.createRef()
  }

  apiRequest = params => {
    return new Promise(async resolve => {
      let {
        dispatch,
        form: { getFieldValue }
      } = this.props
      let obj = {
        ...params,
        ...this.state.filters,
        articleStatus: 'Reviewed Comments'
      }
      let regExpFilters = []

      let data = await dispatch(
        getAllArticle({ ...obj, regExFilters: regExpFilters })
      )
      console.log(data)
      this.setState({ total: data.total })
      resolve(data)
    })
  }

  reload = () => {
    this.table.current.reload()
  }
  reloadTable = data => {
    if (data.fromDate && data.toDate) {
      data['submittedDate'] = {
        $gte: data.fromDate,
        $lte: data.toDate
      }
      delete data['fromDate']
      delete data['toDate']
    }
    this.setState({ filters: data }, () => {
      this.reload()
    })
  }

  deleteArticle = async data => {
    let resp = await Request.deleteArticle(data)
    this.reload()
  }

  getJournalList = async () => {
    let { data } = await Request.journalName()
    let journalList = []
    _.each(data, item => {
      journalList.push({
        _id: item.slugJournal,
        value: item.slugJournal,
        display: item.name
      })
    })
    this.setState({
      journalList,
      volumeList: [],
      issueList: []
    })
  }

  getVolumeList = async data => {
    let obj = {
      slugJournal: data
    }
    let resp = await Request.journalVolume(obj)
    let volumeList = []
    _.each(resp, item => {
      volumeList.push({
        _id: item.slugVolume,
        value: item.slugVolume,
        display: item.name
      })
    })
    this.setState({
      volumeList,
      issueList: []
    })
  }

  getIssueList = async data => {
    let obj = {
      slugJournal: this.props.form.getFieldValue('slugJournal'),
      slugVolume: data
    }
    let resp = await Request.journalIssue(obj)
    let issueList = []
    _.each(resp, item => {
      issueList.push({
        _id: item.slugIssue,
        value: item.slugIssue,
        display: item.name
      })
    })
    this.setState({
      issueList
    })
  }

  componentDidMount() {
    this.getJournalList()
  }

  sendMailFxn = record => {
    let { dispatch } = this.props
    chooseEmailModalFxn({
      callback: async email => {
        let obj = {
          articleId: record._id,
          fromEmail: email,
          emailType: 'acknowledgement',
          template:
            record.processingCharges > 0
              ? 'acknowledgementWithCharges'
              : 'acknowledgementWithoutCharges',
          type: 'article'
        }
        let resp = await dispatch(
          SendTemplateEmail({
            email,
            id: record._id,
            obj,
            template: obj.template
          })
        )
        if (resp && resp.success) {
          notification.success({
            message: resp.message
          })
          this.reload()
        }
      }
    })
  }

  render() {
    const {
      dispatch,
      form: { getFieldDecorator, getFieldValue, setFieldsValue },
      loading
    } = this.props
    const { journalList, volumeList, issueList } = this.state

    const formProps = {
      getFieldDecorator,
      getFieldValue,
      loading
    }

    const columns = [
      {
        key: 'actions',
        width: 150,
        render: (val, record) => (
          <React.Fragment>
            {' '}
            <a className={'roundSmallBtn'} href={`/articleView/${record._id}`}>
              View Details
            </a>
            <Button
              className={'roundSmallBtn'}
              onClick={() =>
                this.setState({ visibleActivityList: true, article: record })
              }>
              Article Activities
            </Button>
          </React.Fragment>
        )
      },
      {
        title: 'Submitted Date',
        key: 'submittedDate',
        dataIndex: 'submittedDate',
        width: 100,
        render: item => {
          return item ? DisplayDate(item) : null
        }
      },
      {
        title: 'Article Id',
        key: 'articleNo',
        dataIndex: 'articleNo'
      },
      {
        title: 'Author Name',
        key: 'correspondingAuthor',
        dataIndex: 'correspondingAuthor'
      },
      {
        title: 'Reviewer',
        key: 'underReviewDetails',
        dataIndex: 'underReviewDetails',
        render: item => {
          return item && item.reviewerId && item.reviewerId.name
            ? item.reviewerId.name
            : ''
        }
      },
      {
        title: 'Send to Reviewer',
        key: 'underReviewDetails.sendDate',
        dataIndex: 'underReviewDetails.sendDate',
        width: 100,
        render: item => {
          return item ? DisplayDate(item) : null
        }
      },
      {
        title: 'Comments Received',
        key: 'underReviewDetails.receiveDate',
        dataIndex: 'underReviewDetails.receiveDate',
        width: 100,
        render: item => {
          return item ? DisplayDate(item) : null
        }
      },
      {
        title: 'Action',
        key: 'Action',
        render: (item, record) => {
          return (
            <React.Fragment>
              <Button
                className={'roundSmallBtn'}
                onClick={() =>
                  this.setState({ visibleDrawer: true, article: record })
                }>
                Comments to author
              </Button>
            </React.Fragment>
          )
        }
      }
    ]

    const fil = {
      labelCol: {
        xs: {
          span: 24
        },
        sm: {
          span: 24
        },
        md: {
          span: 24
        }
      },
      wrapperCol: {
        xs: {
          span: 24
        },
        sm: {
          span: 24
        },
        md: {
          span: 24
        }
      }
    }

    return (
      <PageHeaderWrapper title={`Reviewed Manuscript (${this.state.total})`}>
        <Card bordered={true}>
          <ArticleFilters
            callback={data => {
              this.reloadTable(data)
            }}
          />
          <TableComp
            ref={this.table}
            columns={columns}
            apiRequest={this.apiRequest}
          />
        </Card>
        {this.state.visibleDrawer ? (
          <CommentsToAuthorDrawer
            visible={this.state.visibleDrawer}
            articleId={this.state.article._id}
            article={this.state.article}
            Id={this.state.article._id}
            callback={this.reload}
            edit={false}
            onClose={() => this.setState({ visibleDrawer: false })}
          />
        ) : null}
        {this.state.visibleActivityList ? (
          <ArticleActivityList
            articleId={this.state.article._id}
            articleNo={this.state.article.articleNo}
            visible={this.state.visibleActivityList}
            onClose={() => this.setState({ visibleActivityList: false })}
          />
        ) : null}
      </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global }) => ({
  categories: global.categories
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllArticle)
