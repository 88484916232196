import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Icon,
  Input,
  notification,
  Popconfirm,
  Row,
  Tooltip
} from 'antd'
import _ from 'lodash'
import React, { Component, useState } from 'react'
import { connect } from 'react-redux'
import { FormUtils, TableComp } from 'sz-react-utils-lite'
import {
  Countries,
  DesignationList,
  DisciplineFilter,
  DisplayDate,
  MemberRatings,
  MemberStatus,
  MemberTypes
} from '../../../components/_utils/appUtils'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import Request from '../../../request'
import { getPushPathWrapper } from '../../../routes'
import {apiUrl} from '../../../settings'
import {DeleteReviewer, fullDeleteMemberFxn, ReActivateMember} from '../../article/actions'
import ViewMember from '../../article/articleView/viewMember'
import DeleteReviewerDrawer from '../../article/drawers/DeleteReviewerDrawer'
import MemberHistory from '../../article/reviewer/memberHistory'
import { getAllMember } from '../actions'
import styles from './styles.less'

const publishArr = [
  { value: true, display: 'Publish', _id: 'publish' },
  { value: false, display: 'Un-Publish', _id: 'unpublish' }
]

@Form.create()
class AllArticle extends Component {
  state = {
    edit: false,
    journalList: [],
    volumeList: [],
    issueList: [],
    isPublished: null,
    slugJournal: null,
    slugVolume: null,
    slugIssue: null,
    articleId: null,
    ratingList: [],
    total: '',
    memberDrawerVisible: false,
    reviewerDrawerVisible: false,
    reviewerId: '',
    reviewerData: {},
    checkDeleted: false
  }

  constructor(props) {
    super(props)
    this.table = React.createRef()
  }

  apiRequest = params => {
    return new Promise(async resolve => {
      let { dispatch } = this.props
      // if (this.state.checkDeleted) {
      //   params = { deleted: 'true' }
      // }
      let obj = {
        deleted: this.state.checkDeleted,
        ...params
        /*  isPublished: this.props.form.getFieldValue('isPublished') ? [this.props.form.getFieldValue('isPublished') == 'publish' ? true : false] : null,
          slugJournal: this.props.form.getFieldValue('slugJournal') ? [this.props.form.getFieldValue('slugJournal')] : null,
          slugVolume: this.props.form.getFieldValue('slugVolume') ? [this.props.form.getFieldValue('slugVolume')] : null,
          slugIssue: this.props.form.getFieldValue('slugIssue') ? [this.props.form.getFieldValue('slugIssue')] : null,
          articleId: this.props.form.getFieldValue('articleId') ? [this.props.form.getFieldValue('articleId')] : null*/
      }
      let regExpFilters = ['name', 'areaOfInterest']
      let data = await dispatch(
        getAllMember({ ...obj, regExFilters: regExpFilters })
      )
      this.setState({ total: data.total })
      resolve(data)
    })
  }

  reload = () => {
    this.table.current.reload()
  }

  getJournalList = async () => {
    let { data } = await Request.journalName()
    let journalList = []
    _.each(data, item => {
      journalList.push({
        _id: item.slugJournal,
        value: item.slugJournal,
        display: item.name
      })
    })
    this.setState({
      journalList,
      volumeList: [],
      issueList: []
    })
  }

  getVolumeList = async data => {
    let obj = {
      slugJournal: data
    }
    let resp = await Request.journalVolume(obj)
    let volumeList = []
    _.each(resp, item => {
      volumeList.push({
        _id: item.slugVolume,
        value: item.slugVolume,
        display: item.name
      })
    })
    this.setState({
      volumeList,
      issueList: []
    })
  }

  getIssueList = async data => {
    let obj = {
      slugJournal: this.props.form.getFieldValue('slugJournal'),
      slugVolume: data
    }
    let resp = await Request.journalIssue(obj)
    let issueList = []
    _.each(resp, item => {
      issueList.push({
        _id: item.slugIssue,
        value: item.slugIssue,
        display: item.name
      })
    })
    this.setState({
      issueList
    })
  }

  filterArticle = () => {
    this.apiRequest()
    this.reload()
  }

  componentDidMount() {
    this.getJournalList()
    let rating = MemberRatings()
    this.setState({ ratingList: rating })
  }

  events = {
    showMemberHistory: data => {
      this.setState({
        memberDrawerVisible: true,
        reviewerData: data,
        reviewerId: data._id
      })
    },
    hideMemberHistory: () => {
      this.setState({ memberDrawerVisible: false, reviewerId: '' })
    }
  }

  showDeletedMembers = e => {
    this.setState({ checkDeleted: !this.state.checkDeleted }, item => {
      this.table.current.reload()
    })
  }

  reActivateMember = async data => {
    let {dispatch} = this.props
    let {success} = await dispatch(ReActivateMember({id: data._id}))
    if (success) {
      this.table.current.reload()
    }
  }
  fullDeleteMember = async data => {
    let {dispatch} = this.props
    let {success} = await dispatch(fullDeleteMemberFxn({id: data._id}))
    if (success) {
      this.table.current.reload()
    }
  }

  render() {
    const {
      dispatch,
      form: {getFieldDecorator, getFieldValue},
      loading
    } = this.props
    const {journalList, volumeList, issueList, checkDeleted} = this.state

    const formProps = {
      getFieldDecorator,
      getFieldValue,
      loading
    }

    const columnsOld = [
      {
        key: 'actions',
        render: (val, record) => (
          <React.Fragment>
            <Tooltip title="Edit Details">
              {/*<Button className={styles.btn}
                    shape="circle"
                    onClick={() => {
                      dispatch(getPushPathWrapper('article.edit', { id: record._id }))
                    }} icon="edit"/>*/}
            </Tooltip>
          </React.Fragment>
        )
      },
      {
        title: 'Reviewer',
        key: 'reviewerId',
        dataIndex: 'reviewerId',
        render: (item, record) => {
          return (
            <div>
              Rev.Id : {item}
              <br />
              Status : {record.status}
              <br />
            </div>
          )
        }
      },
      {
        title: 'Rating',
        key: 'rating',
        dataIndex: 'rating',
        render: item => {
          return `${item} star`
        }
      },
      {
        title: 'Journals',
        key: 'journals',
        dataIndex: 'journals',
        render: item => {
          return (
            <ul>
              {item.map((item1, key) => {
                return <li key={key}>{item1}</li>
              })}
            </ul>
          )
        }
      },
      {
        title: 'Name',
        key: 'name',
        // dataIndex: 'name',
        filterRegex: true,
        searchTextName: 'name'
      },
      {
        title: 'Designation',
        key: 'designation',
        dataIndex: 'designation',
        searchTextName: 'designation'
      },
      {
        title: 'Email',
        key: 'email',
        dataIndex: 'email',
        searchTextName: 'email'
      },
      {
        title: 'Area Of Interest',
        key: 'areaOfInterest',
        dataIndex: 'areaOfInterest',
        searchTextName: 'areaOfInterest'
      },

      {
        title: 'Certificate Sent Date',
        key: 'certificateSentDate',
        dataIndex: 'certificateSentDate',
        render: val => {
          return <div>{val ? DisplayDate(val) : null}</div>
        }
      }
    ]

    const columns = [
      {
        title: 'Action',
        key: 'actions',
        width: 140,
        render: (val, record) => (
          <React.Fragment>
            <ViewMember revData={record} revId={record._id}>
              <Button className={'roundSmallBtn'}>View Member</Button>
            </ViewMember>
            <br />
            <Button
              onClick={() => {
                this.events.showMemberHistory(record)
              }}
              className={'roundSmallBtn'}>
              View History
            </Button>
            <br />
            {checkDeleted ? (
                <>
                  <Popconfirm
                      placement="topLeft"
                      title={`Are you sure you want to Re-activate Member?`}
                      onConfirm={event => this.reActivateMember(record)}
                      okText="Yes"
                      cancelText="No">
                    <Button className={'roundSmallBtn'}>Re-Activate</Button>
                  </Popconfirm>


                  <Popconfirm
                      placement="topLeft"
                      title={`Are you sure you want to Permanent Delete Member?`}
                      onConfirm={event => this.fullDeleteMember(record)}
                      okText="Yes"
                      cancelText="No">
                    <Button className={'roundSmallBtn white'} type={'danger'}>Permanent Delete</Button>
                  </Popconfirm>

                </>
            ) : (
              <>
                {record.status == 'Available' ? (
                  <Button
                    className={'roundSmallBtn'}
                    onClick={() => {
                      this.setState({
                        reviewerDrawerVisible: true,
                        reviewerId: record._id
                      })
                    }}>
                    Delete
                  </Button>
                ) : null}
              </>
            )}
          </React.Fragment>
        )
      },
      {
        title: 'Name',
        key: 'name',
        dataIndex: 'name',
        filterRegex: true,
        searchTextName: 'name',
        width: '120px',
        render: (item, record) => {
          return (
            <div>
              {item}
              <a
                className={'linkIcon'}
                onClick={() => {
                  dispatch(
                    getPushPathWrapper('member.edit', { id: record._id })
                  )
                }}>
                {' '}
                <Icon type={'edit'} />
              </a>
            </div>
          )
        }
      },
      {
        title: 'Designation',
        key: 'designation',
        dataIndex: 'designation',
        width: '130px',
        filters: DesignationList.map(x => ({ text: x, value: x }))
      },
      {
        title: 'Area Of Interest',
        key: 'areaOfInterest',
        dataIndex: 'areaOfInterest',
        filterRegex: true,
        searchTextName: 'areaOfInterest',
        width: '120px'
      },
      {
        title: 'Country',
        key: 'country',
        dataIndex: 'country',
        width: '100px',
        filters: Countries.map(x => ({ text: x.name, value: x.name }))
      },
      {
        title: 'Email',
        key: 'email',
        dataIndex: 'email',
        searchTextName: 'email',
        width: '150px'
      },
      {
        title: 'Reviewer Id',
        key: 'reviewerId',
        dataIndex: 'reviewerId',
        searchTextName: 'email',
        width: '100px'
      },

      {
        title: 'Member Type',
        key: 'memberType',
        dataIndex: 'memberType',
        width: '100px',
        filters: MemberTypes.map(x => ({ text: x, value: x }))
      },
      {
        title: 'Rating',
        key: 'rating',
        dataIndex: 'rating',
        width: '90px',
        render: item => {
          return `${item} star`
        },
        filters: this.state.ratingList.map(x => ({
          text: x.label,
          value: x.value
        }))
      },
      {
        title: 'Status',
        key: 'status',
        dataIndex: 'status',
        width: '80px',
        render: item => {
          return item
        },
        filters: MemberStatus.map(x => ({ text: x, value: x }))
      } /*
      {
        title: 'Date of Enter',
        key: 'createdAt',
        dataIndex: 'createdAt',
        width: '100px',
        render: item => {
          return item ? DisplayDate(item) : null
        },
        searchDateName: 'createdAt'
      },*/,
      {
        title: 'Discipline',
        key: 'category',
        dataIndex: 'category',
        width: '100px',
        filters: DisciplineFilter.map(x => ({ text: x, value: x }))
      },
      {
        title: 'Journals',
        key: 'journals',
        dataIndex: 'journals',
        //width: '130px',
        render: item => {
          return (
            <ul>
              {item && item.length
                ? item.map((item1, key) => {
                    return <li key={key}>{item1}</li>
                  })
                : null}
            </ul>
          )
        },
        filters: journalList.map(x => ({ text: x.display, value: x.display }))
      }
    ]

    const fil = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 }
      }
    }

    return (
      <PageHeaderWrapper
        title={`All Member (${this.state.total})`}
        content={[
          <Button
            key="1"
            type="primary"
            onClick={() => {
              dispatch(getPushPathWrapper('member.addMember'))
            }}>
            <Icon type={'plus'} />
            Add Member
          </Button>
        ]}>
        <Card bordered={true}>
          <Form
            onSubmit={this.handleSubmit}
            hideRequiredMark
            className={'inputForm'}>
            {/* <Row gutter={16}>
              {inputSchma.fields.map((item, key) => {
                return (
                  <Col span={4} key={key}>
                    <GetEachFormFields getFieldDecorator={getFieldDecorator}
                                       item={item} formItemLayout={formItemLayout}/>
                  </Col>
                )
              })}
              <Col span={4}>
                <Form.Item style={{ marginTop: 40 }}>
                  <Button type="primary" onClick={() => {
                    this.filterArticle()
                  }}>
                    FILTER
                  </Button>
                </Form.Item>
              </Col>
            </Row>*/}
          </Form>
          <Checkbox
            value={this.state.checkDeleted}
            checked={this.state.checkDeleted}
            onChange={e => this.showDeletedMembers(e)}
            style={{ float: 'right', marginBottom: 10 }}>
            View deleted members
          </Checkbox>
          <TableComp
            extraProps={{ scroll: { x: 1400 } }}
            ref={this.table}
            columns={columns}
            apiRequest={this.apiRequest}
          />
        </Card>

        {this.state.memberDrawerVisible ? (
          <MemberHistory
            visible={this.state.memberDrawerVisible}
            //articleId={article._id}
            reviewerId={this.state.reviewerId}
            data={this.state.reviewerData}
            onClose={this.events.hideMemberHistory}
          />
        ) : null}

        {this.state.reviewerDrawerVisible ? (
          <DeleteReviewerDrawer
            visible={this.state.reviewerDrawerVisible}
            reviewerId={this.state.reviewerId}
            onClose={() => {
              this.setState({ reviewerDrawerVisible: false })
              this.reload()
            }}
          />
        ) : null}
      </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global }) => ({
  categories: global.categories
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllArticle)
