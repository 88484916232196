import { notification } from 'antd'
import {
  hidePageLoad,
  hideProgressPageLoad,
  showPageLoad,
  showProgressPageLoad
} from '../../../../modules/actions'
import { customAxios as axios, getToken } from '../../../../request'
import { addAdminUserUrl } from '../apis'
export const addAdminUser = valData => async dispatch => {
  console.log(valData, 'valllllladmin')
  dispatch(showPageLoad())
  let { data } = await axios.post(addAdminUserUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }

  return data
}
