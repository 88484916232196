import React, { useEffect, useState, useRef } from 'react'
import { Button, Card, Collapse, Form, Popconfirm, Table, Tooltip } from 'antd'
import { chooseEmailModalFxn, DisplayDate, GetDownloadLink } from '../../../components/_utils/appUtils'
import { TableComp } from 'sz-react-utils-lite'
import _ from 'lodash'
import ReceiveDateDrawer from '../reviewer/editSelectReceiveDate'
import { ChooseEmailModal } from '../../../components/_utils/appUtils'
import { RowTable } from './RowTable'
import CommentsToAuthorDrawer from '../drawers/commentsToAuthorDrawer'
import { ResendComments } from '../actions'
import { connect } from 'react-redux'
import { useDispatch } from 'react-redux'
import RevisedArticleDrawer from '../drawers/RevisedArticleDrawer'

const { Panel } = Collapse

const commentsToAuthor = (props) => {
  let { article } = props
  let { underReviewDetails, articleStatus, sendToAuthorDate } = article
  let [receiveDateDrawerVisible, setReceiveDateDrawerVisible] = useState(false)
  let [reviewerId, setReviewerId] = useState('')
  let headerTitle = `Comments to Author ${sendToAuthorDate ? `(${DisplayDate(sendToAuthorDate)})` : ''}`
  let [visibleCommentToAuthorDrawer, setVisibleCommentToAuthorDrawer] = useState(false)
  let [visibleRevisedArticle, setVisibleRevisedArticle] = useState(false)
  let dispatch = useDispatch()
  const table = useRef()
  let events = {
    reloadTable: () => {
      table.current.reload()
    },
    showAndHideReceiveDate: (value, id = '') => {
      setReceiveDateDrawerVisible(value)
      setReviewerId(id)
    },
    show: () => {
      setVisibleCommentToAuthorDrawer(true)
    },
    hide: () => {
      setVisibleCommentToAuthorDrawer(false)
    }
  }

  let BtnAction = (event) => {
    events.show()
    event.stopPropagation()
  }

  const handleResendComments = (e) => {
    const { form } = props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      chooseEmailModalFxn({
        title: 'Send Comments to Author',
        callback: async (email) => {
          valData.articleId = article._id
          valData.id = article._id
          valData.fromEmail = email
          let { success, data } = await dispatch(ResendComments(valData))
          dispatch({ type: 'START_LOAD_ARTICLE' })
        }
      })
    })
  }
  let getExtraButton = () => {
    return (
      <React.Fragment>
        <Button type={'primary'} size={'small'} onClick={(e) => {
          handleResendComments(e)
          e.stopPropagation()
        }}>Resend Comments</Button>
        {' '}
        {underReviewDetails && underReviewDetails.receiveDate && !sendToAuthorDate ?
          <Button type={'primary'} size={'small'} onClick={BtnAction}>Add</Button> : null}
        {sendToAuthorDate ?
          <Button type={'primary'} size={'small'} onClick={BtnAction}>Edit</Button> : null}
      </React.Fragment>
    )
  }

  return (
    <div>
      <Collapse expandIconPosition={'right'} defaultActiveKey={['1']}>
        <Panel header={headerTitle} key='1' extra={getExtraButton()}>
          <div className={'tableBox striped'}>
            <RowTable title={'Date'} value={
              sendToAuthorDate && DisplayDate(sendToAuthorDate)
            } />
            <RowTable title={'Set Article Status'} value={
              article.statusType ? article.statusType : null
            } />
            <RowTable title={'Reminder Date'} value={
              article.reminderDateCommentsToAuthor ? DisplayDate(article.reminderDateCommentsToAuthor) : null
            } />
          </div>
        </Panel>
      </Collapse>
      {receiveDateDrawerVisible ? <ReceiveDateDrawer
        visible={receiveDateDrawerVisible}
        reviewerId={reviewerId}
        article={article}
        onClose={() => events.showAndHideReceiveDate(false)}
      /> : null}
      {visibleCommentToAuthorDrawer ? <CommentsToAuthorDrawer
        visible={visibleCommentToAuthorDrawer}
        articleId={article._id}
        article={article}
        edit={true}
        onClose={() => events.hide()}
      /> : null}
    </div>
  )
}

// export default commentsToAuthor

const WrappedForm = Form.create()(commentsToAuthor)

export default WrappedForm
