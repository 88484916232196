import { Button, Card, Col, Drawer, Form, Input, notification } from 'antd'
import _ from 'lodash'
import React from 'react'
import { CustomCkEditor } from '../../components/_utils/appUtils'

@Form.create()
class EditInformation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      title: '',
      menuHeading: '',
      description: '',
      loading: false
    }
  }

  componentDidMount() {
    this.setState({
      ...this.props.editInfo
    })
  }

  submitForm = () => {
    if (!this.state.title) {
      notification.warning({
        message: 'Enter title.'
      })
      return
    }
    if (!this.state.menuHeading) {
      notification.warning({
        message: 'Enter Menu Heading.'
      })
      return
    }
    if (!this.state.description) {
      notification.warning({
        message: 'Enter content.'
      })
      return
    }
    if (this.props.editInfo.title == this.state.title) {
      if (this.props.editInfo) {
        this.props.updateInfo(this.state)
      }
      this.props.onClose()
    } else {
      let newData = _.find(this.props.arrayData, item => {
        return item.title == this.state.title
      })

      if (newData == undefined) {
        if (this.props.editInfo) {
          this.props.updateInfo(this.state)
        }
        this.props.onClose()
      } else {
        notification.error({
          message: 'Title already exists, enter unique title.'
        })
      }
    }
  }

  render() {
    let { visible, onClose } = this.props
    return (
      <>
        <Drawer
          title={'Update Information'}
          width={'70%'}
          visible={visible}
          maskClosable={true}
          placement="right"
          onClose={onClose}>
          <Card>
            <Form hideRequiredMark className={'inputForm'}>
              <Col span={24} className="flexColumn">
                <Form.Item label="Enter Title" name="title" required>
                  <Input
                    value={this.state.title}
                    placeholder="Enter title..."
                    onChange={e => {
                      this.setState({
                        title: e.target.value
                      })
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="Enter Menu Heading"
                  name="menuHeading"
                  required>
                  <Input
                    value={this.state.menuHeading}
                    placeholder="Enter Menu Heading..."
                    onChange={e => {
                      this.setState({
                        menuHeading: e.target.value
                      })
                    }}
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <CustomCkEditor
                  html={this.state.description}
                  // basic={true}
                  height={400}
                  title={'Content'}
                  onChange={value => {
                    this.setState({
                      description: value
                    })
                  }}
                />
              </Col>
              <Col span={6}>
                <Form.Item>
                  <Button
                    type="primary"
                    onClick={() => {
                      this.submitForm()
                    }}>
                    Update Information
                  </Button>
                </Form.Item>
              </Col>
            </Form>
          </Card>
        </Drawer>
      </>
    )
  }
}

export default EditInformation
