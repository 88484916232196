import { Button, Card, Form, Icon, Input, notification, Tooltip } from 'antd'
import _ from 'lodash'
import React, { Component } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { connect } from 'react-redux'
import { FormUtils, TableComp } from 'sz-react-utils-lite'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
  ChooseEmailModal,
  chooseEmailModalFxn,
  DisplayDate,
  formItemLayout
} from '../../../components/_utils/appUtils'
import Request from '../../../request'
import { getPushPathWrapper } from '../../../routes'
import { SendTemplateEmail } from '../../email/actions'
import { commentToAuthor, getAllArticle, ResendComments } from '../actions'
import ArticleActivityList from '../articleView/ArticleActivityList'
import { ArticleFilters } from '../articleView/articlePages'
import RevisedArticleDrawer from '../drawers/RevisedArticleDrawer'
const publishArr = [
  { value: true, display: 'Publish', _id: 'publish' },
  { value: false, display: 'Un-Publish', _id: 'unpublish' }
]

@Form.create()
class CommentsToAuthor extends Component {
  state = {
    edit: false,
    journalList: [],
    volumeList: [],
    issueList: [],
    isPublished: null,
    slugJournal: null,
    slugVolume: null,
    slugIssue: null,
    articleId: null,
    filters: {},
    visibleRevisedArticle: false,
    article: {},
    total: '',
    visibleActivityList: false
  }

  constructor(props) {
    super(props)
    this.table = React.createRef()
  }

  apiRequest = params => {
    return new Promise(async resolve => {
      let {
        dispatch,
        form: { getFieldValue }
      } = this.props
      let obj = {
        ...params,
        ...this.state.filters,
        articleStatus: 'Comments To Author'
      }
      let regExpFilters = []

      let data = await dispatch(
        getAllArticle({ ...obj, regExFilters: regExpFilters })
      )
      this.setState({ total: data.total })
      resolve(data)
    })
  }

  reload = () => {
    this.table.current.reload()
  }
  reloadTable = data => {
    if (data.fromDate && data.toDate) {
      data['submittedDate'] = {
        $gte: data.fromDate,
        $lte: data.toDate
      }
      delete data['fromDate']
      delete data['toDate']
    }
    this.setState({ filters: data }, () => {
      this.reload()
    })
  }

  deleteArticle = async data => {
    let resp = await Request.deleteArticle(data)
    this.reload()
  }

  getJournalList = async () => {
    let { data } = await Request.journalName()
    let journalList = []
    _.each(data, item => {
      journalList.push({
        _id: item.slugJournal,
        value: item.slugJournal,
        display: item.name
      })
    })
    this.setState({
      journalList,
      volumeList: [],
      issueList: []
    })
  }

  getVolumeList = async data => {
    let obj = {
      slugJournal: data
    }
    let resp = await Request.journalVolume(obj)
    let volumeList = []
    _.each(resp, item => {
      volumeList.push({
        _id: item.slugVolume,
        value: item.slugVolume,
        display: item.name
      })
    })
    this.setState({
      volumeList,
      issueList: []
    })
  }

  getIssueList = async data => {
    let obj = {
      slugJournal: this.props.form.getFieldValue('slugJournal'),
      slugVolume: data
    }
    let resp = await Request.journalIssue(obj)
    let issueList = []
    _.each(resp, item => {
      issueList.push({
        _id: item.slugIssue,
        value: item.slugIssue,
        display: item.name
      })
    })
    this.setState({
      issueList
    })
  }

  componentDidMount() {
    this.getJournalList()
  }

  sendMailFxn = record => {
    let { dispatch } = this.props
    chooseEmailModalFxn({
      callback: async email => {
        let obj = {
          articleId: record._id,
          fromEmail: email,
          emailType: 'ResendComments',
          template: 'commentsToAuthorTemplate',
          type: 'article'
        }
        let resp = await dispatch(
          SendTemplateEmail({
            email,
            id: record._id,
            obj,
            template: obj.template
          })
        )
        if (resp && resp.success) {
          this.reload()
        } else {
          notification.error({
            message: resp.message
          })
        }
      }
    })
  }

  handleSubmit = (record, e) => {
    const { form, onClose, dispatch } = this.props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      chooseEmailModalFxn({
        title: 'Send Comments to Author',
        callback: async email => {
          valData.articleId = record._id
          valData.id = record._id
          valData.fromEmail = email
          let { success, data } = await dispatch(ResendComments(valData))
          dispatch({ type: 'START_LOAD_ARTICLE' })
        }
      })
    })
  }

  render() {
    const {
      dispatch,
      form: { getFieldDecorator, getFieldValue, setFieldsValue },
      loading
    } = this.props
    const { journalList, volumeList, issueList } = this.state

    const formProps = {
      getFieldDecorator,
      getFieldValue,
      loading
    }

    const columns = [
      {
        key: 'actions',
        title: 'Article Id',
        width: '85px',
        render: (val, record) => (
          <Tooltip title="Edit Details">
            <a href={`/articleView/${record._id}`}>{record.articleNo}</a>
            {/*<a*/}
            {/*  onClick={() => {*/}
            {/*    dispatch(getPushPathWrapper('articleView', { id: record._id }))*/}
            {/*  }}>*/}
            {/*  */}
            {/*</a>*/}
          </Tooltip>
        )
      },
      {
        title: 'Submitted Date',
        key: 'submittedDate',
        dataIndex: 'submittedDate',
        width: 100,
        render: item => {
          return item ? DisplayDate(item) : null
        }
      },
      {
        title: 'ManuScript Title',
        key: 'manuScriptTitle',
        dataIndex: 'manuScriptTitle',
        render: item => {
          return item ? ReactHtmlParser(item) : null
        }
      },
      {
        title: 'Comments Received',
        key: 'underReviewDetails.receiveDate',
        dataIndex: 'underReviewDetails.receiveDate',
        width: 100,
        render: item => {
          return item ? DisplayDate(item) : null
        }
      },
      {
        title: 'Comments Send',
        key: 'sendToAuthorDate',
        dataIndex: 'sendToAuthorDate',
        width: 100,
        render: item => {
          return item ? DisplayDate(item) : null
        }
      },
      {
        title: 'Resend Comments ',
        key: 'resendCommentDate',
        width: 100,
        dataIndex: 'resendCommentDate',
        render: item => {
          return item ? DisplayDate(item) : null
        }
      },
      {
        title: 'Reminder',
        key: 'reminderDateCommentsToAuthor',
        width: 100,
        dataIndex: 'reminderDateCommentsToAuthor',
        render: item => {
          return item ? DisplayDate(item) : null
        }
      },
      {
        title: 'Action',
        key: 'Action',
        render: (item, record) => {
          return (
            <>
              {/*<ChooseEmailModal id={record._id} template={'resendComments'} obj={{ type: 'article' }}>*/}
              <Button
                onClick={e => this.handleSubmit(record, e)}
                className={'roundSmallBtn'}>
                Resend Comments
              </Button>
              {/*</ChooseEmailModal>*/}
              <Button
                className={'roundSmallBtn'}
                onClick={() =>
                  this.setState({
                    visibleRevisedArticle: true,
                    article: record
                  })
                }>
                Revised Article
              </Button>
              <Button
                className={'roundSmallBtn'}
                onClick={() =>
                  this.setState({ visibleActivityList: true, article: record })
                }>
                Article Activities
              </Button>
            </>
          )
        }
      }
    ]

    const fil = {
      labelCol: {
        xs: {
          span: 24
        },
        sm: {
          span: 24
        },
        md: {
          span: 24
        }
      },
      wrapperCol: {
        xs: {
          span: 24
        },
        sm: {
          span: 24
        },
        md: {
          span: 24
        }
      }
    }

    return (
      <PageHeaderWrapper title={`Comments To Author (${this.state.total})`}>
        <Card bordered={true}>
          <ArticleFilters
            callback={data => {
              this.reloadTable(data)
            }}
          />
          <TableComp
            ref={this.table}
            columns={columns}
            apiRequest={this.apiRequest}
          />
        </Card>
        {this.state.visibleRevisedArticle ? (
          <RevisedArticleDrawer
            visible={this.state.visibleRevisedArticle}
            articleId={this.state.article._id}
            article={this.state.article}
            Id={this.state.article._id}
            callback={this.reload}
            edit={false}
            onClose={() => this.setState({ visibleRevisedArticle: false })}
          />
        ) : null}
        {this.state.visibleActivityList ? (
          <ArticleActivityList
            articleId={this.state.article._id}
            articleNo={this.state.article.articleNo}
            visible={this.state.visibleActivityList}
            onClose={() => this.setState({ visibleActivityList: false })}
          />
        ) : null}
      </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global }) => ({
  categories: global.categories
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommentsToAuthor)
