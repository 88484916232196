import React, {useEffect, useState} from 'react'
import {Button, Card, Drawer, Select} from 'antd'
import {Timeline} from 'antd';
import {useDispatch} from "react-redux";
import {onlineSubmissionActivityFxn} from "../actions";
import {displayDateWithtime} from "../../../components/_utils/appUtils";

const HistoryDrawer = (props) => {
    let dispatch = useDispatch()
    let {visible, onClose, onlineSubmissionId} = props;
    let [activities, setActivities] = useState([])
    let loadActivity = async () => {
        let obj = {
            results: 100,
            count: 100,
            onlineSubmissionId
        }
        let {data} = await dispatch(onlineSubmissionActivityFxn(obj))
        setActivities(data);
    }

    useEffect(() => {
        loadActivity()
    }, [])
    return (
        <Drawer
            title={"Online Submission Activities"}
            width={'35%'}
            onClose={onClose}
            visible={visible}
            bodyStyle={{paddingBottom: 80}}
            footer={
                <div className={'alignRight'}>
                    <Button onClick={onClose} style={{marginRight: 8}}>
                        Cancel
                    </Button>
                </div>
            }>
            <Card>
                <Timeline>
                    {activities && activities.length ? activities.map((item) => {
                        return (
                            <>
                                <Timeline.Item className={'timeline-block'}>
                                    {item.details}
                                    <br/>
                                    <strong className={'date'}>
                                        {displayDateWithtime(item.addedOn)}
                                    </strong>
                                </Timeline.Item>
                            </>
                        )
                    }) : null}
                </Timeline>
            </Card>
        </Drawer>
    )
}
export default HistoryDrawer
