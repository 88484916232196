import { Card } from 'antd'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'

import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
  Countries,
  DefaultTablePagination
} from '../../../components/_utils/appUtils'
import { getFeedBacks } from '../actions'

const regExpFilters = [
  'feedBack',
  'email',
  'name',
  'journalName',
  'institueName'
]

class FeedBacks extends Component {
  constructor(props) {
    super(props)

    this.table = React.createRef()
  }

  loadFeedBacks = params => {
    return new Promise(async resolve => {
      let { dispatch } = this.props
      let obj = {
        ...params
      }
      let data = await dispatch(getFeedBacks({ ...obj }))
      resolve(data)
    })
  }

  render() {
    const { dispatch } = this.props

    const columns = [
      {
        title: 'Name ',
        key: 'name',
        sorter: true,
        dataIndex: 'name',
        searchTextName: 'name',
        filterByRegex: true,

        width: 130
      },
      {
        title: 'Institute Name ',
        key: 'instituteName',
        sorter: true,
        dataIndex: 'instituteName',
        searchTextName: 'instituteName',
        filterByRegex: true,

        width: 130
      },
      {
        title: 'Journal Name',
        key: 'journalName',

        dataIndex: 'journalName',
        searchTextName: 'journalName',
        filterByRegex: true,

        width: 130
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        searchTextName: 'email',
        filterByRegex: true,

        width: 130
      },

      {
        title: 'FeedBack',
        dataIndex: 'feedBack',
        key: 'feedBack',
        searchTextName: 'feedBack',
        filterByRegex: true,
        width: 130
      }
    ]
    return (
      <PageHeaderWrapper title={'All Institutes'}>
        <Card bordered={true}>
          <TableComp
            ref={this.table}
            extraProps={{ scroll: { x: 1000 } }}
            columns={columns}
            apiRequest={this.loadFeedBacks}
          />
        </Card>
      </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global }) => ({
  categories: global.categories
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FeedBacks)
