import React, { createElement } from 'react'
import classNames from 'classnames'
import { Button } from 'antd'
import config from './typeConfig'
import styles from './index.less'
import { push } from 'connected-react-router'
import { connect } from 'react-redux'

class Exception extends React.PureComponent {
  static defaultProps = {
    backText: 'back to home',
    redirect: '/'
  }

  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const {
      className,
      backText,
      linkElement = 'a',
      type,
      title,
      desc,
      img,
      actions,
      redirect,
      dispatch,
      ...rest
    } = this.props
    const pageType = type in config ? type : '404'
    const clsString = classNames(styles.exception, className)


    return (
      <div className={clsString} {...rest}>
        <div className={styles.imgBlock}>
          <div
            className={styles.imgEle}
            style={{ backgroundImage: `url(${img || config[pageType].img})` }}
          />
        </div>
        <div className={styles.content}>
          <h1>{title || config[pageType].title}</h1>
          <div className={styles.desc}>{desc || config[pageType].desc}</div>


          <div className={styles.actions}>
            {actions ||
            createElement(
              linkElement,
              {
                to: redirect,
                href: redirect
              },
              <Button type="primary">{backText}</Button>
            )}

            <Button onClick={() => {
              let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
              if (user && user._id) {
                dispatch({
                  type: 'LEAVE_SOCKET',
                  leaveRoom: true,
                  userId: user && user._id
                })
              }
              localStorage.removeItem('token')
              localStorage.removeItem('user')
              document.body.className = ''
              dispatch({
                type: 'SET_CURRENT_USER',
                user: null
              })
              dispatch(push('/login'))
              // window.location.reload();
            }} type="danger" style={{ marginLeft: 10 }}>Logout</Button>

          </div>


        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ counter, global }) => ({})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Exception)
