import React, { useEffect, useState, useRef } from 'react'
import { Button, Card, Collapse, Popconfirm, Table, Tooltip } from 'antd'
import { chooseEmailModalFxn, DisplayDate, GetDownloadLink } from '../../../components/_utils/appUtils'
import { RowTable } from './RowTable'
import ReactHtmlParser from 'react-html-parser'
import ApprovalArticleDrawer from '../drawers/ApprovalArticleDrawer'
import { SendTemplateEmail } from '../../email/actions'
import { useDispatch } from 'react-redux'

const { Panel } = Collapse
const ApprovedArticleDetails = (props) => {
  let { article } = props
  let {
    sendToAuthorDate,
    approvedArticleDate,
    invoice
  } = article
  let [setReceiveDateDrawerVisible] = useState(false)
  let [setReviewerId] = useState('')
  let headerTitle = `Approved ${approvedArticleDate ? `(${DisplayDate(approvedArticleDate)})` : ''}`
  let [ids, setIds] = useState({})
  let [visibleApprovedArticleDrawer, setVisibleApprovedArticleDrawer] = useState(false)
  let dispatch = useDispatch()
  const table = useRef()
  let events = {
    reloadTable: () => {
      table.current.reload()
    },
    showAndHideReceiveDate: (value, id = '') => {
      setReceiveDateDrawerVisible(value)
      setReviewerId(id)
    },
    show: () => {
      setVisibleApprovedArticleDrawer(true)
    },
    hide: () => {
      setVisibleApprovedArticleDrawer(false)
    }
  }       

  let BtnAction = (event) => {
    events.show()
    event.stopPropagation()
  }


  let resendApprovedArticle = (event) => {
    event.stopPropagation()
    chooseEmailModalFxn({
      callback: async (email) => {
        let obj = {
          id: article._id,
          fromEmail: email,
          template: 'approvedArticle',
          type: 'article',
          attachmentArr: ['invoice']
        }
        let resp = await dispatch(SendTemplateEmail({
          email: obj.fromEmail,
          id: article._id,
          obj,
          template: obj.template
        }))
        if (resp && resp.success) {
          dispatch({ type: 'START_LOAD_ARTICLE' })
        }
      }
    })
  }

  let getExtraButton = () => {
    return (
      <React.Fragment>
        {sendToAuthorDate && !approvedArticleDate ?
          <Button type={'primary'} size={'small'} onClick={BtnAction}>Add</Button> : null}
        {approvedArticleDate ?
          <Button type={'primary'} size={'small'} onClick={BtnAction}>Edit</Button> : null}

        {article.approvedArticleDate && article.articleStatus == 'Approved Article' ?
          <Button type={'primary'} size={'small'} onClick={resendApprovedArticle} className={'ml-5'}>Resend
            Approval</Button> : null}

      </React.Fragment>
    )
  }

  return (
    <div>
      <Collapse expandIconPosition={'right'} defaultActiveKey={['1']}>
        <Panel header={headerTitle} key='1' extra={getExtraButton()}>
          <div className={'tableBox striped'}>
            <RowTable title={'Date'} value={
              approvedArticleDate && DisplayDate(approvedArticleDate)
            }/>
            <RowTable title={'Approval Status'} value={
              article.approvalType ? article.approvalType : null
            }/>
            <RowTable title={'Special Note'} value={
              article.approvalSpecialNote ? ReactHtmlParser(article.approvalSpecialNote) : null
            }/>
            <RowTable title={'Approval Amount'} value={
              article.approvalAmount ? ReactHtmlParser(article.approvalAmount) : null
            }/>
            <RowTable title={'Uploaded Invoice'} value={
              invoice && invoice.path ?
                <a download className='downloadLink' target={'_blank'}
                   href={invoice.path}>Download</a> : 'No Attachment'
            }/>
          </div>
        </Panel>
      </Collapse>

      {visibleApprovedArticleDrawer ? <ApprovalArticleDrawer
        visible={visibleApprovedArticleDrawer}
        articleId={article._id}
        article={article}
        callback={() => console.log('done')}
        edit={approvedArticleDate ? true : false}
        onClose={() => events.hide()}
      /> : null}
    </div>
  )
}

export default ApprovedArticleDetails
