import { notification } from 'antd';
import { getToken, customAxios as axios } from '../../../request';
import { showPageLoad, hidePageLoad } from '../../../modules/actions';


import { addKeywordUrl, keywordListUrl, removeKeywordUrl } from '../api/api';


// add keywords
export const addKeyword = (valData) => async (dispatch) => {
    // console.log('valData', valData)
    dispatch(showPageLoad())
    let { data } = await axios.post(addKeywordUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}
// get all keywords
export const keywordList = (valData) => async (dispatch) => {
    let config = {
        params: { ...valData },
        ...getToken()
      }
    // dispatch(showPageLoad())
    let { data } = await axios.get(keywordListUrl(), config)
    // dispatch(hidePageLoad())
    return data
}

export const removeKeyword = (record) => async (dispatch) => {
    dispatch(showPageLoad())
    // console.log(record)
    let { data } = await axios.delete(removeKeywordUrl(record._id), getToken());
    dispatch(hidePageLoad())
    return data;
}
