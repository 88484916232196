import {
  Button,
  Card,
  Col,
  Drawer,
  Form,
  Input,
  notification,
  Row,
  Select
} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import React from 'react'
import { UpdateDoiImageFxn } from '../article/actions'
import { connect } from 'react-redux'

const { Option } = Select

@Form.create()
class EditTable extends React.Component {
  // formRef = React.createRef
  constructor(props) {
    super(props)
    this.state = {
      image: '',
      description: '',
      loading: false
    }
  }

  submitForm = async (e) => {
    let { editTable, onClose, dispatch } = this.props
    let { image, description } = this.state
    if (!description) {
      notification.warning({
        message: 'Enter description '
      })
      return
    }
    if (!image || (image && !image.name)) {
      notification.warning({
        message: 'Choose Image'
      })
      return
    }

    let fd = new FormData()
    let obj = {
      articleId: editTable.articleId,
      fieldId: editTable._id,
      tableType: editTable.tableType,
      description
    }
    fd.append('obj', JSON.stringify(obj))
    fd.append('image', image)
    let resp = await dispatch(UpdateDoiImageFxn(fd))
    if (resp && resp.success) {
      notification.success({ message: resp.message })
      onClose()

    } else {
      notification.error({ message: resp.message })
    }
  }

  componentDidMount() {
    this.setState({
      description: this.props.editTable.description,
      oldImage: this.props.editTable.image.path
    })
  }

  render() {
    let { visible, onClose, title, onChange, editTable } = this.props
    return (
      <>
        <Drawer
          title={`Edit ${title}`}
          width={'70%'}
          visible={visible}
          maskClosable={true}
          placement='right'
          onClose={onClose}>
          <Card>
            <Form hideRequiredMark className={'inputForm'}>
              <Row>
                <Col span={24}>
                  <Form.Item label='Description' name='description' required>
                    <TextArea
                      value={this.state.description}
                      placeholder='Enter Description...'
                      onChange={e => {
                        this.setState({
                          description: e.target.value
                        })
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label='Image'>
                    <Input
                      type='file'
                      className='form-control'
                      accept='image/png, image/gif, image/jpeg'
                      onChange={e => {
                        let file = e.target.files[0]
                        file.path = URL.createObjectURL(file)
                        this.setState({ image: file })
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item>
                    <Button
                      type='primary'
                      onClick={() => {
                        this.submitForm()
                      }}>
                      Update
                    </Button>
                  </Form.Item>
                </Col>
                <br />
                <Col span={24}>
                  <Form.Item label='Picture'>
                    <img
                      width={'auto'}
                      height={200}
                      src={this.state.oldImage}
                    />
                  </Form.Item>
                </Col>

              </Row>
            </Form>
          </Card>
        </Drawer>
      </>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  null,
  mapDispatchToProps
)(EditTable)
