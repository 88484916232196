import React, { PureComponent } from 'react'
import {
  Form,
  Input,
  DatePicker,
  Select,
  Button,
  notification,
  Card,
  Icon, Row, Col
} from 'antd'
import _ from 'lodash'
import moment from 'moment'

import { hideLoader, showLoader } from '../../../modules/actions'
import { apiUrl as apiurl } from '../../../settings'
import { connect } from 'react-redux'
import { getUrlParams } from '../../../routes'

import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import FormUtils from '../../../components/_utils/formUtils'
import { AddJournal, UpdateJournal, GetJournal } from '../actions'
import { getSlugUrl, InputBox, formItemLayout } from '../../../components/_utils/appUtils'
import GetEachFormFields from '../../../components/_utils/appFormUtils'

@Form.create()
class JournalPriceList extends PureComponent {

  state = {
    edit: false,
    fileKey: moment(),
    oldJournalFile: {}
  }

  constructor(props) {
    super(props)
  }

  handleSubmit = e => {
    const { dispatch, form } = this.props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        let { id: _id, journalFile } = this.state
        let x = {}
        let fd = new FormData()
        if (!!_id) {
          valData._id = _id
        }
        fd.append('obj', JSON.stringify(valData))

        if (journalFile && journalFile.name) {
          fd.append('journalFile', journalFile)
        }

        if (!!_id) {

          x = await dispatch(UpdateJournal(fd))
        } else {
          x = await dispatch(AddJournal(fd))
        }

        let { error, message } = x

        if (!error) {
          notification.success({
            message: message
          })

          if (!_id) {
            form.resetFields()
            this.setState({ fileKey: moment() })
          }


        } else {
          notification.error({
            message: message,
            description: message
          })
        }
      }
    })
  }

  setFormValues = async (id) => {

    const { dispatch, form } = this.props

    dispatch(showLoader())

    let { error, data } = await dispatch(GetJournal({ id }))

    if (error) {
      notification.error({
        message: 'Error Loading Data'
      })
    } else {
      setTimeout(() => {
        form.setFieldsValue(data)
        if (data.journalFile && data.journalFile.path) {
          this.setState({ oldJournalFile: data.journalFile })
        }

      }, 500)
    }

    dispatch(hideLoader())

  }

  handleChange = (item) => {

  }
  chooseDocument = (e) => {
    let { name, files } = e.target
    if (files && files.length) {
      this.setState({ [name]: files[0] })
    }
  }

  componentDidMount() {
    let data = getUrlParams('journals.edit', this.props.pathname)
    if (!!data) {
      let { id } = data
      this.setState({
        edit: true,
        id: id
      })
      this.setFormValues(id)
    }
  }

  render() {
    return (
      <PageHeaderWrapper
        title={'Journal Price List'}>

        <Card bordered={true}>
          <p>Journal Price List</p>
        </Card>

      </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading,
  categories: global.categories,
  search: router.location.search,
  pathname: router.location.pathname
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JournalPriceList)
