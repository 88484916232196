import {
  Button,
  Card,
  Col,
  Drawer,
  Form,
  Input,
  notification,
  Row,
  Table,
  Tooltip
} from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { useDispatch } from 'react-redux'
import { CkEditor2013 } from '../../../components/_utils/appUtils'
import Request from '../../../request'
import { GetJournalWebPage, UpdateJournalWebPage } from '../actions'
const EditDrawer2 = props => {
  const { id } = props

  const dispatch = useDispatch()
  let { visible, onClose, sendData, edit } = props
  let [reData, setReDate] = useState({
    menuHeading: '',
    title: '',
    description: '',
    journalWebPageId: props.journalWebPageId,
    elementId: sendData._id
  })

  onClose = () => {
    props.onClose()
  }


  useEffect(
    () => {
      setReDate(prevData => {
        return {
          ...prevData,
          title: sendData.title,
          description: sendData.content,
          menuHeading: sendData.menuHeading
        }
      })
    },

    [sendData]
  )

  const handleSubmit = async data => {
    setReDate(prevData => {
      return {
        ...prevData,
        ...data
      }
    })

    const params = {
      _id: reData.journalWebPageId,
      dataId: reData.elementId,
      title: reData.title,
      menuHeading: reData.menuHeading,
      content: reData.description
    }

    let result = await Request.updateWebPage(params)

    if (!result.error) {
      notification.success({
        message: 'Field updated successfully'
      })

      props.getGuideline()
      onClose()
    } else {
      notification.error({
        message: 'Error Saving',
        description: result.message
      })
    }
  }

  const handleChange = data => {
    setReDate(prevData => {
      return {
        ...prevData,
        ...data
      }
    })
  }

  return (
    <>
      <Drawer
        width={'80%'}
        visible={props.visible}
        maskClosable={true}
        placement="right"
        onClose={props.onClose}>
        <Card>
          <Form hideRequiredMark className={'inputForm'}>
            <Col span={24} className="flexColumn">
              <Form.Item label="Enter Title" name="title">
                <Input
                  value={reData.title}
                  placeholder="Enter title..."
                  onChange={({ target }) =>
                    handleChange({ title: target.value })
                  }
                />
              </Form.Item>
              <Form.Item label="Enter Menu Heading" name="menuHeading">
                <Input
                  value={reData.menuHeading}
                  placeholder="Enter Menu Heading..."
                  onChange={({ target }) =>
                    handleChange({ menuHeading: target.value })
                  }
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <CkEditor2013
                html={reData.description}
                style={{ height: '200px' }}
                basic={true}
                value={reData.description}
                onChange={value => handleChange({ description: value })}
              />
            </Col>
            <Col span={24}>
              <Form.Item style={{ marginTop: '8%' }}>
                <Button type="primary" onClick={handleSubmit}>
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Form>
        </Card>
      </Drawer>
    </>
  )
}

const EditDrawer = Form.create()(EditDrawer2)

export default EditDrawer
