import React, { useEffect, useState } from 'react'
import { Button, Card, Drawer, Select } from 'antd'
import { ChooseEmailModal, InputBox } from '../../../components/_utils/appUtils'
import _ from 'lodash'

const { Option } = Select

const ScheduleDrawer = (props) => {
  let { submission, journals, visible, onClose } = props
  let [journalId, setJournalId] = useState('')
  let [journal, setJournal] = useState('')
  useEffect(() => {
    setJournalId(submission.journalId)
  }, [])
  useEffect(() => {
    updateJournalName()
  }, [journalId])

  const updateJournalName = () => {
    let findJournal = _.find(journals, (item) => {
      return item.journalId == journalId
    })
    if (findJournal) {
      setJournal(findJournal.value)
    }
  }

  return (
    <Drawer
      title={(<p>Schedule Journal<br /><br />
        <h3>{submission.journal} ({submission.onlineSubmissionId})</h3></p>)}
      width={500}
      onClose={onClose}
      visible={visible}
      bodyStyle={{ paddingBottom: 80 }}
      footer={
        <div className={'alignRight'}>
          <Button onClick={onClose} style={{ marginRight: 8 }}>
            Cancel
          </Button>
          <Button onClick={onClose} type='primary'>
            Submit
          </Button>
        </div>
      }>
      <Card>
        <InputBox title={'Choose Journal'}>
          <Select onChange={(e) => {
            setJournalId(e)
          }} value={journalId}>
            {journals && journals.length ? journals.map((item, key) => {
              return (
                <Option key={key} value={item.journalId}>{item.text}</Option>
              )
            }) : null}
          </Select>
        </InputBox>

        <ChooseEmailModal
          id={submission._id}
          callback={onClose}
          template={'schedule'}
          obj={{
            type: 'onlineSubmission',
            status: 'Schedule',
            journalId: journalId,
            journal: journal,
            countryName: submission.country
          }}>
          <Button
            className={'linkIcon'}>
            Submit
          </Button>
        </ChooseEmailModal>
      </Card>
    </Drawer>
  )
}
export default ScheduleDrawer
