import {
  Button,
  Card,
  Col,
  Divider,
  Drawer,
  Form,
  Icon,
  Input,
  Popconfirm,
  Row,
  Table,
  Tooltip
} from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { FormUtils, TableComp } from 'sz-react-utils-lite'
import PageHeaderWrapper from '../../components/PageHeaderWrapper'
import {
  addKeyword,
  keywordList,
  removeKeyword
} from '../keyword/actions/actions'

const AddKeyword = props => {
  const [keyword, setKeyword] = useState([])
  const dispatch = useDispatch()
  const table = useRef()
  let { form } = props
  const {
    form: { getFieldDecorator, getFieldValue, setFieldsValue },
    loading
  } = props
  const formItemLayout = {
    labelCol: {
      xs: { span: 2 },
      sm: { span: 3 },
      md: { span: 7 }
    },
    wrapperCol: {
      xs: { span: 22 },
      sm: { span: 22 },
      md: { span: 22 }
    }
  }

  const inputSchma = {
    fields: [
      {
        label: 'KeyWord',
        key: 'keyword',
        placeholder: 'Keyword',
        required: true
      }
    ]
  }

  const handleSubmit = async e => {
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      // console.log(valData);
      if (!err) {
        let result = await dispatch(addKeyword(valData))
        if (result) {
          setFieldsValue({ keyword: '' })
        }
      }
      getKeywords()
    })
  }

  useEffect(() => {
    console.log(props)
    // getKeywords()
  }, [])

  const getKeywords = async () => {
    let result = await dispatch(keywordList())
    // console.log(result)
    table.current.reload()
    setKeyword(result)
  }

  const columns = [
    {
      title: 'Keyword',
      dataIndex: 'keyword',
      key: 'keyword',
      // sorter: true,
      searchTextName: 'keyword'
      // render: (val, record) => (
      //     <div>

      //     </div>
      // )
    },
    {
      title: 'Action',
      key: 'operation',

      render: (item, record) => (
        <div className="alignCenter">
          <Popconfirm
            placement="topLeft"
            title={`Are you Sure, want to delete?`}
            onConfirm={() => handleDelete(record)}
            okText="Yes"
            cancelText="No">
            <Button
              ghost
              shape="circle"
              type="danger"
              // onClick={() => handleDelete(record)}
              icon="delete"
            />
          </Popconfirm>
        </div>
      )
    }
  ]
  // delete keyword
  const handleDelete = async record => {
    let result = await dispatch(removeKeyword(record))
    getKeywords()
  }

  const apiRequest = params => {
    return new Promise(async resolve => {
      let regExpFilters = ['keyword']
      let result = await dispatch(
        keywordList({ ...params, regExFilters: regExpFilters })
      )
      resolve(result)
    })
  }

  return (
    <>
      <Drawer
        visible={props.visible}
        title="Add Keyword"
        onClose={props.onClose}
        width="80%">
        <PageHeaderWrapper>
          <Card bordered={true}>
            <Form onSubmit={handleSubmit}>
              <Row gutter={16}>
                <Col span={12}>
                  <FormUtils
                    inputSchema={inputSchma}
                    formItemLayout={formItemLayout} // optional //for the design part
                    getFieldDecorator={getFieldDecorator} //input validation
                    setFieldsValue={setFieldsValue}
                  />
                </Col>
                <Col span={8}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ marginTop: '90px', right: '8.2rem' }}>
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
            <Divider />
            <TableComp
              pagination={false}
              ref={table}
              sorter
              size="small"
              columns={columns}
              apiRequest={apiRequest}
            />
          </Card>
        </PageHeaderWrapper>
      </Drawer>
    </>
  )
}
// export default keyword;
let formWrap = Form.create()(AddKeyword)
export default formWrap
