import React, { Component } from 'react'
import PageHeaderWrapper from '../../components/PageHeaderWrapper'
import {
  Card,
  Tooltip,
  notification,
  Input, Button, Icon, Form
} from 'antd'
import Request from '../../request'
import { getPushPathWrapper } from '../../routes'
import _ from 'lodash'
import { connect } from 'react-redux'
import { TableComp, FormUtils } from 'sz-react-utils-lite'
import { ChooseEmailModal, chooseEmailModalFxn, DisplayDate, formItemLayout } from '../../components/_utils/appUtils'
import { getAllArticle, getReviewerPendingArticle } from '../article/actions'
import { SendTemplateEmail } from '../email/actions'
import RevisedArticleDrawer from '../article/drawers/RevisedArticleDrawer'
import ReviewerPendingDrawer from '../article/drawers/ReviewerPendingDrawer'


const publishArr = [
  { value: true, display: 'Publish', _id: 'publish' },
  { value: false, display: 'Un-Publish', _id: 'unpublish' }
]

@Form.create()
class PendingArticlesComponent extends Component {
  state = {
    edit: false,
    journalList: [],
    volumeList: [],
    issueList: [],
    isPublished: null,
    slugJournal: null,
    slugVolume: null,
    slugIssue: null,
    articleId: null,
    filters: {},
    visibleRevisedArticle: false,
    visibleDrawer: false,
    article: {}
  }

  constructor(props) {
    super(props)
    console.log(props)
    this.table = React.createRef()
  }

  apiRequest = () => {
    return new Promise(async (resolve) => {
      let { dispatch, form: { getFieldValue } } = this.props
      let obj = {
        status: 'Pending'
      }
      let data = await dispatch(getReviewerPendingArticle({ ...obj }))
      console.log(data)
      resolve(data)
    })
  }

  reload = () => {
    this.table.current.reload()
  }
  reloadTable = (data) => {
    if (data.fromDate && data.toDate) {
      data['submittedDate'] = {
        $gte: data.fromDate,
        $lte: data.toDate
      }
      delete data['fromDate']
      delete data['toDate']
    }
    this.setState({ filters: data }, () => {
      this.reload()
    })
  }


  deleteArticle = async (data) => {
    let resp = await Request.deleteArticle(data)
    this.reload()
  }

  getJournalList = async () => {
    let { data } = await Request.journalName()
    let journalList = []
    _.each(data, (item) => {
      journalList.push({
        _id: item.slugJournal,
        value: item.slugJournal,
        display: item.name
      })
    })
    this.setState({
      journalList,
      volumeList: [],
      issueList: []
    })

  }

  getVolumeList = async (data) => {
    let obj = {
      slugJournal: data
    }
    let resp = await Request.journalVolume(obj)
    let volumeList = []
    _.each(resp, (item) => {
      volumeList.push({
        _id: item.slugVolume,
        value: item.slugVolume,
        display: item.name
      })
    })
    this.setState({
      volumeList,
      issueList: []
    })
  }

  getIssueList = async (data) => {
    let obj = {
      slugJournal: this.props.form.getFieldValue('slugJournal'),
      slugVolume: data
    }
    let resp = await Request.journalIssue(obj)
    let issueList = []
    _.each(resp, (item) => {
      issueList.push({
        _id: item.slugIssue,
        value: item.slugIssue,
        display: item.name
      })
    })
    this.setState({
      issueList
    })
  }


  componentDidMount() {
    this.getJournalList()
  }

  sendMailFxn = (record) => {
    let { dispatch } = this.props
    chooseEmailModalFxn({
      callback: async (email) => {
        let obj = {
          articleId: record._id,
          fromEmail: email,
          emailType: 'ResendComments',
          template: 'commentsToAuthorTemplate',
          type: 'article'
        }
        let resp = await dispatch(SendTemplateEmail({
          email,
          id: record._id,
          obj,
          template: obj.template
        }))
        if (resp && resp.success) {
          this.reload()
        } else {
          notification.error({
            message: resp.message
          })
        }

      }
    })
  }

  render() {

    const {
      dispatch,
      form: { getFieldDecorator, getFieldValue, setFieldsValue },
      loading,
      showTitle = false
    } = this.props
    const { journalList, volumeList, issueList } = this.state

    const formProps = {
      getFieldDecorator, getFieldValue, loading
    }


    const columns = [
      {
        key: 'actions',
        title: 'Article Id',
        render: (val, record) => (
          record.articleNo
        )
      },
      {
        title: 'Received Date',
        key: 'submittedDate',
        dataIndex: 'submittedDate',
        render: (item) => {
          return item ? DisplayDate(item) : null
        }
      },
      {
        title: 'Comments',
        key: 'underReviewDetails.articleAttachment',
        render: (item, record) => {
          return (
            <Button className={'roundSmallBtn'}
                    onClick={() => this.setState({ visibleDrawer: true, article: record })}>
              Add Comments</Button>
          )
        }
      },
      {
        title: 'Status',
        key: 'webStatus',
        render: (item) => {
          return (
            <p>{item}</p>
          )
        }
      }
    ]

    const fil =
      {
        labelCol: {
          xs: {
            span: 24
          }
          ,
          sm: {
            span: 24
          }
          ,
          md: {
            span: 24
          }
        }
        ,
        wrapperCol: {
          xs: {
            span: 24
          }
          ,
          sm: {
            span: 24
          }
          ,
          md: {
            span: 24
          }
        }
      }

    return (
      <>
        <Card bordered={true} title={showTitle ? 'Pending Article' : ''} extra={showTitle ? sampleBtn() : null}>
          <TableComp ref={this.table} columns={columns} apiRequest={this.apiRequest} />
        </Card>
        {this.state.visibleDrawer ? <ReviewerPendingDrawer
          visible={this.state.visibleDrawer}
          articleId={this.state.article._id}
          article={this.state.article}
          Id={this.state.article._id}
          callback={this.reload}
          edit={false}
          onClose={() => this.setState({ visibleDrawer: false })}
        /> : null}
      </>
    )

  }
}


const sampleBtn = () => {
  return (
    <>
      <Button href={require('../../assets/Format-for-giving-comments.docx')} download type='primary' size={'small'}
              style={{ marginRight: 10, color: 'white' }}>Click here to download Comments format</Button>
      <Button href={require('../../assets/Sample-Manuscript.pdf')} type='primary' style={{ color: 'white' }}
              size={'small'}>Click here to download Sample Article</Button>
    </>
  )
}

const PendingArticle = (props) => {
  return (
    <PageHeaderWrapper
      title={'Pending Articles'}
      content={sampleBtn()}
    >
      <PendingArticlesComponent {...props} />
    </PageHeaderWrapper>
  )
}
export { PendingArticlesComponent }
const mapStateToProps = (
  {
    global
  }
) => (
  {
    categories: global.categories
  }
)
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PendingArticle)
