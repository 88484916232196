import React, { useEffect, useState, useRef } from 'react'
import { Button, Card, Collapse, Popconfirm, Table, Tooltip } from 'antd'
import { DisplayDate, GetDownloadLink } from '../../../components/_utils/appUtils'
import { TableComp } from 'sz-react-utils-lite'
import _ from 'lodash'
import ReceiveDateDrawer from '../reviewer/editSelectReceiveDate'
import { RowTable } from './RowTable'
import AcceptanceDrawer from '../drawers/AcceptanceDrawer'
import CompletePublishDrawer from '../drawers/CompletePublishDrawer'
import CompletePublishUpdateDrawer from '../drawers/CompletePublishUpdateDrawer'

const { Panel } = Collapse
const PublishArticleDetails = (props) => {
  let { article } = props
  let {
    onlinePublishedDate,
    sendForPublicationDate,
    finalAttachmentPublished,
    contentAttachmentPublished,
    coverAttachmentPublished
  } = article
  let [receiveDateDrawerVisible, setReceiveDateDrawerVisible] = useState(false)
  let [isEdit, setIsEdit] = useState(false)
  let [reviewerId, setReviewerId] = useState('')
  let headerTitle = `Complete Published ${sendForPublicationDate ? `(${DisplayDate(sendForPublicationDate)})` : ''}`
  let [visibleCommentToAuthorDrawer, setVisibleCommentToAuthorDrawer] = useState(false)
  const table = useRef()
  let events = {
    reloadTable: () => {
      table.current.reload()
    },
    showAndHideReceiveDate: (value, id = '') => {
      setReceiveDateDrawerVisible(value)
      setReviewerId(id)
    },
    show: () => {
      setVisibleCommentToAuthorDrawer(true)
    },
    hide: () => {
      setVisibleCommentToAuthorDrawer(false)
    }
  }

  let BtnAction = (event) => {
    setIsEdit(true)
    events.show()
    event.stopPropagation()
  }
 let BtnUpdateAction = (event) => {
   setIsEdit(false)
    events.show()
    event.stopPropagation()
  }

  let getExtraButton = () => {
    return (
      <React.Fragment>
        {onlinePublishedDate && !sendForPublicationDate ?
          <Button type={'primary'} size={'small'} onClick={BtnAction}>Add</Button> : null}
        {sendForPublicationDate ?
          <Button type={'primary'} size={'small'} onClick={BtnUpdateAction} style={{marginRight:'10px'}}>Update</Button> : null}
        {sendForPublicationDate ?
          <Button type={'primary'} size={'small'} onClick={BtnAction}>Edit</Button> : null}
      </React.Fragment>
    )
  }

  return (
    <div>
      <Collapse expandIconPosition={'right'} defaultActiveKey={['1']}>
        <Panel header={headerTitle} key='1' extra={getExtraButton()}>
          <div className={'tableBox striped'}>
            <RowTable title={'Date'} value={
              sendForPublicationDate && DisplayDate(sendForPublicationDate)
            } />
            <RowTable title={'Volume Number'} value={
              article.volumeName ? article.volumeName : null
            } />
            <RowTable title={'Issues'} value={
              article.issues ? article.issues : null
            } />
            <RowTable title={'volume Number And Issue'} value={
              article.volumeNumberAndIssue ? article.volumeNumberAndIssue : null
            } />
            <RowTable title={'Page Range'} value={
              article.pageRange ? article.pageRange : null
            } />
            <RowTable title={'Article Order'} value={
              article.articleOrder ? article.articleOrder : null
            } />
            <RowTable title={'Final Attachment'} value={
              finalAttachmentPublished && finalAttachmentPublished.path ?
                <a download className='downloadLink' target={'_blank'}
                   href={finalAttachmentPublished.path}>Download</a> : 'No Attachment'
            } />
            <RowTable title={'Attachment of Content'} value={
              contentAttachmentPublished && contentAttachmentPublished.path ?
                <a download className='downloadLink' target={'_blank'}
                   href={contentAttachmentPublished.path}>Download</a> : 'No Attachment'
            } />
            <RowTable title={'Attachment of Cover'} value={
              coverAttachmentPublished && coverAttachmentPublished.path ?
                <a download className='downloadLink' target={'_blank'}
                   href={coverAttachmentPublished.path}>Download</a> : 'No Attachment'
            } />
          </div>
        </Panel>
      </Collapse>
      {receiveDateDrawerVisible ? <ReceiveDateDrawer
        visible={receiveDateDrawerVisible}
        reviewerId={reviewerId}
        article={article}
        onClose={() => events.showAndHideReceiveDate(false)}
      /> : null}
      {visibleCommentToAuthorDrawer && isEdit? <CompletePublishDrawer
        visible={visibleCommentToAuthorDrawer}
        article={article}
        edit={sendForPublicationDate ? true : false}
        onClose={() => events.hide()}
      /> : null}
      {visibleCommentToAuthorDrawer && (!isEdit) ? <CompletePublishUpdateDrawer
        visible={visibleCommentToAuthorDrawer}
        article={article}
        edit={sendForPublicationDate ? true : false}
        onClose={() => events.hide()}
      /> : null}
    </div>
  )
}

export default PublishArticleDetails
