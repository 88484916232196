import React from 'react'
import {Button, Card, Col, Drawer, Form, notification, Row} from 'antd'
import {chooseEmailModalFxn, formItemLayout, RemarksList} from '../../../components/_utils/appUtils'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import {ChangeReviewerHistory, SendReceiveDate} from '../actions'
import moment from 'moment'
import {connect} from 'react-redux'

@Form.create()
class ChangeHistory extends React.Component {
    handleSubmit = (e) => {
        const {dispatch, form, onSubmit, selectedReviewer} = this.props
        let {articleId, reviewerId} = selectedReviewer
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                valData.articleId = articleId
                valData.reviewerId = reviewerId
                if (valData.remarks == "Direct Article Received") {
                    chooseEmailModalFxn({
                        callback: async (email) => {
                            valData.fromEmail = email;
                            nextProcess()
                        }
                    })
                } else {
                    nextProcess()
                }

                async function nextProcess() {
                    let resp = await dispatch(ChangeReviewerHistory(valData))
                    if (resp && resp.success) {
                        dispatch({type: 'START_LOAD_ARTICLE'})
                        form.resetFields()
                        onSubmit()
                    }
                }
            } else {
                notification.error({
                    message: 'Please enter all required fields.'
                })
            }
        })
    }

    componentDidMount() {
        const {form: {setFieldsValue}, selectedReviewer} = this.props
        setTimeout(() => {
            setFieldsValue({
                sendDate: selectedReviewer.sendDate ? moment(selectedReviewer.sendDate) : null,
                remarks: selectedReviewer.remarks ? selectedReviewer.remarks : null,
                comments: selectedReviewer.comments ? selectedReviewer.comments : null,
                reminderDate: selectedReviewer.reminderDate ? moment(selectedReviewer.reminderDate) : null,
                receiveDate: selectedReviewer.receiveDate ? moment(selectedReviewer.receiveDate) : null
            })
        }, 500)
    }

    render() {
        const {form: {getFieldDecorator, getFieldValue, setFieldsValue}, visible, onClose} = this.props

        let inputSchema = [
            {
                label: 'Send Date',
                key: 'sendDate',
                placeholder: 'Send Date',
                type: 'date'
            },
            {
                label: 'Remarks',
                key: 'remarks',
                showSearch: true,
                options: RemarksList,
                keyAccessor: x => x,
                valueAccessor: x => x,
                placeholder: 'Remarks',
                type: 'select',
                onChange: (val) => {
                    setFieldsValue({remarks: val})
                }
            },
            {
                label: 'Comments',
                key: 'comments',
                type: 'textArea',
                hidden: !(getFieldValue('remarks') == 'Other text box' || getFieldValue('remarks') == 'Direct Article Received'),
                required: (getFieldValue('remarks') == 'Other text box' || getFieldValue('remarks') == 'Direct Article Received'),
                placeholder: 'Comments'
            },
            {
                label: 'Reminder Date',
                key: 'reminderDate',
                placeholder: 'Reminder Date',
                type: 'date'
            },
            {
                label: 'Receive Date',
                key: 'receiveDate',
                placeholder: 'Receive Date',
                type: 'date'
            }
        ]
        return (
            <Drawer visible={visible} onClose={onClose} width={'40%'} title={'Change History'}>
                <Card bordered={true}>
                    <Form onSubmit={this.handleSubmit} hideRequiredMark style={{marginTop: 8}} className={'inputForm'}>
                        <Row gutter={16}>
                            {inputSchema.map((item, key) => {
                                return (
                                    <Col span={24} key={key}>
                                        <GetEachFormFields
                                            item={item}
                                            getFieldDecorator={getFieldDecorator}
                                            formItemLayout={formItemLayout}/>
                                    </Col>
                                )
                            })}
                            <Col span={24}>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit">
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </Drawer>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    null,
    mapDispatchToProps
)(ChangeHistory)
