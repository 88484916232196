import { getDashboardCountUrl } from '../apis'
import { getToken, customAxios as axios } from '../../../request'
import { showPageLoad, hidePageLoad } from '../../../modules/actions'
import { notification } from 'antd'

export const GetDashboardCount = (valData) => async (dispatch) => {
  // dispatch(showPageLoad())
  let { data } = await axios.post(getDashboardCountUrl(), valData, getToken())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  dispatch(hidePageLoad())
  return data
}
