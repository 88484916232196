import { notification } from 'antd'
import { showPageLoad, hidePageLoad } from '../../../modules/actions'
import { getToken, customAxios as axios } from '../../../request'
import { allArticleUrl, articleUrl } from '../../article/apis'
import { AuthorGuidelineUrl } from '../apis'

export const addAuthorGuideline = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(AuthorGuidelineUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    notification.success({
      message: data.message || 'Success'
    })
  }
  return data
}
export const updateAuthorGuideline = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.put(AuthorGuidelineUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    notification.success({
      message: data.message || 'Success'
    })
  }
  return data
}

export const getAuthorGuideLine = (filters) => async (dispatch) => {
  dispatch(showPageLoad())
  let config = {
    params: { ...filters },
    ...getToken()
  }
  let { data } = await axios.get(AuthorGuidelineUrl(), config)
  dispatch(hidePageLoad())
  /* if (data.error) {
     notification.error({
       message: data.message || 'Error'
     })
   }*/
  return data
}
