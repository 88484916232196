import React, { useState } from 'react'
import { Input, notification } from 'antd'
import { ArticleSearchById } from '../../containers/article/actions'
import { getPushPathWrapper } from '../../routes'
import { useDispatch } from 'react-redux'

const ArticleSearch = (props) => {
  let [articleNo, setArticleNo] = useState('')
  let dispatch = useDispatch()
  const searchArticle = async () => {
    if (!articleNo) {
      notification.warn({ message: 'Enter article no.' })
      return
    }
    let { success, message, data } = await ArticleSearchById({ articleNo })
    if (success) {
      dispatch(getPushPathWrapper('articleView', { id: data._id }))
      dispatch({ type: 'REFRESH_ARTICLE', refreshArticle: true })
    } else {
      setArticleNo('')
      return
    }
    //dispatch({ type: 'STOP_LOAD_ARTICLE' })
    // dispatch({ type: 'START_LOAD_ARTICLE' })
  }
  const searchBtn = () => {
    return (
      <a onClick={() => searchArticle()}>Go!</a>
    )
  }
  return (
    <React.Fragment>
      <div className={'searchBox'}>
        <Input className={'antInputSearch'} addonAfter={searchBtn()}
               value={articleNo} placeholder={'Search your Article by Id'}
               onPressEnter={() => searchArticle()}
               onChange={(e) => setArticleNo(e.target.value)}/>
      </div>
    </React.Fragment>
  )
}
export { ArticleSearch }
