import React, { useState, useEffect } from 'react'
import { Button, Card, Col, Form, Row } from 'antd'
import GetEachFormFields from '../../components/_utils/appFormUtils'
import {
  formItemLayout,
  MonthArr,
  YearArr,
  ProcessList
} from '../../components/_utils/appUtils'
import Request from '../../request'
import _ from 'lodash'

const nationalityList = [
  'Indian',
  'Foreign'
]
const DashboardFilter = (props) => {
  const { dispatch, form: { getFieldDecorator, getFieldValue, setFieldsValue }, callback } = props
  let [journalList, setJournalList] = useState([])
  useEffect(() => {
    getJournalList()
  }, [])
  const getJournalList = async () => {
    let { data } = await Request.journalName()
    let journalList = []
    _.each(data, (item) => {
      journalList.push({
        _id: item.slugJournal,
        value: item.slugJournal,
        display: item.name
      })
    })
    setJournalList(journalList)
  }

  const inputSchema = {
    fields: [
      {
        label: 'Journals',
        key: 'journal',
        type: 'select',
        options: journalList,
        placeholder: 'Journals',
        keyAccessor: x => x.display,
        valueAccessor: x => x.display,
        allowClear: true,
        onChange: (val) => {
          setFieldsValue({ journal: val }, () => {
            filterDashboard()
          })
        }
      },
      {
        label: 'Nationality',
        key: 'nationality',
        type: 'select',
        options: nationalityList,
        placeholder: 'Nationality',
        allowClear: true,
        keyAccessor: x => x,
        valueAccessor: x => x,
        onChange: (val) => {
          setFieldsValue({ nationality: val }, () => {
            filterDashboard()
          })
        }
      },
      {
        label: 'Month',
        key: 'month',
        type: 'select',
        options: MonthArr,
        placeholder: 'Month',
        allowClear: true,
        keyAccessor: x => x.value,
        valueAccessor: x => x.name,
        onChange: (val) => {
          setFieldsValue({ month: val }, () => {
            filterDashboard()
          })
        }
      },
      {
        label: 'Year',
        key: 'year',
        type: 'select',
        options: YearArr(),
        placeholder: 'Year',
        keyAccessor: x => x,
        valueAccessor: x => x,
        allowClear: true,
        onChange: (val) => {
          setFieldsValue({ year: val }, () => {
            filterDashboard()
          })
        }
      },
      {
        label: 'Review Process',
        key: 'processType',
        type: 'select',
        options: ProcessList,
        placeholder: 'Process',
        allowClear: true,
        keyAccessor: x => x,
        valueAccessor: x => x,
        onChange: (val) => {
          setFieldsValue({ processType: val }, () => {
            filterDashboard()
          })
        }
      }

    ]

  }

  const filterDashboard = () => {
    let year = getFieldValue('year') ? getFieldValue('year') : null
    let month = getFieldValue('month') ? getFieldValue('month') : null
    let nationality = getFieldValue('nationality') ? getFieldValue('nationality') : null
    let processType = getFieldValue('processType') ? getFieldValue('processType') : null
    let obj = {
      journal: getFieldValue('journal') ? getFieldValue('journal') : null
    }
    if (nationality) {
      obj.country = nationality == 'Indian' ? 'India' : { $ne: 'India' }
    }
    if (month) {
      obj.month = month
    }
    if (year) {
      obj.year = year
    }
    if (processType) {
      obj.processType = processType
    }
    callback(obj)
  }

  return (
    <Form hideRequiredMark className={'inputForm'}>

      <Row gutter={10}>
        {inputSchema.fields.map((item, key) => {
          return (
            <Col span={4} lg={4} md={8} sm={12} xs={24} key={key}>
              <GetEachFormFields getFieldDecorator={getFieldDecorator}
                                 item={item} formItemLayout={formItemLayout} />
            </Col>
          )
        })}

      </Row>
    </Form>
  )
}

const WrappedDashboardFilter = Form.create()(DashboardFilter)
export default WrappedDashboardFilter
