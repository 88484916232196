import React, { PureComponent } from 'react'
import {
  Form,
  Input,
  DatePicker,
  Select,
  Button,
  notification,
  Card,
  Icon,
  Row, Col, Table, Tooltip
} from 'antd'
import _ from 'lodash'
import moment from 'moment'

import { hideLoader, showLoader } from '../../modules/actions'
import { apiUrl } from '../../settings'
import Request from '../../request'
import { connect } from 'react-redux'
import { getUrlParams } from '../../routes'
import GetEachFormFields from '../../components/_utils/appFormUtils'

import PageHeaderWrapper from '../../components/PageHeaderWrapper'
import { FormUtils } from 'sz-react-utils-lite'
import { CountriesList, JournalList } from '../journals/actions'
import {
  ProcessList,
  ArticleTypeList,
  DesignationList,
  Countries,
  InputBox,
  MemberTypes,
  MemberPositions,
  MemberRatings,
  AuthorTitle,
  Genders, mentionsFeeds
} from '../../components/_utils/appUtils'
import { addMember, getNextReviewerId, checkMemberEmailsUpdate, updateMember } from '../members/actions'
import { singleReviewer } from '../reviewer/actions'
import { CustomCkEditor } from '../../components/_utils/appUtils'
import { chooseEmailModalFxn } from '../../components/_utils/appUtils'
import { getSingleMember } from './actions'
import MentionInfo from '../email/mentionInfo'

@Form.create()
class EditReviewer extends PureComponent {

  state = {
    edit: false,
    journalListArr: [],
    memberImage: {},
    memberCV: {},
    fileKey: moment(),
    reviewerId: '',
    reviewerIdNumber: '',
    ckeditorKey: moment() + 1,
    id: '',
    memberImageUrl: '',
    memberCVUrl: ''
  }

  constructor(props) {
    super(props)
  }

  resetFormData = () => {
    const { dispatch, form } = this.props
    form.setFieldsValue({
      enterDate: moment(),
      memberType: '',
      journals: [],
      rating: '',
      position: '',
      department: '',
      title: '',
      areaOfInterest: '',
      discipline: '',
      name: '',
      instituteAffiliatedWith: '',
      gender: '',
      address: '',
      designation: '',
      country: '',
      email: '',
      instituteWebsite: '',
      secondaryEmail: '',
      contactNo: '',
      otherDetails: '',
      description: ''
    })
  }

  handleSubmit = e => {
    const { dispatch, form } = this.props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      //let user = JSON.parse(localStorage.getItem('user'))
      let id = this.state.id
      console.log('{{{{{{{{}}}}}}}}}}', id)
      if (!err) {
        let { memberImage, memberCV } = this.state
        let fd = new FormData()
        valData._id = id
        valData.description = this.state.description
        fd.append('obj', JSON.stringify(valData))
        if (memberImage && memberImage.name) {
          fd.append('memberImage', memberImage)
        }
        let { success, message } = await dispatch(updateMember(fd))
        if (success) {
          notification.success({
            message: message
          })
        }
      }
    })
  }

  setFormValuesFxn = async () => {

    const { dispatch, form } = this.props
    let user = JSON.parse(localStorage.getItem('user'))
    let { data, error } = await dispatch(getSingleMember(user.reviewerId))
    this.setState({ id: data._id })
    if (error) {
      notification.error({
        message: 'Error Loading Data'
      })
    } else {
      form.setFieldsValue({ ...data })
      let obj = {}
      if (data.memberImage && data.memberImage.path) {
        obj.memberImageUrl = data.memberImage.path
      }
      if (data.description && data.description) {
        obj.description = data.description
      }

      this.setState({ ...obj })
    }
  }

  componentDidMount() {
    this.loadJournalList()
    this.loadReviewerId()
    // let data = getUrlParams('member.reviewer', this.props.pathname)
    this.setFormValuesFxn()

  }


  loadJournalList = async () => {
    const { dispatch, form } = this.props
    let { data } = await JournalList()
    this.setState({ journalListArr: data })
    form.setFieldsValue({ enterDate: moment() })
  }


  loadReviewerId = async () => {
    const { dispatch } = this.props
    let resp = await dispatch(getNextReviewerId())
    this.setState({ reviewerIdNumber: resp.reviewerIdNumber, reviewerId: resp.reviewerId })
  }


  chooseDocument = (e) => {
    let { name, files } = e.target
    if (files && files.length) {
      this.setState({ [name]: files[0] })
    }
  }

  render() {

    const { form: { getFieldDecorator, getFieldValue, setFieldsValue }, loading } = this.props
    const { memberImageUrl, journalListArr, memberCVUrl } = this.state

    const formProps = {
      getFieldDecorator, getFieldValue, loading
    }
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 }
      }
    }

    const inputSchema = {
      fields: [
        {
          label: 'Title',
          key: 'title',
          required: true,
          showSearch: true,
          options: AuthorTitle,
          placeholder: 'Title',
          type: 'select',
          onChange: (val) => {
            setFieldsValue({ title: val })
          }
        },
        {
          label: 'Name',
          key: 'name',
          required: true,
          placeholder: 'Name'
        },
        {
          label: 'Date of Enter',
          key: 'enterDate',
          required: true,
          placeholder: 'Date of Enter',
          type: 'date',
          disabled: true
        },
        {
          label: 'Address',
          key: 'address',
          required: true,
          placeholder: 'Address'
        },
        {
          label: 'Area Of Interest',
          key: 'areaOfInterest',
          required: true,
          placeholder: 'Area Of Interest'
        },
        {
          label: 'Contact number',
          key: 'contactNo',
          placeholder: 'Contact number'
        },
        {
          label: 'Designation',
          key: 'designation',
          required: true,
          placeholder: 'Designation',
          options: DesignationList,
          type: 'select',
          showSearch: true,
          onChange: (val) => {
            setFieldsValue({ designation: val })
          }
        },
        {
          label: 'Department',
          key: 'department',
          required: true,
          placeholder: 'Department'
        },
        {
          label: 'Institute Affiliated With',
          key: 'instituteAffiliatedWith',
          required: true,
          placeholder: 'Institute Affiliated With'
        },
        // {
        //   label: 'Journal of Interest',
        //   key: 'journals',
        //   required: true,
        //   showSearch: true,
        //   options: journalListArr,
        //   keyAccessor: x => x.name,
        //   valueAccessor: x => x.name,
        //   placeholder: 'Journal',
        //   type: 'select',
        //   mode: 'multiple',
        //   onChange: (val) => {
        //     setFieldsValue({ journals: val })
        //   }
        // },
        {
          label: 'Profile Image',
          customField: (
            <InputBox title={`Profile Image`} className={'rowFlex'}>
              <Input type={'file'} name={'memberImage'} id={'memberImage'}
                     className={'form-control'} key={this.state.fileKey}
                     onChange={(e) => {
                       this.chooseDocument(e)
                     }} />
              {memberImageUrl ?
                <a className={'linkBtn'} target={'_blank'} href={memberImageUrl}>
                  <Icon type={'eye'}></Icon>
                </a> : null}
            </InputBox>
          )
        },
        {
          label: 'Profile description',
          key: 'description',
          // type: 'ckeditor',
          // editorKey: this.state.ckeditorKey,
          rows: 2,
          span: 24,
          customField: (
            <>
              <CustomCkEditor title={'Profile description'}
                              extraBox={(
                                <MentionInfo><a><Icon type={'info-circle'} /></a></MentionInfo>
                              )}
                //feeds={mentionsFeeds}
                              html={this.state.description}
                              onChange={(data) => {
                                this.setState({ description: data })
                              }}
              /></>)
        }
      ]

    }


    return (
      <PageHeaderWrapper
        title={'Edit Member'}>

        <Card bordered={true}>
          <Form onSubmit={this.handleSubmit} hideRequiredMark className={'inputForm'} noValidate={true}>
            <Row gutter={16}>
              {inputSchema.fields.map((item, key) => {
                return (
                  !item.hidden && <Col span={item.span ? item.span : 12} md={item.span ? item.span : 12} sm={12} xs={24}
                                       key={key}>
                    {item.customField ? item.customField :
                      <GetEachFormFields
                        item={item}
                        getFieldDecorator={getFieldDecorator}
                        formItemLayout={formItemLayout} />}
                  </Col>
                )
              })}
            </Row>

            <Form.Item style={{ marginTop: 32 }}>
              <Button type='primary' htmlType='submit'>
                Update
              </Button>
            </Form.Item>

          </Form>


        </Card>

      </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading,
  categories: global.categories,
  search: router.location.search,
  pathname: router.location.pathname

})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditReviewer)
