import React, { useEffect, useRef, useState } from 'react'
import { TableComp } from 'sz-react-utils-lite'
import { Drawer, Tooltip, Card, Button, Form, Row, Col, Radio, Input, Icon, notification } from 'antd'
import {
  formItemLayout,
  InputBox
} from '../../components/_utils/appUtils'
import { useDispatch } from 'react-redux'
import { ChooseEmailModal, chooseEmailModalFxn } from '../../components/_utils/appUtils'
import GetEachFormFields from '../../components/_utils/appFormUtils'
import { addAuthorGuideline, getAuthorGuideLine, updateAuthorGuideline } from './actions'
import moment from 'moment'


const AuthorGuidelineDrawer = (props) => {
  let {
    form: { getFieldDecorator, getFieldValue, setFieldsValue },
    Id,
    visible,
    onClose,
    edit,
    callback
  } = props
  const dispatch = useDispatch()

  let [visibleDrawer, setVisibleDrawer] = useState(visible)
  let [revisedArticleAttachment, setRevisedArticleAttachment] = useState({})
  let [guidelineFile, setGuidelineFile] = useState({})
  let [guidelineData, setGuidelineData] = useState({})

  let [attachment, setAttachment] = useState({
    guideLineFile: ''
  })

  const setFormData = (data) => {
    setTimeout(() => {
      props.form.setFieldsValue({
        description: data.description
      })
    }, 100)
    setAttachment({
      guideLineFile: data.guideLineFile && data.guideLineFile.path ? data.guideLineFile.path : ''
    })
  }


  useEffect(() => {
    getGuideline()
  }, [])

  const getGuideline = async () => {
    let data = await dispatch(getAuthorGuideLine())
    if (data.success) {
      setGuidelineData(data.data)
      setFormData(data.data)
    }
  }

  const events = {
    show: (event) => {
      setVisibleDrawer(true)

    },
    hide: (event) => {
      setVisibleDrawer(false)
    }
  }

  const inputSchema = {
    fields: [
      {
        label: 'Sample Manuscript Attachment',
        customField: (
          <InputBox title={`Sample Manuscript Attachment`} className={'rowFlex'}>
            <Input type={'file'} name={'guideLineFile'} id={'guideLineFile'}
                   className={'form-control'}
                   onChange={(e) => {
                     let { name, files } = e.target
                     if (files && files.length) {
                       setGuidelineFile(files[0])
                     }
                   }} />
            {guidelineData && attachment && attachment.guideLineFile ?
              <a className={'linkBtn'} target={'_blank'} href={`${attachment.guideLineFile}`}>
                <Icon type={'eye'} />
              </a> : null}
          </InputBox>
        )
      },
      {
        label: 'Description',
        key: 'description',
        placeholder: 'description',
        span: 24,
        type: 'ckeditor'
      }
    ]

  }


  const handleSubmit = e => {
    const { form } = props
    e.preventDefault()
    let user = JSON.parse(localStorage.getItem('user'))
    form.validateFieldsAndScroll(async (err, valData) => {
      if (err) {
        notification.error({
          message: err.message || 'Error'
        })
      } else {
        let fd = new FormData()
        let obj = {
          description: valData.description
        }
        fd.append('guideLineFile', guidelineFile)
        fd.append('obj', JSON.stringify(obj))
        let resp = {}
        if (guidelineData.companyId) {
          resp = await dispatch(updateAuthorGuideline(fd))
        } else {
          resp = await dispatch(addAuthorGuideline(fd))
        }
        let { success, data } = resp
        if (success) {
          dispatch({ type: 'START_LOAD_ARTICLE' })
          onClose()
        }
      }
    })
  }

  return (
    <>
      <Drawer
        title={'Author GuideLines'}
        width={'45%'}
        visible={visible}
        maskClosable={true}
        placement='right'
        onClose={onClose}>
        <Card className={'GuideLineDrawer'}>
          <Form onSubmit={handleSubmit} hideRequiredMark className={'inputForm'}>
            <Row gutter={16}>
              {inputSchema.fields.map((item, key) => {
                return (
                  <Col span={24} key={key}>
                    {item.customField ? item.customField :
                      <GetEachFormFields
                        item={item}
                        getFieldDecorator={getFieldDecorator}
                        formItemLayout={formItemLayout} />}
                  </Col>
                )
              })}
              <Col span={24}>
                <Form.Item style={{ marginTop: 40 }}>
                  <Button type='primary' htmlType='submit'>
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </Drawer>
    </>
  )
}


const WrappedForm = Form.create()(AuthorGuidelineDrawer)

export default WrappedForm
