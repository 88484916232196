import React, { useEffect, useRef, useState } from 'react'
import { TableComp } from 'sz-react-utils-lite'
import { Drawer, Tooltip, Card, Button, Form, Row, Col, Radio, Input, Icon, notification } from 'antd'
import {
  formItemLayout,
  InputBox
} from '../../../components/_utils/appUtils'
import { useDispatch } from 'react-redux'
import { ChooseEmailModal, chooseEmailModalFxn } from '../../../components/_utils/appUtils'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import { RevisedArticle } from '../actions'
import moment from 'moment'


const RevisedArticleDrawer = (props) => {
  let {
    form: { getFieldDecorator, getFieldValue, setFieldsValue },
    Id,
    visible,
    onClose,
    edit,
    article,
    callback = null
  } = props
  const dispatch = useDispatch()

  let [visibleDrawer, setVisibleDrawer] = useState(visible)
  let [revisedArticleAttachment, setRevisedArticleAttachment] = useState({})
  let [responseFromAuthorAttachment, setResponseFromAuthorAttachment] = useState({})

  let [attachment, setAttachment] = useState({
    responseFromAuthorAttachment: '',
    revisedArticleAttachment: ''
  })
  const setFormData = () => {
    if (edit) {
      setTimeout(() => {
        props.form.setFieldsValue({
          revisedArticleDate: moment(article.revisedArticleDate)
        })
      }, 100)

      setAttachment({
        revisedArticleAttachment: article.revisedArticleAttachment && article.revisedArticleAttachment.path ? article.revisedArticleAttachment.path : '',
        responseFromAuthorAttachment: article.responseFromAuthorAttachment && article.responseFromAuthorAttachment.path ? article.responseFromAuthorAttachment.path : ''
      })
    } else {
      setTimeout(() => {
        props.form.setFieldsValue({
          revisedArticleDate: moment()
        })
      }, 100)
    }
  }

  useEffect(() => {
    setFormData()
  }, [])


  const events = {
    show: (event) => {
      setVisibleDrawer(true)

    },
    hide: (event) => {
      setVisibleDrawer(false)
    }
  }

  const inputSchema = {
    fields: [
      {
        label: 'Date',
        key: 'revisedArticleDate',
        required: true,
        placeholder: 'Date of Enter',
        type: 'date'
      },
      {
        label: 'Revised Article',
        customField: (
          <InputBox title={`Revised article`} className={'rowFlex'}>
            <Input type={'file'} name={'revisedArticleAttachment'} id={'revisedArticleAttachment'}
                   className={'form-control'}
                   onChange={(e) => {
                     let { name, files } = e.target
                     if (files && files.length) {
                       setRevisedArticleAttachment(files[0])
                     }
                   }} />

            {edit && attachment && attachment.revisedArticleAttachment ?
              <a className={'linkBtn'} target={'_blank'} href={`${attachment.revisedArticleAttachment}`}>
                <Icon type={'eye'} />
              </a> : null}
          </InputBox>
        )
      },
      {
        label: 'Response from Author',
        customField: (
          <InputBox title={`Response from Author`} className={'rowFlex'}>
            <Input type={'file'} name={'responseFromAuthorAttachment'} id={'responseFromAuthorAttachment'}
                   className={'form-control'}
                   onChange={(e) => {
                     let { name, files } = e.target
                     if (files && files.length) {
                       setResponseFromAuthorAttachment(files[0])
                     }
                   }} />

            {edit && attachment && attachment.responseFromAuthorAttachment ?
              <a className={'linkBtn'} target={'_blank'} href={`${attachment.responseFromAuthorAttachment}`}>
                <Icon type={'eye'} />
              </a> : null}
          </InputBox>
        )
      }
    ]

  }


  const handleSubmit = e => {
    const { form } = props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (err) {
        notification.error({
          message: err.message || 'Error'
        })
      } else {
        chooseEmailModalFxn({
          title: 'Revised Article',
          callback: async (email) => {
            let fd = new FormData()
            let obj = {
              articleId: article._id,
              //reviewerId: article.underReviewDetails.reviewerId,
              revisedArticleDate: valData.revisedArticleDate,
              template: 'revisedArticleAcknowledgement',
              fromEmail: email
            }
            fd.append('obj', JSON.stringify(obj))

            if (revisedArticleAttachment && revisedArticleAttachment.name) {
              fd.append('revisedArticleAttachment', revisedArticleAttachment)
            }
            if (responseFromAuthorAttachment && responseFromAuthorAttachment.name) {
              fd.append('responseFromAuthorAttachment', responseFromAuthorAttachment)
            }
            let { success, data } = await dispatch(RevisedArticle(fd))
            if (success) {
              dispatch({ type: 'START_LOAD_ARTICLE' })
              onClose()
              if (callback) {
                callback()
              }
              setRevisedArticleAttachment({})
              setResponseFromAuthorAttachment({})
            }
          }
        })
      }
    })
  }

  return (
    <>
      {/*<div onClick={(event) => events.show(event)}>*/}
      {/*  {children}*/}
      {/*</div>*/}
      <Drawer
        title={`Revised Article | Article No. ${article.articleNo}`}
        width={'35%'}
        visible={visible}
        maskClosable={false}
        placement='right'
        onClose={onClose}>
        <Card>
          <Form onSubmit={handleSubmit} hideRequiredMark className={'inputForm'}>
            <Row gutter={16}>
              {inputSchema.fields.map((item, key) => {
                return (
                  <Col span={24} key={key}>
                    {item.customField ? item.customField :
                      <GetEachFormFields
                        item={item}
                        getFieldDecorator={getFieldDecorator}
                        formItemLayout={formItemLayout} />}
                  </Col>
                )
              })}
              <Col span={24}>
                <Form.Item style={{ marginTop: 40 }}>
                  <Button type='primary' htmlType='submit'>
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </Drawer>
    </>
  )
}


const WrappedForm = Form.create()(RevisedArticleDrawer)

export default WrappedForm
