import React, { useState, useEffect, useCallback } from 'react'
import { Collapse, Button, Drawer, Input, Row, Col, Card, Form, notification, Icon } from 'antd'
// import { RowTable } from './RowTable'
import {
  DisplayDate,
  GetDownloadLink,
  InputBox,
  PaymentModes,
  formItemLayout, chooseEmailModalFxn
} from '../../../components/_utils/appUtils'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import { processPaymentApi } from '../actions'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import { apiUrl } from '../../../settings'

const { Panel } = Collapse

const ProcessChangeDrawer = (props) => {
  const dispatch = useDispatch()
  let { article, onClose, visible, callback, form: { getFieldDecorator, setFieldsValue } } = props
  let [updateSubmittedData, setUpdateSubmittedData] = useState(false)
  let [visibleProcess, setVisibleProcess] = useState(false)
  let [certificateAttachment, setCertificateAttachment] = useState({})

  let { processPayment } = article
  let chooseDocument = (e) => {
    let { name, files } = e.target
    if (files && files.length) {
      setCertificateAttachment(files[0])
    }
  }
  useEffect(() => {
    setFormValue()
  }, [])

  let setFormValue = () => {
    setTimeout(() => {
      setFieldsValue({
        paymentMode: 'Cash',
        paymentType: article.currency,
        paymentDate: moment()
      })
    }, 500)
  }

  let events = {
    showDrawer: () => {
      setVisibleProcess(true)
      if (processPayment && processPayment.processingFee) {
        setTimeout(() => {
          setFieldsValue({
            ...processPayment,
            paymentDate: moment(article.paymentDate)
          })
        }, 500)
      } else {
        setTimeout(() => {
          setFieldsValue({
            paymentMode: 'Cash',
            paymentType: article.currency,
            paymentDate: moment()
          })
        }, 500)
      }
    },
    hideDrawer: () => {
      setVisibleProcess(false)
    },
    submitPayment: (e) => {
      const { form } = props
      e.preventDefault()
      form.validateFieldsAndScroll(async (err, valData) => {
        chooseEmailModalFxn({
          callback: async (email) => {
            let fd = new FormData()
            let obj = {
              processPayment: valData,
              articleId: article._id,
              status: 'Under Process',
              fromEmail: email,
              template: valData.processingFee > 0 ? 'processingChargesAcknowledgement' : 'zeroProcessingChargesAcknowledgement',
              type: 'article'
            }
            fd.append('obj', JSON.stringify(obj))
            fd.append('certificateAttachment', certificateAttachment)
            let { success, message } = await dispatch(processPaymentApi(fd))

            if (success) {
              form.resetFields()
              onClose()
              callback()
              dispatch({ type: 'START_LOAD_ARTICLE' })
              notification.success({
                message: message
              })
            }
          }
        })
      })
    }
  }

  let processFields = [
    {
      label: 'Payment Type',
      key: 'paymentType',
      required: true,
      placeholder: 'Payment Type',
      type: 'select',
      options: ['Rs.', 'USD'],
      onChange: (e) => {
        setFieldsValue({ paymentType: e })
      }
    },
    {
      label: 'Process Payment',
      key: 'processingFee',
      required: true,
      placeholder: 'Process Payment',
      type: 'number'
    },
    {
      label: 'Payment Mode',
      key: 'paymentMode',
      required: true,
      placeholder: 'Payment Mode',
      type: 'select',
      options: PaymentModes,
      onChange: (e) => {
        setFieldsValue({ paymentMode: e })
      }
    },
    {
      label: 'Process Payment Date',
      key: 'paymentDate',
      required: true,
      placeholder: 'Process Payment',
      type: 'date'
    },
    {
      label: 'Certificate Attachment',
      customField: (
        <InputBox title={`Certificate Attachment`} className={'rowFlex'}>
          <Input type={'file'} name={'certificateAttachment'} id={'certificateAttachment'}
                 className={'form-control'}
                 onChange={(e) => {
                   chooseDocument(e)
                 }} />
          {processPayment && processPayment.certificateAttachment ?
            <a className={'linkBtn'} target={'_blank'}
               href={GetDownloadLink(`/uploads/${processPayment.certificateAttachment}`)}>
              <Icon type={'eye'}></Icon>
            </a> : null}
        </InputBox>
      )
    }
  ]
  return (

    <div>
      {visible ?
        <Drawer visible={visible}
                title={`Processing Charges | Article No. ${article.articleNo}`}
                width={800}
                placement='right'
                onClose={onClose}>
          <Card bordered={true}>
            <Form hideRequiredMark className={'inputForm'} onSubmit={events.submitPayment}>
              <Row gutter={16}>
                {processFields.map((item, key) => {
                  return (
                    <Col span={12} md={12} sm={12} xs={24}
                         key={key}>
                      {item.customField ? item.customField :
                        <GetEachFormFields
                          item={item}
                          getFieldDecorator={getFieldDecorator}
                          formItemLayout={formItemLayout} />}
                    </Col>
                  )
                })}
                <Col span={24}>
                  <Form.Item style={{ marginTop: 32 }}>
                    <Button type='primary' htmlType='submit'>
                      SUBMIT
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Drawer>
        : null}
    </div>
  )
}

const ProcessingChargesDrawer = Form.create()(ProcessChangeDrawer)


export default ProcessingChargesDrawer
