import {
  Badge,
  Button,
  Card,
  Col,
  Form,
  Icon,
  Input,
  notification,
  Row,
  Switch,
  Table,
  Tooltip
} from 'antd'
import _ from 'lodash'
import moment from 'moment'
import React, { Component, useState } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { connect } from 'react-redux'
import { FormUtils, TableComp } from 'sz-react-utils-lite'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import {
  DisplayDate,
  formItemLayout
} from '../../../components/_utils/appUtils'
import Request from '../../../request'
import { getPushPathWrapper } from '../../../routes'
import { apiUrl } from '../../../settings'
import { getAllArticle } from '../actions'
import ArticleActivityList from '../articleView/ArticleActivityList'
import { ArticleFilters } from '../articleView/articlePages'
import { SelectReviewer } from '../reviewer/selectReviewer'
import styles from './styles.less'

const publishArr = [
  { value: true, display: 'Publish', _id: 'publish' },
  { value: false, display: 'Un-Publish', _id: 'unpublish' }
]

@Form.create()
class UnderProcessArticle extends Component {
  state = {
    edit: false,
    journalList: [],
    filters: {},
    ids: {},
    visibleSelectReview: false,
    article: {},
    visibleActivityList: false,
    total: ''
  }

  constructor(props) {
    super(props)
    this.table = React.createRef()
  }

  apiRequest = params => {
    return new Promise(async resolve => {
      let {
        dispatch,
        form: { getFieldValue }
      } = this.props
      let obj = {
        ...params,
        ...this.state.filters,
        articleStatus: 'Under Process'
      }
      let regExpFilters = []

      let data = await dispatch(
        getAllArticle({ ...obj, regExFilters: regExpFilters })
      )
      this.setState({ total: data.total })
      resolve(data)
    })
  }

  reload = () => {
    this.table.current.reload()
  }

  deleteArticle = async data => {
    let resp = await Request.deleteArticle(data)
    this.reload()
  }
  events = {
    showDrawer: article => {
      let ids = {}
      _.each(article.selectedReviewer, value => {
        if (value.reviewerId && value.reviewerId._id) {
          ids[value.reviewerId._id] = true
        }
      })
      this.setState({
        ids,
        visibleSelectReview: true,
        article
      })
    },
    hideDrawer: () => {
      this.setState(
        {
          ids: {},
          visibleSelectReview: false,
          article: {}
        },
        () => {
          this.reload()
        }
      )
    }
  }

  getVolumeList = async data => {
    let obj = {
      slugJournal: data
    }
    let resp = await Request.journalVolume(obj)
    let volumeList = []
    _.each(resp, item => {
      volumeList.push({
        _id: item.slugVolume,
        value: item.slugVolume,
        display: item.name
      })
    })
    this.setState({
      volumeList,
      issueList: []
    })
  }

  getJournalList = async () => {
    let { data } = await Request.journalName()
    let journalList = []
    _.each(data, item => {
      journalList.push({
        _id: item.slugJournal,
        value: item.slugJournal,
        display: item.name
      })
    })
    this.setState({
      journalList
    })
  }
  getIssueList = async data => {
    let obj = {
      slugJournal: this.props.form.getFieldValue('slugJournal'),
      slugVolume: data
    }
    let resp = await Request.journalIssue(obj)
    let issueList = []
    _.each(resp, item => {
      issueList.push({
        _id: item.slugIssue,
        value: item.slugIssue,
        display: item.name
      })
    })
    this.setState({
      issueList
    })
  }

  reloadTable = data => {
    if (data.fromDate && data.toDate) {
      data['paymentDate'] = {
        $gte: data.fromDate,
        $lte: data.toDate
      }
      delete data['fromDate']
      delete data['toDate']
    }
    this.setState({ filters: data }, () => {
      this.reload()
    })
  }

  componentDidMount() {}

  render() {
    const {
      dispatch,
      form: { getFieldDecorator, getFieldValue, setFieldsValue },
      loading
    } = this.props
    const { journalList, visibleSelectReview, ids, article } = this.state

    const formProps = {
      getFieldDecorator,
      getFieldValue,
      loading
    }
    const columns = [
      {
        key: 'actions',
        width: 150,
        render: (val, record) => (
          <React.Fragment>
            <a className={'roundSmallBtn'} href={`/articleView/${record._id}`}>
              View Details
            </a>
            <Button
              className={'roundSmallBtn'}
              onClick={() => this.events.showDrawer(record)}>
              Select Reviewer
            </Button>
            <Button
              className={'roundSmallBtn'}
              onClick={() =>
                this.setState({ visibleActivityList: true, article: record })
              }>
              Article Activities
            </Button>
          </React.Fragment>
        )
      },
      {
        title: 'Submitted Date',
        key: 'submittedDate',
        dataIndex: 'submittedDate',
        width: 100,
        render: item => {
          return item ? DisplayDate(item) : null
        }
      },
      {
        title: 'Article Id',
        key: 'articleNo',
        dataIndex: 'articleNo',
        width: '85px'
      },
      {
        title: 'P.C. Received',
        key: 'paymentDate',
        dataIndex: 'paymentDate',
        width: 100,
        render: item => {
          return item ? DisplayDate(item) : null
        }
      },
      {
        title: 'Title',
        key: 'manuScriptTitle',
        dataIndex: 'manuScriptTitle',
        render: item => {
          return item ? ReactHtmlParser(item) : null
        }
      },
      {
        title: 'Area Of Research',
        key: 'areaOfResearch',
        dataIndex: 'areaOfResearch'
      },
      {
        title: 'Author Name',
        key: 'correspondingAuthor',
        dataIndex: 'correspondingAuthor'
      },
      {
        title: 'Country',
        key: 'country',
        dataIndex: 'country'
      }
    ]

    const fil = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 }
      }
    }

    return (
      <PageHeaderWrapper title={`Under Process Article (${this.state.total})`}>
        <Card bordered={true}>
          <ArticleFilters
            callback={data => {
              this.reloadTable(data)
            }}
          />
          <TableComp
            ref={this.table}
            columns={columns}
            apiRequest={this.apiRequest}
          />
        </Card>

        {visibleSelectReview ? (
          <SelectReviewer
            article={article}
            ids={ids}
            onClose={() => this.events.hideDrawer()}
            visible={visibleSelectReview}
          />
        ) : null}
        {this.state.visibleActivityList ? (
          <ArticleActivityList
            articleId={this.state.article._id}
            articleNo={this.state.article.articleNo}
            visible={this.state.visibleActivityList}
            onClose={() => this.setState({ visibleActivityList: false })}
          />
        ) : null}
      </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global }) => ({
  categories: global.categories
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnderProcessArticle)
