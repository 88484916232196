import axios from 'axios'

import { notification } from 'antd'
import { apiUrl } from './settings'

export const API_URL = apiUrl

let authAxios = axios.create({
  baseURL: apiUrl
})

export const getToken = () => {
  return {
    headers: {
      'Access-Control-Allow-Origin': '*',
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  }
}

export let customAxios = axios.create({})

customAxios.interceptors.response.use(
  function(response) {
    return response
  },
  function(error) {
    handleErr(error)
    return Promise.reject(error)
  }
)

export const handleErr = err => {
  try {
    if (err.response.status === 401) {
      localStorage.clear()
      window.location.reload()
      console.log('this should be here ')
    }
  } catch (e) {
  }
}

class Request {
  constructor() {
  }

  error = err => {
    try {
      if (err.response.status === 401) {
        localStorage.clear()
      }
    } catch (e) {
    }
  }

  login(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/login', data)
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  forgotPassword(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/forget/password', data)
        .then(d => {
          let { data } = d
          // console.log('d', data)
          if (!data.error) {
            notification.success({
              message: data.message
            })
          }
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  ResetPassword(data) {
    return new Promise((next, error) => {
      authAxios
        .put('/reset-password', data)
        .then(d => {
          let { data } = d
          // console.log('d', data)
          if (!data.error) {
            notification.success({
              message: data.message
            })
          }
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  checkResetLink(data) {
    return new Promise((next, error) => {
      authAxios
        .post('/api/checkResetLink', data)
        .then(d => {
          let { data } = d
          next(data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  addUser(data) {
    return new Promise(next => {
      authAxios
        .post('/users', { ...data }, getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getAllJournals(data) {
    return new Promise(next => {
      let config = {
        params: { ...data },
        ...getToken()
      }
      authAxios
        .get('/allJournals', config)
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getNews(data) {
    return new Promise(next => {
      authAxios
        .get('/news', { params: { ...data } }, getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  addNews(data) {
    return new Promise(next => {
      authAxios
        .post('/news', { ...data }, getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  updateNews(data, id) {
    return new Promise(next => {
      authAxios
        .put('/news', { ...data, ...id }, getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getAllNews(data) {
    return new Promise(async (next) => {
      let config = {
        params: { ...data },
        ...await getToken()
      }
      authAxios
        .get('/allNews', config)
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  deleteNews(data) {
    return new Promise(next => {
      authAxios
        .post('/delete/news', { ...data }, getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getNewsLetter(data) {
    return new Promise(next => {
      authAxios
        .get('/newsLetter', { params: { ...data } }, getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  addNewsLetter(data) {
    return new Promise(next => {
      authAxios
        .post('/newsLetter', { ...data }, getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  updateNewsLetter(data, id) {
    return new Promise(next => {
      authAxios
        .put('/newsLetter', { ...data, ...id }, getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getAllNewsLetter(data) {
    return new Promise(async (next) => {
      let config = {
        params: { ...data },
        ...await getToken()
      }
      authAxios
        .get('/allNewsLetter', config)
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  deleteNewsLetter(data) {
    return new Promise(next => {
      authAxios
        .post('/delete/newsLetter', { ...data }, getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getJournalAjax() {
    return new Promise(next => {
      authAxios
        .get('/journalsAjax', getToken())
        .then(d => {
          next(d.data.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getSubscription(data) {
    return new Promise(next => {
      authAxios
        .get('/subscription', { params: { ...data } }, getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  addSubscription(data) {
    return new Promise(next => {
      // console.log('aagya')
      authAxios
        .post('/subscription', { ...data }, getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  updateSubscription(data, id) {
    return new Promise(next => {
      authAxios
        .put('/subscription', { ...data, ...id }, getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getAllSubscription(data) {
    return new Promise(next => {
      authAxios
        .get('/allSubscription', { params: { ...data } }, getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getAllArticle(data) {
    return new Promise(next => {
      authAxios
        .get('/allArticle', { params: { ...data } }, getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  deleteArticle(data) {
    return new Promise(next => {
      authAxios
        .post('/delete/article', { ...data }, getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getArticle(data) {
    return new Promise(next => {
      authAxios
        .get('/article', { params: { ...data } }, getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  updateArticle(data, id) {
    return new Promise(next => {
      authAxios
        .put('/article', { ...data, ...id }, getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  journalName(data) {
    return new Promise(next => {
      authAxios
        .get('/getJournalName', getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  journalVolume(data) {
    return new Promise(next => {
      authAxios
        .post('/getJournalVolume', { ...data }, getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getAllSubscriber(data) {
    return new Promise(next => {
      authAxios
        .get('/allSubscriber', { params: { ...data } }, getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getLastYearPackageInfo(data) {
    return new Promise(next => {
      authAxios
        .post('/getLastYearPackageInfo', { ...data }, getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  addAuthorGuideLine(data) {
    return new Promise(next => {
      authAxios
        .post('/authorGuideLine', { ...data }, getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  updateAuhtorGuideLine(data, id) {
    return new Promise(next => {
      authAxios
        .put('/authorGuideLine', { ...data }, getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getAuthorGuideLine() {
    return new Promise(next => {
      authAxios
        .get('/authorGuideLine', getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getSubscriptionYearByJournals(data) {
    return new Promise(next => {
      authAxios
        .post('/getSubscriptionYearByJournals', { ...data })
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getAllPayments(data) {
    return new Promise(next => {
      authAxios
        .get('/allPayment', { params: { ...data } }, getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  deleteUserApi(data) {
    return new Promise(async next => {
      authAxios
        .post('/deleteUser', { ...data })
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  addJournalVolume(data) {
    return new Promise(async next => {
      // console.log(getToken())
      authAxios
        .post('/volume', { ...data }, getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getJournalsVolumeList(data) {
    return new Promise(next => {
      let config = {
        params: { ...data },
        ...getToken()
      }
      authAxios
        .get('/volume', config)
        .then(data => {
          next(data.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  addJournalIssue(data) {
    return new Promise(next => {
      authAxios
        .post('/issues', { ...data }, getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getJournalIssue(data) {
    return new Promise(next => {
      let config = {
        params: { ...data },
        ...getToken()
      }
      authAxios
        .get('/issues', config)
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  removeJournalIssue(data) {
    return new Promise(next => {
      let config = {
        params: { ...data },
        ...getToken()
      }
      // console.log('config', config)
      authAxios
        .delete('/issues', config)
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getChiefEditor(data) {
    return new Promise(next => {
      authAxios
        .post('/getChiefEditor', data, getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  updateWebPage(data) {
    return new Promise(next => {
      authAxios
        .post('/updateWebPageDrawer', { ...data }, getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  deleteDrawerItem(data) {
    return new Promise(next => {
      authAxios
        .post('/deleteDrawerItem', { ...data }, getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }

  getAdminUsers(data) {
    return new Promise(next => {
      authAxios
        .get('/getAdminUsers', getToken())
        .then(d => {
          next(d.data)
        })
        .catch(err => {
          next({ error: true, err })
          this.error(err)
        })
    })
  }
}

export default new Request()
