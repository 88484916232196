import { notification } from 'antd'
import {
  listAllReviewerURL,
  singleReviewerURL
} from '../apis'
import { getToken, customAxios as axios } from '../../../request'
import { hidePageLoad, showPageLoad } from '../../../modules/actions'

export const listAllReviewer = async (filters) => {
  let config = {
    params: { ...filters },
    ...getToken()
  }
  let { data } = await axios.get(listAllReviewerURL(), config)
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const UpdateReviewerStatus = (obj) => async (dispatch) => {
  let { data } = await axios.post(listAllReviewerURL(), obj, getToken())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    notification.success({
      message: data.message || 'Success'
    })
  }
  return data
}

export const singleReviewer = (id) => async (dispatch) => {
  let config = {
    ...getToken()
  }
  let { data } = await axios.get(singleReviewerURL(id), config)
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
