import React from 'react'
import { Row, Col } from 'antd'

const RowTable = (props) => {
  let { title, value } = props
  return (
    <div className={'tableRow'}>
      <div className={'title'}>
        {title}:
      </div>
      <div>
        {value}
      </div>
    </div>
  )
}

const CustomCardTitle = (props) => {
  let { title } = props

  return (
    <div className={'customTitle'}>
      {title}
    </div>
  )

}
export { RowTable, CustomCardTitle }
