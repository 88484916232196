import React, { useEffect, useState } from 'react'
import { Row, Col, Card, Drawer, Form, Button, Icon } from 'antd'
import { connect } from 'react-redux'
import ReactHtmlParser from 'react-html-parser'

const DescriptionDrawer = (props) => {

  // let [visible, setVisible] = useState(false)
  // let events = {
  //   showDrawer: () => {
  //     setVisible(true)
  //   },
  //   hideDrawer: () => {
  //     setVisible(false)
  //   }
  // }

  let { children, data, visible, onClose } = props
  return (
    <React.Fragment>

      {visible ?
        <Drawer visible={visible}
                width={'60%'}
                placement='right'
                onClose={onClose}
                title={'Profile Description'}>
          <Card>
            {ReactHtmlParser(data)}
          </Card>
        </Drawer>
        : null}
    </React.Fragment>
  )
}
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapDispatchToProps
)(DescriptionDrawer)

// export default ViewMember