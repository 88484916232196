import React, { useEffect, useRef, useState } from 'react'
import { Drawer, Card, Button, Form, Row, Col } from 'antd'
import {
  DisplayDate,
  formItemLayout
} from '../../../components/_utils/appUtils'
import { useDispatch } from 'react-redux'
import { ChooseEmailModal, chooseEmailModalFxn } from '../../../components/_utils/appUtils'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import { AcceptanceArticle } from '../actions'
import moment from 'moment'
import { currencyList, PaymentModes } from '../../../components/_utils/appUtils'

const AcceptanceDrawer = (props) => {
  let {
    children,
    form: { getFieldDecorator, setFieldsValue },
    callback = null,
    visible,
    onClose,
    article,
    edit
  } = props
  const dispatch = useDispatch()


  let setFormData = () => {
    let { article, edit } = props
    if (edit) {
      props.form.setFieldsValue({
        acceptedArticlePaymentDate: moment(article.acceptedArticlePaymentDate),
        acceptedArticlePayment: article.acceptedArticlePayment,
        currencyTypeOfApprovalAmount: article.currencyTypeOfApprovalAmount,
        acceptedArticlePaymentMode: article.acceptedArticlePaymentMode,
        acceptedArticlePaymentDiscription: article.composeIssues
      })
    } else {
      props.form.setFieldsValue({
        currencyTypeOfApprovalAmount: 'Rs.',
        acceptedArticlePaymentDate: moment()
      })
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setFormData()
    }, 1000)

  }, [])


  let [visibleDrawer, setVisibleDrawer] = useState(false)

  const events = {
    show: (event) => {
      setVisibleDrawer(true)
      setTimeout(() => {
        setFormData()
      }, 1000)

    },
    hide: (event) => {
      setVisibleDrawer(false)
    }
  }

  const inputSchema = {
    fields: [
      {
        label: 'Date',
        key: 'acceptedArticlePaymentDate',
        required: true,
        placeholder: 'Date of Enter',
        type: 'date'
      },
      {
        label: 'Amount Received',
        key: 'acceptedArticlePayment',
        placeholder: 'Amount'
      },
      {
        label: 'Currency',
        key: 'currencyTypeOfApprovalAmount',
        required: true,
        placeholder: 'Currency',
        type: 'select',
        options: currencyList,
        onChange: (e) => {
          setFieldsValue({ currencyTypeOfApprovalAmount: e })
        }
      },
      {
        label: 'Mode Of payment',
        key: 'acceptedArticlePaymentMode',
        required: true,
        type: 'select',
        options: PaymentModes,
        onChange: (e) => {
          setFieldsValue({ acceptedArticlePaymentMode: e })
        }
      },
      {
        label: 'Notes',
        key: 'acceptedArticlePaymentDiscription',
        placeholder: 'Notes',
        type: 'textArea',
        span: 24
      }
    ]
  }


  const handleSubmit = e => {
    const { form, article } = props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      chooseEmailModalFxn({
        callback: async (email) => {
          valData.fromEmail = email
          valData.articleId = article._id
          valData.template = valData.acceptedArticlePayment > 0 ? 'acceptanceArticle' : 'acceptanceArticleWithoutCharges'
          if (!edit) {
            valData.articleStatus = 'Accepted Article'
          }
          let { success } = await dispatch(AcceptanceArticle(valData))
          if (success) {
            onClose()
            dispatch({ type: 'START_LOAD_ARTICLE' })
            if (props.callback) {
              props.callback()
            }
            events.hide()
          }
        }
      })
    })
  }

  return (
    <>
      <div onClick={() => events.show()}>
        {children}
      </div>
      <Drawer
        title={`Acceptance Article | Article No. ${article.articleNo}`}
        width={'60%'}
        visible={visible}
        placement='right'
        onClose={onClose}>
        <Card>
          <Form onSubmit={handleSubmit} hideRequiredMark className={'inputForm'}>
            <Row gutter={16}>
              {inputSchema.fields.map((item, key) => {
                return (
                  <Col span={item.span ? item.span : 12} key={key}>
                    <GetEachFormFields getFieldDecorator={getFieldDecorator}
                                       item={item} formItemLayout={formItemLayout}/>
                  </Col>
                )
              })}
              <Col span={24}>
                <Form.Item style={{ marginTop: 40 }}>
                  <Button type='primary' htmlType='submit'>
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </Drawer>
    </>
  )
}


const WrappedForm = Form.create()(AcceptanceDrawer)

export default WrappedForm
