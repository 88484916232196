import React, { useEffect, useRef, useState } from 'react'
import { TableComp } from 'sz-react-utils-lite'
import { Drawer, Tooltip, Card, Button, Form, Row, Col, Radio, Input, Icon, notification } from 'antd'
import {
  DisplayDate,
  DefaultTablePagination,
  formItemLayout,
  NotificationBox, InputBox, GetDownloadLink
} from '../../../components/_utils/appUtils'
import { getAllMember } from '../../members/actions'
import { useDispatch } from 'react-redux'
import { ArticleInfo } from '../articleView/articlePages'
import { ChooseEmailModal, chooseEmailModalFxn } from '../../../components/_utils/appUtils'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import { ReviewerComments, RevisedArticle } from '../actions'
import moment from 'moment'
import { getSingleMember } from '../../ReviewerContainer/actions'


const ReviewerPendingDrawer = (props) => {
  let {
    form: { getFieldDecorator, getFieldValue, setFieldsValue },
    Id,
    visible,
    onClose,
    edit,
    article
  } = props
  const dispatch = useDispatch()

  let [visibleDrawer, setVisibleDrawer] = useState(visible)
  let [webArticleFile, setWebArticleFile] = useState({})
  let [webCommentsFile, setWebCommentsFile] = useState({})
  // let [responseFromAuthorAttachment, setResponseFromAuthorAttachment] = useState({})

  let [attachment, setAttachment] = useState({
    responseFromAuthorAttachment: '',
    revisedArticleAttachment: ''
  })
  const setFormData = () => {
    if (edit) {
      setTimeout(() => {
        props.form.setFieldsValue({
          revisedArticleDate: moment(article.revisedArticleDate)
        })
      }, 100)

      setAttachment({
        revisedArticleAttachment: article.revisedArticleAttachment && article.revisedArticleAttachment.path ? article.revisedArticleAttachment.path : '',
        responseFromAuthorAttachment: article.responseFromAuthorAttachment && article.responseFromAuthorAttachment.path ? article.responseFromAuthorAttachment.path : ''
      })
    } else {
      setTimeout(() => {
        props.form.setFieldsValue({
          revisedArticleDate: moment()
        })
      }, 100)
    }
  }

  useEffect(() => {
    setFormData()
  }, [])


  const events = {
    show: (event) => {
      setVisibleDrawer(true)

    },
    hide: (event) => {
      setVisibleDrawer(false)
    }
  }

  const inputSchema = {
    fields: [
      {
        label: 'Article File',
        customField: (
          <InputBox title={`Article File`} className={'rowFlex'}>
            <Input type={'file'} name={'webArticleFile'} id={'webArticleFile'}
                   className={'form-control'}
                   onChange={(e) => {
                     let { name, files } = e.target
                     if (files && files.length) {
                       setWebArticleFile(files[0])
                     }
                   }} />

            {/*{edit && attachment && attachment.revisedArticleAttachment ?*/}
            {/*  <a className={'linkBtn'} target={'_blank'} href={`${attachment.revisedArticleAttachment}`}>*/}
            {/*    <Icon type={'eye'} />*/}
            {/*  </a> : null}*/}
          </InputBox>
        )
      },
      {
        label: 'Comments File',
        customField: (
          <InputBox title={`Comments File`} className={'rowFlex'}>
            <Input type={'file'} name={'webCommentsFile'} id={'webCommentsFile'}
                   className={'form-control'}
                   onChange={(e) => {
                     let { name, files } = e.target
                     if (files && files.length) {
                       setWebCommentsFile(files[0])
                     }
                   }} />

            {/*{edit && attachment && attachment.responseFromAuthorAttachment ?*/}
            {/*  <a className={'linkBtn'} target={'_blank'} href={`${attachment.responseFromAuthorAttachment}`}>*/}
            {/*    <Icon type={'eye'} />*/}
            {/*  </a> : null}*/}
          </InputBox>
        )
      }
    ]

  }


  const handleSubmit = e => {
    const { form, callback } = props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (err) {
        notification.error({
          message: err.message || 'Error'
        })
      } else {
        let user = JSON.parse(localStorage.getItem('user'))
        let { data: { _id }, error } = await dispatch(getSingleMember(user.reviewerId))
        let fd = new FormData()
        let obj = {
          articleId: article._id,
          reviewerId: _id
          // template: 'revisedArticleAcknowledgement',
          // fromEmail: email
        }
        fd.append('obj', JSON.stringify(obj))

        if (webArticleFile && webArticleFile.name) {
          fd.append('webArticleFile', webArticleFile)
        }
        if (webCommentsFile && webCommentsFile.name) {
          fd.append('webCommentsFile', webCommentsFile)
        }
        let { success, data } = await dispatch(ReviewerComments(fd))
        if (success) {
          dispatch({ type: 'START_LOAD_ARTICLE' })
          onClose()
          //callback()
          setWebArticleFile({})
          setWebCommentsFile({})
        }
      }
    })
  }

  return (
    <>
      {/*<div onClick={(event) => events.show(event)}>*/}
      {/*  {children}*/}
      {/*</div>*/}
      <Drawer
        title={`Add Comments | Article No. ${article.articleNo}`}
        width={'50%'}
        visible={visible}
        placement='right'
        onClose={onClose}>
        <Card>
          <Form onSubmit={handleSubmit} hideRequiredMark className={'inputForm'}>
            <Row gutter={16}>
              {inputSchema.fields.map((item, key) => {
                return (
                  <Col span={item.span ? item.span : 8} key={key}>
                    {item.customField ? item.customField :
                      <GetEachFormFields
                        item={item}
                        getFieldDecorator={getFieldDecorator}
                        formItemLayout={formItemLayout} />}
                  </Col>
                )
              })}
              <Col span={24}>
                <Form.Item style={{ marginTop: 40 }}>
                  <Button type='primary' htmlType='submit'>
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </Drawer>
    </>
  )
}


const WrappedForm = Form.create()(ReviewerPendingDrawer)

export default WrappedForm


//
// {record.underReviewDetails.articleAttachment && record.underReviewDetails.articleAttachment.path ?
//   <a target={'_blank'} href={`${record.underReviewDetails.articleAttachment.path}`}>
//     Article Attachment
//   </a> : null}
// <br />
// {record.underReviewDetails.commentsAttachment && record.underReviewDetails.commentsAttachment.path ?
//   <a target={'_blank'} href={`${record.underReviewDetails.commentsAttachment.path}`}>
//     Comments Attachments
//   </a> : null}