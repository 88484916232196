import {
  Button,
  Card,
  Col,
  Collapse,
  Drawer,
  Form,
  Icon,
  Input,
  notification,
  Row
} from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import {
  chooseEmailModalFxn,
  DisplayDate,
  formItemLayout,
  GetDownloadLink,
  InputBox,
  PaymentModes
} from '../../../components/_utils/appUtils'
import { apiUrl } from '../../../settings'
import { processPaymentApi } from '../actions'
import { RowTable } from './RowTable'

const { Panel } = Collapse

const ProcessingChargesFxn = props => {
  const dispatch = useDispatch()
  let {
    article,
    form: { getFieldDecorator, setFieldsValue }
  } = props
  let [updateSubmittedData, setUpdateSubmittedData] = useState(false)
  let [visibleProcess, setVisibleProcess] = useState(false)
  let [certificateAttachment, setCertificateAttachment] = useState({})
  let { processPayment } = article
  let chooseDocument = e => {
    let { name, files } = e.target
    if (files && files.length) {
      setCertificateAttachment(files[0])
    }
  }

  let events = {
    showDrawer: () => {
      setVisibleProcess(true)
      if (processPayment && processPayment.processingFee) {
        setTimeout(() => {
          setFieldsValue({
            ...processPayment,
            paymentDate: moment(article.paymentDate)
          })
        }, 500)
      } else {
        setTimeout(() => {
          setFieldsValue({
            paymentMode: 'Cash',
            paymentType: article.currency,
            paymentDate: moment()
          })
        }, 500)
      }
    },
    hideDrawer: () => {
      setVisibleProcess(false)
    },
    submitPayment: e => {
      const { form } = props
      e.preventDefault()
      form.validateFieldsAndScroll(async (err, valData) => {
        console.log('google.nosc', valData)
        chooseEmailModalFxn({
          callback: async email => {
            let fd = new FormData()
            let obj = {
              processPayment: valData,
              articleId: article._id,
              status: 'Under Process',
              fromEmail: email,
              template:
                valData.processingFee > 0
                  ? 'processingChargesAcknowledgement'
                  : 'zeroProcessingChargesAcknowledgement',
              type: 'article'
            }
            fd.append('obj', JSON.stringify(obj))
            fd.append('certificateAttachment', certificateAttachment)
            let { success, message } = await dispatch(processPaymentApi(fd))

            if (success) {
              form.resetFields()
              dispatch({ type: 'START_LOAD_ARTICLE' })
              events.hideDrawer()
              notification.success({
                message: message
              })
            }
          }
        })
      })
    }
  }

  let processFields = [
    {
      label: 'Payment Type',
      key: 'paymentType',
      required: true,
      placeholder: 'Payment Type',
      type: 'select',
      options: ['Rs.', 'USD'],
      onChange: e => {
        setFieldsValue({ paymentType: e })
      }
    },
    {
      label: 'Process Payment',
      key: 'processingFee',
      required: true,
      placeholder: 'Process Payment',
      type: 'number'
    },
    {
      label: 'Payment Mode',
      key: 'paymentMode',
      required: true,
      placeholder: 'Payment Mode',
      type: 'select',
      options: PaymentModes,
      onChange: e => {
        setFieldsValue({ paymentMode: e })
      }
    },
    {
      label: 'Process Payment',
      key: 'paymentDate',
      required: true,
      placeholder: 'Process Payment',
      type: 'date'
    },
    {
      label: 'Certificate Attachment',
      customField: (
        <InputBox title={`Certificate Attachment`} className={'rowFlex'}>
          <Input
            type={'file'}
            name={'certificateAttachment'}
            id={'certificateAttachment'}
            className={'form-control'}
            onChange={e => {
              chooseDocument(e)
            }}
          />
          {processPayment && processPayment.certificateAttachment ? (
            <a
              className={'linkBtn'}
              target={'_blank'}
              href={GetDownloadLink(
                `/uploads/${processPayment.certificateAttachment}`
              )}>
              <Icon type={'eye'} />
            </a>
          ) : null}
        </InputBox>
      )
    }
  ]

  let getExtraButton = () => {
    return (
      <Button
        type={'primary'}
        size={'small'}
        onClick={event => {
          event.stopPropagation()
          events.showDrawer()
        }}>
        {article.processPayment ? 'Edit' : 'Add'}
      </Button>
    )
  }
  let headerTitle = `Processing Charges ${
    article.paymentDate ? `(${DisplayDate(article.paymentDate)})` : ''
  }`
  return (
    <div>
      <Collapse defaultActiveKey={['1']} expandIconPosition={'right'}>
        <Panel header={headerTitle} key="1" extra={getExtraButton()}>
          <div className={'tableBox'}>
            <RowTable title={'Date'} value={DisplayDate(article.paymentDate)} />
            <RowTable
              title={'Amount'}
              value={
                processPayment &&
                processPayment.processingFee &&
                updateSubmittedData === false
                  ? `${processPayment.paymentType} ${
                      processPayment.processingFee
                    }`
                  : null
              }
            />
            <RowTable
              title={'Mode Of Payment'}
              value={
                processPayment &&
                processPayment.processingFee &&
                !updateSubmittedData
                  ? `${processPayment.paymentMode}`
                  : null
              }
            />
            <RowTable
              title={'Certificate Attachment'}
              value={
                processPayment &&
                processPayment.certificateAttachment &&
                processPayment.certificateAttachment.path ? (
                  <a
                    download
                    target={'_blank'}
                    className="downloadLink"
                    href={processPayment.certificateAttachment.path}>
                    {' '}
                    Download
                  </a>
                ) : (
                  'No Attachment'
                )
              }
            />
          </div>
        </Panel>
      </Collapse>

      {visibleProcess ? (
        <Drawer
          visible={visibleProcess}
          width={800}
          placement="right"
          onClose={events.hideDrawer}
          title={`Processing Charges`}>
          <Card bordered={true}>
            <Form
              hideRequiredMark
              className={'inputForm'}
              onSubmit={events.submitPayment}>
              <Row gutter={16}>
                {processFields.map((item, key) => {
                  return (
                    <Col span={12} md={12} sm={12} xs={24} key={key}>
                      {item.customField ? (
                        item.customField
                      ) : (
                        <GetEachFormFields
                          item={item}
                          getFieldDecorator={getFieldDecorator}
                          formItemLayout={formItemLayout}
                        />
                      )}
                    </Col>
                  )
                })}
                <Col span={24}>
                  <Form.Item style={{ marginTop: 32 }}>
                    <Button type="primary" htmlType="submit">
                      SUBMIT
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Drawer>
      ) : null}
    </div>
  )
}

const ProcessingCharges = Form.create()(ProcessingChargesFxn)

export default ProcessingCharges
