import React, {useEffect, useRef, useState} from 'react'
import {Drawer, Card, Button, Form, Row, Col, Input, Icon, notification} from 'antd'
import {
    formItemLayout, InputBox
} from '../../../components/_utils/appUtils'
import {useDispatch} from 'react-redux'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import {updateCompletePublishArticleFxn} from '../actions'
import Request from '../../../request'
import _ from 'lodash'
import moment from 'moment'
import {hidePageLoad, showPageLoad} from "../../../modules/actions";

const CompletePublishUpdateDrawer = (props) => {
    let {
        children,
        form: {getFieldDecorator, setFieldsValue, getFieldValue},
        callback = null,
        visible,
        onClose,
        article,
        edit
    } = props
    const dispatch = useDispatch()

    let [attachment, setAttachment] = useState({
        finalAttachmentPublished: '',
        contentAttachmentPublished: '',
        coverAttachmentPublished: ''
    })

    useEffect(() => {
        getJournalList()
        dispatch(showPageLoad())
        setTimeout(() => {
            setFormData()
        }, 500)
    }, [])


    let setFormData = () => {
        let {article} = props
        if (edit) {
            props.form.setFieldsValue({
                sendForPublicationDate: moment(article.sendForPublicationDate),
                journalId: article.journalId,
                volumeName: article.volumeName,
                issues: article.issues,
                pageRange: article.pageRange,
                articleOrder: article.articleOrder
            })
            setAttachment({
                finalAttachmentPublished: article.finalAttachmentPublished && article.finalAttachmentPublished.path ? article.finalAttachmentPublished.path : '',
                contentAttachmentPublished: article.contentAttachmentPublished && article.contentAttachmentPublished.path ? article.contentAttachmentPublished.path : '',
                coverAttachmentPublished: article.coverAttachmentPublished && article.coverAttachmentPublished.path ? article.coverAttachmentPublished.path : ''
            })
            if (article.journalId) {
                getJournalsVolumeData(article.journalId)
                setJournalId(article.journalId)
            }
            dispatch(hidePageLoad())
        } else {
            props.form.setFieldsValue({
                sendForPublicationDate: moment()
            })
            dispatch(hidePageLoad())
        }
    }

    let [visibleDrawer, setVisibleDrawer] = useState(false)
    let [journalId, setJournalId] = useState('')
    let [journalNameList, setJournalNameList] = useState([])
    let [journalVolumeList, setJournalVolumeList] = useState([])
    let [journalIssueList, setJournalIssueList] = useState([])
    let [finalArticleAttachment, setFinalArticleAttachment] = useState({})
    let [contentAttachmentPublished, setContentAttachmentPublished] = useState({})
    let [volumeId, setVolumeId] = useState('')

    const events = {
        show: (event) => {
            setVisibleDrawer(true)
            getJournalList()
            setTimeout(() => {
                setFormData()
            }, 1000)
        },
        hide: (event) => {
            setVisibleDrawer(false)
        }
    }

    const getJournalList = async () => {
        let {data} = await Request.journalName()
        let journalList = []
        _.each(data, (item) => {
            journalList.push({
                _id: item._id,
                value: item.slugJournal,
                display: item.name
            })
        })
        setJournalNameList(journalList)
    }

    const getJournalsVolumeData = async (id) => {
        let {data} = await Request.getJournalsVolumeList({journalId: id})
        let volumeList = []
        _.each(data, (item) => {
            volumeList.push({
                _id: item._id,
                name: item.volumeName
            })
        })
        setJournalVolumeList(volumeList)
    }

    useEffect(() => {
        getJournalsIssueData(getFieldValue('volumeName'))
    }, [getFieldValue('volumeName'), journalVolumeList])

    const getJournalsIssueData = async (volumeName) => {

        let volume = _.find(journalVolumeList, (item) => {
            return item.name.toLowerCase() == volumeName.toLowerCase()
        })
        if (volume && volume._id) {
            setVolumeId(volume._id)
            let {data} = await Request.getJournalIssue({journalId: getFieldValue('journalId'), volumeId: volume._id})
            let issueList = []
            _.each(data, (item) => {
                issueList.push({
                    _id: item._id,
                    name: item.issueName
                })
            })
            setJournalIssueList(issueList)
        }
    }

    const inputSchema = {
        fields: [
            {
                label: 'Date',
                key: 'sendForPublicationDate',
                required: true,
                placeholder: 'Select Date',
                type: 'date'
            },
            {
                label: 'Journal Name',
                key: 'journalId',
                placeholder: 'Select',
                type: 'select',
                required: true,
                options: journalNameList,
                onChange: (e) => {
                    setFieldsValue({journalId: e, volumeName: '', issues: ''})
                    getJournalsVolumeData(e)
                }
            },
            {
                label: 'Volume',
                key: 'volumeName',
                placeholder: 'Select',
                type: 'select',
                required: true,
                keyAccessor: x => x.name,
                valueAccessor: x => x.name,
                options: journalVolumeList,
                onChange: (e) => {
                    setFieldsValue({volumeName: e, issues: ''})
                }
            },
            {
                label: 'Issue',
                key: 'issues',
                placeholder: 'Select',
                type: 'select',
                required: true,
                keyAccessor: x => x.name,
                valueAccessor: x => x.name,
                options: journalIssueList,
                onChange: (e) => {
                    setFieldsValue({issues: e})
                }
            },
            {
                title: 'Page Range',
                key: 'pageRange',
                required: true
            },
            {
                title: 'Article Order',
                key: 'articleOrder',
                required: true
            },
            {
                label: 'Final Manuscript Attachment',
                customField: (
                    <InputBox title={`Final Manuscript Attachment`} className={'rowFlex'}>
                        <Input type={'file'} name={'finalAttachmentPublished'} id={'finalAttachmentPublished'}
                               className={'form-control'}
                               onChange={(e) => {
                                   let {name, files} = e.target
                                   if (files && files.length) {
                                       setFinalArticleAttachment(files[0])
                                   }
                               }}/>
                        {edit && attachment && attachment.finalAttachmentPublished ?
                            <a className={'linkBtn'} target={'_blank'} href={`${attachment.finalAttachmentPublished}`}>
                                <Icon type={'eye'}/>
                            </a> : null}
                    </InputBox>
                )
            },
            {
                label: 'Attachment of Content',
                customField: (
                    <InputBox title={`Attachment of Content`} className={'rowFlex'}>
                        <Input type={'file'} name={'contentAttachmentPublished'} id={'contentAttachmentPublished'}
                               className={'form-control'}
                               onChange={(e) => {
                                   let {name, files} = e.target
                                   if (files && files.length) {
                                       setContentAttachmentPublished(files[0])
                                   }
                               }}/>
                        {edit && attachment && attachment.contentAttachmentPublished ?
                            <a className={'linkBtn'} target={'_blank'}
                               href={`${attachment.contentAttachmentPublished}`}>
                                <Icon type={'eye'}/>
                            </a> : null}
                    </InputBox>
                )
            }

        ]
    }
    const handleSubmit = e => {
        const {form, article} = props
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                if (!edit) {
                    if (!finalArticleAttachment || (finalArticleAttachment && !finalArticleAttachment.name)) {
                        notification.error({
                            message: 'Required',
                            description: 'Choose Final Article Attachment'
                        })
                        return
                    }
                }

                let fd = new FormData()
                let obj = {
                    ...valData,
                    articleId: article,
                    volumeId: volumeId
                }
                fd.append('obj', JSON.stringify(obj))

                if (finalArticleAttachment && finalArticleAttachment.name) {
                    fd.append('finalAttachmentPublished', finalArticleAttachment)
                }
                if (contentAttachmentPublished && contentAttachmentPublished.name) {
                    fd.append('contentAttachmentPublished', contentAttachmentPublished)
                }
                let {success} = await dispatch(updateCompletePublishArticleFxn(fd))
                if (success) {
                    onClose()
                    if (callback) {
                        callback()
                    }
                    dispatch({type: 'START_LOAD_ARTICLE'})
                    events.hide()
                }
            } else {
                notification.error({
                    message: 'Required',
                    description: 'Enter all required fields'
                })
                return
            }

        })
    }
    return (
        <>
            <div onClick={() => events.show()}>
                {children}
            </div>
            <Drawer
                title={`Update Complete Publish Article | Article No. ${article.articleNo}`}
                width={'70%'}
                visible={visible}
                placement='right'
                onClose={onClose}>
                <Card>
                    <Form onSubmit={handleSubmit} hideRequiredMark className={'inputForm'}>
                        <Row gutter={16}>
                            {inputSchema.fields.map((item, key) => {
                                return (
                                    <Col span={item.span ? item.span : 8} key={key}>
                                        {item.customField ? item.customField :
                                            <GetEachFormFields
                                                item={item}
                                                getFieldDecorator={getFieldDecorator}
                                                formItemLayout={formItemLayout}/>}
                                    </Col>
                                )
                            })}
                            <Col span={24}>
                                <Form.Item style={{marginTop: 40}}>
                                    <Button type='primary' htmlType='submit'>
                                        Update
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </Drawer>
        </>
    )
}


const WrappedForm = Form.create()(CompletePublishUpdateDrawer)

export default WrappedForm
