import { Button, Card, Col, Drawer, Form, notification, Row } from 'antd'
import React from 'react'
import { useDispatch } from 'react-redux'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import { formItemLayout } from '../../../components/_utils/appUtils'
import { addAdminUser } from './actions'

const AddAdminUser = props => {
  let {
    form: { getFieldDecorator, setFieldsValue }
  } = props
  const dispatch = useDispatch()

  const inputSchema = {
    fields: [
      {
        label: 'Name',
        key: 'name',
        placeholder: 'Enter Your Name',
        required: true
      },

      {
        label: 'Password',
        key: 'password',
        placeholder: 'password',
        type: 'password',
        required: true
      },
      {
        label: 'Email',
        key: 'email',
        placeholder: 'Enter Your Email',
        type: 'email',
        required: true
      },
      {
        label: 'Mobile No',
        key: 'mobileNo',
        placeholder: 'Enter Your Mobile No',
        required: true
      },
      {
        label: 'Address',
        key: 'address',
        placeholder: 'Enter Your Address',
        required: true
      },
      {
        label: 'Designation',
        key: 'designation',
        placeholder: 'Enter Your Designation',
        required: true
      }
    ]
  }

  const handleSubmit = e => {
    const { form, article } = props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        if (valData.password.length < 7) {
          notification.warning({
            message: 'Password can not be less than 7 digits'
          })
          return
        }
        valData.userType = 'Admin'
        let resp = await dispatch(addAdminUser(valData))
        if (resp && resp.success) {
          dispatch({ type: 'START_LOAD_ARTICLE' })
          notification.success({
            message: resp.message
          })
          form.resetFields()
        }
      } else {
        notification.error({
          message: 'Please enter all required fields.'
        })
      }
    })
  }

  return (
    <>
      <PageHeaderWrapper title={'Add Admin User'}>
        <Card>
          <Form
            onSubmit={handleSubmit}
            hideRequiredMark
            className={'inputForm'}>
            <Row gutter={16}>
              {inputSchema.fields.map((item, key) => {
                return (
                  <Col span={item.span ? item.span : 12} key={key}>
                    <GetEachFormFields
                      getFieldDecorator={getFieldDecorator}
                      item={item}
                      formItemLayout={formItemLayout}
                    />
                  </Col>
                )
              })}
              <Col span={24}>
                <Form.Item style={{ marginTop: 40 }}>
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </PageHeaderWrapper>
    </>
  )
}

const WrappedForm = Form.create()(AddAdminUser)

export default WrappedForm
