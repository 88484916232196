import React, { useEffect, useRef, useState } from 'react'
import { Drawer, Card, Button, Form, Row, Col, notification, Icon } from 'antd'
import {
  CustomCkEditor,
  DisplayDate,
  formItemLayout, mentionsFeeds,
  NotificationBox
} from '../../../components/_utils/appUtils'
import { useDispatch } from 'react-redux'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import { DOIArticle } from '../actions'
import moment from 'moment'


const DOIDrawer = (props) => {
  let {
    children,
    form: { getFieldDecorator },
    callback = null,
    visible,
    onClose,
    article,
    edit
  } = props
  const dispatch = useDispatch()

  useEffect(() => {
    setTimeout(() => {
      setFormData()
    }, 1000)
  }, [])


  let [doiAbstract, setDoiAbstract] = useState('')
  let [doiReferences, setDoiReferences] = useState('')

  let setFormData = () => {
    let { article } = props
    if (edit) {
      props.form.setFieldsValue({
        doiArticleTitle: article.manuScriptTitle,
        correspondingAuthor: article.correspondingAuthor,
        doiProcessDate: moment(article.doiProcessDate),
        doiAuthorName: article.doiAuthorName,
        doiAuthorAddress: article.doiAuthorAddress,
        doiKeywords: article.doiKeywords
        // doiAbstract: article.doiAbstract,
        //doiReferences: article.doiReferences
      })
      setTimeout(() => {
        setDoiAbstract(article.doiAbstract)
        setDoiReferences(article.doiReferences)
      }, 400)
    } else {
      props.form.setFieldsValue({
        doiArticleTitle: article.manuScriptTitle,
        correspondingAuthor: article.correspondingAuthor,
        doiProcessDate: moment()
      })
      setTimeout(() => {
        setDoiAbstract(article.abstract)
      }, 400)

    }
  }


  let [visibleDrawer, setVisibleDrawer] = useState(false)

  const events = {
    show: (event) => {
      setVisibleDrawer(true)
    },
    hide: (event) => {
      setVisibleDrawer(false)
    }
  }

  const inputSchema = {
    fields: [
      {
        label: 'Date',
        key: 'doiProcessDate',
        required: true,
        placeholder: 'Date of Enter',
        type: 'date',
        span: 12
      },
      {
        title: 'Author',
        key: 'correspondingAuthor',
        required: true,
        span: 12
      },
      {
        label: 'Article Title',
        key: 'doiArticleTitle',
        type: 'editor',
        required: true,
        span: 24
      },

      {
        label: 'All Author Names',
        key: 'doiAuthorName',
        required: true,
        placeholder: 'all author names',
        type: 'textArea',
        span: 24
      },
      {
        label: 'Address',
        key: 'doiAuthorAddress',
        type: 'textArea',
        required: true,
        span: 24
      },
      {
        label: 'Keyword',
        key: 'doiKeywords',
        type: 'editor',
        required: true,
        span: 24
      },
      {
        label: 'Abstract',
        key: 'abstract',
        required: true,
        span: 24,
        customField: (
          <CustomCkEditor title={'Abstract'}
                          key={'abstract'}
                          feeds={mentionsFeeds}
                          html={doiAbstract}
                          onChange={(data) => {
                            setDoiAbstract(data)
                          }}
          />)
      },
      {
        label: 'References',
        key: 'doiReferences',
        required: true,
        span: 24,
        customField: (
          <CustomCkEditor title={'References'}
                          key={'doiReferences'}
                          feeds={mentionsFeeds}
                          html={doiReferences}
                          onChange={(data) => {
                            setDoiReferences(data)
                          }}
          />)
      }
    ]
  }


  const handleSubmit = e => {
    const { form, article } = props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        if (!valData.doiArticleTitle) {
          notification.error({
            message: 'Required',
            description: 'Enter Manuscript Title'
          })
          return
        }
        if (!valData.doiAuthorAddress) {
          notification.error({
            message: 'Required',
            description: 'Enter DOI Author Address'
          })
          return

        }
        if (!valData.doiKeywords) {
          notification.error({
            message: 'Required',
            description: 'Enter DOI Keywords'
          })
          return
        }
        if (doiAbstract === '') {
          notification.error({
            message: 'Required',
            description: 'Enter DOI Abstract'
          })
          return
        }
        if (doiReferences === '') {
          notification.error({
            message: 'Required',
            description: 'Enter DOI References'
          })
          return
        }
        valData.articleId = article._id
        valData.doiReferences = doiReferences
        valData.doiAbstract = doiAbstract
        valData.manuScriptTitle = valData.doiArticleTitle
        if (!edit) {
          valData.articleStatus = 'DOI'
        }
        let { success } = await dispatch(DOIArticle(valData))
        if (success) {
          dispatch({ type: 'START_LOAD_ARTICLE' })
          onClose()
          if (callback) {
            callback()
          }
          events.hide()
        }
      } else {
        notification.error({
          message: 'Required',
          description: 'Fill all required fields.'
        })
      }

    })
  }

  return (
    <>
      <div onClick={() => events.show()}>
        {children}
      </div>
      <Drawer
        title={`DOI Article | Article No. ${article.articleNo}`}
        width={'70%'}
        visible={visible}
        placement='right'
        onClose={onClose}>
        <Card>
          <Form onSubmit={handleSubmit} hideRequiredMark className={'inputForm'}>
            <Row gutter={16}>
              {inputSchema.fields.map((item, key) => {
                return (
                  <Col span={item.span ? item.span : 8} key={key}>
                    {item.customField ? item.customField :
                      <GetEachFormFields
                        item={item}
                        getFieldDecorator={getFieldDecorator}
                        formItemLayout={formItemLayout}/>}
                  </Col>
                )
              })}
              <Col span={24}>
                <div>
                  <Form.Item style={{ marginTop: 40 }}>
                    <Button type='primary' htmlType='submit'>
                      Submit
                    </Button>
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </Form>
        </Card>
      </Drawer>
    </>
  )
}


const WrappedForm = Form.create()(DOIDrawer)

export default WrappedForm
