import { notification } from 'antd'
import {
  hidePageLoad,
  hideProgressPageLoad,
  showPageLoad,
  showProgressPageLoad
} from '../../../modules/actions'
import { customAxios as axios, getToken } from '../../../request'
import {
  AcceptanceArticleURL,
  AddDoiArticleUrl,
  ApproveArticleURL,
  ChangeReviewerHistoryUrl,
  CompletePublishArticleURL,
  ComposeArticleURL,
  DOIArticleURL,
  DeleteArticleURL,
  DeleteReviewerURL,
  GalleyProofArticleURL,
  OnlinePublishArticleURL,
  ReActivateMemberURL,
  ResendArticleUrl,
  ResendCommentsURL,
  ReviewerCommentsURL,
  RevisedArticleURL,
  UpdateDoiImageUrl,
  WithdrawalArticleURL,
  addNotesUrl,
  addReviewerInArticleUrl,
  allArticleUrl,
  allNotesUrl,
  articleNoteCountUrl,
  articleSearchByIdUrl,
  articleUrl,
  commentsToAuthorUrl,
  deleteArticleNoteUrl,
  editReceiveDateUrl,
  generateAndDepositXmlUrl,
  getAllArticleActivityUrl,
  getArticleByIdUrl,
  getMemberHistoryUrl,
  getNotesUrl,
  getReviewerPendingArticleURL,
  getReviewerReviewedArticleURL,
  lastArticleNoUrl,
  notesOnlineSubmissionUrl,
  processPaymentUrl,
  reviewerAwardUrl,
  sendCommentsUrl,
  sendReceiveDateUrl,
  singleArticleUrl,
  updateOnlineSubmissionUrl,
  viewGeneratedXmlUrl,
  addBlogUrl, updateCompletePublishArticleURL, fullDeleteMemberUrl
} from '../apis'

export const addArticle = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(articleUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const singleArticle = id => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(singleArticleUrl(id), {}, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
export const updateArticle = (valData, id) => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.put(singleArticleUrl(id), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const getAllArticle = filters => async dispatch => {
  dispatch(showPageLoad())
  let config = {
    params: { ...filters },
    ...getToken()
  }
  let { data } = await axios.get(allArticleUrl(), config)
  dispatch(hidePageLoad())
  /* if (data.error) {
     notification.error({
       message: data.message || 'Error'
     })
   }*/
  return data
}

export const processPaymentApi = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(processPaymentUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const addReviewerInArticle = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(
    addReviewerInArticleUrl(),
    valData,
    getToken()
  )
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const getArticleById = (articleId, selected = {}) => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(
    getArticleByIdUrl(),
    { articleId, selected },
    getToken()
  )
  dispatch(hidePageLoad())

  return data
}

export const getMemberHistory = filters => async dispatch => {
  dispatch(showPageLoad())
  let config = {
    params: { ...filters },
    ...getToken()
  }
  let { data } = await axios.get(getMemberHistoryUrl(), config)
  dispatch(hidePageLoad())
  return data
}

export const getNotes = filters => async dispatch => {
  dispatch(showPageLoad())
  let config = {
    params: { ...filters },
    ...getToken()
  }
  let { data } = await axios.get(getNotesUrl(), config)
  dispatch(hidePageLoad())
  return data
}
export const deleteArticleNote = valData => async dispatch => {
  dispatch(showPageLoad())

  let { data } = await axios.post(deleteArticleNoteUrl(), valData, getToken())
  dispatch(hidePageLoad())

  return data
}
export const SendReceiveDate = valdata => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(sendReceiveDateUrl(), valdata, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const SendComments = valdata => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(sendCommentsUrl(), valdata, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }

  return data
}
export const AddNotes1 = valdata => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(addNotesUrl(), valdata, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }

  return data
}

export const LastArticleNo = valdata => async dispatch => {
  // dispatch(showPageLoad())
  let { data } = await axios.post(lastArticleNoUrl(), valdata, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }

  return data
}

export const EditReceiveDate = valdata => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(editReceiveDateUrl(), valdata, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
export const commentToAuthor = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(commentsToAuthorUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    notification.success({
      message: data.message || 'Success'
    })
  }
  return data
}

export const ResendComments = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(ResendCommentsURL(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    notification.success({
      message: data.message || 'Success'
    })
  }
  return data
}

export const ReviewerAward = valdata => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(reviewerAwardUrl(), valdata, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }

  return data
}
export const RevisedArticle = valdata => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(RevisedArticleURL(), valdata, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    notification.success({
      message: data.message || 'Success'
    })
  }
  return data
}
export const ChangeReviewerHistory = valdata => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(
    ChangeReviewerHistoryUrl(),
    valdata,
    getToken()
  )
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    notification.success({
      message: data.message || 'Success'
    })
  }
  return data
}
export const ResendArticle = valdata => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(ResendArticleUrl(), valdata, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    notification.success({
      message: data.message || 'Success'
    })
  }
  return data
}

export const ApproveArticle = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.put(ApproveArticleURL(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    notification.success({
      message: data.message || 'Email send Successfully'
    })
  }
  return data
}

export const AcceptanceArticle = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.put(AcceptanceArticleURL(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    notification.success({
      message: data.message || 'Success'
    })
  }
  return data
}
export const ComposeArticle = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.put(ComposeArticleURL(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    notification.success({
      message: data.message || 'Success'
    })
  }
  return data
}
export const GalleyProofArticle = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.put(GalleyProofArticleURL(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    notification.success({
      message: data.message || 'Success'
    })
  }
  return data
}
export const DOIArticle = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.put(DOIArticleURL(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    notification.success({
      message: data.message || 'Success'
    })
  }
  return data
}

export const OnlinePublishArticle = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.put(OnlinePublishArticleURL(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    notification.success({
      message: data.message || 'Success'
    })
  }
  return data
}

export const CompletePublishArticle = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.put(
    CompletePublishArticleURL(),
    valData,
    getToken()
  )
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    notification.success({
      message: data.message || 'Success'
    })
  }
  return data
}

export const ReviewerComments = valdata => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(ReviewerCommentsURL(), valdata, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    notification.success({
      message: data.message || 'Success'
    })
  }
  return data
}

export const getReviewerPendingArticle = filters => async dispatch => {
  dispatch(showPageLoad())
  let config = {
    params: { ...filters },
    ...getToken()
  }
  let { data } = await axios.get(getReviewerPendingArticleURL(), config)
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const generateAndDepositXml = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(
    generateAndDepositXmlUrl(),
    valData,
    getToken()
  )
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
    dispatch(hidePageLoad())
  }
  return data
}
export const viewGeneratedXml = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(viewGeneratedXmlUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
    dispatch(hidePageLoad())
  }
  return data
}

export const getReviewerReviewedArticle = filters => async dispatch => {
  dispatch(showPageLoad())
  let config = {
    params: { ...filters },
    ...getToken()
  }
  let { data } = await axios.get(getReviewerReviewedArticleURL(), config)
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const ArticleSearchById = async valData => {
  let { data } = await axios.post(articleSearchByIdUrl(), valData, getToken())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const DeleteArticle = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.put(DeleteArticleURL(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    notification.success({
      message: data.message || 'Email send Successfully'
    })
  }
  return data
}
export const WithdrawalArticle = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.put(WithdrawalArticleURL(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    notification.success({
      message: data.message || 'success'
    })
  }
  return data
}
export const DeleteReviewer = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.put(DeleteReviewerURL(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    notification.success({
      message: data.message || 'Reviewer Deleted successfully'
    })
  }
  return data
}
export const ReActivateMember = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(ReActivateMemberURL(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    notification.success({
      message: data.message || 'Reviewer Deleted successfully'
    })
  }
  return data
}

export const AddDoiArticleFxn = valData => async dispatch => {
  dispatch(showProgressPageLoad())
  let { data } = await axios.post(AddDoiArticleUrl(), valData, {
    onUploadProgress: progressEvent => {
      let percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      )
      dispatch({ type: 'PROGRESS_START', percentage: percentCompleted })
    },
    ...getToken()
  })
  dispatch(hideProgressPageLoad())
  dispatch({ type: 'PROGRESS_END' })
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
export const UpdateDoiImageFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(UpdateDoiImageUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
export const getAllNotes = filters => async dispatch => {
  dispatch(showPageLoad())
  let config = {
    params: { ...filters },
    ...getToken()
  }
  let { data } = await axios.get(allNotesUrl(), config)

  dispatch(hidePageLoad())
  /* if (data.error) {
       notification.error({
         message: data.message || 'Error'
       })
     }*/
  return data
}
export const getAllArticleActivities = filters => async dispatch => {
  dispatch(showPageLoad())
  let config = {
    params: { ...filters },
    ...getToken()
  }
  let { data } = await axios.get(getAllArticleActivityUrl(), config)
  dispatch(hidePageLoad())
  return data
}

export const articleNoteCountFxn = async valData => {
  let { data } = await axios.post(articleNoteCountUrl(), valData, getToken())
  return data
}
export const notesListFxn = filters => async dispatch => {
  dispatch(showPageLoad())
  let config = {
    params: { ...filters },
    ...getToken()
  }
  let { data } = await axios.get(notesOnlineSubmissionUrl(), config)

  dispatch(hidePageLoad())
  /* if (data.error) {
       notification.error({
         message: data.message || 'Error'
       })
     }*/
  return data
}
export const AddNoteOnlineSubmission = valdata => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(
    notesOnlineSubmissionUrl(),
    valdata,
    getToken()
  )
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }

  return data
}
export const updateOnlineSubmission = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(
    updateOnlineSubmissionUrl(),
    valData,
    getToken()
  )
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const AddBlogFxn = valData => async dispatch => {
  dispatch(showProgressPageLoad())
  let { data } = await axios.post(addBlogUrl(), valData, {
    onUploadProgress: progressEvent => {
      let percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      )
      dispatch({ type: 'PROGRESS_START', percentage: percentCompleted })
    },
    ...getToken()
  })
  dispatch(hideProgressPageLoad())
  dispatch({type: 'PROGRESS_END'})
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}


export const updateCompletePublishArticleFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let {data} = await axios.post(updateCompletePublishArticleURL(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    notification.success({
      message: data.message || 'Success'
    })
  }
  return data
}

export const fullDeleteMemberFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let {data} = await axios.post(fullDeleteMemberUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    notification.success({
      message: data.message || 'Reviewer Deleted successfully'
    })
  }
  return data
}
