import { apiUrl } from '../../../settings'

export const getSingleMemberUrl = (id) => {
  return apiUrl + `/getSingleMember/${id}`
}
export const getSingleMemberByRevId = (id) => {
  return apiUrl + `/getSingleMemberByRevId/${id}`
}
export const changePasswordURL = () => {
  return apiUrl + `/changePassword`
}