import React, { Component } from 'react'
import { Route, Link, Redirect, Switch } from 'react-router-dom'
import menuData, { getUrlPath } from '../../routes'
import Exception from '../../components/Exception'
import AuthLayout from '../../layouts/AuthLayout'
import BasicLayout from '../../layouts/BasicLayout'
import Login from '../../containers/login'
import ForgetPassword from '../../containers/login/forget-password'
import '../../index.css'
import _ from 'lodash'
import { Spin, Progress } from 'antd'
import { connect } from 'react-redux'
import { hidePageLoad, hideLoader, hideProgressPageLoad } from '../../modules/actions'
import ResetPassword from '../login/ResetPassword'

const Exp = () => (<Exception
  type='404'
  desc={'You Seems lost !!'}
  linkElement={Link}
  redirect={'/dashboard'}
  backText={'Go To Homepage?'}
/>)
const Exp403 = () => (<Exception
  type='403'
  desc={'Sorry You Don\'t have access to this area !!'}
  linkElement={Link}
  redirect={'/dashboard'}
  backText={'Go To Homepage?'}
/>)

class BasicLayoutWrapper extends Component {
  render() {

    const { menuData, component, path } = this.props

    let user = (localStorage.getItem('user') != 'undefined') ? JSON.parse(localStorage.getItem('user')) : null

    if (!user) {
      return (<Redirect to='/login' />)
    }

    let menuItem = _(menuData)
      .thru(function(coll) {
        return _.union(coll, _.map(coll, 'children'))
      })
      .flatten()
      .find({ 'path': path })
    if (menuItem.authority !== undefined && menuItem.authority.indexOf(user.userType) === -1) {
      console.log('this user should not be here ', path)
      return <Exp403 />
    }

    if (!menuItem.title) {
      menuItem.title = 'Untitled'
    }
    // console.log(menuItem)

    return (
      <BasicLayout
        location={window.location}
        pageTitle={`${menuItem.title}`}
        menuData={menuData}>
        {!!component ? <this.props.component /> : <Exp />}
      </BasicLayout>)
  }
}

class App extends Component {

  constructor(props) {

    super(props)
    this.state = {
      token: localStorage.getItem('token'),
      user: (localStorage.getItem('user') != 'undefined') ? JSON.parse(localStorage.getItem('user')) : null
    }
  }

  componentDidMount() {
    this.props.dispatch(hidePageLoad())
    this.props.dispatch(hideProgressPageLoad())
    this.props.dispatch(hideLoader())
  }

  render() {

    const { user } = this.state
    const { pageLoading, progressLoading, progressPercentage, currentUser } = this.props

    const checkRightsFxn = (item) => {
      if (currentUser && currentUser.isAdmin) {
        return true
      } else {
        return false
      }
    }

    return (
      <div>
        {pageLoading &&
        <div className={'outerLoader'}>
          <Spin style={{ fontSize: 100 }} />
          Loading...
        </div>}

        {progressLoading && <div className={'outerLoader'}>
          <Progress type='circle' percent={progressPercentage} format={percent => `${percent}%`} width={200} />
          <br />
          Loading...
        </div>}


        <Switch>

          {menuData.map((item, key) => {
            if (!item.children) {
              let routeBox = (
                (<Route exact path={item.path} key={item.path} render={(route) => {
                  return <BasicLayoutWrapper component={item.component} path={item.path} user={user}
                                             menuData={menuData} />
                }} />)
              )
              return (
                item.restrict ? checkRightsFxn(item) ? routeBox : null : routeBox
              )
            }
          })}

          {menuData.map((item, key) => {
            if (item.children) {
              return item.children.map((child, k) => {
                let routeBox = (
                  <Route exact path={child.path} key={child.path} render={(route) => {
                    return <BasicLayoutWrapper component={child.component} path={child.path} user={user}
                                               menuData={menuData} />
                  }} />
                )
                return (
                  child.restrict ? checkRightsFxn(child) ? routeBox : null : routeBox
                )
              })
            }
          })}

          <Route exact path='/login' render={(route) => {
            return (
              <AuthLayout
                location={window.location}
                menuData={menuData}>
                <Login />
              </AuthLayout>
            )
          }} />


          <Route exact path='/forget-password' render={(route) => {
            return (
              <AuthLayout
                location={window.location}
                menuData={menuData}>

                <ForgetPassword />

              </AuthLayout>
            )
          }} />

          <Route path='/reset-password' render={(route) => {

            return (
              <AuthLayout
                location={window.location}
                menuData={menuData}>

                <ResetPassword />

              </AuthLayout>
            )
          }} />

          <Route exact path='/' render={(route) => {
            return (
              <Redirect to='/dashboard' />
            )
          }} />
        </Switch>
      </div>)
  }
}

const mapStateToProps = ({ global, router }) => ({
  pageLoading: global.pageLoading,
  progressPercentage: global.progressPercentage,
  progressLoading: global.progressLoading,
  currentUser: global.currentUser

})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App)
