import React, { Component } from 'react'
import { Button, Row, Col } from 'antd'

class InputBox extends Component {
  constructor(props) {
    super()
    this.state = {}
  }

  render() {
    let { title = '', className = '', extra, required = false } = this.props
    return (
      <div>
        <Row gutter={16} className={'inputBox'}>
          <Col span={24} className={'labelRow'}>
            {title ? <React.Fragment>
              <label className={'labelNew'}>{title}{required && '*'} : </label>
              {extra ? <div className={'extra'}>{extra}</div> : ''}
            </React.Fragment> : ''}
          </Col>
          <Col span={24}>
            <div className={`${className} innerBox`}>
              {this.props.children}
            </div>
          </Col>
        </Row>
      </div>

    )
  }
}

export default InputBox

