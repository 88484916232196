import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Icon,
  Input,
  Popconfirm,
  Row,
  Table,
  Tooltip
} from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { FormUtils, TableComp } from 'sz-react-utils-lite'
import PageHeaderWrapper from '../../components/PageHeaderWrapper'
import { DefaultTablePagination } from '../../components/_utils/appUtils'
import { addKeyword, keywordList, removeKeyword } from './actions/actions'

const KeywordComponent = props => {
  const [keyword, setKeyword] = useState([])
  const dispatch = useDispatch()
  let [pageState, setPageState] = useState({
    page: 1,
    results: 0
  })
  const table = useRef()
  let { form } = props
  const {
    form: { getFieldDecorator, getFieldValue, setFieldsValue },
    loading
  } = props
  const formItemLayout = {
    labelCol: {
      xs: { span: 2 },
      sm: { span: 3 },
      md: { span: 7 }
    },
    wrapperCol: {
      xs: { span: 22 },
      sm: { span: 22 },
      md: { span: 22 }
    }
  }

  const inputSchma = {
    fields: [
      {
        label: 'Keyword',
        key: 'keyword',
        placeholder: 'Keyword',
        required: true
      }
      // {
      //     label: 'Slug KeyWord',
      //     key: 'slugKeyword',
      //     placeholder: 'Slug KeyWord',
      // },
    ]
  }

  const handleSubmit = async e => {
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      // console.log(valData);
      if (!err) {
        let result = await dispatch(addKeyword(valData))
        if (result) {
          setFieldsValue({ keyword: '' })
        }
      }
      getKeywords()
    })
  }

  useEffect(() => {
    // getKeywords()
  }, [])

  const getKeywords = async () => {
    let result = await dispatch(keywordList())
    // console.log(result)
    table.current.reload()
    setKeyword(result)
  }

  const columns = [
    {
      title: ' Serial No. ',
      dataIndex: 'orderNo',
      //align: 'center',
      key: 'index',
      width: 100,
      render: (text, record, index) => {
        let currentIndex = parseInt(pageState.page - 1) * parseInt(pageState.results)
        return (
          <>
            {currentIndex + index + 1}
          </>
        )
      }
    },
    {
      title: 'Keyword',
      dataIndex: 'keyword',
      key: 'keyword',
      // sorter: true,
      searchTextName: 'keyword',
      render: (val, record) => (
        <div style={{ textTransform: 'capitalize' }}>{val}</div>
      )
    },
    {
      title: 'Action',
      key: 'operation',
      width: 150,
      render: (item, record) => (
        <div className='alignCenter'>
          <Popconfirm
            placement='topLeft'
            title={`Are you Sure, want to delete?`}
            onConfirm={() => handleDelete(record)}
            okText='Yes'
            cancelText='No'>
            <Button ghost shape='circle' type='danger' icon='delete' />
          </Popconfirm>
        </div>
      )
    }
  ]
  // delete keyword
  const handleDelete = async record => {
    let result = await dispatch(removeKeyword(record))
    getKeywords()
  }

  const apiRequest = params => {
    return new Promise(async resolve => {
      let regExpFilters = ['keyword']
      let result = await dispatch(
        keywordList({
          ...params,
          regExFilters: regExpFilters,
          sortField: 'keyword',
          sortOrder: 'ascend'
        })
      )
      setPageState({
        page: params.page ? params.page : 1,
        results: params.results
      })
      resolve(result)
    })
  }

  return (
    <>
      <Card bordered={true}>
        <Form onSubmit={handleSubmit}>
          <Row gutter={16}>
            <Col span={12}>
              <FormUtils
                inputSchema={inputSchma}
                formItemLayout={formItemLayout} // optional //for the design part
                getFieldDecorator={getFieldDecorator} //input validation
                setFieldsValue={setFieldsValue}
              />
            </Col>
            <Col span={2}>
              <Button
                type='primary'
                htmlType='submit'
                style={{ marginTop: 42 }}>
                Add Keyword
              </Button>
            </Col>
          </Row>
        </Form>
        <TableComp
          ref={table}
          sorter
          size='small'
          columns={columns}
          pagination={{ ...DefaultTablePagination(), defaultPageSize: 50 }}
          apiRequest={apiRequest}
        />
      </Card>
    </>
  )
}
// export default keyword;
let formWrap = Form.create()(KeywordComponent)
export default formWrap
