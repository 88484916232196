import React, { useState, useEffect } from 'react'
import { DisplayDate } from '../../../components/_utils/appUtils'
import ReactHtmlParser from 'react-html-parser'
import { Row, Col } from 'antd'

const RowTable = (props) => {
  let { title, value } = props
  return (
    <div className={'row'}>
      <div className={'title'}>
        {title} :
      </div>
      <dic>
        {value}
      </dic>
    </div>
  )
}

const ArticleInfo = (props) => {
  let { article } = props

  return (
    <div className={'articleInfoBox'}>
      <Row>
        <Col span={12}>
          <RowTable title={'Article No'} value={article.articleNo}/>
        </Col>
        <Col span={12}>
          <RowTable title={'Article Submit Date'}
                    value={article.submittedDate ? DisplayDate(article.submittedDate) : null}/>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <RowTable title={'Article Title'}
                    value={article.manuScriptTitle ? ReactHtmlParser(article.manuScriptTitle) : null} />
        </Col>
        <Col span={12}>
          <RowTable title={'Process Type'}
                    value={article.processType}/>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <RowTable title={'Area Of Research'} value={article.areaOfResearch}/>
        </Col>
        <Col span={12}>
          <RowTable title={'Author Name'} value={article.correspondingAuthor}/>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <RowTable title={'Institute Affiliated with'} value={article.correspondingAuthorAffiliation}/>
        </Col>
        <Col span={12}>
          <RowTable title={'Author Country'} value={article.correspondingAuthorCountry}/>
        </Col>
      </Row>


    </div>
  )
}
export default ArticleInfo
