import React, { useEffect, useRef, useState } from 'react'
import { Drawer, Card, Button, Form, Row, Col, Input, Icon } from 'antd'
import {
  DisplayDate,
  formItemLayout, InputBox,
  NotificationBox
} from '../../../components/_utils/appUtils'
import { useDispatch } from 'react-redux'
import { ChooseEmailModal, chooseEmailModalFxn } from '../../../components/_utils/appUtils'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import { OnlinePublishArticle } from '../actions'
import moment from 'moment'

let doiNo = '10.18805'
let shortNameObj = {
  'Legume Research': '',
  'Indian Journal of Agricultural Research': 'IJARe',
  'Indian Journal of Animal Research': 'IJAR',
  'Agricultural Science Digest': 'ag',
  'Asian Journal of Dairy and Food Research': 'ajdfr',
  'Bhartiya Krishi Anusandhan Patrika': '',
  'Agricultural Reviews': 'ag'
}
const OnlinePublishDrawer = (props) => {
  let {
    children,
    form: { getFieldDecorator, setFieldsValue },
    callback = null,
    article,
    visible,
    onClose,
    edit
  } = props
  const dispatch = useDispatch()

  useEffect(() => {
    setTimeout(() => {
      setFormData()
    }, 1000)
  }, [])


  let setFormData = () => {
    let { article, edit } = props
    if (edit) {
      props.form.setFieldsValue({
        onlinePublishedDate: moment(article.onlinePublishedDate),
        doiNumber: article.doiNumber
      })
      setAttachment({
        finalArticleAttachemntWithDoi: article.finalArticleAttachemntWithDoi && article.finalArticleAttachemntWithDoi.path ? article.finalArticleAttachemntWithDoi.path : ''
      })
    } else {
      let journalDoi = shortNameObj[article.journal]
      if (journalDoi) {
        journalDoi = journalDoi + '.'
      }
      props.form.setFieldsValue({
        onlinePublishedDate: moment(),
        doiNumber: `${doiNo}/${journalDoi}${article.articleNo}`
      })
    }
  }

  let [visibleDrawer, setVisibleDrawer] = useState(false)
  let [FinalArticleAttachmentWithDOI, setFinalArticleAttachmentWithDOI] = useState({})

  let [attachment, setAttachment] = useState({
    finalArticleAttachemntWithDoi: ''
  })

  const events = {
    show: (event) => {
      setVisibleDrawer(true)
    },
    hide: (event) => {
      setVisibleDrawer(false)
    }
  }

  const inputSchema = {
    fields: [
      {
        label: 'Date',
        key: 'onlinePublishedDate',
        required: true,
        placeholder: 'Date of Enter',
        type: 'date'
      },
      {
        title: 'DOI generated',
        key: 'doiNumber'
      },
      {
        label: 'Manuscript Attachment with DOI',
        customField: (
          <InputBox title={`Manuscript Attachment with DOI`} className={'rowFlex'}>
            <Input type={'file'} name={'finalArticleAttachemntWithDoi'} id={'finalArticleAttachemntWithDoi'}
                   className={'form-control'}
                   onChange={(e) => {
                     let { name, files } = e.target
                     if (files && files.length) {
                       setFinalArticleAttachmentWithDOI(files[0])
                     }
                   }} />
            {edit && attachment && attachment.finalArticleAttachemntWithDoi ?
              <a className={'linkBtn'} target={'_blank'} href={`${attachment.finalArticleAttachemntWithDoi}`}>
                <Icon type={'eye'} />
              </a> : null}
          </InputBox>
        )
      }

    ]
  }


  const handleSubmit = e => {
    const { form, article } = props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      chooseEmailModalFxn({
        title: 'Approve Article',
        callback: async (email) => {
          let fd = new FormData()
          let obj = {
            articleId: article._id,
            ...valData,
            fromEmail: email,
            template: 'OnlinePublishTemplate',
            journalId: article.journalId
          }
          if (!edit) {
            obj.articleStatus = 'Online Publish'
          }
          fd.append('obj', JSON.stringify(obj))
          fd.append('finalArticleAttachemntWithDoi', FinalArticleAttachmentWithDOI)
          let { success } = await dispatch(OnlinePublishArticle(fd))
          if (success) {
            onClose()
            if (callback) {
              callback()
            }

            dispatch({ type: 'START_LOAD_ARTICLE' })
            events.hide()
          }
        }
      })
    })
  }


  return (
    <>
      <div onClick={() => events.show()}>
        {children}
      </div>
      <Drawer
        title={`Online Publish Article | Article No. ${article.articleNo}`}
        width={'70%'}
        visible={visible}
        placement='right'
        onClose={onClose}>
        <Card>
          <Form onSubmit={handleSubmit} hideRequiredMark className={'inputForm'}>
            <Row gutter={16}>
              {inputSchema.fields.map((item, key) => {
                return (
                  <Col span={item.span ? item.span : 8} key={key}>
                    {item.customField ? item.customField :
                      <GetEachFormFields
                        item={item}
                        getFieldDecorator={getFieldDecorator}
                        formItemLayout={formItemLayout} />}
                  </Col>
                )
              })}
              <Col span={24}>
                <Form.Item style={{ marginTop: 40 }}>
                  <Button type='primary' htmlType='submit'>
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </Drawer>
    </>
  )
}


const WrappedForm = Form.create()(OnlinePublishDrawer)

export default WrappedForm
