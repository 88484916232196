import React, { Component } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
  Table,
  Badge,
  Card,
  Tooltip,
  notification,
  Switch,
  Input, Button, Icon, Form, Row, Col
} from 'antd'
import Request from '../../../request'
import { getPushPathWrapper } from '../../../routes'
import { apiUrl } from '../../../settings'
import _ from 'lodash'
import styles from '../all/styles.less'
import EditSubscription from '../edit/index'
import { connect } from 'react-redux'
import { TableComp, FormUtils } from 'sz-react-utils-lite'
import { func } from 'prop-types'
import { allSubscription, journalsAjaxList } from '../actions'
import { getReviewerPendingArticle } from '../../article/actions'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import { formItemLayout } from '../../../components/_utils/appUtils'

const keyObj = {
  'oneYear': 'One Year',
  'threeYear': 'Three Year',
  'lifeTime': 'Life Time',
  'online': 'Online',
  'print': 'Print',
  'printOnline': 'Print Online'
}
const regExpFilters = ['journalName', 'nationality']

@Form.create()
class AllSubscription extends Component {
  state = {
    editSubscription: false,
    journalList: [],
    nationalityArr: [{ value: 'Indian', display: 'Indian', _id: 'Indian' }, {
      value: 'Foreign',
      display: 'Foreign',
      _id: 'Foreign'
    }],
    yearArr: [],
    yearList: [],
    subscriptionList: []
  }

  constructor(props) {
    super(props)
    this.table = React.createRef()
  }

  apiRequest = (params) => {
    let { dispatch, form: { getFieldValue } } = this.props
    return new Promise(async (resolve) => {
      let obj = {
        ...params,
        journalId: this.props.form.getFieldValue('journalId') ? [this.props.form.getFieldValue('journalId')] : null,
        year: this.props.form.getFieldValue('year') ? [this.props.form.getFieldValue('year')] : null,
        nationality: getFieldValue('nationality') ? [getFieldValue('nationality')] : null
      }

      let data = await dispatch(allSubscription({ ...obj, regExFilters: regExpFilters }))
      console.log(data)
      resolve(data)
    })
  }

  getSubscriptionYearByJournals = () => {
    let journalId = this.props.form.getFieldValue('journalId')
    if (journalId) {
      Request.getSubscriptionYearByJournals({ journalId: journalId }).then((resp) => {
        if (resp) {
          let yearArr = []
          _.each(resp, (item) => {
            yearArr.push({ value: item, display: item, _id: item })
          })
          setTimeout(() => {
            this.setState({ yearArr })
          }, 500)
        }
      })
    }

  }

  setYears = () => {
    let yearArr = []
    let i = 2019
    while (i < 2026) {
      yearArr.push({ value: i, display: i, _id: i })
      i++
    }
    this.setState({ yearList: yearArr })
  }

  journalAjax = async () => {
    let { dispatch } = this.props
    let { data } = await dispatch(journalsAjaxList())
    let journalListArr = data
    console.log('journal', journalListArr)
    // let journalListArr = await Request.getJournalAjax()
    let journalList = []
    _.each(journalListArr, (item, key) => {
      journalList.push({ value: item, display: item.name, _id: item._id })
    })

    this.setState({ journalList })
  }

  reload = () => {
    this.table.current.reload()
  }

  filterSub = () => {
    this.apiRequest()
    this.reload()
  }

  openSubsciption = (subscriptionId) => {

    this.setState({ editSubscription: true, subscriptionId: subscriptionId })

  }
  closeEditSubscription = () => {
    this.setState({ editSubscription: false })
    this.reload()
  }
  subscriptionSubmit = (reminObj) => {
    this.setState({ remindObj: reminObj })
    this.closeEditSubscription()
  }

  componentDidMount() {
    this.setYears()
    this.journalAjax()
  }

  render() {

    const { form: { getFieldDecorator, getFieldValue }, loading, dispatch } = this.props
    const { journalList } = this.state
    // const { journalList, yearArr, nationalityArr } = this.state

    const formProps = {
      getFieldDecorator, getFieldValue, loading
    }

    const inputSchma = {
      fields: [
        {
          label: 'Journal',
          key: 'journalId',
          required: true,
          placeholder: 'Journal',
          type: 'select',
          width: '250px',
          options: journalList,
          onChange: (val) => {
            this.props.form.setFieldsValue({ journalId: val })
            this.getSubscriptionYearByJournals()
          }
        },
        {
          label: 'Nationality',
          key: 'nationality',
          required: true,
          placeholder: 'nationality',
          type: 'select',
          width: '250px',
          options: ['Indian', 'Foreign'],
          onChange: (val) => {
            this.props.form.setFieldsValue({ nationality: val })
            this.getSubscriptionYearByJournals()
          }
        },
        {
          label: 'Year',
          key: 'year',
          required: true,
          placeholder: 'Year',
          type: 'select',
          options: this.state.yearList,
          onChange: (val) => {
            this.props.form.setFieldsValue({ year: val })
          }
        }
      ]
    }

    const priceTable = [
      {
        title: 'Year',
        key: 'name',
        dataIndex: 'name',
        render: (val, record) => {
          return <div>{keyObj[val]} ({record.nationality == 'Indian' ? 'Rs' : 'USD'})</div>
        }
      },

      {
        title: 'Print',
        key: 'print',
        dataIndex: 'print'
      },

      {
        title: 'Online',
        key: 'online',
        dataIndex: 'online'
      },
      {
        title: 'Print+Online',
        key: 'printOnline',
        dataIndex: 'printOnline'
      }
    ]

    const columns = [
      {
        title: 'Journal',
        key: 'journalId',
        dataIndex: 'journalId',
        render: (val, record) => {
          return <div>{val.name} </div>
        }
      },
      {
        key: 'nationality',
        dataIndex: 'nationality',
        // searchTextName: 'nationality',
        filterByRegex: true
      },
      {
        key: 'year'
      },
      {
        title: 'Prices',
        key: 'priceArr',
        dataIndex: 'priceArr',
        render: (val) => {
          return (
            <Table columns={priceTable} className={styles.priceTable}
              pagination={false}
              dataSource={val}
            />
          )
        }
      },
      {
        key: 'actions', render: (val) => (<React.Fragment>

          <Tooltip title='Edit Details'>
            <Button className={styles.btn}
              shape='circle'
              onClick={() => {
                dispatch(getPushPathWrapper('subscription.edit', { id: val._id }))
                // this.openSubsciption(val._id)
              }} icon='edit' />
          </Tooltip>
        </React.Fragment>)
      }
    ]
    return (
      <PageHeaderWrapper
        title={'All Subscription'}>
        {/* {
                    this.state.editSubscription ? <EditSubscription subscriptionId={this.state.subscriptionId}
                        onClose={this.closeEditSubscription}
                        onSubmit={this.subscriptionSubmit}
                    /> : null}*/}

        <Card bordered={true}>
          <Form hideRequiredMark className={'inputForm'}>

            <Row gutter={10}>
              {inputSchma.fields.map((item, key) => {
                return (
                  <Col span={6} lg={6} md={8} sm={12} xs={24} key={key}>
                    <GetEachFormFields getFieldDecorator={getFieldDecorator}
                      item={item} formItemLayout={formItemLayout} />
                  </Col>
                )
              })}
              <Col span={4} lg={4} md={8} sm={12} xs={24}>
                <Form.Item style={{ marginTop: 38 }}>
                  <Button type='primary' loading={this.props.loading} onClick={() => {
                    this.filterSub()
                  }}>
                    FILTER
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          {/*<Form onSubmit={this.handleSubmit} hideRequiredMark style={{ marginTop: 8 }}>*/}
          {/*  <Row gutter={24}>*/}
          {/*    <Col span={6} style={{backgroundColor:'red'}} className={styles.filterDiv}>*/}
          {/*      <div className={styles.filterDiv}>*/}
          {/*        <FormUtils inputSchema={inputSchma} {...formProps} />*/}
          {/*      </div>*/}
          {/*    </Col>*/}
          {/*    <Col span={4}>*/}
          {/*      <Form.Item style={{ marginTop: 12 }}>*/}
          {/*        <Button type='primary' loading={this.props.loading} onClick={() => {*/}
          {/*          this.filterSub()*/}
          {/*        }}>*/}
          {/*          FILTER*/}
          {/*        </Button>*/}
          {/*      </Form.Item>*/}
          {/*    </Col>*/}
          {/*  </Row>*/}
          {/*</Form>*/}
          <TableComp ref={this.table} columns={columns} apiRequest={this.apiRequest} />
        </Card>

      </PageHeaderWrapper>)

  }
}


const mapStateToProps = ({ global }) => ({
  categories: global.categories
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllSubscription)
