import { Card, Drawer, Tooltip } from 'antd'
import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import {
  ArticleStatus,
  DefaultTablePagination,
  displayDateWithtime
} from '../../../components/_utils/appUtils'
import Request from '../../../request'
import { getArticleActivities } from '../actions'

const regExPFilters = ['message', 'articleNo']
const ActivityListAdmin = props => {
  let [journalNameList, setJournalNameList] = useState([])
  useEffect(() => {
    getJournalList()
  }, [])

  let { visible, onClose, operatedByUserId, name } = props
  const getJournalList = async () => {
    let { data } = await Request.journalName()
    let journalList = []
    _.each(data, item => {
      journalList.push({
        _id: item._id,
        value: item.name,
        display: item.name
      })
    })
    setJournalNameList(journalList)
  }
  const table = useRef()
  const dispatch = useDispatch()
  const columns = [
    {
      key: 'articleNo',
      title: 'Article No',
      width: 100,
      searchTextName: 'articleNo',
      filterByRegex: true,
      render: (val, record) => {
        return (
          <Tooltip title="View Article">
            <a href={`/articleView/${record.articleId}`}>{val}</a>
          </Tooltip>
        )
      }
    },
    // {
    //   title: 'Article ID',
    //   key: 'articleId',
    //   dataIndex: 'articleId',
    //   searchTextName: 'articleId',
    //   filterByRegex: true,
    //   width: '120px'
    // },{
    //     title: 'Company Id',
    //     key: 'companyId',
    //     dataIndex: 'companyId',
    //     searchTextName: 'companyId',
    //     filterByRegex: true,
    //     width: '120px'
    //   },
    {
      title: 'Article Status',
      key: 'status',
      dataIndex: 'status',
      filters: ArticleStatus.map(x => ({ text: x.status, value: x.status })),
      width: 150
    },
    {
      title: 'Journal',
      key: 'journalId',
      dataIndex: 'journalId',
      width: 250,
      render: item => {
        return item && item.name ? item.name : null
      },
      filters: journalNameList.map(x => ({
        text: x.display,
        value: x._id
      }))
    },
    {
      title: '  Message',
      key: 'message',
      dataIndex: 'message',
      searchTextName: 'message',
      filterByRegex: true
    },
    {
      title: 'Time',
      key: 'time',
      dataIndex: 'time',
      width: 160,
      render: item => {
        return item ? displayDateWithtime(item) : null
      }
    }

    // {
    //   title: 'Action',
    //   key: 'actions',
    //   width: 280,
    //   render: (val, record) => (
    //     <React.Fragment>
    //       {(record.selectedReviewer.remarks || record.selectedReviewer.comments || record.selectedReviewer.receiveDate) ?
    //         <Button className={'roundSmallBtn'} onClick={() => events.showDrawer(record.selectedReviewer)}>Change
    //           History</Button> : null}
    //     </React.Fragment>)

    // }
  ]
  const apiRequest = params => {
    return new Promise(async resolve => {
      let obj = {
        ...params,
        sortField: '_id',
        sortOrder: 'descend'
      }
      let data = await dispatch(
        getArticleActivities({
          ...obj,
          operatedByUserId,
          regExFilters: regExPFilters
        })
      )
      resolve(data)
    })
  }

  return (
    <Drawer
      title={`Article Activities - ${name}`}
      width={'80%'}
      visible={visible}
      placement="right"
      onClose={onClose}>
      <Card>
        <TableComp
          ref={table}
          columns={columns}
          apiRequest={apiRequest}
          pagination={DefaultTablePagination({}, 50)}
        />
      </Card>
    </Drawer>
  )
}

export default ActivityListAdmin
