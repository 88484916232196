import React from 'react'
import { Modal } from 'antd'

const NotificationBox = (props) => {
  let { callback = null, message, title, type = 'success' } = props
  let processDone = () => {
    if (callback) {
      callback()
    }
  }
  return (
    Modal[type]({
      className: 'notificationBox',
      content: (
        <div>
          <div className={'title'}>{title}</div>
          <div className={'message'}>{message}</div>
        </div>
      ),
      onOk: () => {
        processDone()
      },
      onCancel: () => {
        processDone()
      }
    })
  )
}
export default NotificationBox

