import { Button, Card, Col, Drawer, Form, notification, Row } from 'antd'
import moment from 'moment/moment'
import React from 'react'
import { connect } from 'react-redux'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import {
  AuthorTitle,
  Countries,
  DateFormat,
  DesignationList,
  DisplayDate,
  displayDateWithtime,
  formItemLayout
} from '../../../components/_utils/appUtils'
import { hideLoader, showLoader } from '../../../modules/actions'
import { AddNoteOnlineSubmission, notesListFxn } from '../actions'
@Form.create()
class AddNotesOnlineSubmission extends React.Component {
  state = {
    noteData: []
  }
  handleSubmit = e => {
    const {
      dispatch,
      form,
      addNotesSubmit,
      onlineSubmissionId,
      articleNo
    } = this.props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        valData.onlineSubmissionId = onlineSubmissionId
        valData.articleNo = articleNo
        valData.time = moment().format('lll')

        let resp = await dispatch(AddNoteOnlineSubmission(valData))

        if (resp && resp.success) {
          dispatch({ type: 'START_LOAD_ARTICLE' })
          notification.success({
            message: resp.message
          })
          form.resetFields()
          this.loadNotes()
        }
      } else {
        notification.error({
          message: 'Please enter all required fields.'
        })
      }
    })
  }
  loadNotes = params => {
    return new Promise(async resolve => {
      let { dispatch, onlineSubmissionId, articleNo } = this.props
      let obj = {
        ...params,
        onlineSubmissionId
      }
      let data = await dispatch(notesListFxn({ ...obj }))
      console.log(data, 'dddddddddddddddd')
      resolve(data)
      this.setState({ noteData: data })
    })
  }
  componentDidMount() {
    this.loadNotes()
  }
  render() {
    const { noteData } = this.state
    const {
      form: { getFieldDecorator, getFieldValue, setFieldsValue },
      visible,
      onClose
    } = this.props

    let inputSchema = [
      {
        label: 'Add Note',
        key: 'note',
        required: true,
        type: 'textArea',
        placeholder: 'Add Note'
      }
      // {
      //   label: 'Added By',
      //   key: 'addedBy',
      //   required: true,
      //   placeholder: 'Added By'
      // },
    ]
    return (
      <Drawer
        visible={visible}
        onClose={onClose}
        width={'70%'}
        title={' Notes'}>
        <Card bordered={false}>
          <Form
            onSubmit={this.handleSubmit}
            hideRequiredMark
            className={'inputForm'}>
            <Row gutter={16}>
              {inputSchema.map((item, key) => {
                return (
                  <Col span={24} key={key}>
                    <GetEachFormFields
                      item={item}
                      getFieldDecorator={getFieldDecorator}
                      formItemLayout={formItemLayout}
                    />
                  </Col>
                )
              })}
              <Col span={24}>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Col>
              <Col span={24}>
                {noteData &&
                  noteData.map((item, i) => {
                    return (
                      <>
                          <div key={i} className="custom_card mb10">
                            <Card
                                bordered={true}
                                title={`${item.addedBy} `}
                                extra={
                                  <>
                                    {moment(item.time).format('DD.MM.YYYY,h:mm A')}
                                  </>
                                }>
                              {item.note}
                            </Card>
                        </div>
                      </>
                    )
                  })}
              </Col>
            </Row>
          </Form>
        </Card>
      </Drawer>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  null,
  mapDispatchToProps
)(AddNotesOnlineSubmission)
