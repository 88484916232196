import React, { useEffect, useState, useRef } from 'react'
import { Button, Card, Collapse, Popconfirm, Table, Tooltip } from 'antd'
import { chooseEmailModalFxn, DisplayDate, GetDownloadLink } from '../../../components/_utils/appUtils'
import { TableComp } from 'sz-react-utils-lite'
import _ from 'lodash'
import ReceiveDateDrawer from '../reviewer/editSelectReceiveDate'
import { RowTable } from './RowTable'
import AcceptanceDrawer from '../drawers/AcceptanceDrawer'
import { useDispatch } from 'react-redux'
import { API_URL } from '../../../request'
import { SendTemplateEmail } from '../../email/actions'

const { Panel } = Collapse
const ApprovedArticleDetails = (props) => {
  let { article } = props
  let {
    acceptedArticlePaymentDate,
    acceptanceLetter,
    approvedArticleDate
  } = article
  let [reviewerId, setReviewerId] = useState('')
  let headerTitle = `Accepted ${acceptedArticlePaymentDate ? `(${DisplayDate(acceptedArticlePaymentDate)})` : ''}`
  let [visibleAcceptanceDrawer, setVisibleAcceptanceDrawer] = useState(false)
  const table = useRef()
  let dispatch = useDispatch()
  let events = {
    reloadTable: () => {
      dispatch({ type: 'START_LOAD_ARTICLE' })
    },
    showAndHideReceiveDate: (value, id = '') => {
      setVisibleAcceptanceDrawer(value)
      setReviewerId(id)
    },
    show: () => {
      setVisibleAcceptanceDrawer(true)
    },
    hide: () => {
      setVisibleAcceptanceDrawer(false)
    }
  }

  let BtnAction = (event) => {
    events.show()
    event.stopPropagation()
  }

  let resendAcceptedArticleDetails = (event) => {
    event.stopPropagation()
    chooseEmailModalFxn({
      callback: async (email) => {
        let obj = {
          id: article._id,
          fromEmail: email,
          template: article.acceptedArticlePayment > 0 ? 'acceptanceArticle' : 'acceptanceArticleWithoutCharges',
          type: 'article',
          attachmentArr: ['acceptanceLetter']
        }
        let resp = await dispatch(SendTemplateEmail({
          email: obj.fromEmail,
          id: article._id,
          obj,
          template: obj.template
        }))
        if (resp && resp.success) {
          dispatch({ type: 'START_LOAD_ARTICLE' })
        }
      }
    })
  }
  let downloadAcceptedArticle = (event) => {
    event.stopPropagation()
    window.open(article.acceptanceLetter.path, '_blank')
  }

  let getExtraButton = () => {
    return (
      <React.Fragment>
        {article.acceptanceLetter && article.acceptanceLetter.path ?
          <Button type={'primary'} size={'small'} className={'mr-5'} onClick={downloadAcceptedArticle}>Acceptance
            Letter</Button> : null}
        {approvedArticleDate && !acceptedArticlePaymentDate ?
          <Button type={'primary'} size={'small'} onClick={BtnAction}>Add</Button> : null}
        {acceptedArticlePaymentDate ?
          <Button type={'primary'} size={'small'} onClick={BtnAction}>Edit</Button> : null}

        {article.acceptedArticlePaymentDate && article.articleStatus == 'Accepted Article' ?
          <Button type={'primary'} size={'small'} onClick={resendAcceptedArticleDetails} className={'ml-5'}>Resend
            Acceptance Letter</Button> : null}

      </React.Fragment>
    )
  }

  return (
    <div>
      <Collapse expandIconPosition={'right'} defaultActiveKey={['1']}>
        <Panel header={headerTitle} key='1' extra={getExtraButton()}>
          <div className={'tableBox striped'}>
            <RowTable title={'Date'} value={
              acceptedArticlePaymentDate && DisplayDate(acceptedArticlePaymentDate)
            }/>
            <RowTable title={'Approval Status'} value={
              article.acceptedArticlePayment ? `${article.currencyTypeOfApprovalAmount} ${article.acceptedArticlePayment}` : null
            }/>
            <RowTable title={'Mode Of Payment'} value={
              article.acceptedArticlePaymentMode ? article.acceptedArticlePaymentMode : null
            }/>
            <RowTable title={'Other Description'} value={
              article.acceptedArticlePaymentDiscription ? article.acceptedArticlePaymentDiscription : null
            }/>
            <RowTable title={'Acceptance Letter'} value={
              acceptanceLetter && acceptanceLetter.path ?
                <a download className='downloadLink' target={'_blank'}
                   href={acceptanceLetter.path}>Download</a> : 'No Attachment'
            }/>
          </div>
        </Panel>
      </Collapse>
      {visibleAcceptanceDrawer ? <AcceptanceDrawer
        visible={visibleAcceptanceDrawer}
        articleId={article._id}
        article={article}
        edit={acceptedArticlePaymentDate ? true : false}
        onClose={() => events.hide()}
      /> : null}
    </div>
  )
}

export default ApprovedArticleDetails
