import { apiUrl } from '../../../settings'

export const JournalListUrl = () => {
  return `${apiUrl}/journalsAjax`
}
export const CountriesUrl = () => {
  return `${apiUrl}/countries`
}
export const JournalChiefEditorUrl = () => {
  return `${apiUrl}/JournalChiefEditorUrl`
}
export const journalUrl = () => {
  return `${apiUrl}/journal`
}
export const getWebPageUrl = () => {
  return `${apiUrl}/api/singlePage`
}
export const updateWebPageUrl = () => {
  return `${apiUrl}/api/updateWebPage`
}

export const getJournalWebPageUrl = () => {
  return `${apiUrl}/api/singleJournalWebPage`
}
export const updateJournalWebPageUrl = () => {
  return `${apiUrl}/api/updateJournalWebPage`
}
export const issuesUrl = () => {
  return `${apiUrl}/issues`
}
export const addWebPageUrl = () => {
  return `${apiUrl}/api/addWebPage`
}
export const deleteWebPageUrl = () => {
  return `${apiUrl}/api/deleteWebPage`
}

export const updateIssueUrl = () => {
  return `${apiUrl}/updateIssue`
}
