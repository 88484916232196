import { notification } from 'antd'
import {
  memberUrl,
  singleMemberUrl,
  getNextReviewerIdUrl,
  checkMemberEmailsUrl,
  getSingleMemberURL,
  updateMemberUrl,
  checkMemberEmailsUpdateUrl
} from '../apis'
import { getToken, customAxios as axios } from '../../../request'
import { showPageLoad, hidePageLoad } from '../../../modules/actions'

export const addMember = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(memberUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
export const singleMember = (id) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(singleMemberUrl(id), {}, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
export const updateMember = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.put(memberUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}


export const getNextReviewerId = () => async (dispatch) => {
  dispatch(showPageLoad())
  let config = {
    ...getToken()
  }
  let { data } = await axios.get(getNextReviewerIdUrl(), config)
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}


export const checkMemberEmails = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(checkMemberEmailsUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}


export const getAllMember = (filters) => async (dispatch) => {
  dispatch(showPageLoad())
  let config = {
    params: { ...filters },
    ...getToken()
  }
  let { data } = await axios.get(memberUrl(), config)
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data

}
export const getSingleMember = (id) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(getSingleMemberURL(id), {}, getToken())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  dispatch(hidePageLoad())
  return data
}


export const checkMemberEmailsUpdate = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(checkMemberEmailsUpdateUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
