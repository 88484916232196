import {
  Button,
  Card,
  Col,
  Drawer,
  Form,
  Input,
  notification,
  Popconfirm,
  Row,
  Select,
  Table,
  Tooltip
} from 'antd'
import _ from 'lodash'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import {
  ChooseEmailModal,
  chooseEmailModalFxn,
  Countries,
  currencyList,
  DesignationList,
  formItemLayout
} from '../../../components/_utils/appUtils'
import AddAffiliate from '../../editArticle/addAffiliate'
import EditAffiliate from '../../editArticle/editAffiliate'
import { ApproveArticle, updateOnlineSubmission } from '../actions'
import ApprovedArticle from '../views/ApprovedArticle'

const { Option } = Select
const EditOnlineSubmission = props => {
  let {
    children,
    form: { getFieldDecorator, setFieldsValue },
    callback = null,
    visible,
    onClose,
    edit,
    article
  } = props
  const dispatch = useDispatch()
  const [affiliationData, setAffiliationData] = useState([])
  const [open1, setOpen1] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const updateAffiliateState1 = {
    openEdit: false,
    indexVal1: null,
    editVal: {}
  }
  const [updateAffiliateState, setUpdateAffiliateState] = useState(
    updateAffiliateState1
  )
  const authorData1 = {
    loop: null,
    authors: [],
    noOfAuthors: null,
    superScriptList: []
  }
  const [authorData, setAuthorData] = useState(authorData1)
  let _updateState = data => {
    setAuthorData(preState => {
      return {
        ...preState,
        ...data
      }
    })
  }
  const loopVal = noOfAuthors => {
    noOfAuthors = parseInt(noOfAuthors)
    let authors = []
    let superScriptList = ['*']
    for (let i = 0; i < noOfAuthors; i++) {
      authors.push({
        name: '',
        email: '',
        script: [],
        orchId: '',
        instituteAffiliated: '',
        designation: '',
        secondaryEmail: '',
        phoneNo: ''
      })
    }
    for (let i = 0; i < affiliationData.length; i++) {
      superScriptList.push(i + 1)
    }

    setAuthorData({ authors, noOfAuthors, superScriptList })
  }

  const addAffiliateData = data => {
    const { loop, noOfAuthors, authors } = authorData
    let cloneData = _.clone(affiliationData)
    cloneData.push(data)
    setAffiliationData(cloneData)
    let superScriptList = ['*']
    for (let i = 0; i < cloneData.length; i++) {
      superScriptList.push(i + 1)
    }
    setAuthorData({
      loop,
      noOfAuthors,
      authors,
      superScriptList: superScriptList
    })
  }
  const oncloseEditAffiliate = () => {
    setUpdateAffiliateState({ openEdit: false })
  }
  const showDrawer1 = (item, index) => {
    setOpen1(true)
  }
  const onClose1 = () => {
    setOpen1(false)
  }

  // edit
  const updateAffiliate = row => {
    let cloneData = _.clone(affiliationData)
    cloneData[row.index] = row
    setAffiliationData(cloneData)
    setOpenEdit(true)
  }
  const showDrawerEditAffiliate = (val, index) => {
    setUpdateAffiliateState({ openEdit: true, editVal: val, indexVal1: index })
  }

  const handleDeleteAffilation = key => {
    const { loop, noOfAuthors, authors } = authorData
    const newData = affiliationData.filter((item, index) => index !== key)
    let superScriptList2 = ['*']
    for (let i = 0; i < newData.length; i++) {
      superScriptList2.push(i + 1)
    }
    setAuthorData({
      loop,
      noOfAuthors,
      authors,
      superScriptList: superScriptList2
    })

    setAffiliationData(newData)
  }

  const authorsUpdate = (data, index) => {
    let { loop, superScriptList, authors, noOfAuthors } = authorData
    let cloneAuth = _.clone(authors)
    if (cloneAuth[index]) {
      cloneAuth[index] = { ...cloneAuth[index], ...data }
      setAuthorData({
        loop,
        superScriptList,
        noOfAuthors,
        authors: cloneAuth
      })
    }
  }
  let setFormData = () => {
    let { article } = props
    let address = ''
    let affiliationData = []
    let authors = []
    let superScriptList = ['*']
    let correspondingAuthor = {}
    if (article.correspondingAuthor) {
      correspondingAuthor.name = article.correspondingAuthor
      superScriptList.push('1')
    }
    if (article.correspondingAuthorsEmail) {
      correspondingAuthor.email = article.correspondingAuthorsEmail
    }

    if (article.correspondingAuthorAffiliation) {
      affiliationData.push({
        affiliate: article.correspondingAuthorAffiliation
      })
    }

    if (article) {
      if (article.correspondingAuthorAffiliation) {
        address = article.correspondingAuthorAffiliation
      }

      if (article.correspondingAuthorAddress) {
        address = address + ', ' + article.correspondingAuthorAddress
      }
    }

    // authors.push(correspondingAuthor)
    if (article.authors && article.authors.length) {
      _.each(article.authors, (item, key) => {
        authors.push({
          name: item.name,
          email: item.email,
          designation: item.designation,
          secondaryEmail: item.secondaryEmail,
          phoneNo: item.phoneNo,
          instituteAffiliated: item.instituteAffiliated,
          country: item.country
        })
        affiliationData.push({ affiliate: item.instituteAffiliated })
        superScriptList.push(key + 2)
      })
    }

    setAuthorData({
      noOfAuthors: authors.length,
      authors: authors,
      superScriptList: superScriptList
    })

    setAffiliationData(affiliationData)

    props.form.setFieldsValue({
      manuScriptTitle: article.manuScriptTitle,

      correspondingAuthor: article.correspondingAuthor,
      correspondingAuthorsEmail: article.correspondingAuthorsEmail,
      correspondingAuthorCountry: article.correspondingAuthorCountry
    })
  }



  useEffect(() => {
    setTimeout(() => {
      setFormData()
    }, 1000)
  }, [])

  let [visibleDrawer, setVisibleDrawer] = useState(false)

  const events = {
    show: event => {
      setVisibleDrawer(true)
      setTimeout(() => {
        setFormData()
      }, 1000)
    },
    hide: event => {
      setVisibleDrawer(false)
    }
  }

  const inputSchema = {
    fields: [
      {
        label: 'Article Title',
        key: 'manuScriptTitle',
        type: 'textarea',
        required: true,
        span: 24
      },
      {
        label: 'Corresponding Author',
        key: 'correspondingAuthor',
        required: true,
        placeholder: 'Corresponding Author'
      },
      {
        label: 'Corresponding Author Email',
        key: 'correspondingAuthorsEmail',
        required: true,
        placeholder: 'Authors Email'
      },
      {
        label: 'Country',
        key: 'correspondingAuthorCountry',
        required: true,
        showSearch: true,
        placeholder: 'Country',
        options: Countries,
        keyAccessor: x => x.name,
        valueAccessor: x => x.name,
        type: 'select',
        onChange: val => {
          setFieldsValue({ correspondingAuthorCountry: val })
        }
      }
      // {
      //   label: 'Corres',
      //   key: 'approvedArticleDate',
      //   required: true,
      //   placeholder: 'Enter Approval Date',
      //   type: 'date'
      // },
      // {
      //   label: 'Invoice Date',
      //   key: 'invoiceDate',
      //   required: true,
      //   placeholder: 'Date of Enter',
      //   type: 'date'
      // }
      // {
      //   label: 'Currency',
      //   key: 'currencyTypeOfApprovalAmount',
      //   required: true,
      //   placeholder: 'Currency',
      //   type: 'select',
      //   options: currencyList,
      //   onChange: e => {
      //     setFieldsValue({ paymentType: e })
      //   }
      // },
      //   {
      //     label: 'Approval Amount',
      //     key: 'approvalAmount',
      //     required: true,
      //     placeholder: 'Approval Amount',
      //     type: 'number'
      //   },
      //   {
      //     label: 'Invoice Name',
      //     key: 'approvalInvoiceName'
      //   },
      //   {
      //     label: 'Address',
      //     key: 'approvalInvoiceAddress',
      //     type: 'textArea',
      //     required: true,
      //     span: 16
      //   },
      //   {
      //     label: 'All Author Names',
      //     key: 'approvalInvoiceAuthorName',
      //     required: true,
      //     placeholder: 'all author names',
      //     type: 'textArea',
      //     span: 24
      //   },

      //   {
      //     label: 'Approval Type',
      //     key: 'approvalType',
      //     type: 'radioGroup',
      //     required: true,
      //     span: 24,
      //     options: ['Full Article', 'Short Communication', 'Review Article'],
      //     onChange: x => {
      //       props.form.setFieldsValue({
      //         approvalType: x.target.value
      //       })
      //     }
      //   }
      //   {
      //     label: 'Special Note',
      //     key: 'approvalSpecialNote',
      //     placeholder: 'Notes',
      //     span: 24,
      //     type: 'textArea'
      //   }
    ]
  }

  const handleSubmit = e => {
    const { form, article, reload } = props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        valData.onlineSubmissionById = article._id
        valData.authors = authorData.authors
        let { success, message } = await dispatch(
          updateOnlineSubmission(valData)
        )

        if (success) {
          notification.success({
            message: message
          })
          reload()
        } else {
          notification.error({
            message: message
          })
        }
      } else {
        notification.error({
          message: 'Required',
          description: 'Fill all required fields.'
        })
      }
    })
  }

  return (
    <>
      <div onClick={() => events.show()}>{children}</div>
      <Drawer
        title={`Edit Online Submission`}
        width={'86%'}
        visible={visible}
        placement='right'
        onClose={onClose}>
        <Card>
          <Form
            onSubmit={handleSubmit}
            hideRequiredMark
            className={'inputForm'}>
            <Row gutter={16}>
              {inputSchema.fields.map((item, key) => {
                return (
                  <Col span={item.span ? item.span : 8} key={key}>
                    <GetEachFormFields
                      getFieldDecorator={getFieldDecorator}
                      item={item}
                      formItemLayout={formItemLayout}
                    />
                  </Col>
                )
              })}
              <Col span={24}>

                <Card title='Authors'>
                  {authorData.authors && authorData.authors.length ? (
                    <>
                      {authorData.authors.map((item, key) => {
                        return (
                          <>
                            <Row key={key} gutter={16}>
                              <Col span={3}>
                                <Form.Item label='Name'>
                                  <Input
                                    placeholder=' Enter Name'
                                    value={item.name}
                                    onChange={e => {
                                      authorsUpdate(
                                        { name: e.target.value },
                                        key
                                      )
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={4}>
                                <Form.Item label='Email Id'>
                                  <Input
                                    placeholder='Enter email Address'
                                    type={'email'}
                                    value={item.email}
                                    onChange={e => {
                                      authorsUpdate(
                                        { email: e.target.value },
                                        key
                                      )
                                    }}
                                  />
                                </Form.Item>
                              </Col>{' '}
                              <Col span={4}>
                                <Form.Item label=' Secondary Email Id'>
                                  <Input
                                    placeholder='Enter email Address'
                                    type={'email'}
                                    value={item.secondaryEmail}
                                    onChange={e => {
                                      authorsUpdate(
                                        { secondaryEmail: e.target.value },
                                        key
                                      )
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                              {/* <Col span={3}>
                                <Form.Item label="Super Script ">
                                  <div className={'rowFlex'}>
                                    <Select
                                      onChange={e => {
                                        authorsUpdate({ script: e }, key)
                                      }}
                                      mode="multiple"
                                      value={item.script}
                                      placeholder="Please select Super Script">
                                      {authorData.superScriptList &&
                                        authorData.superScriptList.map(
                                          (item, key) => {
                                            return (
                                              <Option value={item}>
                                                {item}
                                              </Option>
                                            )
                                          }
                                        )}
                                    </Select>
                                  </div>
                                </Form.Item>
                              </Col> */}
                              {/* </Row> */}
                              <Col span={3}>
                                <Form.Item label='Institute Affiliated'>
                                  <Input
                                    placeholder='Enter Institute Affiliated'
                                    value={item.instituteAffiliated}
                                    onChange={e => {
                                      authorsUpdate(
                                        {
                                          instituteAffiliated: e.target.value
                                        },
                                        key
                                      )
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={3}>
                                <Form.Item label='Designation'>
                                  {/* <Input
                                    placeholder="Enter Designation"
                                    value={item.designation}
                                    onChange={e => {
                                      authorsUpdate(
                                        {
                                          designation: e.target.value
                                        },
                                        key
                                      )
                                    }}
                                  /> */}
                                  <Select
                                    onChange={e => {
                                      authorsUpdate(
                                        {
                                          designation: e
                                        },
                                        key
                                      )
                                    }}
                                    value={item.designation}>
                                    {DesignationList && DesignationList.length
                                      ? DesignationList.map((value, key) => {
                                        return (
                                          <option value={value} key={value}>
                                            {value}
                                          </option>
                                        )
                                      })
                                      : null}
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col span={3}>
                                <Form.Item label='Phone Number '>
                                  <Input
                                    placeholder='Enter Phone Number '
                                    value={item.phoneNo}
                                    onChange={e => {
                                      authorsUpdate(
                                        { phoneNo: e.target.value },
                                        key
                                      )
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={3}>
                                <Form.Item label='Choose Country '>
                                  {' '}
                                  <Select
                                    onChange={e => {
                                      authorsUpdate(
                                        {
                                          country: e
                                        },
                                        key
                                      )
                                    }}
                                    value={item.country}>
                                    {Countries && Countries.length
                                      ? Countries.map((value, key) => {
                                        return (
                                          <option
                                            value={value.name}
                                            key={value.id}>
                                            {value.name}
                                          </option>
                                        )
                                      })
                                      : null}
                                  </Select>
                                </Form.Item>
                              </Col>
                              {/* <Col span={6}>
                                <Form.Item label="Phone Number">
                                  <Input
                                    placeholder="Enter Phone Number"
                                    value={item.phoneNo}
                                    onChange={e => {
                                      authorsUpdate(
                                        { phoneNo: e.target.value },
                                        key
                                      )
                                    }}
                                  />
                                </Form.Item>
                              </Col> */}
                              {/* <Col span={6}>
                                <Form.Item label="Request Date">
                                  <DatePicker
                                    value={item.requestDate}
                                    onChange={date => {
                                      authorsUpdate(
                                        {  requestDate: date },
                                        key
                                      )
                                    }}
                                  />
                                </Form.Item>
                              </Col> */}
                            </Row>
                          </>
                        )
                      })}
                    </>
                  ) : null}
                </Card>
              </Col>
              <Col span={24}>
                <Form.Item style={{ marginTop: 40 }}>
                  <Button type='primary' htmlType='submit'>
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </Drawer>
      <AddAffiliate
        addAffiliateData={addAffiliateData}
        visible={open1}
        onClose={() => {
          onClose1()
        }}
      />

      {updateAffiliateState.openEdit ? (
        <EditAffiliate
          visible={updateAffiliateState.openEdit}
          updateAffiliate={updateAffiliate}
          editVal={updateAffiliateState.editVal}
          indexVal1={updateAffiliateState.indexVal1}
          onClose={() => {
            oncloseEditAffiliate()
          }}
        />
      ) : null}
    </>
  )
}

const WrappedForm = Form.create()(EditOnlineSubmission)

export default WrappedForm
