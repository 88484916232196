import React, { useEffect, useRef, useState } from 'react'
import { Drawer, Card, Button, Form, Row, Col, notification } from 'antd'
import {
  DisplayDate,
  formItemLayout,
  NotificationBox
} from '../../../components/_utils/appUtils'
import { useDispatch } from 'react-redux'
import { ChooseEmailModal, chooseEmailModalFxn } from '../../../components/_utils/appUtils'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import { ApproveArticle, WithdrawalArticle } from '../actions'
import moment from 'moment'

const WithdrawalArticleDrawer = (props) => {
  let {
    children,
    form: { getFieldDecorator, setFieldsValue },
    callback = null,
    visible,
    onClose,
    article,
    edit
  } = props
  const dispatch = useDispatch()

  let [visibleDrawer, setVisibleDrawer] = useState(false)

  const events = {
    show: (event) => {
      setVisibleDrawer(true)
    },
    hide: (event) => {
      setVisibleDrawer(false)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setFieldsValue({
        withdrawnDate: moment()
      })
    }, 1000)
  }, [])

  const inputSchema = {
    fields: [
      {
        label: 'Withdrawal Date',
        key: 'withdrawnDate',
        required: true,
        type: 'date'
      },
      {
        label: 'Withdrawal Note',
        key: 'withdrawalReason',
        required: true,
        span: 24,
        type: 'textarea'
      }
    ]
  }


  const handleSubmit = e => {
    const { form, article } = props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      chooseEmailModalFxn({
        callback: async (email) => {
          if (!err) {
            let obj = {
              fromEmail: email,
              articleId: article._id,
              articleStatus: 'Withdrawled',
              template: 'withdrawalTemplate',
              withdrawnDate: valData.withdrawnDate,
              withdrawalReason: valData.withdrawalReason
            }
            let { success } = await dispatch(WithdrawalArticle(obj))
            if (success) {
              // callback()
              onClose()
              dispatch({ type: 'START_LOAD_ARTICLE' })
              events.hide()
            }
          } else {
            notification.error({
              message: 'Required',
              description: 'Fill all required fields.'
            })
          }
        }
      })
    })
  }

  return (
    <>
      <div onClick={() => events.show()}>
        {children}
      </div>
      <Drawer
        title={`Withdraw Article | Article No. ${article.articleNo}`}
        width={'40%'}
        visible={visible}
        placement='right'
        onClose={onClose}>
        <Card>
          <Form onSubmit={handleSubmit} hideRequiredMark className={'inputForm'}>
            <Row gutter={16}>
              {inputSchema.fields.map((item, key) => {
                return (
                  <Col span={item.span ? item.span : 12} key={key}>
                    <GetEachFormFields getFieldDecorator={getFieldDecorator}
                                       item={item} formItemLayout={formItemLayout} />
                  </Col>
                )
              })}
              <Col span={24}>
                <Form.Item style={{ marginTop: 40 }}>
                  <Button type='primary' htmlType='submit'>
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </Drawer>
    </>
  )
}


const WrappedForm = Form.create()(WithdrawalArticleDrawer)

export default WrappedForm
