import { Card, Drawer } from 'antd'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import {
  ArticleStatus,
  DefaultTablePagination,
  displayDateWithtime
} from '../../../components/_utils/appUtils'
import { getAllArticleActivities } from '../actions'

const regExPFilters = [
  'message',
  'status',
  'articleId',
  'companyId',
  'articleNo'
]
const ArticleActivityList = props => {
  let { visible, onClose, articleId, articleNo } = props
  let [activityList, setActivityList] = useState([])
  useEffect(() => {
    apiRequest()
  }, [])
  const table = useRef()
  const dispatch = useDispatch()
  // const columns = [
  //   {
  //     title: '  Message',
  //     key: 'message',
  //     dataIndex: 'message',
  //     searchTextName: 'message',
  //     filterByRegex: true,
  //     width: 100
  //   },
  //   {
  //     title: 'Article No',
  //     key: 'articleNo',
  //     dataIndex: 'articleNo',
  //     searchTextName: 'articleNo',
  //     filterByRegex: true,
  //     width: 100
  //   },
  //   // {
  //   //   title: 'Article ID',
  //   //   key: 'articleId',
  //   //   dataIndex: 'articleId',
  //   //   searchTextName: 'articleId',
  //   //   filterByRegex: true,
  //   //   width: 100
  //   // },{
  //   //     title: 'Company Id',
  //   //     key: 'companyId',
  //   //     dataIndex: 'companyId',
  //   //     searchTextName: 'companyId',
  //   //     filterByRegex: true,
  //   //     width: 100
  //   //   },
  //   {
  //     title: 'Article Status',
  //     key: 'status',
  //     dataIndex: 'status',

  //     filters: ArticleStatus.map(x => ({ text: x.status, value: x.status })),
  //     width: 100
  //   },

  //   {
  //     title: 'Time',
  //     key: 'time',
  //     dataIndex: 'time',
  //     width: 100,
  //     render: item => {
  //       return item ? displayDateWithtime(item) : null
  //     }
  //   }

  //   // {
  //   //   title: 'Action',
  //   //   key: 'actions',
  //   //   width: 280,
  //   //   render: (val, record) => (
  //   //     <React.Fragment>
  //   //       {(record.selectedReviewer.remarks || record.selectedReviewer.comments || record.selectedReviewer.receiveDate) ?
  //   //         <Button className={'roundSmallBtn'} onClick={() => events.showDrawer(record.selectedReviewer)}>Change
  //   //           History</Button> : null}
  //   //     </React.Fragment>)

  //   // }
  // ]
  const apiRequest = params => {
    return new Promise(async resolve => {
      let obj = {
        ...params
      }

      let data = await dispatch(
        getAllArticleActivities({
          ...obj,
          sortField: '_id',
          sortOrder: 'descend',
          articleId,
          results: 200,
          regExFilters: regExPFilters
        })
      )
      resolve(data)
      setActivityList(data.data)
    })
  }

  return (
    <Drawer
      title={`Article Activities Details | ${articleNo}`}
      width={'65%'}
      visible={visible}
      placement="right"
      onClose={onClose}>
      <Card className={'activityCard'}>
        {activityList &&
          activityList.map((item, i) => {
            return (
              <>
                <div key={i} className="custom_card mb10">
                  <Card
                    bordered={true}
                    title={
                      <div>
                        {item.operatedByUserId.name} -
                        <span>({item.status})</span>
                      </div>
                    }
                    extra={
                      <>{moment(item.time).format('DD.MM.YYYY,h:mm A')}</>
                    }>
                    {item.message}
                  </Card>
                </div>
              </>
            )
          })}
      </Card>
    </Drawer>
  )
}

export default ArticleActivityList
