import React, { useRef, useState, useEffect } from 'react'
import { TableComp } from 'sz-react-utils-lite'
import { Drawer, Tooltip, Card, Button, Popconfirm, Form, Row, Col, Radio } from 'antd'
import {
  DisplayDate,
  DefaultTablePagination,
  formItemLayout
} from '../../../components/_utils/appUtils'
import { getAllMember } from '../../members/actions'
import { useDispatch } from 'react-redux'
import { chooseEmailModalFxn } from '../../../components/_utils/appUtils'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import { SendComments, commentToAuthor } from '../actions'
import moment from 'moment'


const CommentsToAuthorDrawer = (props) => {
  let {
    visible,
    onClose,
    form: { getFieldDecorator, getFieldValue, setFieldsValue },
    callback = null,
    articleId,
    article,
    edit
  } = props
  const dispatch = useDispatch()

  const setFormData = () => {
    if (edit) {
      setTimeout(() => {
        props.form.setFieldsValue({
          sendToAuthorDate: moment(article.sendToAuthorDate),
          statusType: article.statusType
        })
      }, 100)
    } else {
      setTimeout(() => {
        props.form.setFieldsValue({
          sendToAuthorDate: moment()
        })
      }, 100)
    }
  }

  useEffect(() => {
    setFormData()
  }, [])

  const inputSchema = {
    fields: [
      {
        label: 'Date',
        key: 'sendToAuthorDate',
        required: true,
        placeholder: 'Date of Enter',
        type: 'date',
        span: 24
      },
      {
        key: 'statusType',
        label: 'Status Type',
        type: 'radioGroup',
        required: true,
        span: 24,
        options: ['Comments', 'Rejection'],
        onChange: x => {
          props.form.setFieldsValue({
            statusType: x.target.value
          })
        }
      }
    ]
  }


  const handleSubmit = e => {
    const { form, onClose } = props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      chooseEmailModalFxn({
        title: 'Send Comments to Author',
        callback: async (email) => {
          valData.articleId = articleId
          valData.fromEmail = email
          let { success, data } = await dispatch(commentToAuthor(valData))
          if (success) {
            form.setFieldsValue({
              statusType: '',
              sendToAuthorDate: '',
              articleId: ''
            })
            dispatch({ type: 'START_LOAD_ARTICLE' })
            onClose()
            if (callback) {
              callback()
            }
          }
        }
      })
    })
  }

  return (
    <Drawer
      title={`Comments To Author | Article No. ${article.articleNo}`}
      width={'40%'}
      visible={visible}
      placement='right'
      onClose={onClose}>
      <Card>
        <Form onSubmit={handleSubmit} hideRequiredMark className={'inputForm'}>
          <Row gutter={16}>
            {inputSchema.fields.map((item, key) => {
              return (
                <Col span={item.span ? item.span : 8} key={key}>
                  <GetEachFormFields getFieldDecorator={getFieldDecorator}
                                     item={item} formItemLayout={formItemLayout} />
                </Col>
              )
            })}
            <Col span={24}>
              <Form.Item style={{ marginTop: 40 }}>
                <Button type='primary' htmlType='submit'>
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>

      </Card>
    </Drawer>
  )
}


const WrappedForm = Form.create()(CommentsToAuthorDrawer)

export default WrappedForm
