import { Alert, Button, Card, Icon, notification } from 'antd'
import React, { Component } from 'react'
import { connect, useDispatch } from 'react-redux'
import { ArticleSearch } from '../../../components/ArticleSearch'

import { getUrlParams } from '../../../routes'
import {
  articleNoteCountFxn,
  deleteArticleNote,
  getArticleById,
  getNotes,
  singleArticle
} from '../actions'
import PageHeaderWrapper from '../ArticleHeader'
import AddNotes from '../drawers/AddNotes'
import ComposingDrawer from '../drawers/ComposingDrawer'
import DeleteArticleDrawer from '../drawers/DeleteArticleDrawer'
import WithdrawalArticleDrawer from '../drawers/WithdrawalArticleDrawer'
import ArticleActivityList from './ArticleActivityList'
import {
  AcceptanceArticleDetails,
  ApprovedArticleDetails,
  ArticleDetails,
  ArticleStatus,
  CommentsToAuthor,
  ComposingDetails,
  DOIDetails,
  GalleyProofDetails,
  OnlineArticleDetails,
  PrintPublication,
  ProcessingCharges,
  PublishArticleDetails,
  ReviewedArticles,
  ReviewerDetails,
  RevisedArticleTable
} from './articlePages'

const $ = window.$

class ArticleView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      article: {},
      visibleDrawer: false,
      visibleWithdrawalDrawer: false,
      visibleAddNote: false,
      visibleActivityList: false,
      noteArrived: false,
      totalNotes: 0
    }
  }

  componentDidMount() {
    this.loadSingleArticle('didMount')
    this.reducerStop()
    this.stickyHeader()
    this.loadArticleCount()
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (
      nextProps &&
      nextProps.ArticleReducer &&
      nextProps.ArticleReducer.loadArticle
    ) {
      this.loadSingleArticle('reloadArticle')
      this.props.dispatch({ type: 'STOP_LOAD_ARTICLE' })
    }
    if (
      nextProps &&
      nextProps.ArticleReducer &&
      nextProps.ArticleReducer.refreshArticle
    ) {
      this.loadSingleArticle('refreshArticle')
      this.props.dispatch({ type: 'REFRESH_ARTICLE', refreshArticle: false })
    }
  }

  reducerStop = () => {
    this.props.dispatch({ type: 'STOP_LOAD_ARTICLE' })
    this.props.dispatch({ type: 'REFRESH_ARTICLE', refreshArticle: false })
  }
  loadSingleArticle = async type => {
    // console.log(type, '++++++++++')
    this.reducerStop()
    let urlObj = getUrlParams('articleView', window.location.pathname)
    if (urlObj && urlObj.id) {
      let { error, data } = await this.props.dispatch(getArticleById(urlObj.id))
      if (error) {
        notification.error({
          message: 'Error Loading Data'
        })
      } else {
        this.setState({ article: data })
        document.title = data.articleNo
        this.checkArticleNotes(data._id)
      }
    } else {
      notification.error({
        message: 'Error Loading Data'
      })
    }
  }
  deleteArticleNotes = _id => {
    return new Promise(async resolve => {
      let { dispatch } = this.props
      let obj = {
        articleId: _id
      }
      let data = await dispatch(deleteArticleNote(obj))
      if (!data.error) {
        this.setState({ visibleAddNote: true, noteArrived: false })
      }
      resolve(data)
    })
  }

  checkArticleNotes = _id => {
    return new Promise(async resolve => {
      let { dispatch } = this.props
      let obj = {
        articleId: _id
      }
      let data = await dispatch(getNotes(obj))
      if (!data.error) {
        this.setState({ noteArrived: true })
      }
      resolve(data)
    })
  }
  stickyHeader = () => {
    $(window).scroll(function() {
      let classCheck = $('aside').hasClass('ant-layout-sider-collapsed')
        ? 'fixedColl'
        : 'fixed'
      if ($(window).scrollTop() >= 250) {
        $('.sticky-header').addClass(classCheck)
        $('.headerPanel').addClass('sticky-header-bg')
      } else {
        $('.sticky-header').removeClass('fixedColl fixed')
        $('.headerPanel').removeClass('sticky-header-bg')
      }
    })
  }

  loadArticleCount = async () => {
    let urlObj = getUrlParams('articleView', window.location.pathname)
    if (urlObj && urlObj.id) {
      let { data } = await articleNoteCountFxn({ articleId: urlObj.id })
      this.setState({ totalNotes: data })
    }
  }

  render() {
    let { article, totalNotes, noteArrived } = this.state
    return (
      <PageHeaderWrapper
        className={'articleHeader'}
        title={
          <div className={'sticky-header'}>
            <div className={'headerPanel'}>
              <div className={'titleDiv'}>
                <ArticleSearch />
                Article Details | Article No - {article.articleNo} |{' '}
                {article.processType}
              </div>

              <div>
                <Button
                  key="1"
                  type="danger"
                  className={'marginH'}
                  onClick={() => this.setState({ visibleDrawer: true })}>
                  <Icon type={'delete'} />
                  Delete
                </Button>
                <Button
                  key="2"
                  type="warning"
                  className={'marginH'}
                  onClick={() =>
                    this.setState({ visibleWithdrawalDrawer: true })
                  }>
                  <Icon type={'rollback'} />
                  Withdrawal
                </Button>
                <Button
                  key="3"
                  className={'marginH ant-btn-success-new'}
                  onClick={() => this.setState({ visibleAddNote: true })}>
                  <Icon type={'plus'} />
                  Notes ({totalNotes})
                  {noteArrived ? <Icon type={'bell'} style={{ color: 'black', fontSize: 16, paddingTop: 5 }} /> : null}
                </Button>
                <Button
                  key="4"
                  type={'info'}
                  className={'marginH'}
                  onClick={() => this.setState({ visibleActivityList: true })}>
                  <Icon type={'eye'} />
                  Article Activities
                </Button>
              </div>
            </div>
          </div>
        }>
        <Card>
          {noteArrived ? (<Alert
              message='Note has been Added'
              style={{ marginBottom: '10px' }}
              type='info'
              closable
              showIcon
              afterClose={() => {
                this.deleteArticleNotes(article._id)
              }}
            />
          ) : null}
          <ArticleStatus article={article} />
          <ArticleDetails article={article} />
          <ProcessingCharges article={article} />

          {article.paymentDate ? (
            <ReviewerDetails article={this.state.article} />
          ) : null}
          {article.underReviewDetails &&
          article.underReviewDetails.receiveDate ? (
            <ReviewedArticles article={article} />
          ) : null}
          {article.underReviewDetails &&
          article.underReviewDetails.receiveDate ? (
            <CommentsToAuthor article={article} />
          ) : null}
          {article.sendToAuthorDate ? (
            <RevisedArticleTable article={article} />
          ) : null}
          {article.revisedArticleDate ? (
            <ApprovedArticleDetails article={article} />
          ) : null}
          {article.approvedArticleDate ? (
            <AcceptanceArticleDetails article={article} />
          ) : null}
          {article.acceptedArticlePaymentDate ? (
            <ComposingDetails article={article} />
          ) : null}
          {article.composingArticleDate || article.doiProcessDate ? (
            <GalleyProofDetails article={article} />
          ) : null}
          {article.sendgalleyProofDate ? (
            <DOIDetails article={article} />
          ) : null}
          {article.doiProcessDate ? (
            <OnlineArticleDetails article={article} />
          ) : null}
          {/*{article.onlinePublishedDate ?*/}
          {/*  <PrintPublication article={article} /> : null}*/}
          {article.onlinePublishedDate ? (
            <PublishArticleDetails article={article} />
          ) : null}
        </Card>
        {this.state.visibleDrawer ? (
          <DeleteArticleDrawer
            visible={this.state.visibleDrawer}
            articleId={article._id}
            article={article}
            Id={article._id}
            onClose={() => this.setState({ visibleDrawer: false })}
          />
        ) : null}
        {this.state.visibleWithdrawalDrawer ? (
          <WithdrawalArticleDrawer
            visible={this.state.visibleWithdrawalDrawer}
            articleId={article._id}
            article={article}
            Id={article._id}
            onClose={() => this.setState({ visibleWithdrawalDrawer: false })}
          />
        ) : null}
        {this.state.visibleAddNote ? (
          <AddNotes
            visible={this.state.visibleAddNote}
            article={article}
            articleId={article._id}
            articleNo={article.articleNo}
            onClose={() => this.setState({ visibleAddNote: false })}
          />
        ) : null}
        {this.state.visibleActivityList ? (
          <ArticleActivityList
            articleId={article._id}
            articleNo={article.articleNo}
            visible={this.state.visibleActivityList}
            onClose={() => this.setState({ visibleActivityList: false })}
          />
        ) : null}
      </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global, router, ArticleReducer }) => ({
  search: router.location.search,
  pathname: router.location.pathname,
  ArticleReducer: ArticleReducer
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ArticleView)
