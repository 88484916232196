import React, { useEffect, useState, useRef } from 'react'
import { Button, Card, Collapse, Popconfirm, Table, Tooltip, notification } from 'antd'
import { DisplayDate, GetDownloadLink } from '../../../components/_utils/appUtils'
import { TableComp } from 'sz-react-utils-lite'
import { SelectReviewer } from '../reviewer/selectReviewer'
import _ from 'lodash'
import MemberHistory from '../reviewer/memberHistory'
import ReceiveDateDrawer from '../reviewer/selectReceiveDate'
import AddRemarkDrawer from '../reviewer/addRemark'
import { ChooseEmailModal, chooseEmailModalFxn } from '../../../components/_utils/appUtils'
import ViewMember from './viewMember'
import { ReviewerAward, ResendArticle } from '../actions'
import { useDispatch } from 'react-redux'

const { Panel } = Collapse
const ReviewerDetails = (props) => {
  let { article } = props
  let { selectedReviewer, articleStatus } = article
  let [visibleSelectReview, setVisibleSelectReview] = useState(false)
  let [memberDrawerVisible, setMemberDrawerVisible] = useState(false)
  let [receiveDateDrawerVisible, setReceiveDateDrawerVisible] = useState(false)
  let [addRemarkDrawerVisible, setAddRemarkDrawerVisible] = useState(false)
  let [reviewerId, setReviewerId] = useState('')
  let [reviewerData, setReviewerData] = useState({})
  let headerTitle = `Under Review ${selectedReviewer && selectedReviewer.length && selectedReviewer[0].sendDate ? `(${DisplayDate(selectedReviewer[0].sendDate)})` : ''}`
  let [ids, setIds] = useState({})
  let dispatch = useDispatch()
  const table = useRef()
  let events = {
    reloadTable: () => {
      table.current.reload()
    },
    showDrawer: () => {
      let ids = {}
      _.each(selectedReviewer, function(value) {
        if (value.reviewerId && value.reviewerId._id) {
          ids[value.reviewerId._id] = true
        }
      })
      setIds(ids)
      setVisibleSelectReview(true)
    },
    hideDrawer: () => {
      setVisibleSelectReview(false)
      // events.reloadTable();
    },
    showMemberHistory: (record) => {
      setMemberDrawerVisible(true)
      setReviewerData(record.reviewerId)
      setReviewerId(record.reviewerId._id)
    },
    hideMemberHistory: () => {
      setMemberDrawerVisible(false)
      setReviewerId('')
    },
    showAndHideReceiveDate: (value, id = '') => {
      setReceiveDateDrawerVisible(value)
      setReviewerId(id)
    },
    showAndHideAddRemarkDrawer: (value, id = '') => {
      setAddRemarkDrawerVisible(value)
      setReviewerId(id)
    },
    reviewerAwardSend: async (id) => {
      chooseEmailModalFxn({
        title: 'Reviewer Certificate',
        callback: async (email) => {
          let obj = {
            articleId: article._id,
            reviewerId: id._id,
            fromEmail: email,
            template: 'reviewerThanksAfterReceivingComments'
          }
          let resp = await dispatch(ReviewerAward(obj))
          if (resp && resp.success) {
            dispatch({ type: 'START_LOAD_ARTICLE' })
          }
        }
      })
    },
    resendArticle: (reviewerId) => {
      chooseEmailModalFxn({
        title: 'Resend Article',
        callback: async (email) => {
          let obj = {
            articleId: article._id,
            reviewerId: reviewerId,
            fromEmail: email,
            template: 'requestToNewReviewer'
          }
          let resp = await dispatch(ResendArticle(obj))
          if (resp && resp.success) {
            dispatch({ type: 'START_LOAD_ARTICLE' })
          }
        }
      })
    }
  }
  let getExtraButton = () => {
    return (
      <React.Fragment>
        {articleStatus == 'Under Process' ? <Button type={'primary'} size={'small'} onClick={(event) => {
          events.showDrawer()
          event.stopPropagation()
        }}>Select Reviewer</Button> : null}

        {!(articleStatus != 'Under Reviewer') ? <Button type={'primary'} size={'small'} onClick={(event) => {
          events.showDrawer()
          event.stopPropagation()
        }}>Select Another Reviewer</Button> : null}

      </React.Fragment>
    )
  }

  const columns = [

    {
      title: 'Reviewer ID',
      key: 'reviewerId',
      dataIndex: 'reviewerId',
      render: (item, record) => {
        return (
          <>
            {item && item.reviewerId ? <ViewMember revId={item._id}>
              <a>{item.reviewerId}</a>
            </ViewMember> : null}
          </>
        )
      }
    },
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      render: (item, record) => {
        return (
          <span>
            {record && record.reviewerId && record.reviewerId.name ? record.reviewerId.name : null}
          </span>
        )
      }
    },
    {
      title: 'Rating',
      key: 'rating',
      dataIndex: 'rating',
      render: (item, record) => {
        return (
          <span>
            {record && record.reviewerId && record.reviewerId.rating ? `${record.reviewerId.rating} star` : null}
          </span>

        )
      }
    },
    {
      title: 'Send Date',
      key: 'sendDate',
      dataIndex: 'sendDate',
      render: (item, record) => {
        return (
          <a>
            {item ? DisplayDate(item) : null}
          </a>
        )
      }
    },
    {
      title: 'Reminder Date',
      key: 'reminderDate',
      dataIndex: 'reminderDate',
      render: (item, record) => {
        return (
          <a>
            {item ? DisplayDate(item) : null}
          </a>
        )
      }
    },
    {
      title: 'Remark',
      key: 'remarks',
      dataIndex: 'remarks',
      render: (item, record) => {
        return (
          <div>
            {item ? item : null}
            {record.comments ? <div>
              Comments : {record.comments}
            </div> : null}
          </div>
        )
      }
    },
    {
      title: 'Remark Date',
      key: 'commentsDate',
      dataIndex: 'commentsDate',
      render: (item, record) => {
        return (
          <>
            {record.receiveDate ? null : DisplayDate(item)}
          </>
        )
      }
    },
    {
      title: 'Received Date',
      key: 'receiveDate',
      dataIndex: 'receiveDate',
      render: (item, record) => {
        return (
          <>
            {item ? DisplayDate(item) : null}
          </>
        )
      }
    },
    {
      title: 'Action',
      key: 'actions',
      width: 280,
      render: (val, record) => {
        let { reviewerId } = record
        return (
          <React.Fragment>
            {!(record.status == 'Inactive') ? <React.Fragment>
              {!record.receiveDate ?
                <Button className={'roundSmallBtn'}
                        onClick={() => events.showAndHideReceiveDate(true, reviewerId._id)}>Receive
                  Date</Button> : null}

              <Button className={'roundSmallBtn'}
                      onClick={() => events.showAndHideAddRemarkDrawer(true, reviewerId._id)}>Remarks</Button>

              <Button className={'roundSmallBtn'}
                      onClick={() => events.resendArticle(reviewerId._id)}>Resend Article</Button>
            </React.Fragment> : null}

            <Button className={'roundSmallBtn'}
                    onClick={() => events.showMemberHistory(record)}>View
              History</Button>


            {record.status == 'Inactive' && record.articleReward && record.articleReward.path && (record.receiveDate && record.updateHistory !== true) ?
              <React.Fragment>
                <Tooltip title="Download">
                  <a className={'roundSmallBtn'} target={'_blank'}
                     href={record.articleReward.path}>
                    Download
                  </a>
                </Tooltip>
                {/*   {!article.reviewerAwardMail ?
                  <Tooltip title="Send Mail">
                    <Popconfirm placement="topLeft" title={`Are you sure you want to send Mail ?`}
                                onConfirm={() => events.reviewerAwardSend(record.reviewerId)} okText="Yes"
                                cancelText="No">
                      <a className={'roundSmallBtn'}>
                        Send Mail
                      </a>
                    </Popconfirm>
                  </Tooltip> : null}*/}
              </React.Fragment>
              : null}

          </React.Fragment>
        )
      }

    }


  ]

  return (
    <div>
      <Collapse defaultActiveKey={['1']} expandIconPosition={'right'}>
        <Panel header={headerTitle} key="1" extra={getExtraButton()}>
          <Table columns={columns} dataSource={article.selectedReviewer} bordered={true} size={'small'}
                 ref={table}
                 pagination={false}/>
        </Panel>
      </Collapse>
      {visibleSelectReview ?
        <SelectReviewer article={article} ids={ids} onClose={() => events.hideDrawer()}
                        page={'articleView'}
                        visible={visibleSelectReview}/> : null}

      {memberDrawerVisible && reviewerId ? <MemberHistory
        visible={memberDrawerVisible}
        reviewerId={reviewerId}
        data={reviewerData}
        articleId={article._id}
        onClose={events.hideMemberHistory}
      /> : null}

      {receiveDateDrawerVisible ? <ReceiveDateDrawer
        visible={receiveDateDrawerVisible}
        reviewerId={reviewerId}
        article={article}
        onClose={() => events.showAndHideReceiveDate(false)}
      /> : null}

      {addRemarkDrawerVisible ? <AddRemarkDrawer
        visible={addRemarkDrawerVisible}
        reviewerId={reviewerId}
        article={article}
        onClose={() => events.showAndHideAddRemarkDrawer(false)}
      /> : null}
    </div>
  )
}

export default ReviewerDetails
