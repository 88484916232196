import {
  Button,
  Card,
  Col,
  Drawer,
  Form,
  Icon,
  Input,
  notification,
  Radio,
  Row,
  Tooltip
} from 'antd'
import _ from 'lodash'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import {
  AssignToName,
  ChooseEmailModal,
  chooseEmailModalFxn,
  currencyList,
  DisplayDate,
  formItemLayout,
  InputBox,
  NotificationBox,
  PaymentModes
} from '../../../components/_utils/appUtils'
import Request from '../../../request'
import { ComposeArticle } from '../actions'

const ComposingDrawer = props => {
  let {
    children,
    form: { getFieldDecorator, setFieldsValue },
    callback = null,
    visible,
    onClose,
    article,
    edit
  } = props
  const dispatch = useDispatch()

  let [attachment, setAttachment] = useState({
    composingArticleAttachment: ''
  })

  useEffect(() => {
    getJournalList()
    getAdminUsers()
    setTimeout(() => {
      setFormData()
    }, 1000)
  }, [])

  let setFormData = () => {
    let { article, edit } = props
    if (edit) {
      props.form.setFieldsValue({
        acceptedArticlePaymentDate: moment(article.acceptedArticlePaymentDate),
        assignToName: article.assignToName,
        composeJournalName: article.composeJournalName,
        composeVolumes: article.composeVolumes,
        composeIssues: article.composeIssues,
        composingArticleDate: moment(article.composingArticleDate)
      })
      setAttachment({
        composingArticleAttachment:
          article.composingArticleAttachment &&
          article.composingArticleAttachment.path
            ? article.composingArticleAttachment.path
            : ''
      })
    } else {
      props.form.setFieldsValue({
        composingArticleDate: moment()
      })
    }
  }

  let [visibleDrawer, setVisibleDrawer] = useState(false)
  let [composeRevisedArticle, setComposeRevisedArticle] = useState({})
  let [journalNameList, setJournalNameList] = useState([])
  let [adminUserList, setAdminUserList] = useState([])

  const events = {
    show: event => {
      setVisibleDrawer(true)
      setTimeout(() => {
        setFormData()
      }, 1000)
    },
    hide: event => {
      setVisibleDrawer(false)
    }
  }

  const inputSchema = {
    fields: [
      {
        label: 'Attachments',
        customField: (
          <InputBox title={`Upload Revised Article`} className={'rowFlex'}>
            <Input
              type={'file'}
              name={'revisedArticle'}
              id={'revisedArticle'}
              className={'form-control'}
              onChange={e => {
                let { name, files } = e.target
                if (files && files.length) {
                  setComposeRevisedArticle(files[0])
                }
              }}
            />
            {edit && attachment && attachment.composingArticleAttachment ? (
              <a
                className={'linkBtn'}
                target={'_blank'}
                href={`${attachment.composingArticleAttachment}`}>
                <Icon type={'eye'} />
              </a>
            ) : null}
          </InputBox>
        )
      },
      {
        label: 'Date',
        span: 6,
        key: 'composingArticleDate',
        required: true,
        placeholder: 'Enter Composing Date',
        type: 'date'
      },
      {
        label: 'Assign To',
        key: 'assignToName',
        required: true,
        placeholder: 'Select',
        options: adminUserList,
        type: 'radioGroup',
        span: 10,
        onChange: e => {
          setFieldsValue({ assignToName: e.target.value })
        }
      },
      {
        label: 'Journals',
        key: 'composeJournalName',
        placeholder: 'Select',
        type: 'select',
        options: journalNameList,
        keyAccessor: x => x.value,
        valueAccessor: x => x.value,
        onChange: e => {
          console.log(e, 'skmdfmksdmf')
          setFieldsValue({ composeJournalName: e })
        }
      },
      {
        label: 'Volumes',
        key: 'composeVolumes',
        placeholder: 'volumes'
      },
      {
        label: 'Issues',
        key: 'composeIssues',
        placeholder: 'issues'
      },
      {
        label: 'Composed',
        span: 6,
        key: 'composed',
        placeholder: 'Enter Composed Date',
        type: 'date',
        hidden: !edit
      }
    ]
  }

  const getJournalList = async () => {
    let { data } = await Request.journalName()
    let journalList = []
    _.each(data, item => {
      journalList.push({
        _id: item.slugJournal,
        value: item.name,
        display: item.name
      })
    })
    setJournalNameList(journalList)
  }

  const getAdminUsers = async () => {
    let { data } = await Request.getAdminUsers()
    let adminUsers = []
    _.each(data, item => {
      adminUsers.push(item.name)
    })
    setAdminUserList(adminUsers)
  }

  const handleSubmit = e => {
    const { form, article } = props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        let fd = new FormData()
        let obj = {
          articleId: article._id,
          assignToName: valData.assignToName,
          composeJournalName: valData.composeJournalName,
          composeVolumes: valData.composeVolumes,
          composeIssues: valData.composeIssues,
          composed: valData.composed
        }
        if (!edit) {
          obj.composingArticleDate = new Date()
          obj.articleStatus = 'Composing Article'
        } else {
          obj.composingArticleDate = valData.composingArticleDate
        }
        fd.append('obj', JSON.stringify(obj))
        fd.append('composingArticleAttachment', composeRevisedArticle)
        let { success } = await dispatch(ComposeArticle(fd))
        if (success) {
          onClose()
          if (callback) {
            callback()
          }
          dispatch({ type: 'START_LOAD_ARTICLE' })
          events.hide()
        }
      } else {
        notification.error({
          message: 'Required',
          description: 'Fill all required fields.'
        })
      }
    })
  }

  return (
    <>
      <div onClick={() => events.show()}>{children}</div>
      <Drawer
        title={`Composing Article | Article No. ${article.articleNo}`}
        width={'70%'}
        visible={visible}
        placement='right'
        onClose={onClose}>
        <Card>
          <Form
            onSubmit={handleSubmit}
            hideRequiredMark
            className={'inputForm'}>
            <Row gutter={16}>
              {inputSchema.fields.map((item, key) => {
                return (
                  <Col span={item.span ? item.span : 8} key={key}>
                    {item.customField ? (
                      item.customField
                    ) : (
                      <GetEachFormFields
                        item={item}
                        getFieldDecorator={getFieldDecorator}
                        formItemLayout={formItemLayout}
                      />
                    )}
                  </Col>
                )
              })}
              <Col span={24}>
                <Form.Item style={{ marginTop: 40 }}>
                  <Button type='primary' htmlType='submit'>
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </Drawer>
    </>
  )
}

const WrappedForm = Form.create()(ComposingDrawer)

export default WrappedForm
