import React, { useEffect, useRef, useState } from 'react'
import { Drawer, Tooltip, Card, Button, Form, Row, Col, Radio, Input, Icon, notification } from 'antd'
import {
  DisplayDate,
  formItemLayout, InputBox,
  NotificationBox
} from '../../../components/_utils/appUtils'
import { useDispatch } from 'react-redux'
import { ChooseEmailModal, chooseEmailModalFxn } from '../../../components/_utils/appUtils'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import { GalleyProofArticle } from '../actions'
import moment from 'moment'
import Request from '../../../request'
import _ from 'lodash'

const GalleyProofDrawer = (props) => {
  let {
    children,
    form: { getFieldDecorator, setFieldsValue },
    callback = null,
    visible,
    onClose,
    article,
    edit
  } = props
  const dispatch = useDispatch()

  let [attachment, setAttachment] = useState({
    composingArticleAttachment: ''
  })


  useEffect(() => {
    getJournalList()
    setTimeout(() => {
      setFormData()
    }, 1000)
  }, [])


  let setFormData = () => {
    let { article, edit } = props
    if (edit) {
      props.form.setFieldsValue({
        galleyProofEarlyPublication: article.galleyProofEarlyPublication,
        sendgalleyProofDate: moment(article.sendgalleyProofDate)
      })
      setAttachment({
        attachmentAtgalleyProof: article.attachmentAtgalleyProof && article.attachmentAtgalleyProof.path ? article.attachmentAtgalleyProof.path : ''
      })
    } else {
      props.form.setFieldsValue({
        sendgalleyProofDate: moment()
      })
    }
  }


  let [visibleDrawer, setVisibleDrawer] = useState(false)
  let [attachmentAtGalleyProof, setAttachmentAtGalleyProof] = useState({})
  let [journalNameList, setJournalNameList] = useState([])

  const events = {
    show: (event) => {
      setVisibleDrawer(true)
    },
    hide: (event) => {
      setVisibleDrawer(false)
    }
  }

  const inputSchema = {
    fields: [
      {
        label: 'Galley Proof Attachment',
        customField: (
          <InputBox title={`Galley Proof Attachment`} className={'rowFlex'}>
            <Input type={'file'} name={'attachmentAtgalleyProof'} id={'attachmentAtgalleyProof'}
                   className={'form-control'}
                   onChange={(e) => {
                     let { name, files } = e.target
                     if (files && files.length) {
                       setAttachmentAtGalleyProof(files[0])
                     }
                   }} />
            {edit && attachment && attachment.attachmentAtgalleyProof ?
              <a className={'linkBtn'} target={'_blank'} href={`${attachment.attachmentAtgalleyProof}`}>
                <Icon type={'eye'} />
              </a> : null}
          </InputBox>
        )
      },
      {
        label: 'Date',
        span: 6,
        key: 'sendgalleyProofDate',
        required: true,
        placeholder: 'GalleyProof Date',
        type: 'date'
      },
      {
        label: 'Early Publication',
        key: 'galleyProofEarlyPublication',
        required: true,
        placeholder: 'Select',
        options: [{ label: 'Yes', value: true }, { label: 'No', value: false }],
        type: 'radioGroup',
        span: 16,
        onChange: (e) => {
          console.log(e.target.value)
          setFieldsValue({ galleyProofEarlyPublication: e.target.value })
        }
      }
    ]
  }
  const getJournalList = async () => {
    let { data } = await Request.journalName()
    let journalList = []
    _.each(data, (item) => {
      journalList.push({
        _id: item.slugJournal,
        value: item.slugJournal,
        display: item.name
      })
    })
    setJournalNameList(journalList)
  }


  const handleSubmit = e => {
    const { form, article, callback = null } = props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        if (!edit) {
          if (!attachmentAtGalleyProof || (attachmentAtGalleyProof && !attachmentAtGalleyProof.name)) {
            notification.error({
              message: 'Required',
              description: 'Choose Galley Proof.'
            })
            return
          }
        }
        chooseEmailModalFxn({
          title: 'Galley Proof',
          callback: async (email) => {
            let fd = new FormData()
            let obj = {
              fromEmail: email,
              articleId: article._id,
              earlyPublication: valData.earlyPublication,
              galleyProofEarlyPublication: valData.galleyProofEarlyPublication,
              template: 'galleyProofArticle'
            }
            if (!edit) {
              obj.articleStatus = 'Galley Proof'
              obj.sendgalleyProofDate = new Date()
            } else {
              obj.sendgalleyProofDate = valData.sendgalleyProofDate
            }
            fd.append('obj', JSON.stringify(obj))
            fd.append('attachmentAtgalleyProof', attachmentAtGalleyProof)
            let { success } = await dispatch(GalleyProofArticle(fd))
            if (success) {
              onClose()
              if (callback) {
                callback()
              }
              dispatch({ type: 'START_LOAD_ARTICLE' })
              events.hide()
            }
          }
        })
      } else {
        notification.error({
          message: 'Required',
          description: 'Fill all required fields.'
        })
      }
    })
  }

  return (
    <>
      <div onClick={() => events.show()}>
        {children}
      </div>
      <Drawer
        title={`Galley Proof Article | Article No. ${article.articleNo}`}
        width={'35%'}
        visible={visible}
        maskClosable={true}
        placement='right'
        onClose={onClose}>
        <Card>
          <Form onSubmit={handleSubmit} hideRequiredMark className={'inputForm'}>
            <Row gutter={16}>
              {inputSchema.fields.map((item, key) => {
                return (
                  <Col span={24} key={key}>
                    {item.customField ? item.customField :
                      <GetEachFormFields
                        item={item}
                        getFieldDecorator={getFieldDecorator}
                        formItemLayout={formItemLayout}/>}
                  </Col>
                )
              })}
              <Col span={24}>
                <Form.Item style={{ marginTop: 40 }}>
                  <Button type='primary' htmlType='submit'>
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </Drawer>
    </>
  )
}


const WrappedForm = Form.create()(GalleyProofDrawer)

export default WrappedForm
