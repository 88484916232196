import {
    Button,
    Card,
    Col,
    Collapse,
    Form,
    Icon,
    Input,
    notification,
    Popconfirm,
    Row,
    Select,
    Table,
    Tag,
    Tooltip
} from 'antd'
import CollapsePanel from 'antd/lib/collapse/CollapsePanel'
import async from 'async'
import _ from 'lodash'
import moment from 'moment'
import React, {PureComponent} from 'react'
import ReactHtmlParser from 'react-html-parser'
import {connect} from 'react-redux'
import PageHeaderWrapper from '../../components/PageHeaderWrapper'
import GetEachFormFields from '../../components/_utils/appFormUtils'
import {
    CkEditor2013, CustomCkEditor, getFigureContent,
    InputBox,
    mentionsFeeds
} from '../../components/_utils/appUtils'
import {getUrlParams} from '../../routes'
import {AddDoiArticleFxn, singleArticle} from '../article/actions'
import {searchKeyword} from './actions'
import AddAffiliate from './addAffiliate'
import {default as AddInformation} from './addInformation'
import AddKeyword from './addKeywordDrawer'
import AddTable from './addTable'
import EditAffiliate from './editAffiliate'
import EditInformation from './editInformation'
import EditTable from './editTable'

const {Option} = Select
const $ = window.$
const tableDataArr = [
    {name: 'table', label: 'Tables'},
    {name: 'figure', label: 'Figures'},
    {name: 'supplementaryTable', label: 'Supplementary Tables'},
    {name: 'annexure', label: 'Annexure'},
    {name: 'anova', label: 'Anova'},
    {name: 'plate', label: 'Plate'},
    {name: 'flowChart', label: 'Flow Chart'},
    {name: 'diagram', label: 'Diagram'}
]

@Form.create()
class AddDoiArticle extends PureComponent {
    state = {
        edit: false,
        fileKey: moment(),
        ckeditorKey: moment() + 1,
        infoData: [],
        affiliationData: [],
        keywords: [],
        featuredImage: '',
        inputVisible: false,
        inputValue: '',
        loop: null,
        authors: [],
        noOfAuthors: null,
        page: 1,
        valGet: false,
        editTable: {},
        editInfo: {},
        keywordList: [],
        editVal: {},
        indexVal1: null,
        table: [],
        figure: [],
        supplementaryTable: [],
        annexure: [],
        anova: [],
        doiArticleNo: null,
        plate: [],
        flowChart: [],
        diagram: [],
        tableDrawerState: {
            visible: false,
            tableType: '',
            tableTitle: ''
        },
        superScriptList: [],
        currentStatus: ''
    }
    keywordEvents = {
        getKeyword: async value => {
            if (value) {
                let {data} = await searchKeyword({keyword: value})
                if (data) {
                    this.setState({keywordList: data})
                }
            }
        },
        chooseKeyword: value => {
            let clData = _.clone(this.state.keywords)
            let clDataList = _.clone(this.state.keywordList)
            let findData = _.find(clDataList, item => {
                return item._id == value.key
            })
            clData.push({
                keywordId: findData._id,
                keyword: findData.keyword,
                slugKeyword: findData.slugKeyword
            })
            this.setState({
                keywords: clData,
                keywordList: []
            })
        },

        removeKeyword: id => {
            let clData = _.clone(this.state.keywords)
            clData = _.reject(clData, item => {
                return item.keywordId == id
            })
            this.setState({
                keywords: clData
            })
        }
    }

    events = {
        addData: data => {
            let cloneData = _.clone(this.state[data.tableType])
            cloneData.push(data)
            this.setState({
                [data.tableType]: cloneData
            })
        },
        showDrawer: data => {
            this.setState({
                tableDrawerState: {
                    visible: true,
                    tableType: data.name,
                    tableTitle: data.label
                }
            })
        },

        hideDrawer: () => {
            this.setState({
                tableDrawerState: {
                    visible: false,
                    tableType: '',
                    tableTitle: ''
                }
            })
        },
        deleteItem: (index, tableType) => {
            let cloneData = _.clone(this.state[tableType])
            cloneData = _.reject(cloneData, (item, key) => {
                return key == index
            })
            this.setState({
                [tableType]: cloneData
            })
        },
        onCloseKeyword: () => {
            this.setState({
                open4: false
            })
        },
        showKeywordDrawer: () => {
            this.setState({
                open4: true
            })
        }
    }

    constructor(props) {
        super(props)
    }

    stickyHeader = () => {
        $(window).scroll(function () {
            let classCheck = $('aside').hasClass('ant-layout-sider-collapsed')
                ? 'fixedColl'
                : 'fixed'
            if ($(window).scrollTop() >= 250) {
                $('.sticky-header').addClass(classCheck)
                $('.headerPanel').addClass('sticky-header-bg')
            } else {
                $('.sticky-header').removeClass('fixedColl fixed')
                $('.headerPanel').removeClass('sticky-header-bg')
            }
        })
    }

    chooseDocument = e => {
        let {name, files} = e.target
        if (files && files.length) {
            this.setState({[name]: files[0]})
        }
    }

    oncloseEditAffiliate = () => {
        this.setState({
            openEdit: false
        })
    }
    showDrawerEditAffiliate = (val, index) => {
        this.setState({openEdit: true, editVal: val, indexVal1: index})
    }
    updateAffiliate = row => {
        let cloneData = _.clone(this.state.affiliationData)
        cloneData[row.index] = row
        this.setState({affiliationData: cloneData, openEdit: false})
    }
    loopVal = noOfAuthors => {
        let {affiliationData} = this.state
        noOfAuthors = parseInt(noOfAuthors)
        let authors = []
        let superScriptList = ['*', '#']
        for (let i = 0; i < noOfAuthors; i++) {
            authors.push({
                name: '',
                email: '',
                script: [],
                orchId: ''
            })
        }
        for (let i = 0; i < affiliationData.length; i++) {
            superScriptList.push(i + 1)
        }
        this.setState({authors, noOfAuthors, superScriptList})
    }

    authorsUpdate = (data, index) => {
        let {authors} = this.state
        let cloneAuth = _.clone(authors)
        if (cloneAuth[index]) {
            cloneAuth[index] = {...cloneAuth[index], ...data}
            this.setState({
                authors: cloneAuth
            })
        }
    }

    showDrawerEditInfo = (item, index) => {
        this.setState({
            editInfo: {...item, index},
            ['open2']: true
        })
    }

    onClose2 = () => {
        this.setState({open2: false})
    }

    updateTable = row => {
        let cloneData = _.clone(this.state.infoT)
        cloneData[row.index] = row
        this.setState({infoT: cloneData, open2: false})
    }

    updateInfo = row => {
        let cloneData = _.clone(this.state.infoData)
        cloneData[row.index] = row
        this.setState({infoData: cloneData, open2: false})
    }

    onClose = () => {
        this.setState({
            ['open']: false
        })
    }

    handleDelete = key => {
        const newData = this.state.infoData.filter(item => item.menuHeading !== key)
        this.setState({infoData: newData})
    }

    addDetails = data => {
        let cloneData = _.clone(this.state.infoData)
        cloneData.push(data)
        this.setState({
            infoData: cloneData
        })
    }

    addAffiliateData = data => {
        let cloneData = _.clone(this.state.affiliationData)
        cloneData.push(data)
        this.setState(
            {
                affiliationData: cloneData
            },
            () => {
                let {affiliationData} = this.state
                let superScriptList = []
                for (let i = 0; i < affiliationData.length; i++) {
                    superScriptList.push(i + 1)
                }
                this.setState({superScriptList})
            }
        )
    }

    onClose1 = () => {
        this.setState({
            ['open1']: false
        })
    }

    // editAffilaiate= data => {
    //   if
    //   // let cloneData = _.clone(this.state.affiliationData)
    //   // cloneData.push(data)
    //   // this.setState({
    //   //   affiliationData: cloneData
    //   // })
    // }

    handleDeleteAffilation = key => {
        const newData = this.state.affiliationData.filter(
            (item, index) => index !== key
        )
        let superScriptList2 = []
        for (let i = 0; i < newData.length; i++) {
            superScriptList2.push(i + 1)
        }
        this.setState({superScriptList: superScriptList2})

        this.setState({affiliationData: newData})
    }

    handleSubmit = async e => {
        const {dispatch, form} = this.props
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                let {
                    infoData,
                    authors,
                    noOfAuthors,
                    keywords,
                    affiliationData,
                    doiAbstract,
                    doiArticleTitle,
                    featuredImage,
                    articleId,
                    doiReferences,
                    currentStatus
                } = this.state

                if (!doiArticleTitle) {
                    notification.error({
                        message: 'Required',
                        description: 'Enter Manuscript Title'
                    })
                    return
                }

                if (!keywords || (keywords && !keywords.length)) {
                    notification.error({
                        message: 'Required',
                        description: 'Enter DOI Keywords'
                    })
                    return
                }
                if (doiAbstract === '') {
                    notification.error({
                        message: 'Required',
                        description: 'Enter DOI Abstract'
                    })
                    return
                }
                if (doiReferences === '') {
                    notification.error({
                        message: 'Required',
                        description: 'Enter DOI References'
                    })
                    return
                }

                let fd = await new FormData()
                let obj = {
                    ...valData,
                    articleId,
                    doiAuthors: authors,
                    infoData,
                    noOfAuthors,
                    doiKeywordList: keywords,
                    affiliationData,
                    doiAbstract,
                    doiArticleTitle,
                    doiReferences,
                    manuScriptTitle: doiArticleTitle
                }
                if (featuredImage && featuredImage.name) {
                    fd.append('featuredImage', featuredImage)
                }
                async.each(
                    tableDataArr,
                    (item, cb1) => {
                        if (this.state[item.name] && this.state[item.name].length) {
                            obj[item.name] = this.state[item.name]
                            _.each(this.state[item.name], (item1, key) => {
                                if (item1.image && item1.image.name) {
                                    fd.append(`${item.name}Images`, item1.image)
                                }
                            })
                            cb1()
                        } else {
                            obj[item.name] = []
                            cb1()
                        }
                    },
                    async () => {
                        // Galley Proof
                        if (currentStatus == 'Galley Proof') {
                            obj.articleStatus = 'DOI'
                        }

                        fd.append('obj', JSON.stringify(obj))
                        console.log(obj, "objjj")
                        let resp = await dispatch(AddDoiArticleFxn(fd))
                        console.log(resp, "respppp")
                        if (resp && resp.success) {
                            notification.success({
                                message: resp.message
                            })
                        }
                    }
                )
            }
        })
    }

    showDrawer = () => {
        this.setState({
            ['open']: true
        })
    }

    showDrawer1 = (item, index) => {
        this.setState({
            ['open1']: true
        })
    }

    componentDidMount() {
        this.stickyHeader()
        this.loadArticleData()
    }

    loadArticleData = () => {
        let data = getUrlParams('article.addDoi', this.props.pathname)
        if (!!data) {
            let {id} = data
            this.setFormValuesFxn(id)
        }
    }

    editTableEvents = {
        showDrawerEditTable: (item, tableType) => {
            let {articleId} = this.state
            this.setState({
                editTable: {...item, tableType, articleId},
                openTable: true
            })
        },
        hideDrawerEditTable: () => {
            this.setState({
                editTable: {},
                openTable: false
            }, () => {
                this.loadArticleData()
            })
        }
    }

    setFormValuesFxn = async id => {
        const {dispatch, form} = this.props
        let {error, data} = await dispatch(singleArticle(id))
        if (error) {
            notification.error({
                message: 'Error Loading Data'
            })
        } else {
            let obj = {
                doiProcessDate: moment(data.doiProcessDate),
                doiAuthorName: data.doiAuthorName,
                doiAuthorAddress: data.doiAuthorAddress,
                doiKeywordList: data.doiKeywordList
            }
            this.props.form.setFieldsValue({...obj})
            let superScriptList = ['*', '#']
            if (data.affiliationData && data.affiliationData.length) {
                for (let i = 0; i < data.affiliationData.length; i++) {
                    superScriptList.push(i + 1)
                }
            }

            let stateObj = {
                doiArticleNo: data.articleNo,
                articleId: data._id,
                doiArticleTitle: data.doiArticleTitle
                    ? data.doiArticleTitle
                    : data.manuScriptTitle,
                doiAbstract: data.doiAbstract ? data.doiAbstract : data.abstract,
                infoData: data.infoData ? data.infoData : [],
                noOfAuthors: data.noOfAuthors ? data.noOfAuthors : '',
                superScriptList: superScriptList,
                authors: data.doiAuthors ? data.doiAuthors : [],
                affiliationData: data.affiliationData ? data.affiliationData : [],
                keywords: data.doiKeywordList ? data.doiKeywordList : [],
                oldFeatureImage: data.featuredImage ? data.featuredImage : {},
                doiReferences: data.doiReferences ? data.doiReferences : ''
            }

            _.each(tableDataArr, item => {
                if (data[item.name]) {
                    stateObj[item.name] = data[item.name]
                }
            })
            this.setState({...stateObj, currentStatus: data.articleStatus})
        }
    }

    render() {
        // }

        const {
            form: {getFieldDecorator, getFieldValue, setFieldsValue, featuredImage},
            loading
        } = this.props
        const {
            authors,
            oldFeatureImage,
            tableDrawerState,
            superScriptList
        } = this.state

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 18},
                md: {span: 14}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 24},
                md: {span: 24}
            }
        }

        const inputSchema = {
            fields: [
                {
                    label: 'Date',
                    key: 'doiProcessDate',
                    required: true,
                    placeholder: 'Date',
                    type: 'date',
                    span: 8
                },
                {
                    label: 'Featured Image',
                    key: 'featuredImage',
                    span: 8,
                    type: 'file',
                    customField: (
                        <InputBox title={`Featured Image`} className={'rowFlex'}>
                            <Input
                                width='100%'
                                type={'file'}
                                name={'featuredImage'}
                                id={'featuredImage'}
                                className={'form-control'}
                                key={this.state.fileKey}
                                onChange={e => {
                                    this.chooseDocument(e)
                                }}
                            />
                            {oldFeatureImage && oldFeatureImage.path ? (
                                <a
                                    className={'linkBtn'}
                                    target={'_blank'}
                                    href={oldFeatureImage.path}>
                                    <Icon type={'eye'}/>
                                </a>
                            ) : null}
                        </InputBox>
                    )
                },
                {
                    label: 'Article Title',
                    key: 'articleTitle',
                    span: 24,
                    customField: (
                        <CustomCkEditor
                            title={'Article Title'}
                            basic={true}
                            height={100}
                            html={this.state.doiArticleTitle}
                            onChange={data => {
                                this.setState({
                                    doiArticleTitle: data
                                })
                            }}
                        />
                    )
                },
                {
                    label: 'Abstract',
                    key: 'abstract',
                    type: 'ckeditor',
                    span: 24,
                    customField: (
                        <CustomCkEditor
                            html={this.state.doiAbstract}
                            title={'Abstract'}
                            onChange={data => {
                                this.setState({
                                    doiAbstract: data
                                })
                            }}
                            height={500}
                        />
                    )
                },
                {
                    label: 'References',
                    key: 'doiReferences',
                    required: true,
                    span: 24,
                    customField: (
                        <>
                            <CustomCkEditor
                                standard={true}
                                title={'References'}
                                // feeds={mentionsFeeds}
                                html={this.state.doiReferences}
                                height={500}
                                onChange={data => {
                                    this.setState({
                                        doiReferences: data
                                    })
                                }}
                            />

                        </>
                    )
                }
            ]
        }
        const columns = [
            {
                title: 'Serial no',
                name: 'serial',
                key: 'serial',
                render: (text, record, index) => {
                    return <div>{index + 1}</div>
                },
                width: 200
            },
            {
                title: 'Affiliate',
                name: 'affiliate',
                key: 'affiliate',
                dataIndex: 'affiliate'
            },
            {
                title: 'Action',
                name: 'action',
                key: 'action',
                dataIndex: 'action',
                width: 100,
                render: (item, index, indexValue) => {
                    return (
                        <>
                            <Tooltip
                                title='Edit  information'
                                style={{marginRight: '20px'}}>
                                <Button
                                    style={{border: 20}}
                                    shape='circle'
                                    onClick={e => {
                                        this.showDrawerEditAffiliate(index, indexValue)
                                    }}
                                    icon={'edit'}
                                />
                            </Tooltip>
                            <Popconfirm
                                title={`Are you Sure, You want to delete?`}
                                onConfirm={e => {
                                    this.handleDeleteAffilation(indexValue)
                                }}
                                okText='Yes'
                                cancelText='No'>
                                <Button shape='circle' icon={'delete'}/>
                            </Popconfirm>
                        </>
                    )
                }
            }
        ]

        const getColumnField = tableType => {
            let tableColumn = [
                {
                    title: ' Image ',
                    name: 'image',
                    key: 'image',
                    dataIndex: 'image',
                    width: 200,
                    render: image => {
                        return <img src={image.path} style={{height: 80}}/>
                    }
                },
                {
                    title: 'Description',
                    name: 'description',
                    key: 'description',
                    dataIndex: 'description'
                },
                {
                    title: 'Action',
                    name: 'action',
                    key: 'action',
                    dataIndex: 'action',
                    width: 100,
                    render: (item, record, index) => {
                        return (
                            <>
                                {record && record._id ? <Tooltip
                                    title='Edit  information'>
                                    <Button style={{marginRight: 5}}
                                            shape='circle'
                                            onClick={e => {
                                                this.editTableEvents.showDrawerEditTable(record, tableType)
                                            }}
                                            icon={'edit'}
                                    />
                                </Tooltip> : null}
                                <Popconfirm
                                    title={`Are you Sure, You want to delete?`}
                                    onConfirm={e => {
                                        this.events.deleteItem(index, tableType)
                                    }}
                                    okText='Yes'
                                    cancelText='No'>
                                    <Button shape='circle' icon={'delete'}/>
                                </Popconfirm>
                            </>
                        )
                    }
                }
            ]
            return tableColumn
        }

        return (
            <PageHeaderWrapper
                className={'articleHeader'}
                title={
                    <div className={'sticky-header'}>
                        <div className={'headerPanel'}>
                            <div className='titleDiv'>
                                {this.state.doiArticleNo
                                    ? `Article DOI Details | Article No ${
                                        this.state.doiArticleNo
                                    }`
                                    : 'Add Doi Aricle'}
                            </div>
                        </div>
                    </div>
                }>
                <Card bordered={true} className={'doi-form'}>
                    <Form
                        onSubmit={this.handleSubmit}
                        hideRequiredMark
                        className={'inputForm'}
                        noValidate={true}>
                        <Row gutter={16}>
                            {inputSchema.fields.map((item, key) => {
                                return (
                                    !item.hidden && (
                                        <Col
                                            span={item.span ? item.span : 12}
                                            md={item.span ? item.span : 12}
                                            sm={12}
                                            xs={24}
                                            key={key}>
                                            {item.customField ? (
                                                item.customField
                                            ) : (
                                                <GetEachFormFields
                                                    item={item}
                                                    getFieldDecorator={getFieldDecorator}
                                                    formItemLayout={formItemLayout}
                                                />
                                            )}
                                        </Col>
                                    )
                                )
                            })}
                        </Row>

                        {this.state.open4 ? (
                            <AddKeyword
                                visible={this.state.open4}
                                onClose={() => {
                                    this.events.onCloseKeyword()
                                }}
                            />
                        ) : null}

                        <Card title={'Keywords'}>
                            <Row gutter={16}>
                                <Col span={8}>
                                    <Form.Item>
                                        <Select
                                            value={this.state.keywordList}
                                            showSearch={true}
                                            filterOption={false}
                                            labelInValue
                                            onChange={this.keywordEvents.chooseKeyword}
                                            onSearch={this.keywordEvents.getKeyword}
                                            name='keywords'
                                            placeholder=' Search Keywords'>
                                            {this.state.keywordList &&
                                            this.state.keywordList.map(item => (
                                                <Option key={item._id}>{item.keyword}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={2}>
                                    <Button
                                        style={{marginTop: 3}}
                                        onClick={() => {
                                            this.events.showKeywordDrawer()
                                        }}
                                        className='ant-btn ant-btn-primary'>
                                        Add Keyword
                                    </Button>
                                </Col>
                                <Col span={24}>
                                    {this.state.keywords &&
                                    this.state.keywords.map((item, i) => {
                                        return (
                                            <>
                                                <Tag>
                                                    {item.keyword}
                                                    <a
                                                        onClick={() => {
                                                            this.keywordEvents.removeKeyword(item.keywordId)
                                                        }}>
                                                        <Icon type={'close'}/>
                                                    </a>
                                                </Tag>
                                            </>
                                        )
                                    })}
                                </Col>
                            </Row>
                        </Card>

                        <Card
                            title={'Affiliation'}
                            extra={
                                <Form.Item name='add' style={{textAlign: 'right'}}>
                                    <Button
                                        size='small'
                                        onClick={this.showDrawer1}
                                        className='ant-btn ant-btn-primary'>
                                        Add Affiliation
                                    </Button>
                                </Form.Item>
                            }>
                            {' '}
                            <Table
                                size='small'
                                columns={columns}
                                className={'figureTable'}
                                dataSource={this.state.affiliationData}
                                pagination={false}
                            />
                        </Card>

                        <Card title='Authors'>
                            <Form.Item label='Enter Number of Authors'>
                                <Input
                                    style={{width: '34%'}}
                                    value={this.state.noOfAuthors}
                                    type={'number'}
                                    onChange={e => {
                                        this.loopVal(e.target.value)
                                    }}
                                />
                            </Form.Item>

                            {authors && authors.length ? (
                                <>
                                    {authors.map((item, key) => {
                                        return (
                                            <>
                                                <Row key={key} gutter={16}>
                                                    <Col span={6}>
                                                        <Form.Item label='Name'>
                                                            <Input
                                                                placeholder=' Enter Name'
                                                                value={item.name}
                                                                onChange={e => {
                                                                    this.authorsUpdate(
                                                                        {name: e.target.value},
                                                                        key
                                                                    )
                                                                }}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={6}>
                                                        <Form.Item label='Email Address'>
                                                            <Input
                                                                placeholder='Enter email Address'
                                                                type={'email'}
                                                                value={item.email}
                                                                onChange={e => {
                                                                    this.authorsUpdate(
                                                                        {email: e.target.value},
                                                                        key
                                                                    )
                                                                }}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={6}>
                                                        <Form.Item label='Super Script '>
                                                            <Select
                                                                onChange={e => {
                                                                    this.authorsUpdate({script: e}, key)
                                                                }}
                                                                mode='multiple'
                                                                value={item.script}
                                                                placeholder='Please select Super Script'>
                                                                {superScriptList &&
                                                                superScriptList.map((item, key) => {
                                                                    return <Option value={item}>{item}</Option>
                                                                })}
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={6}>
                                                        <Form.Item label='Orch Id'>
                                                            <Input
                                                                placeholder='Enter Orch Id'
                                                                value={item.orchId}
                                                                onChange={e => {
                                                                    this.authorsUpdate(
                                                                        {orchId: e.target.value},
                                                                        key
                                                                    )
                                                                }}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </>
                                        )
                                    })}
                                </>
                            ) : null}
                        </Card>
                        <Card
                            title={'Information'}
                            extra={
                                <Form.Item name='add'>
                                    <Button
                                        size='small'
                                        onClick={this.showDrawer}
                                        className='ant-btn ant-btn-primary'>
                                        Add Information
                                    </Button>
                                </Form.Item>
                            }>
                            {this.state.infoData &&
                            this.state.infoData.map((item, i) => {
                                return (
                                    <>
                                        <div key={i} style={{marginTop: '18px'}}>
                                            <Collapse>
                                                <CollapsePanel
                                                    header={`${item.title} (${item.menuHeading})`}
                                                    extra={
                                                        <h1>
                                                            <Tooltip
                                                                title='Edit  information'
                                                                style={{marginLeft: '20px'}}>
                                                                <Button
                                                                    style={{border: 20}}
                                                                    shape='circle'
                                                                    onClick={e => {
                                                                        this.showDrawerEditInfo(item, i)
                                                                        e.stopPropagation()

                                                                    }}
                                                                    icon={'edit'}
                                                                />
                                                            </Tooltip>
                                                            <Popconfirm
                                                                placement='topLeft'
                                                                title={`Are you Sure, You want to delete?`}
                                                                onConfirm={e => {
                                                                    this.handleDelete(item.menuHeading)
                                                                    e.stopPropagation()
                                                                }}
                                                                okText='Yes'
                                                                cancelText='No'>
                                                                <Button
                                                                    style={{border: 20, marginLeft: 10}}
                                                                    shape='circle'
                                                                    icon={'delete'}
                                                                />
                                                            </Popconfirm>
                                                        </h1>
                                                    }>
                                                    <p>{ReactHtmlParser(getFigureContent(item.description))}</p>
                                                </CollapsePanel>
                                            </Collapse>
                                        </div>
                                    </>
                                )
                            })}
                        </Card>

                        {tableDataArr.map((item, key) => {
                            return (
                                <Card
                                    key={key}
                                    title={item.label}
                                    extra={
                                        <Form.Item
                                            name='addTable'
                                            style={{textAlign: 'right', marginTop: 8}}>
                                            <Button
                                                size='small'
                                                onClick={() => this.events.showDrawer(item)}
                                                className='ant-btn ant-btn-primary'>
                                                Add
                                            </Button>
                                        </Form.Item>
                                    }>
                                    <Col span={24} className={'custom_card'}>
                                        {this.state[item.name] && this.state[item.name].length ? (
                                            <Table
                                                className={'figureTable'}
                                                bordered={true}
                                                dataSource={this.state[item.name]}
                                                columns={getColumnField(item.name)}
                                                size={'small'}
                                                pagination={false}
                                            />
                                        ) : null}
                                    </Col>
                                </Card>
                            )
                        })}
                        <Form.Item style={{marginTop: 32}}>
                            <Button type='primary' htmlType='submit'>
                                SUBMIT
                            </Button>
                        </Form.Item>
                    </Form>

                    {tableDrawerState.visible ? (
                        <AddTable
                            title={tableDrawerState.tableTitle}
                            tableType={tableDrawerState.tableType}
                            visible={tableDrawerState.visible}
                            addTableData={this.events.addData}
                            onClose={() => {
                                this.events.hideDrawer()
                            }}
                        />
                    ) : null}

                    <AddInformation
                        addDetails={this.addDetails}
                        visible={this.state.open}
                        onClose={() => {
                            this.onClose()
                        }}
                        arrayData={this.state.infoData}
                    />
                    {/* edit Table */}
                    {this.state.editTable && this.state.openTable ? (
                        <EditTable
                            title={this.state.editTable.tableType}
                            visible={this.state.openTable}
                            editTable={this.state.editTable}
                            onClose={() => {
                                this.editTableEvents.hideDrawerEditTable()
                            }}
                        />
                    ) : null}

                    {this.state.editInfo && this.state.open2 ? (
                        <EditInformation
                            arrayData={this.state.infoData}
                            visible={this.state.open2}
                            editInfo={this.state.editInfo}
                            updateInfo={valData => {
                                this.updateInfo(valData)
                            }}
                            onClose={() => {
                                this.onClose2()
                            }}
                        />
                    ) : null}
                    <AddAffiliate
                        addAffiliateData={this.addAffiliateData}
                        visible={this.state.open1}
                        onClose={() => {
                            this.onClose1()
                        }}
                    />

                    {this.state.openEdit ? (
                        <EditAffiliate
                            visible={this.state.openEdit}
                            updateAffiliate={this.updateAffiliate}
                            editVal={this.state.editVal}
                            indexVal1={this.state.indexVal1}
                            onClose={() => {
                                this.oncloseEditAffiliate()
                            }}
                        />
                    ) : null}
                </Card>
            </PageHeaderWrapper>
        )
    }
}

const mapStateToProps = ({global, router}) => ({
    loading: global.buttonLoading,
    categories: global.categories,
    search: router.location.search,
    pathname: router.location.pathname
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddDoiArticle)
