import {
    Button,
    Card, Tooltip
} from 'antd'
import React, {Component} from 'react'
import {connect} from 'react-redux'
import {TableComp} from 'sz-react-utils-lite'

import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
    Countries,
    DefaultTablePagination
} from '../../../components/_utils/appUtils'
import {getAllUsersFxn} from '../actions'
import EditUser from "../drawers/editUser";
import styles from "../all/styles.less";

const regExpFilters = [
    'local.email',
    'address',
    'instituteAddress',
    'instituteName',
    'mobileNo',
    'userName',
    'displayPassword'
]

class allInstitute extends Component {
  state = {
      authorData: [],
      userId: "",
      visible: false,
      userType: ""
  }

    constructor(props) {
        super(props)

        this.table = React.createRef()
    }


    events = {
        showEdit: (data) => {
            this.setState({
                visible: true, userId: data._id,
                userType: data.userType
            })
        },
        hideEdit: () => {
            this.setState({
                visible: false, userId: "", userType: ""
            })
        },
        onSubmit: () => {
            this.setState({
                visible: false, userId: "", userType: ""
            }, () => {
                this.table.current.reload()
            })
        }
    }


    loadAuthors = params => {
        return new Promise(async resolve => {
            let {dispatch} = this.props
            let obj = {
                ...params,
                userType: 'Institute'
            }
            let data = await dispatch(
                getAllUsersFxn({...obj, regExFilters: regExpFilters})
      )
      resolve(data)
    })
  }

  render() {
      let {state: {visible, userId, userType}, events} = this

    const columns = [
      {
        title: 'Institute ',
        key: 'instituteName',
        sorter: true,
        dataIndex: 'instituteName',
        searchTextName: 'instituteName',
        filterByRegex: true,
        fixed: 'left',
        width: 130
      },
      {
        title: 'Institute Address',
        key: 'instituteAddress',

        dataIndex: 'instituteAddress',
        searchTextName: 'instituteAddress',
        filterByRegex: true,
        fixed: 'left',
        width: 130
      },
      {
        title: 'Email',
        dataIndex: 'local.email',
        key: 'local.email',
        searchTextName: 'email',
        filterByRegex: true,
        fixed: 'left',
        width: 150
      },

      {
        title: 'Department',
        dataIndex: 'department',
        key: 'department',
        searchTextName: 'department',
        filterByRegex: true
      },
      {
        title: ' Postal Code',
        dataIndex: 'postalCode',
        key: 'postalCode',
        searchTextName: 'postalCode',
        filterByRegex: true
      },

      {
        title: 'Phone No',
        dataIndex: 'phoneNo',
        key: 'phoneNo',
        searchTextName: 'phoneNo',
        filterByRegex: true
      },

      {
        title: 'User Name',
        dataIndex: 'userName',
        key: 'userName',
        searchTextName: 'userName',
        filterByRegex: true
      },
      {
        title: 'Password',
        dataIndex: 'displayPassword',
        key: 'displayPassword',
        searchTextName: 'displayPassword',
        filterByRegex: true
      },
      {
        title: 'Country',
        dataIndex: 'country',
          key: 'country',
          filters: Countries.map(x => ({text: x.name, value: x.name})),
          render: (item, record) => {
              return (
                  <div>
                      <p>{item}</p>
                      <p>{record.correspondingAuthorAffiliation}</p>
                  </div>
              )
          }
      },
        {
            title: 'Actions',
            key: 'actions',
            width: 150,
            render: (item, record) => {
                return (
                    <div className={'wrapRow'}>
                        <a className={'btn round btn-default'} onClick={() => {
                            this.events.showEdit(record)
                        }}>Edit</a>

                    </div>
                )
            }
        }
    ]
    return (
        <PageHeaderWrapper title={'All Institutes'}>
            <Card bordered={true}>
                <TableComp
                    ref={this.table}
                    extraProps={{scroll: {x: 1350}}}
                    columns={columns}
                    apiRequest={this.loadAuthors}
                />
            </Card>


            {visible ? <EditUser
                visible={visible} userId={userId}
                userType={userType}
                onSubmit={events.onSubmit}
                onClose={events.hideEdit}/> : ""}
        </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global }) => ({
  categories: global.categories
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(allInstitute)
