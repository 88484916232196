import React, { useRef } from 'react'
import { TableComp } from 'sz-react-utils-lite'
import { Drawer, Tooltip, Card, Button, Popconfirm, Form, Row, Col } from 'antd'
import {
  DisplayDate,
  DefaultTablePagination,
  formItemLayout,
  NotificationBox
} from '../../../components/_utils/appUtils'
import { getAllMember } from '../../members/actions'
import { useDispatch } from 'react-redux'
import { ArticleInfo } from '../articleView/articlePages'
import { ChooseEmailModal, chooseEmailModalFxn, RemarksList } from '../../../components/_utils/appUtils'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import { SendComments } from '../actions'

const AddRemarks = (props) => {
  let { visible, onClose, article, reviewerId, form: { getFieldDecorator, getFieldValue, setFieldsValue } } = props
  const dispatch = useDispatch()

  const events = {}
  const inputSchema = {
    fields: [
      {
        label: 'Remarks',
        key: 'remarks',
        required: true,
        showSearch: true,
        options: RemarksList,
        keyAccessor: x => x,
        valueAccessor: x => x,
        placeholder: 'Remarks',
        type: 'select',
        onChange: (val) => {
          setFieldsValue({ remarks: val })
        },
        span: 24
      },
      {
        label: 'Comments',
        key: 'comments',
        type: 'textArea',
        hidden: getFieldValue('remarks') !== 'Other text box',
        required: getFieldValue('remarks') == 'Other text box',
        placeholder: 'Comments',
        span: 24
      }
    ]

  }


  const handleSubmit = e => {
    const { form } = props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        valData.articleId = article._id
        valData.reviewerId = reviewerId
        chooseEmailModalFxn({
          callback: async (email) => {
            valData.fromEmail = email
            let { success, data } = await dispatch(SendComments(valData))
            if (success) {
              dispatch({ type: 'START_LOAD_ARTICLE' })
              onClose()
            }
          }
        })
      }
    })
  }
  return (
    <Drawer
      title={'Add Remarks'}
      width={'65%'}
      visible={visible}
      placement='right'
      onClose={onClose}>
      <Card>
        <Form onSubmit={handleSubmit} hideRequiredMark className={'inputForm'}>

          <Row gutter={16}>
            {inputSchema.fields.map((item, key) => {
              return (
                <Col span={item.span ? item.span : 8} key={key}>
                  <GetEachFormFields getFieldDecorator={getFieldDecorator}
                                     item={item} formItemLayout={formItemLayout} />
                </Col>
              )
            })}
            <Col span={24}>
              <Form.Item style={{ marginTop: 40 }}>
                <Button type='primary' htmlType='submit'>
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>


        </Form>

      </Card>
    </Drawer>
  )
}


const WrappedForm = Form.create()(AddRemarks)
export default WrappedForm