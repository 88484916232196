import React, { useEffect, useRef, useState, useSyncExternalStore } from 'react'
import {
  Drawer,
  Tooltip,
  Card,
  Button,
  Form,
  Row,
  Col,
  Input,
  Popconfirm,
  notification,
  message
} from 'antd'
import { useDispatch } from 'react-redux'
import { CkEditor2013 } from '../../../components/_utils/appUtils'
import MentionInfo from '../../email/mentionInfo'
import { UpdateWebPage } from '../actions'

const WebPagesDrawer = props => {
  const [formData, setFormData] = useState({
    title: '',
    menuHeading: ''
  })
  useEffect(() => {
    setFormData((preData) => {
      return {
        ...preData,
        ...props.data
      }
    })
  }, [])


  let {
    form: { getFieldDecorator, getFieldValue, setFieldsValue },
    visible,
    onClose,
    page,
    callback,
    companyId
  } = props
  const dispatch = useDispatch()

  let { data } = props

  const handleChange = data => {
    setFormData((preData) => {
      return {
        ...preData,
        ...data
      }
    })
  }

  const handleSubmit = async e => {
    if (!formData.title) {
      notification.warning({
        message: `Please enter values in Title`

      })
      return
    }
    if (!formData.menuHeading) {
      notification.warning({
        message: `Please enter values in Menu Heading`

      })
      return
    }
    if (!formData.description) {
      notification.warning({
        message: `Please enter values in Description`

      })
      return
    }
    e.preventDefault()
    let obj = {
      formData,
      page,
      companyId
    }
    let resp = await dispatch(UpdateWebPage(obj))
    let { success, data } = resp
    if (success) {
      dispatch({ type: 'START_LOAD_ARTICLE' })
      props.func()
      props.onClose()
      notification.success({
        message: `Journal update successfully`

      })
    }

  }
  return (
    <>
      <Drawer
        width={'65%'}
        title='Edit Publication Ethics'
        placement='right'
        onClose={props.onClose}
        visible={props.visible}>
        <Card>
          <Row>
            <Col>
              <Form>
                <Form.Item
                  label='Enter Title'
                  name='title'
                  rules={[
                    {
                      required: true,
                      message: 'Please input your title!'
                    }
                  ]}>
                  <Input
                    placeholder='Enter Title'
                    value={formData.title}
                    onChange={({ target }) =>
                      handleChange({ title: target.value })
                    }
                  />
                </Form.Item>
                <Form.Item
                  label='Menu Heading'
                  name='menuHeading'
                  rules={[
                    {
                      required: true,
                      message: 'Please input your menu heading!'
                    }
                  ]}>
                  <Input
                    placeholder='Menu Heading'
                    value={formData.menuHeading}
                    onChange={({ target }) =>
                      handleChange({ menuHeading: target.value })
                    }
                  />
                </Form.Item>
                <Form.Item
                  label='Description'
                  name='description'
                  rules={[
                    {
                      required: true,
                      message: 'Please input your menu heading!'
                    }
                  ]}>
                  <CkEditor2013
                    html={formData.description}
                    basic={true}
                    customCls={'lgCk'}
                    value={formData.description}
                    onChange={data => handleChange({ description: data })}
                  />
                </Form.Item>
                <Button type='primary' onClick={handleSubmit}>
                  Edit WebPage
                </Button>
              </Form>
            </Col>
          </Row>
        </Card>
      </Drawer>
    </>
  )
}

const WrappedForm = Form.create()(WebPagesDrawer)

export default WrappedForm

