import { notification } from 'antd'
import { getToken, customAxios as axios } from '../../../request'

import { getSingleMemberByRevId, changePasswordURL } from '../apis'
import { hidePageLoad, showPageLoad } from '../../../modules/actions'

export const getSingleMember = (revId) => async (dispatch) => {
  let config = {
    ...getToken()
  }
  let { data } = await axios.get(getSingleMemberByRevId(revId), config)
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const changePassword = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.put(changePasswordURL(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    notification.success({
      message: data.message || 'Email send Successfully'
    })
  }
  return data
}
