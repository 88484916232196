import React, { useEffect } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
    Table,
    Badge,
    Card,
    Tooltip,
    notification,
    Switch,
    Input, Button, Icon, Form, Row, Col, Drawer, Select, Divider
} from 'antd'
import Request from '../../../request'
import { getPushPathWrapper } from '../../../routes'
import { apiUrl } from '../../../settings'
import _ from 'lodash'
import styles from '../all/styles.less'
import EditSubscription from '../edit/index'
import { connect } from 'react-redux'
import { TableComp, FormUtils } from 'sz-react-utils-lite'
import { func } from 'prop-types'
import { updateSubscription } from '../actions'
import { getReviewerPendingArticle } from '../../article/actions'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import { formItemLayout } from '../../../components/_utils/appUtils';
// import subscriptionDrawer from './subscriptionDrawer'; //Not in use
import { useDispatch } from 'react-redux'

const EditSubscriptionComponent = (props) => {
    let dispatch = useDispatch()
    // console.log(props)
    let { form } = props;

    const formItemLayout = {
        labelCol: {
            xs: { span: 2 },
            sm: { span: 3 },
            md: { span: 7 }
        },
        wrapperCol: {
            xs: { span: 22 },
            sm: { span: 22 },
            md: { span: 22 }
        }
    }

    const submitFormLayout = {
        wrapperCol: {
            xs: { span: 24, offset: 0 },
            sm: { span: 10, offset: 4 },
            md: { span: 12, offset: 8 }
        }
    }

    const {
        form: { getFieldValue, getFieldDecorator, setFieldsValue }
    } = props;

    const inputSchma = {
        fields: [
            {
                label: 'Year',
                key: 'year',
                placeholder: 'Year',
                required: true,
                type: "select",
                options: ["2015", "2016", "2017", "2018", "2019", "2020", "2021", "2022", "2023", "2024", "2025", "2026", "2027", "2028", "2029", "2030"],
                onChange: (val) => {
                    setFieldsValue({ year: val })
                }
            },
            {
                key: 'priceInInr',
                label: 'Price in INR',
                placeholder: "Price in Indian Currency",
                required: true,
                type: "number"
            },
            {
                key: 'priceInUsd',
                label: 'Price in USD',
                placeholder: "Price in Foriegn Currency",
                required: true,
                type: "number"
            }
        ]
    }

    useEffect(() => {
        setPreFill()
    }, [])

    const setPreFill = () => {
        setTimeout(() => {
            setFieldsValue({ ...props.data })
        }, 500)
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        form.validateFieldsAndScroll(async (err, valData) => {
            console.log(valData)
            if (!err) {
                // console.log(valData)
                let obj={
                    ...valData,
                    journalId:props.data.journalId,
                    subscriptionId:props.data._id
                }
                let result = await dispatch(updateSubscription(obj))
                if(result && result.success){
                    props.onClose()

                }
                console.log(result)
            }
            const setPreFill = () => {
                setTimeout(() => {
                    setFieldsValue({})
                }, 500)
            }
            setPreFill()

        })

    }


    return (
        <>
            <Drawer
                title="Edit Subscription"
                placement="right"
                onClose={props.onClose}
                visible={props.visible}
                width={380}>
                <Card>
                    <Col span={24}>
                        <Form onSubmit={handleSubmit}>
                            <FormUtils
                                inputSchema={inputSchma}
                                formItemLayout={formItemLayout} // optional //for the design part
                                getFieldDecorator={getFieldDecorator} //input validation
                                setFieldsValue={setFieldsValue}
                            />
                            <Button type='primary' block htmlType='submit' submitFormLayout={submitFormLayout} >Edit Subscription</Button>
                        </Form>
                    </Col>
                </Card>
            </Drawer>
        </>
    )
}

let formWrap = Form.create()(EditSubscriptionComponent)
export default formWrap