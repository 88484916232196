import React, { useState, useRef } from 'react'
import { Button, Card, Collapse } from 'antd'
import { chooseEmailModalFxn, DisplayDate, GetDownloadLink } from '../../../components/_utils/appUtils'
import { RowTable } from './RowTable'
import GalleyProofDrawer from '../drawers/GalleyProofDrawer'
import { SendTemplateEmail } from '../../email/actions'
import { useDispatch } from 'react-redux'

const { Panel } = Collapse
const GalleyProofDetails = (props) => {
  let { article } = props
  let {
    composingArticleDate,
    sendgalleyProofDate,
    attachmentAtgalleyProof,
    galleyReminderDate
  } = article
  let [receiveDateDrawerVisible, setReceiveDateDrawerVisible] = useState(false)
  let [reviewerId, setReviewerId] = useState('')
  let headerTitle = `Galley Proof / Reprints ${sendgalleyProofDate ? `(${DisplayDate(sendgalleyProofDate)})` : ''}`
  let [visibleCommentToAuthorDrawer, setVisibleCommentToAuthorDrawer] = useState(false)
  const table = useRef()
  let dispatch = useDispatch()
  let events = {
    reloadTable: () => {
      table.current.reload()
    },
    showAndHideReceiveDate: (value, id = '') => {
      setReceiveDateDrawerVisible(value)
      setReviewerId(id)
    },
    show: () => {
      setVisibleCommentToAuthorDrawer(true)
    },
    hide: () => {
      setVisibleCommentToAuthorDrawer(false)
    }
  }

  let BtnAction = (event) => {
    events.show()
    event.stopPropagation()
  }

  let resendGalleyProof = (event) => {
    event.stopPropagation()
    chooseEmailModalFxn({
      callback: async (email) => {
        let obj = {
          id: article._id,
          fromEmail: email,
          template: 'galleyProofArticle',
          type: 'article',
          attachmentArr: ['attachmentAtgalleyProof']
        }
        let resp = await dispatch(SendTemplateEmail({
          email: obj.fromEmail,
          id: article._id,
          obj,
          template: obj.template
        }))
        if (resp && resp.success) {
          dispatch({ type: 'START_LOAD_ARTICLE' })
        }
      }
    })
  }

  let getExtraButton = () => {
    return (
      <React.Fragment>
        {composingArticleDate && !sendgalleyProofDate ?
          <Button type={'primary'} size={'small'} onClick={BtnAction}>Add</Button> : null}
        {sendgalleyProofDate ?
          <Button type={'primary'} size={'small'} onClick={BtnAction}>Edit</Button> : null}

        {article.sendgalleyProofDate && article.articleStatus == 'Galley Proof' ?
          <Button type={'primary'} size={'small'} onClick={resendGalleyProof} className={'ml-5'}>Resend
            Galley Proof</Button> : null}

      </React.Fragment>
    )
  }

  return (
    <div>
      <Collapse expandIconPosition={'right'} defaultActiveKey={['1']}>
        <Panel header={headerTitle} key='1' extra={getExtraButton()}>
          <div className={'tableBox striped'}>
            <RowTable title={'Date'} value={
              sendgalleyProofDate && DisplayDate(sendgalleyProofDate)
            } />
            <RowTable title={'Galley Proof Attachment'} value={
              attachmentAtgalleyProof && attachmentAtgalleyProof.path ?
                <a download className='downloadLink' target={'_blank'}
                   href={attachmentAtgalleyProof.path}>Download</a> : 'No Attachment'
            } />
            <RowTable title={'Early Publication'} value={
              article.galleyProofEarlyPublication ? 'Yes' : 'No'
            } />
            <RowTable title={'Reminder Date'} value={
              galleyReminderDate && DisplayDate(galleyReminderDate)
            } />
          </div>
        </Panel>
      </Collapse>

      {visibleCommentToAuthorDrawer ? <GalleyProofDrawer
        visible={visibleCommentToAuthorDrawer}
        articleId={article._id}
        onClose={() => events.hide()}
        article={article}
        edit={sendgalleyProofDate ? true : false}
      /> : null}
    </div>
  )
}

export default GalleyProofDetails
