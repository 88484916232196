import { Button, Card, Col, Drawer, Form, Input, notification, Row } from 'antd'
import moment from 'moment'
import React from 'react'
import { connect } from 'react-redux'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import { formItemLayout, InputBox } from '../../../components/_utils/appUtils'
import { updateIssueFxn } from '../actions'

@Form.create()
class EditIssue extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      coverImage: {},
      issueZip: {},
      fileKey: moment()
    }
  }

  componentWillMount() {
    let {
      issue,
      form: { setFieldsValue }
    } = this.props
    setTimeout(() => {
      setFieldsValue({
        pageRange: issue.pageRange,
        publishDate: issue.publishDate ? moment(issue.publishDate) : ''
      })
    }, 500)
  }

  handleChange = e => {
    let { name, files } = e.target
    if (files && files.length) {
      this.setState({ [name]: files[0] })
    }
  }

  handleSubmitIssue = e => {
    const { dispatch, form, issue, onClose } = this.props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        let { coverImage, issueZip } = this.state
        let fd = new FormData()
        valData.issueId = issue._id
        valData.slugIssue = issue.slugIssue
        valData.journalId = issue.journalId
        fd.append('obj', JSON.stringify(valData))
        if (coverImage && coverImage.name) {
          fd.append('coverImage', coverImage)
        }
        if (issueZip && issueZip.name) {
          fd.append('issueZip', issueZip)
        }
        let x = await dispatch(updateIssueFxn(fd))
        let { error, message } = x

        this.setState({ fileKey: moment() })
        if (!error) {
          notification.success({
            message: message
          })
          onClose()
        } else {
          notification.error({
            message: message,
            description: message
          })
        }
      }
    })
  }

  render() {
    const {
      form: { getFieldDecorator },
      visible,
      onClose,
      issue
    } = this.props
    const inputSchema = {
      fields: [
        {
          label: 'Page Range',
          key: 'pageRange',
          required: true,
          placeholder: 'pageRange'
        },
        {
          label: 'Publish Date',
          key: 'publishDate',
          required: true,
          placeholder: 'Publish Date',
          type: 'date'
        },
        {
          label: 'Cover Image',
          key: 'coverImage',
          customField: (
            <InputBox title={`Cover Image`}>
              <Input
                type={'file'}
                name={'coverImage'}
                id={'coverImage'}
                className={'form-control'}
                key={this.state.fileKey}
                onChange={e => {
                  this.handleChange(e)
                }}
              />
            </InputBox>
          )
        },
        {
          label: 'Issue Zip',
          key: 'issueZip',
          customField: (
            <InputBox title={`Issue Zip`}>
              <Input
                type={'file'}
                name={'issueZip'}
                id={'issueZip'}
                className={'form-control'}
                key={this.state.fileKey}
                onChange={e => {
                  this.handleChange(e)
                }}
              />
            </InputBox>
          )
        }
      ]
    }
    return (
      <>
        <Drawer
          title={`Edit Issue - ${issue.issueName}(${issue.issueNumber})`}
          width={'35%'}
          placement="right"
          closable={true}
          onClose={onClose}
          visible={visible}>
          <div style={{ padding: 10 }}>
            <Card bordered={true}>
              <Form
                onSubmit={this.handleSubmitIssue}
                hideRequiredMark
                className={'inputForm'}>
                <Row gutter={10}>
                  {inputSchema.fields.map((item, key) => {
                    return (
                      <Col md={24} sm={24} xs={24} key={key}>
                        {item.customField ? (
                          item.customField
                        ) : (
                          <GetEachFormFields
                            item={item}
                            getFieldDecorator={getFieldDecorator}
                            formItemLayout={formItemLayout}
                          />
                        )}
                      </Col>
                    )
                  })}
                  <Col span={4}>
                    <Form.Item>
                      <Button type="primary" htmlType="submit">
                        Update Issue
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Card>
          </div>
        </Drawer>
      </>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
export default connect(
  null,
  mapDispatchToProps
)(EditIssue)
