import {
    Button,
    Card,
    Drawer,
    Icon,
    Popconfirm,
    Select,
    notification
} from 'antd'
import lodash from 'lodash'
import React, {Component, useEffect} from 'react'
import {connect} from 'react-redux'
import {TableComp} from 'sz-react-utils-lite'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
    ChooseEmailModal,
    Countries,
    DefaultTablePagination,
    DisplayDate,
    InputBox,
    chooseEmailModalFxn
} from '../../../components/_utils/appUtils'
import {getPushPathWrapper} from '../../../routes'
import {apiUrl} from '../../../settings'
import AddNoteForOnlineSubmission from '../../article/drawers/AddNoteForOnlineSubmission'
import {SendTemplateEmail} from '../../email/actions'
import {CountriesList, JournalList} from '../../journals/actions'
import {
    addActivityFxn,
    listAllOnlineSubmission,
    onlineSubmissionExportFxn,
    updateOnlineSubmission
} from '../actions'
import OnlineSubmissionDrawer from './OnlineSubmissionDrawer'
import ScheduleDrawer from './scheduleDrawer'
import HistoryDrawer from './historyDrawer'

const {Option} = Select

const statusFilter = [
    'Pending',
    'Proceed',
    'Not Fit',
    'Another Journal',
    'Schedule',
    'As per format',
    'Withdraw',
    'Delete'
]

class AllOnlineSubmissions extends Component {
    events = {
        showScheduler: data => {
            this.setState({
                scheduleObj: {
                    visible: true,
                    submission: data
                }
            })
        },
        showHistory: (onlineSubmissionId) => {
            this.setState({
                historyObj: {
                    visible: true,
                    onlineSubmissionId
                }
            })
        },
        hideScheduler: data => {
            this.setState(
                {
                    scheduleObj: {
                        visible: false,
                        submission: {}
                    }
                },
                () => {
                    this.reload()
                }
            )
        },
        hideHistory: () => {
            this.setState(
                {
                    historyObj: {
                        visible: false,
                        onlineSubmissionId: ""
                    }
                }
            )
        },

        onCloseAddNote: () => {
            this.setState({visibleAddNote: false})
            this.reload()
        }
    }

    apiRequest = params => {
        return new Promise(async resolve => {
            if (params && !params.status) {
                params.status = [
                    'Pending',
                    'Not Fit',
                    'Another Journal',
                    'Schedule',
                    'As per format'
                ]
            }
            let filters = {
                ...params,
                regExFilters: [
                    'title',
                    'journal',
                    'correspondingAuthor',
                    'correspondingAuthorsEmail',
                    'correspondingAuthorAffiliation'
                ]
            }
            let data = await listAllOnlineSubmission(filters)
            this.setState({
                totalSubmission: data.total,
                filters,
                totalNotes: data.notes ? data.notes.length : 0
            })
            resolve(data)
        })
    }

    exportExcel = async () => {
        let {dispatch} = this.props
        let params = {
            ...this.props.filters,
            results: this.state.totalSubmission
        }
        let resp = await dispatch(onlineSubmissionExportFxn(params))
        if (resp && resp.success) {
            window.open(`${apiUrl}${resp.fileName}`, 'Download')
        }
    }
    loadCountriesList = async () => {
        let {data} = await CountriesList()
        let countryList = []
        lodash.each(data, item => {
            countryList.push({text: item.name, value: item.name})
        })
        this.setState({countryList})
    }

    reload = () => {
        this.table.current.reload()
    }

    constructor(props) {
        super(props)
        this.user = JSON.parse(localStorage.getItem('user'))
        this.state = {
            journals: [],
            countryList: [],
            visible: false,
            otherJournalList: [],
            selectedSubmissionId: '',
            onlineSubmissionId: '',
            totalSubmission: '',
            journalData: {},
            submissionDrawerVisible: false,
            drawerDarecor: '',
            filters: {},
            scheduleObj: {
                visible: false,
                submission: {},
                visibleAddNote: false
            },
            historyObj: {
                visible: false,
                onlineSubmissionId: ""
            }
        }
        this.table = React.createRef()
    }

    deleteOnlineSubmission = async data => {
        let {dispatch} = this.props
        let {success} = await dispatch(
            updateOnlineSubmission({onlineSubmissionId: data._id})
        )
        if (success) {
            this.reload()
        }
    }

    componentDidMount() {
        this.loadJournalList()
    }

    showDrawer = data => {
        this.setState({journalData: data})
        this.setState({
            visible: true
        })
    }

    onClose = () => {
        this.setState({
            visible: false
        })
        this.reload()
    }

    loadJournalList = async () => {
        let {data} = await JournalList()
        let journals = []
        lodash.each(data, item => {
            journals.push({text: item.name, value: item.name, journalId: item._id})
        })
        let obj = {
            journals
        }
        if (journals && journals.length) {
            obj.setJournal = journals[0].value
        }
        this.setState({...obj})
    }

    render() {
        const {dispatch} = this.props
        const {isModalVisible, scheduleObj, journals} = this.state

        const actionBtn = (id, template, status, data) => {
            return (
                <Button
                    className={'roundSmallBtn2'}
                    onClick={() => chooseMailFxnEvent(id, template, status, data)}>
                    {status}
                </Button>
            )
        }
        const actionBtn1 = (id, template, status, data) => {
            return (
                <ChooseEmailModal
                    title={status}
                    id={id}
                    template={template}
                    obj={{type: 'onlineSubmission', status, countryName: data.country}}
                    callback={() => {
                        this.reload()
                    }}>
                    <Button className={'roundSmallBtn'}>{status}</Button>
                </ChooseEmailModal>
            )
        }

        const chooseMailFxnEvent = (id, template, status, data) => {
            chooseEmailModalFxn({
                title: status,
                callback: async email => {
                    let obj = {
                        type: 'onlineSubmission',
                        status,
                        countryName: data.country,
                        fromEmail: email,
                        template: template
                    }
                    let resp = await dispatch(
                        SendTemplateEmail({
                            fromEmail: email,
                            email,
                            id,
                            obj,
                            template: template
                        })
                    )
                    if (resp && resp.success) {
                        this.reload()
                    } else {
                        notification.error({
                            message: resp.message
                        })
                    }
                }
            })
        }

        const columns = [
            {
                title: 'ID',
                key: 'onlineSubmissionId',
                dataIndex: 'onlineSubmissionId',
                filterRegex: true,
                searchTextName: 'onlineSubmissionId'
            },
            {
                title: 'Date',
                width: 100,
                key: 'createdAt',
                dataIndex: 'createdAt',
                searchDateName: 'createdAt',
                render: item => {
                    return DisplayDate(item)
                }
            },
            {
                title: 'Journal',
                key: 'journal',
                dataIndex: 'journal',
                filters: this.state.journals
            },
            {
                title: 'Manuscript Title',
                key: 'manuScriptTitle',
                dataIndex: 'manuScriptTitle',
                filterRegex: false,
                searchTextName: 'manuScriptTitle',
                render: (item, record) => {
                    return (
                        <a
                            onClick={() => {
                                this.setState({
                                    submissionDrawerVisible: true,
                                    drawerData: record
                                })
                            }}>
                            {item}
                        </a>
                    )
                }
            },
            {
                title: 'Country',
                key: 'country',
                dataIndex: 'country',
                filters: Countries.map(x => ({text: x.name, value: x.name})),
                render: (item, record) => {
                    return (
                        <div>
                            <p>{item}</p>
                            <p>{record.correspondingAuthorAffiliation}</p>
                        </div>
                    )
                }
            },
            {
                title: 'Corresponding Author',
                key: 'correspondingAuthor',
                dataIndex: 'correspondingAuthor',
                filterRegex: true,
                width: 100,
                searchTextName: 'correspondingAuthor'
            },
            {
                title: 'Author Email',
                width: 150,
                key: 'correspondingAuthorsEmail',
                dataIndex: 'correspondingAuthorsEmail',
                filterRegex: true,
                searchTextName: 'correspondingAuthorsEmail'
            },
            // {
            //   title: 'Institute Affiliated with',
            //   key: 'correspondingAuthorAffiliation',
            //   dataIndex: 'correspondingAuthorAffiliation',
            //   filterRegex: true,
            //   searchTextName: 'correspondingAuthorAffiliation'
            // },
            {
                title: 'Attachments',
                key: 'manuscriptAttachment',
                dataIndex: 'manuscriptAttachment',
                render: (item, record) => {
                    let {otherAttachment} = record
                    return (
                        <>
                            {item && item.path ? (
                                <a href={`${item.path}`} target={'_blank'}>
                                    Manuscript Attachments
                                </a>
                            ) : null}
                            {otherAttachment && otherAttachment.path ? (
                                <>
                                    <br/>
                                    <br/>{' '}
                                    <a href={`${otherAttachment.path}`} target={'_blank'}>
                                        Other Attachments
                                    </a>
                                </>
                            ) : null}
                        </>
                    )
                }
            },
            {
                title: 'Status',
                key: 'status',
                dataIndex: 'status',
                filters: statusFilter.map(x => ({text: x, value: x}))
            },
            {
                title: 'Actions',
                key: 'actions',
                width: 220,
                render: (val, record) =>
                    record && record.status !== 'Proceed' ? (
                        <React.Fragment>
                            <Button
                                className={'roundSmallBtn2'}
                                onClick={() => {
                                    dispatch(
                                        getPushPathWrapper('article.onlineSubmission', {
                                            id: record._id
                                        })
                                    )
                                }}>
                                Proceed
                            </Button>
                            {actionBtn(record._id, 'notFit', 'Not Fit', record)}

                            <Button
                                className={'roundSmallBtn2'}
                                onClick={() => {
                                    this.showDrawer(record)
                                    this.setState({selectedSubmissionId: record._id})
                                }}>
                                Other Journals
                            </Button>

                            <Button
                                className={'roundSmallBtn2'}
                                onClick={() => {
                                    this.events.showScheduler(record)
                                }}>
                                Schedule
                            </Button>

                            {/*{actionBtn(record._id, 'schedule', 'Schedule', record)}*/}
                            {actionBtn(record._id, 'asPerFormat', 'As per format', record)}
                            {actionBtn(record._id, 'withdraw', 'Withdraw', record)}

                            <Popconfirm
                                placement="topLeft"
                                title={`Are you sure you want to delete ${
                                    record.onlineSubmissionId
                                }?`}
                                onConfirm={() => {
                                    this.deleteOnlineSubmission(record)
                                }}
                                okText="Yes"
                                cancelText="No">
                                <Button className={'roundSmallBtn2'}>Delete</Button>
                            </Popconfirm>
                            <Button
                                key="3"
                                className={'roundSmallBtn2'}
                                onClick={() => {
                                    this.setState({
                                        visibleAddNote: true,
                                        onlineSubmissionId: record._id
                                    })
                                }}>
                                <Icon type={'plus'}/>
                                Notes ({record.notes ? record.notes.length : 0})
                            </Button>
                            <Button
                                className={'roundSmallBtn2'}
                                onClick={() => {
                                    this.events.showHistory(record._id)
                                }}>
                                History
                            </Button>
                        </React.Fragment>
                    ) : null
            }
        ]


        return (
            <PageHeaderWrapper
                title={`Online Submissions (${this.state.totalSubmission})`}
                content={[
                    <>
                        <Button
                            key="1"
                            type="primary"
                            onClick={() => {
                                this.exportExcel()
                            }}>
                            <Icon type={'file'}/>
                            Export
                        </Button>
                    </>
                ]}>
                <Card bordered={true}>
                    <TableComp
                        ref={this.table}
                        columns={columns}
                        apiRequest={this.apiRequest}
                        pagination={DefaultTablePagination({}, 50)}
                    />
                    <Drawer
                        title={
                            <p>
                                Choose Another Journal
                                <br/>
                                <br/>
                                <h3>
                                    {this.state.journalData.journal} (
                                    {this.state.journalData.onlineSubmissionId})
                                </h3>
                            </p>
                        }
                        width={500}
                        onClose={this.onClose}
                        visible={this.state.visible}
                        bodyStyle={{paddingBottom: 80}}
                        footer={
                            <div className={'alignRight'}>
                                <Button onClick={this.onClose} style={{marginRight: 8}}>
                                    Cancel
                                </Button>
                                <Button onClick={this.onClose} type="primary">
                                    Submit
                                </Button>
                            </div>
                        }>
                        <Card>
                            <InputBox title={'Select Journal'}>
                                <Select
                                    onChange={e => this.setState({setJournal: e})}
                                    value={this.state.setJournal}>
                                    {this.state.journals && this.state.journals.length
                                        ? this.state.journals.map((item, key) => {
                                            return (
                                                <Option key={key} value={item.value}>
                                                    {item.text}
                                                </Option>
                                            )
                                        })
                                        : null}
                                </Select>
                            </InputBox>

                            <ChooseEmailModal
                                id={this.state.selectedSubmissionId}
                                callback={this.onClose}
                                template={'anotherJournal'}
                                obj={{
                                    type: 'onlineSubmission',
                                    status: 'Another Journal',
                                    anotherJournalName: this.state.setJournal
                                }}>
                                <Button className={'linkIcon'}>Submit</Button>
                            </ChooseEmailModal>
                        </Card>
                    </Drawer>
                </Card>
                {this.state.submissionDrawerVisible ? (
                    <OnlineSubmissionDrawer
                        visible={this.state.submissionDrawerVisible}
                        data={this.state.drawerData}
                        callBackReload={() => this.reload()}
                        onClose={() => this.setState({submissionDrawerVisible: false})}
                    />
                ) : null}

                {scheduleObj && scheduleObj.visible ? (
                    <ScheduleDrawer
                        submission={scheduleObj.submission}
                        visible={scheduleObj.visible}
                        onClose={this.events.hideScheduler}
                        journals={journals}
                    />
                ) : (
                    ''
                )}
                {this.state.historyObj && this.state.historyObj.visible ? (
                    <HistoryDrawer
                        {...this.state.historyObj}
                        onClose={this.events.hideHistory}
                    />
                ) : null}
                {this.state.visibleAddNote ? (
                    <AddNoteForOnlineSubmission
                        visible={this.state.visibleAddNote}
                        // article={article}
                        // articleId={article._id}
                        // articleNo={article.articleNo}

                        onlineSubmissionId={this.state.onlineSubmissionId}
                        onClose={() => this.events.onCloseAddNote()}
                    />
                ) : null}
            </PageHeaderWrapper>
        )
    }
}

const mapStateToProps = ({global}) => ({
    categories: global.categories
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AllOnlineSubmissions)
