import React, { Component } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
  Table,
  Badge,
  Card,
  Tooltip,
  notification,
  Switch,
  Input, Button, Icon
} from 'antd'
import Request from '../../../request'
import { getPushPathWrapper } from '../../../routes'
import { apiUrl } from '../../../settings'
import _ from 'lodash'
import styles from './styles.less'
import { connect } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'


class AllNews extends Component {

  constructor(props) {
    super(props)
    this.table = React.createRef()
  }

  apiRequest = (params) => {
    return new Promise(async (resolve) => {
      let data = await Request.getAllNews(params)
      resolve(data)
    })
  }

  componentDidMount() {

  }

  reload = () => {
    this.table.current.reload()
  }
  deleteNews = async (data) => {
    let resp = await Request.deleteNews(data)
    this.reload()
  }

  render() {

    const { dispatch } = this.props

    const columns = [
      {
        title: 'News',
        key: 'news',
        sorter: true,
        dataIndex: 'news',
        searchTextName: 'news'
      },
      {
        key: 'actions', render: (val) => (<React.Fragment>

          <Tooltip title="Edit Details">
            <Button className={styles.btn}
                    shape="circle"
                    onClick={() => {
                      dispatch(getPushPathWrapper('news.edit', { id: val._id }))
                    }} icon="edit"/>
          </Tooltip>

          <Tooltip title="Delete News">
            <Button className={styles.btn}
                    loading={val.deleteLoading}
                    onClick={() => {
                      this.deleteNews({ _id: val._id })
                    }}
                    type="danger" shape="circle" icon="delete"/>
          </Tooltip>
        </React.Fragment>)

      }
    ]
    return (
      <PageHeaderWrapper
        title={'All News'}>

        <Card bordered={true}>
          <TableComp ref={this.table} columns={columns} apiRequest={this.apiRequest}/>
        </Card>

      </PageHeaderWrapper>)

  }
}


const mapStateToProps = ({ global }) => ({
  categories: global.categories
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllNews)
