import React from 'react'
import { Button, Card, Col, Drawer, Form, notification, Row } from 'antd'
import { AuthorTitle, DesignationList, formItemLayout, Countries } from '../../../components/_utils/appUtils'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import { hideLoader, showLoader } from '../../../modules/actions'

@Form.create()
class AddAuthors extends React.Component {

  handleSubmit = (e) => {
    const { dispatch, form, addAuthorSubmit } = this.props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        addAuthorSubmit(valData)
        form.resetFields()
      } else {
        notification.error({
          message: 'Please enter all required fields.'
        })
      }
    })
  }

  render() {
    const { form: { getFieldDecorator, getFieldValue, setFieldsValue }, visible, onClose } = this.props

    let inputSchema = [
      {
        label: 'Title',
        key: 'title',
        required: true,
        showSearch: true,
        options: AuthorTitle,
        placeholder: 'Title',
        type: 'select',
        onChange: (val) => {
          setFieldsValue({ title: val })
        }
      },
      {
        label: 'Name',
        key: 'name',
        required: true,
        placeholder: 'Name'
      },
      {
        label: 'Institute Affiliated With',
        key: 'instituteAffiliated',
        placeholder: 'Institute Affiliated With'
      },
      {
        label: 'Designation',
        key: 'designation',
        placeholder: 'Designation',
        options: DesignationList,
        type: 'select',
        onChange: (val) => {
          setFieldsValue({ designation: val })
        }
      },
      {
        label: 'Primary Email',
        key: 'email',
        type: 'email',
        required: true,
        placeholder: 'Primary Email'
      },
      {
        label: 'Secondary Email',
        key: 'secondaryEmail',
        type: 'email',
        placeholder: 'Secondary Email'
      },
      {
        label: 'Contact No',
        key: 'contactNo',
        placeholder: 'Contact No'
      },
      {
        label: 'Country',
        key: 'country',
        showSearch: true,
        placeholder: 'Country',
        options: Countries,
        keyAccessor: x => x.name,
        valueAccessor: x => x.name,
        type: 'select',
        onChange: (val) => {
          setFieldsValue({ country: val })
        }
      }
    ]
    return (
      <Drawer visible={visible} onClose={onClose} width={700}>
        <Card bordered={true}>
          <Form onSubmit={this.handleSubmit} hideRequiredMark style={{ marginTop: 8 }} className={'inputForm'}>
            <Row gutter={16}>
              {inputSchema.map((item, key) => {
                return (
                  <Col span={12} key={key}>
                    <GetEachFormFields
                      item={item}
                      getFieldDecorator={getFieldDecorator}
                      formItemLayout={formItemLayout}/>
                  </Col>
                )
              })}
              <Col span={24}>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Add
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </Drawer>
    )
  }
}

export default AddAuthors
