import { notification } from 'antd'
import update from 'immutability-helper'
import { hidePageLoad, showPageLoad } from '../../../modules/actions'
import { customAxios as axios, getToken } from '../../../request'
import {
  addWebPageUrl,
  CountriesUrl,
  deleteWebPageUrl,
  getJournalWebPageUrl,
  getWebPageUrl,
  issuesUrl,
  JournalChiefEditorUrl,
  JournalListUrl,
  journalUrl,
  updateIssueUrl,
  updateJournalWebPageUrl,
  updateWebPageUrl
} from '../apis'

export const JournalList = async filters => {
  let config = {
    params: { ...filters },
    ...getToken()
  }
  let { data } = await axios.get(JournalListUrl(), config)
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const CountriesList = async () => {
  let { data } = await axios.get(CountriesUrl())
  return data
}

export const JournalChiefEditor = async () => {
  let { data } = await axios.get(JournalChiefEditorUrl())
  return data
}

export const UpdateJournal = valData => async dispatch => {
  dispatch(showPageLoad())
  // console.log(valData, "Backend valData")
  let { data } = await axios.put(journalUrl(), valData, getToken())
  console.log(data, 'Data.....')
  if (data.error) {
    dispatch(hidePageLoad())
    notification.error({
      message: data.message || 'Error'
    })
  }
  dispatch(hidePageLoad())
  return data
}

export const AddJournal = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(journalUrl(), valData, getToken())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  dispatch(hidePageLoad())
  return data
}

export const GetJournal = filter => async dispatch => {
  let config = {
    params: { ...filter },
    ...getToken()
  }
  dispatch(showPageLoad())
  let { data } = await axios.get(journalUrl(), config)
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  dispatch(hidePageLoad())
  return data
}

export const GetWebPage = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(getWebPageUrl(), valData, getToken())
  dispatch(hidePageLoad())
  return data
}
export const UpdateWebPage = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(updateWebPageUrl(), valData, getToken())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  dispatch(hidePageLoad())
  return data
}
export const GetJournalWebPage = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(getJournalWebPageUrl(), valData, getToken())
  dispatch(hidePageLoad())
  return data
}
export const UpdateJournalWebPage = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(
    updateJournalWebPageUrl(),
    valData,
    getToken()
  )
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    notification.success({
      message: data.message || 'Success'
    })
  }
  dispatch(hidePageLoad())
  return data
}
export const AddIssue = valData => async dispatch => {
  console.log(valData, 'valllllll')
  dispatch(showPageLoad())
  let { data } = await axios.post(issuesUrl(), valData, getToken())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  dispatch(hidePageLoad())
  return data
}
export const DeleteWebPage = data => async dispatch => {
  dispatch(showPageLoad())
  return new Promise(next => {
    axios
      .post(deleteWebPageUrl(), data, getToken())
      .then(d => {
        next(d.data)
        dispatch(hidePageLoad())
      })
      .catch(err => {
        next({ error: true, err })
        this.error(err)
        dispatch(hidePageLoad())
      })
  })
}

export const addWebPageFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(addWebPageUrl(), valData, getToken())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  dispatch(hidePageLoad())
  return data
}
export const updateIssueFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(updateIssueUrl(), valData, getToken())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  dispatch(hidePageLoad())
  return data
}
