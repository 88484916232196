import { notification } from 'antd'
import { getToken, customAxios as axios } from '../../request'
import { showPageLoad, hidePageLoad } from '../../modules/actions'
import { subscriptionUrl, journalsAjaxListUrl, allSubscriptionUrl, addSubscriptionUrl, getGroupedSubscriptionUrl, editSubscriptionUrl, removeSubscriptionUrl } from './apis'


export const addSubscription = (valData) => async (dispatch) => {
  console.log('valData', valData)
  dispatch(showPageLoad())
  let { data } = await axios.post(addSubscriptionUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
// getGroupedSubscription
export const getGroupedSubscription = async () => {
  let { data } = await axios.post(getGroupedSubscriptionUrl(), {}, getToken())

  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  console.log(data)
  return data
}

export const updateSubscription = ( valData) => async (dispatch) => {
  dispatch(showPageLoad())
 
  let { data } = await axios.put(editSubscriptionUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }else{
    notification.success({
      message: data.message || 'Success'
    })
  }
  return data
}

export const journalsAjaxList = () => async (dispatch) => {
  dispatch(showPageLoad())
  let config = {
    ...getToken()
  }
  let { data } = await axios.get(journalsAjaxListUrl(), config)
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
export const allSubscription = (obj) => async (dispatch) => {
  // dispatch(showPageLoad())
  let config = {
    params: obj,
    ...getToken()
  }
  console.log('obj all', obj)
  let { data } = await axios.get(allSubscriptionUrl(), config)
  // dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
export const editSubscription = (obj) => async (dispatch) => {
  // dispatch(showPageLoad())
  let config = {
    params: obj,
    ...getToken()
  }
  console.log('obj all', obj)
  let { data } = await axios.get(allSubscriptionUrl(), config)
  // dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
export const removeSubscription =  (record) => async(dispatch) => {
  console.log("xjjshjhsjhsjhjhs")
dispatch(showPageLoad())
  let config = {
    params: record._id,
    ...getToken()
  }
  let { data } = await axios.delete(removeSubscriptionUrl(record._id), config);
  console.log(data)
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

