import { Button, Card, Drawer, Popconfirm, Tooltip } from 'antd'
import _ from 'lodash'
import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import {
  ChooseEmailModal,
  chooseEmailModalFxn,
  DisciplineFilter,
  DisplayDate
} from '../../../components/_utils/appUtils'
import { getPushPathWrapper } from '../../../routes'
import { getAllMember } from '../../members/actions'
import { addReviewerInArticle } from '../actions'
import { ArticleInfo } from '../articleView/articlePages'
import ViewMember from '../articleView/viewMember'
import DeleteReviewerDrawer from '../drawers/DeleteReviewerDrawer'
import MemberHistory from './memberHistory'

const SelectReviewer = props => {
  let { visible, onClose, article, page } = props
  let [reviewerId, setReviewerId] = useState('')
  let [reviewerData, setReviewerData] = useState({})
  let [memberDrawerVisible, setMemberDrawerVisible] = useState(false)
  let [reviewerDrawerVisible, setReviewerDrawerVisible] = useState(false)
  let [ids, setIds] = useState(props.ids)
  const table = useRef()
  const dispatch = useDispatch()
  const columns = [
    {
      title: 'Reviewer ID',
      key: 'reviewerId',
      dataIndex: 'reviewerId',
      render: (item, record) => {
        return (
          <>
            {item && record.reviewerId ? (
              <ViewMember revId={record._id}>
                <a>{record.reviewerId}</a>
              </ViewMember>
            ) : null}
          </>
        )
      }
    },
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      filterRegex: true,
      searchTextName: 'name'
    },
    {
      title: 'Designation',
      key: 'designation',
      dataIndex: 'designation'
    },
    {
      title: 'Area Of Interest',
      key: 'areaOfInterest',
      dataIndex: 'areaOfInterest',
      searchTextName: 'areaOfInterest',
      filterRegex: true
    },
    {
      title: 'Email',
      key: 'email',
      dataIndex: 'email',
      filterRegex: true,
      searchTextName: 'name'
    },
    {
      title: 'Discipline',
      key: 'category',
      dataIndex: 'category',
      filters: DisciplineFilter.map(x => ({ text: x, value: x }))
    },
    {
      title: 'Member Type',
      key: 'memberType',
      dataIndex: 'memberType'
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status'
    },
    {
      title: 'Rating',
      key: 'rating',
      dataIndex: 'rating',
      filterRegex: true,
      searchTextName: 'rating',
      render: item => {
        return `${item} stars`
      }
    },
    {
      title: 'Date of Enter',
      key: 'createdAt',
      dataIndex: 'createdAt',
      width: 100,
      render: item => {
        return item ? DisplayDate(item) : null
      }
    },

    {
      title: 'Action',
      key: 'actions',
      width: 280,
      render: (val, record) => (
        <React.Fragment>
          {!ids[record._id] ? (
            <Button
              className={'roundSmallBtn'}
              onClick={() => events.selectReviewer(record._id)}>
              Select
            </Button>
          ) : null}
          <Button
            className={'roundSmallBtn'}
            onClick={() => events.showMemberHistory(record)}>
            History
          </Button>
          <Button
            className={'roundSmallBtn'}
            onClick={() => {
              dispatch(getPushPathWrapper('member.edit', { id: record._id }))
            }}>
            Edit
          </Button>
          {!ids[record._id] && record.status == 'Available' ? (
            <Button
              className={'roundSmallBtn'}
              onClick={() => {
                setReviewerDrawerVisible(true)
                setReviewerId(record._id)
              }}>
              Delete
            </Button>
          ) : null}
        </React.Fragment>
      )
    }
  ]
  const apiRequest = params => {
    return new Promise(async resolve => {
      let obj = {
        ...params,
        customQuery: { deleteStatus: { $ne: 'true' } }
      }
      let regExpFilters = ['name', 'areaOfInterest', 'email', 'rating']
      let data = await dispatch(
        getAllMember({ ...obj, regExFilters: regExpFilters })
      )
      resolve(data)
    })
  }

  const events = {
    reloadTable: async selectedReviewer => {
      // let { data } = await dispatch(getArticleById(article._id, { selectedReviewer: 1 }))  // for reset selected article reviewer ids
      let ids = {}
      _.each(selectedReviewer, function(value) {
        if (value.reviewerId) {
          ids[value.reviewerId] = true
        }
      })
      setIds(ids)
      table.current.reload()
    },
    selectReviewer: id => {
      chooseEmailModalFxn({
        callback: async email => {
          let obj = {
            id: article._id,
            articleId: article._id,
            reviewerId: id,
            fromEmail: email,
            template: 'requestToNewReviewer',
            type: 'article'
          }
          let { success, selectedReviewer } = await dispatch(
            addReviewerInArticle(obj)
          )
          if (success) {
            if (page && page == 'articleView') {
              dispatch({ type: 'START_LOAD_ARTICLE' })
            }
            events.reloadTable(selectedReviewer)
          }
        }
      })
    },
    showMemberHistory: data => {
      setMemberDrawerVisible(true)
      setReviewerData(data)
      setReviewerId(data._id)
    },
    hideMemberHistory: () => {
      setMemberDrawerVisible(false)
      setReviewerId('')
    }
  }

  return (
    <>
      <Drawer
        title={`Reviewers | Article No. ${article.articleNo}`}
        width={'95%'}
        visible={visible}
        placement="right"
        onClose={onClose}>
        <Card>
          <ArticleInfo article={article} />
          <TableComp
            ref={table}
            columns={columns}
            apiRequest={apiRequest}
            pagination={{
              showSizeChanger: true,
              defaultPageSize: 50,
              pageSizeOptions: ['50', '100', '1000']
            }}
          />
        </Card>
      </Drawer>

      {memberDrawerVisible ? (
        <MemberHistory
          visible={memberDrawerVisible}
          articleId={article._id}
          reviewerId={reviewerId}
          data={reviewerData}
          onClose={events.hideMemberHistory}
        />
      ) : null}

      {reviewerDrawerVisible ? (
        <DeleteReviewerDrawer
          visible={reviewerDrawerVisible}
          reviewerId={reviewerId}
          onClose={() => {
            setReviewerDrawerVisible(false)
            table.current.reload()
          }}
        />
      ) : null}
    </>
  )
}

export { SelectReviewer }
