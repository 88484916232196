import {
  Button,
  Card,
  Col,
  Drawer,
  Form,
  Icon,
  Input,
  notification,
  Popconfirm,
  Row,
  Table,
  Tooltip
} from 'antd'
import Item from 'antd/lib/list/Item'
import React, { useEffect, useRef, useState } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { CkEditor2013 } from '../../../components/_utils/appUtils'
import Request from '../../../request'
import { GetJournalWebPage, UpdateJournalWebPage } from '../actions'
import Editdrawer from './Editdrawer'

const JournalWebPagesDrawer = props => {
  let {
    form: { getFieldDecorator, setFieldsValue },
    visible,
    onClose,
    page,
    journalId,
    name,
    slugJournal,
    sendData
  } = props

  const dispatch = useDispatch()
  let [description, setDescription] = useState(null)

  let [title, setTitle] = useState(null)
  let [menuHeading, setMenuHeading] = useState(null)
  let [newData, setNewData] = useState([])
  let [coData, setCoData] = useState({})
  let [open, setOpen] = useState(false)
  let [journalWebPageId, setJournalWebPageId] = useState('')
  const params = useParams()

  useEffect(() => {
    getGuideline()
  }, [])

  onClose = () => {
    setOpen(false)
  }

  const handleDelete = async e => {
    let obj1 = { ...e, journalWebPageId }
    let resp = await Request.deleteDrawerItem(obj1)
    getGuideline()
  }

  const showDrawer = item => {
    setCoData(prevData => {
      return {
        ...prevData,
        ...item
      }
    })
    setOpen(true)
  }

  const getGuideline = async () => {
    let { data, success } = await dispatch(
      GetJournalWebPage({ page, slugJournal })
    )
    setJournalWebPageId(data._id)
    setNewData(data.data)
  }

  const descriptionField = {
    label: 'Description',
    key: 'description',
    placeholder: 'description',
    span: 24,
    type: 'ckeditor'
  }

  const handleSubmit = async () => {
    if (!title) {
      notification.warning({
        message: 'Enter title.'
      })
      return
    }
    if (!menuHeading) {
      notification.warning({
        message: 'Enter Menu Heading.'
      })
      return
    }
    if (!description) {
      notification.warning({
        message: 'Enter content.'
      })
      return
    }

    let obj = {
      description,
      page,
      journalId,
      slugJournal,
      menuHeading,
      title
    }

    let resp = await dispatch(UpdateJournalWebPage(obj))

    let { success, data } = resp

    if (success) {
      dispatch({ type: 'START_LOAD_ARTICLE' })
      setTitle('')
      setMenuHeading('')
      setDescription('')
      getGuideline()
    }
  }

  return (
    <>
      <Drawer
        title={`${name} - ${page}`}
        width={'80%'}
        visible={props.visible}
        maskClosable={true}
        placement="right"
        onClose={props.onClose}>
        <Card className={'GuideLineDrawer'}>
          <Form hideRequiredMark className={'inputForm'}>
            <Row gutter={16}>
              <Col span={24} className="flexColumn">
                <Form.Item label="Enter Title" name="title">
                  <Input
                    placeholder="Enter title..."
                    value={title}
                    onChange={e => {
                      setTitle(e.target.value)
                    }}
                  />
                </Form.Item>

                <Form.Item
                  label="Menu Heading"
                  name="menuHeading
                ">
                  <Input
                    placeholder="Enter Menu Heading..."
                    value={menuHeading}
                    onChange={e => {
                      setMenuHeading(e.target.value)
                    }}
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <CkEditor2013
                  html={description}
                  basic={true}
                  style={{ height: '200px' }}
                  customCls={'lgCk'}
                  onChange={data => {
                    setDescription(data)
                  }}
                />
              </Col>

              <Col span={24}>
                <Form.Item style={{ marginTop: '2%' }}>
                  <Button type="primary" onClick={handleSubmit}>
                    Submit
                  </Button>
                </Form.Item>
              </Col>

              <Col
                span={24}
                style={{ marginTop: '18px' }}
                className={'custom_card'}>
                {newData &&
                  newData.map((item, i) => {
                    return (
                      <>
                        <div
                          key={i}
                          style={{ marginTop: '18px' }}
                          className="custom_card">
                          <Card
                            title={`${item.title} (${item.menuHeading})`}
                            extra={
                              <>
                                <Tooltip
                                  title="Edit  information"
                                  style={{ marginLeft: '20px' }}>
                                  <Button
                                    style={{ border: 20 }}
                                    shape="circle"
                                    onClick={() => showDrawer(item)}
                                    icon={'edit'}
                                  />
                                </Tooltip>

                                <Popconfirm
                                  placement="topLeft"
                                  title={`Sure to delete?`}
                                  onConfirm={() => handleDelete(item)}
                                  okText="Yes"
                                  cancelText="No">
                                  <Button
                                    style={{ border: 20, marginLeft: 10 }}
                                    shape="circle"
                                    icon={'delete'}
                                  />
                                </Popconfirm>
                              </>
                            }>
                            <h3>{ReactHtmlParser(item.content)}</h3>
                          </Card>
                        </div>
                      </>
                    )
                  })}
              </Col>

              {open && (
                <Editdrawer
                  visible={visible}
                  onClose={() => onClose()}
                  sendData={coData}
                  getGuideline={getGuideline}
                  GetJournalWebPage={GetJournalWebPage}
                  journalWebPageId={journalWebPageId}
                />
              )}
            </Row>
          </Form>
        </Card>
      </Drawer>
    </>
  )
}

const WrappedForm = Form.create()(JournalWebPagesDrawer)

export default WrappedForm
