import { apiUrl } from '../../../settings'

export const emailTemplateUrl = () => {
  return apiUrl + '/template'
}

export const singleTemplateUrl = (id) => {
  return apiUrl + `/template/${id}`
}

export const sendTemplateEmailUrl = () => {
  return apiUrl + `/sendTemplateEmail`
}
