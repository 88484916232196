import {
  Button,
  Card,
  Col,
  Drawer,
  Form,
  Input,
  notification,
  Popconfirm,
  Row,
  Select,
  Table,
  Tooltip
} from 'antd'
import _ from 'lodash'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import {
  ChooseEmailModal,
  chooseEmailModalFxn,
  currencyList,
  formItemLayout
} from '../../../components/_utils/appUtils'
import AddAffiliate from '../../editArticle/addAffiliate'
import EditAffiliate from '../../editArticle/editAffiliate'
import { ApproveArticle } from '../actions'
import ApprovedArticle from '../views/ApprovedArticle'

const { Option } = Select
const ApprovalArticleDrawer = props => {
  let {
    children,
    form: { getFieldDecorator, setFieldsValue },
    callback = null,
    visible,
    onClose,
    edit,
    article
  } = props
  const dispatch = useDispatch()
  const [affiliationData, setAffiliationData] = useState([])
  const [open1, setOpen1] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const updateAffiliateState1 = {
    openEdit: false,
    indexVal1: null,
    editVal: {}
  }
  const [updateAffiliateState, setUpdateAffiliateState] = useState(
    updateAffiliateState1
  )
  const authorData1 = {
    loop: null,
    authors: [],
    noOfAuthors: null,
    superScriptList: []
  }
  const [authorData, setAuthorData] = useState(authorData1)
  let _updateState = data => {
    setAuthorData(preState => {
      return {
        ...preState,
        ...data
      }
    })
  }
  const loopVal = noOfAuthors => {
    noOfAuthors = parseInt(noOfAuthors)
    let authors = []
    let superScriptList = ['*']
    for (let i = 0; i < noOfAuthors; i++) {
      authors.push({
        name: '',
        email: '',
        script: [],
        orchId: ''
      })
    }
    for (let i = 0; i < affiliationData.length; i++) {
      superScriptList.push(i + 1)
    }

    setAuthorData({ authors, noOfAuthors, superScriptList })
  }

  const deleteAuthors = index => {
    let cloneAuthor = _.clone(authorData.authors)
    cloneAuthor = _.reject(cloneAuthor, (item, index1) => {
      return index1 == index
    })
    setAuthorData({ authors: cloneAuthor, noOfAuthors: cloneAuthor.length })
  }
  const addNewAuthor = () => {
    // console.log(authorData, 'auuuuu')
    let authors = _.clone(authorData.authors)

    authors.push({
      name: '',
      email: '',
      script: []
    })
    let noOfAuthors = authorData.noOfAuthors
    noOfAuthors = noOfAuthors + 1
    // console.log(noOfAuthors, 'noOfAuthors')
    let superScriptList = _.clone(authorData.superScriptList)
    // console.log(superScriptList.length)
    superScriptList.push(authorData.noOfAuthors)

    let dataq = { authors, noOfAuthors, superScriptList }
    _updateState(dataq)
    // console.log(authorData, 'ksjdfs')
  }

  const addAffiliateData = data => {
    const { loop, noOfAuthors, authors } = authorData
    let cloneData = _.clone(affiliationData)
    cloneData.push(data)
    setAffiliationData(cloneData)
    let superScriptList = ['*']
    for (let i = 0; i < cloneData.length; i++) {
      superScriptList.push(i + 1)
    }
    setAuthorData({
      loop,
      noOfAuthors,
      authors,
      superScriptList: superScriptList
    })
  }
  const oncloseEditAffiliate = () => {
    setUpdateAffiliateState({ openEdit: false })
  }
  const showDrawer1 = (item, index) => {
    setOpen1(true)
  }
  const onClose1 = () => {
    setOpen1(false)
  }

  // edit
  const updateAffiliate = row => {
    let cloneData = _.clone(affiliationData)
    cloneData[row.index] = row
    setAffiliationData(cloneData)
    setOpenEdit(true)
  }
  const showDrawerEditAffiliate = (val, index) => {
    setUpdateAffiliateState({ openEdit: true, editVal: val, indexVal1: index })
  }

  const handleDeleteAffilation = key => {
    const { loop, noOfAuthors, authors } = authorData
    const newData = affiliationData.filter((item, index) => index !== key)
    let superScriptList2 = ['*']
    for (let i = 0; i < newData.length; i++) {
      superScriptList2.push(i + 1)
    }
    setAuthorData({
      loop,
      noOfAuthors,
      authors,
      superScriptList: superScriptList2
    })

    setAffiliationData(newData)
  }

  const authorsUpdate = (data, index) => {
    let { loop, superScriptList, authors, noOfAuthors } = authorData
    let cloneAuth = _.clone(authors)
    if (cloneAuth[index]) {
      cloneAuth[index] = { ...cloneAuth[index], ...data }
      setAuthorData({
        loop,
        superScriptList,
        noOfAuthors,
        authors: cloneAuth
      })
    }
  }
  let setFormData = () => {
    let { article } = props
    if (edit) {
      let superScriptList = ['*']
      if (article.affiliationData && article.affiliationData.length) {
        setAffiliationData(article.affiliationData)
        for (let i = 0; i < article.affiliationData.length; i++) {
          superScriptList.push(i + 1)
        }
      }

      if (article.noOfAuthors) {
        setAuthorData({
          noOfAuthors: article.noOfAuthors,
          authors: article.doiAuthors,
          superScriptList: superScriptList
        })
      }

      props.form.setFieldsValue({
        manuScriptTitle: article.manuScriptTitle,
        approvalAmount: article.approvalAmount,

        approvalInvoiceName: article.correspondingAuthor,
        approvalInvoiceAddress: article.approvalInvoiceAddress,
        invoiceDate: moment(article.invoiceDate),
        currencyTypeOfApprovalAmount: article.currency,
        approvalType: article.approvalType,
        approvalSpecialNote: article.approvalSpecialNote,
        approvalInvoiceAuthorName: article.approvalInvoiceAuthorName,
        approvedArticleDate: moment(article.approvedArticleDate),
        authors: article.doiAuthors ? article.doiAuthors : []
      })
    } else {
      let authData = nameValue()
      let address = ''
      let affiliationData = []
      let authors = []
      let superScriptList = ['*']
      let correspondingAuthor = {}
      if (article.correspondingAuthor) {
        correspondingAuthor.name = article.correspondingAuthor
        superScriptList.push('1')
      }
      if (article.correspondingAuthorsEmail) {
        correspondingAuthor.email = article.correspondingAuthorsEmail
      }

      if (article.correspondingAuthorAffiliation) {
        affiliationData.push({
          affiliate: article.correspondingAuthorAffiliation
        })
      }

      if (article) {
        if (article.correspondingAuthorAffiliation) {
          address = article.correspondingAuthorAffiliation
        }

        if (article.correspondingAuthorAddress) {
          address = address + ', ' + article.correspondingAuthorAddress
        }
      }

      authors.push(correspondingAuthor)
      if (article.authors && article.authors.length) {
        _.each(article.authors, (item, key) => {
          authors.push({ name: item.name, email: item.email })
          affiliationData.push({ affiliate: item.instituteAffiliated })
          superScriptList.push(key + 2)
        })
      }

      setAuthorData({
        noOfAuthors: authors.length,
        authors: authors,
        superScriptList: superScriptList
      })

      setAffiliationData(affiliationData)

      props.form.setFieldsValue({
        manuScriptTitle: article.manuScriptTitle,
        approvalAmount: article.publicationCharges,
        approvalInvoiceName: article.correspondingAuthor,
        approvalInvoiceAddress: address,
        invoiceDate: moment(),
        currencyTypeOfApprovalAmount: article.currency,
        approvalType: article.articleType == 'Full Research Article' ? 'Full Article' : article.articleType,
        approvedArticleDate: moment(),
        approvalInvoiceAuthorName: authData.approvalInvoiceAuthorName
      })
    }
  }

  let nameValue = () => {
    let authors = [{ name: article.correspondingAuthor }]
    let str = article.correspondingAuthor
    if (article.authors) {
      _.forEach(article.authors, item => {
        str = str + ', ' + item.name
        authors.push({ name: item.name })
      })
    }
    return { approvalInvoiceAuthorName: str, authors }
  }

  useEffect(() => {
    setTimeout(() => {
      setFormData()
    }, 1000)
  }, [])

  let [visibleDrawer, setVisibleDrawer] = useState(false)

  const events = {
    show: event => {
      setVisibleDrawer(true)
      setTimeout(() => {
        setFormData()
      }, 1000)
    },
    hide: event => {
      setVisibleDrawer(false)
    }
  }
  const columns = [
    {
      title: 'Serial no',
      name: 'serial',
      key: 'serial',
      render: (text, record, index) => {
        return <div>{index + 1}</div>
      },
      width: 200
    },
    {
      title: 'Affiliate',
      name: 'affiliate',
      key: 'affiliate',
      dataIndex: 'affiliate'
    },
    {
      title: 'Action',
      name: 'action',
      key: 'action',
      dataIndex: 'action',
      width: 100,
      render: (item, index, indexValue) => {
        return (
          <>
            <Tooltip title='Edit  information' style={{ marginRight: '20px' }}>
              <Button
                style={{ border: 20 }}
                shape='circle'
                onClick={e => {
                  showDrawerEditAffiliate(index, indexValue)
                }}
                icon={'edit'}
              />
            </Tooltip>
            <Popconfirm
              title={`Are you Sure, You want to delete?`}
              onConfirm={e => {
                handleDeleteAffilation(indexValue)
              }}
              okText='Yes'
              cancelText='No'>
              <Button shape='circle' icon={'delete'} />
            </Popconfirm>
          </>
        )
      }
    }
  ]
  const inputSchema = {
    fields: [
      {
        label: 'Article Title',
        key: 'manuScriptTitle',
        type: 'editor',
        required: true,
        span: 24
      },
      {
        label: 'Approved Date',
        key: 'approvedArticleDate',
        required: true,
        placeholder: 'Enter Approval Date',
        type: 'date'
      },
      {
        label: 'Invoice Date',
        key: 'invoiceDate',
        required: true,
        placeholder: 'Date of Enter',
        type: 'date'
      },
      {
        label: 'Currency',
        key: 'currencyTypeOfApprovalAmount',
        required: true,
        placeholder: 'Currency',
        type: 'select',
        options: currencyList,
        onChange: e => {
          setFieldsValue({currencyTypeOfApprovalAmount: e})
        }
      },
      {
        label: 'Approval Amount',
        key: 'approvalAmount',
        required: true,
        placeholder: 'Approval Amount',
        type: 'number'
      },
      {
        label: 'Invoice Name',
        key: 'approvalInvoiceName'
      },
      {
        label: 'Address',
        key: 'approvalInvoiceAddress',
        type: 'textArea',
        required: true,
        span: 16
      },
      {
        label: 'All Author Names',
        key: 'approvalInvoiceAuthorName',
        required: true,
        placeholder: 'all author names',
        type: 'textArea',
        span: 24
      },

      {
        label: 'Approval Type',
        key: 'approvalType',
        type: 'radioGroup',
        required: true,
        span: 24,
        options: ['Full Article', 'Short Communication', 'Review Article'],
        onChange: x => {
          props.form.setFieldsValue({
            approvalType: x.target.value
          })
        }
      },
      {
        label: 'Special Note',
        key: 'approvalSpecialNote',
        placeholder: 'Notes',
        span: 24,
        type: 'textArea'
      }
    ]
  }

  const handleSubmit = e => {
    const { form, article } = props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        chooseEmailModalFxn({
          title: 'Approve Article',
          callback: async email => {
            valData.fromEmail = email
            valData.articleId = article._id
            valData.template = 'approvedArticle'
            if (!edit) {
              valData.articleStatus = 'Approved Article'
              valData.approvedArticleDate = new Date()
            }
            valData.affiliationData = affiliationData
            valData.doiAuthors = authorData.authors
            valData.noOfAuthors = authorData.noOfAuthors
            let { success } = await dispatch(ApproveArticle(valData))
            if (success) {
              callback()
              onClose()
              dispatch({ type: 'START_LOAD_ARTICLE' })
              events.hide()
            }
          }
        })
      } else {
        notification.error({
          message: 'Required',
          description: 'Fill all required fields.'
        })
      }
    })
  }

  return (
    <>
      <div onClick={() => events.show()}>{children}</div>
      <Drawer
          title={`  Approve Article | Article No. ${article.articleNo}`}
          width={'70%'}
          visible={visible}
          placement='right'
          onClose={onClose}>
        <Card>
          <Form
            onSubmit={handleSubmit}
            hideRequiredMark
            className={'inputForm'}>
            <Row gutter={16}>
              {inputSchema.fields.map((item, key) => {
                return (
                  <Col span={item.span ? item.span : 8} key={key}>
                    <GetEachFormFields
                      getFieldDecorator={getFieldDecorator}
                      item={item}
                      formItemLayout={formItemLayout}
                    />
                  </Col>
                )
              })}
              <Col span={24}>
                {' '}
                <Card
                  title={'Affiliation'}
                  extra={
                    <Form.Item name='add' style={{ textAlign: 'right' }}>
                      <Button
                        size='small'
                        onClick={showDrawer1}
                        className='ant-btn ant-btn-primary'>
                        Add Affiliation
                      </Button>
                    </Form.Item>
                  }>
                  <Table
                    size='small'
                    columns={columns}
                    className={'figureTable'}
                    dataSource={affiliationData}
                    pagination={false}
                  />
                </Card>
                <Card
                  title='Authors'
                  extra={
                    <Form.Item name='add' style={{ textAlign: 'right' }}>
                      <Button
                        size='small'
                        onClick={addNewAuthor}
                        className='ant-btn ant-btn-primary'>
                        Add Author
                      </Button>
                    </Form.Item>
                  }>
                  <Form.Item label='Enter Number of Authors'>
                    <Input
                      style={{ width: '34%' }}
                      value={authorData.noOfAuthors}
                      type={'number'}
                      onChange={e => {
                        loopVal(e.target.value)
                      }}
                    />
                  </Form.Item>

                  {authorData.authors && authorData.authors.length ? (
                    <>
                      {authorData.authors.map((item, key) => {
                        return (
                          <>
                            <Row key={key} gutter={16}>
                              <Col span={6}>
                                <Form.Item label='Name'>
                                  <Input
                                    placeholder=' Enter Name'
                                    value={item.name}
                                    onChange={e => {
                                      authorsUpdate(
                                        { name: e.target.value },
                                        key
                                      )
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={6}>
                                <Form.Item label='Email Address'>
                                  <Input
                                    placeholder='Enter email Address'
                                    type={'email'}
                                    value={item.email}
                                    onChange={e => {
                                      authorsUpdate(
                                        { email: e.target.value },
                                        key
                                      )
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={6}>
                                <Form.Item label='Super Script '>
                                  <div className={'rowFlex'}>
                                    <Select
                                      onChange={e => {
                                        authorsUpdate({ script: e }, key)
                                      }}
                                      mode='multiple'
                                      value={item.script}
                                      placeholder='Please select Super Script'>
                                      {authorData.superScriptList &&
                                      authorData.superScriptList.map(
                                        (item, key) => {
                                          return (
                                            <Option value={item}>
                                              {item}
                                            </Option>
                                          )
                                        }
                                      )}
                                    </Select>


                                  </div>
                                </Form.Item>
                              </Col>

                              <Col span={6}>
                                <Form.Item label='Orch Id'>
                                  <div className={'rowFlex'}>
                                    <Input
                                      placeholder='Enter Orch Id'
                                      value={item.orchId}
                                      onChange={e => {
                                        authorsUpdate({ orchId: e.target.value }, key)
                                      }}
                                    />
                                    <Popconfirm
                                      title={`Are you Sure, You want to delete?`}
                                      onConfirm={e => {
                                        deleteAuthors(key)
                                      }}
                                      okText='Yes'
                                      cancelText='No'>
                                      <Button shape='circle' icon={'delete'} />
                                    </Popconfirm>
                                  </div>
                                </Form.Item>
                              </Col>

                            </Row>
                          </>
                        )
                      })}
                    </>
                  ) : null}
                </Card>
              </Col>
              <Col span={24}>
                <Form.Item style={{ marginTop: 40 }}>
                  <Button type='primary' htmlType='submit'>
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </Drawer>
      <AddAffiliate
        addAffiliateData={addAffiliateData}
        visible={open1}
        onClose={() => {
          onClose1()
        }}
      />

      {updateAffiliateState.openEdit ? (
        <EditAffiliate
          visible={updateAffiliateState.openEdit}
          updateAffiliate={updateAffiliate}
          editVal={updateAffiliateState.editVal}
          indexVal1={updateAffiliateState.indexVal1}
          onClose={() => {
            oncloseEditAffiliate()
          }}
        />
      ) : null}
    </>
  )
}

const WrappedForm = Form.create()(ApprovalArticleDrawer)

export default WrappedForm
