export default (
  state = {
    articleData: {},
    loadArticle: false,
    refreshArticle: false
  },
  action
) => {
  switch (action.type) {
    case 'START_LOAD_ARTICLE':
      return {
        ...state,
        loadArticle: true
      }

    case 'STOP_LOAD_ARTICLE':
      return {
        ...state,
        loadArticle: false
      }

    case 'REFRESH_ARTICLE':
      return {
        ...state,
        refreshArticle: action.refreshArticle
      }
    default:
      return state
  }
}
