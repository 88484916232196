import React, {useEffect, useState} from "react"
import {Button, Card, Col, Drawer, Form, Input, notification, Row, Select} from "antd";
import GetEachFormFields from "../../../components/_utils/appFormUtils";
import {Countries, formItemLayout} from "../../../components/_utils/appUtils";
import {useDispatch} from "react-redux";
import {getUserFxn, updateAdminUser, updateUserFxn} from "../actions";

const EditUser = (props) => {
    let dispatch = useDispatch()
    let {visible, onClose, userId, onSubmit, form, title = 'Edit', userType} = props;
    let {getFieldDecorator, setFieldsValue} = form;
    const events = {
        loadSingleUser: async () => {
            let {data} = await dispatch(getUserFxn({userId}));
            setTimeout(() => {
                let {local} = data;
                setFieldsValue({...data, email: local.email})
            }, 200)
        },
        handleSubmit: (e) => {
            e.preventDefault()
            form.validateFieldsAndScroll(async (err, valData) => {
                if (!err) {
                    valData.id = userId
                    let resp = await dispatch(updateUserFxn({...valData, userId}))
                    if (resp && resp.success) {
                        onSubmit()
                    }
                } else {
                    notification.error({
                        message: 'Please enter all required fields.'
                    })
                }
            })
        }
    }
    useEffect(() => {
        events.loadSingleUser()
    }, [userId])
    const inputSchema = [
        {
            label: 'Name',
            key: 'name',
            placeholder: 'Enter Your Name',
            required: true
        },

        {
            label: 'Last Name',
            key: 'lastName',
            placeholder: 'Enter Your Last Name',
            required: true,
            hidden: !(userType == "Author")
        },

        {
            label: 'Email',
            key: 'email',
            placeholder: 'Enter Your Email',
            type: 'email',
            required: true
        },
        {
            label: 'Mobile No',
            key: 'mobileNo',
            placeholder: 'Enter Your Mobile No'
        },
        {
            label: 'Phone No',
            key: 'phoneNo',
            placeholder: 'Enter Your Phone No'
        },
        {
            label: 'User Name',
            dataIndex: 'userName',
            placeholder: 'Enter Your User Name',
            key: 'userName'
        },
        {
            label: 'Country',
            key: 'country',
            showSearch: true,
            placeholder: 'Country',
            options: Countries,
            keyAccessor: x => x.name,
            valueAccessor: x => x.name,
            type: 'select',
            onChange: (val) => {
                setFieldsValue({country: val})
            }
        },
        {
            label: 'Designation',
            key: 'designation',
            placeholder: 'Enter Your Designation'
        },
        {
            label: 'Department',
            key: 'department',
            placeholder: 'Enter Your department',
            required: true
        },
        {
            label: 'Postal Code',
            key: 'postalCode',
            placeholder: 'Enter  Postal Code',
            required: false
        },
        {
            label: 'Institute Name',
            key: 'instituteName',
            placeholder: 'Enter Institute Name',
            required: userType == "Institute"
        },
        {
            label: 'Area of Research',
            key: 'areaResearch',
            placeholder: 'Enter Area Research',
        },
        {
            label: 'Email 2',
            key: 'email2',
            placeholder: 'Enter Email 2',
            type: "email"
        },
        {
            label: 'Address',
            key: 'address',
            placeholder: 'Enter Your Address',
            required: true,
            type: "textarea",
            span: 24
        },
        {
            title: 'Institute',
            dataIndex: 'instituteAddress',
            placeholder: 'Enter Institute Address',
            key: 'instituteAddress',
            type: "textarea",
            required: userType == "Institute",
            span: 24
        },
    ]

    return (
        <>
            <Drawer
                title={`${title} ${userType}`}
                width={'50%'}
                visible={visible}
                placement='right'
                onClose={onClose}>
                <Card>
                    <Form
                        onSubmit={events.handleSubmit}
                        hideRequiredMark
                        className={'inputForm'}>
                        <Row gutter={16}>
                            {inputSchema.map((item, key) => {
                                return (
                                    <Col span={item.span ? item.span : 12} key={key}>
                                        <GetEachFormFields
                                            getFieldDecorator={getFieldDecorator}
                                            item={item}
                                            formItemLayout={formItemLayout}
                                        />
                                    </Col>
                                )
                            })}

                            <Col span={24}>
                                <Form.Item style={{marginTop: 40}}>
                                    <Button type='primary' htmlType='submit'>
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </Drawer>
        </>
    )
}
const WrappedEditUser = Form.create()(EditUser)
export default WrappedEditUser
