import {
  Drawer,
  Form
} from 'antd'
import React from 'react'
import KeywordComponent from '../keyword/keywordComponent'

const AddKeywordDrawer = props => {
  return (
    <>
      <Drawer
        visible={props.visible}
        title='Add Keyword'
        onClose={props.onClose}
        width='60%'>
        <KeywordComponent />
      </Drawer>
    </>
  )
}
let formWrap = Form.create()(AddKeywordDrawer)
export default formWrap
