import React, { Component } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
  Table,
  Badge,
  Card,
  Tooltip,
  notification,
  Switch,
  Input, Button, Icon, Form, Row, Col, Drawer, Select, Divider, Popconfirm
} from 'antd'
import Request from '../../../request'
import { getPushPathWrapper } from '../../../routes'
import { apiUrl } from '../../../settings'
import _ from 'lodash'
import styles from '../all/styles.less'
import EditSubscription from '../edit/index'
import { connect } from 'react-redux'
import { TableComp, FormUtils } from 'sz-react-utils-lite'
import { func } from 'prop-types'
import { allSubscription, journalsAjaxList, addSubscription, getGroupedSubscription, removeSubscription } from '../actions'
import { getReviewerPendingArticle } from '../../article/actions'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import { formItemLayout } from '../../../components/_utils/appUtils';
// import subscriptionDrawer from './subscriptionDrawer'; //Not in use
import EditSubscriptionComponent from "./editSubscription";

const keyObj = {
  'oneYear': 'One Year',
  'threeYear': 'Three Year',
  'lifeTime': 'Life Time',
  'online': 'Online',
  'print': 'Print',
  'printOnline': 'Print Online'
}
const regExpFilters = ['journalName', 'nationality']

@Form.create()
class AllSubscription extends Component {
  state = {
    editSubscription: false,
    journalList: [],
    nationalityArr: [{ value: 'Indian', display: 'Indian', _id: 'Indian' }, {
      value: 'Foreign',
      display: 'Foreign',
      _id: 'Foreign'
    }],
    yearArr: [],
    yearList: [],
    subscriptionList: [],
    visible: false, //define state for Add Drawer
    open: false, //define state for edit Drawer
    subscriptionDat: {},
    sendData: {}
  }

  constructor(props) {
    super(props)
    this.table = React.createRef()
  }

  apiRequest = (params) => {
    let { dispatch, form: { getFieldValue } } = this.props
    return new Promise(async (resolve) => {
      let obj = {
        ...params,
        journalId: this.props.form.getFieldValue('journalId') ? [this.props.form.getFieldValue('journalId')] : null,
        year: this.props.form.getFieldValue('year') ? [this.props.form.getFieldValue('year')] : null,
        nationality: getFieldValue('nationality') ? [getFieldValue('nationality')] : null
      }

      let data = await dispatch(allSubscription({ ...obj, regExFilters: regExpFilters }))
      // console.log(data)
      resolve(data)
    })
    console.log(this.obj)
  }



  getSubscriptionYearByJournals = () => {
    let journalId = this.props.form.getFieldValue('journalId')
    // console.log(journalId)
    if (journalId) {
      Request.getSubscriptionYearByJournals({ journalId: journalId }).then((resp) => {
        if (resp) {
          let yearArr = []
          _.each(resp, (item) => {
            yearArr.push({ value: item, display: item, _id: item })
          })
          setTimeout(() => {
            this.setState({ yearArr })
          }, 500)
        }
      })
    }
  }


  setYears = () => {
    let yearArr = []
    let i = 2019
    while (i < 2026) {
      yearArr.push({ value: i, display: i, _id: i })
      i++
    }
    this.setState({ yearList: yearArr })
  }

  journalAjax = async () => {
    let { dispatch } = this.props
    let { data } = await dispatch(journalsAjaxList())
    this.setState({ journalList: data })
  }

  reload = () => {
    this.table.current.reload()
  }

  filterSub = () => {
    this.apiRequest()
    this.reload()
  }

  openSubsciption = (subscriptionId) => {

    this.setState({ editSubscription: true, subscriptionId: subscriptionId })

  }
  closeEditSubscription = () => {
    this.setState({ editSubscription: false })
    this.reload()
  }
  subscriptionSubmit = (reminObj) => {
    this.setState({ remindObj: reminObj })
    this.closeEditSubscription()
  }

  componentDidMount() {
    this.setYears()
    this.journalAjax()
    this.apiGroupedSubscription()
  }

  // Drawer


  showDrawer = (journalId) => {
    this.setState({
      visible: true,
      journalId: journalId
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });

  };
  setPreFill = () => {
    this.props.form.setFieldsValue({
      year: "",
      priceInInr: "",
      priceInUsd: ""
    })
  }

  // Add subscription post request
  handleSubmit = async (e) => {
    e.preventDefault();
    console.log(e, 'eeee');
    let { dispatch } = this.props
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        valData.journalId = this.state.journalId;
        // console.log(valData)
        let result = await dispatch(addSubscription(valData))
        // console.log(result)
        this.onClose()
        this.apiGroupedSubscription()
        this.setPreFill()

      }
    })
  }

  apiGroupedSubscription = async () => {
    let result = await getGroupedSubscription()
    // console.log(result)
    this.setState({
      subscriptionData: result.data
    })
  }

  onEditClose = () => {
    this.setState({
      open: false,
      sendData:{}
      // journalId: journalId
    }, () => { this.apiGroupedSubscription() });
  }

  handleEdit = (record) => {
    // console.log("Clicked..")
    // console.log(record)
    this.setState({ sendData: record })
    this.setState({ open: true });
  }


  handleDelete = async (record) => {
    let { dispatch } = this.props
    console.log(record)
    console.log(record._id)
    const result = await dispatch(removeSubscription(record));
    console.log(result)
    this.apiGroupedSubscription()
    // window.location.reload();
  }


  render() {

    const { form: { getFieldDecorator, getFieldValue, setFieldsValue }, loading, dispatch } = this.props
    const { journalList, journalId, subscriptionData } = this.state
    // const { journalList, yearArr, nationalityArr } = this.state
    // console.log(journalId)
    // console.log(journalList)



    const formProps = {
      getFieldDecorator, getFieldValue, loading
    }

    const columns = [
      {
        key: "year",
        title: "Year",
        dataIndex: "year"
      },
      {
        title: "Price in INR",
        key: "priceInInr",
        dataIndex: "priceInInr"
      },
      {
        title: "Price in USD",
        key: "priceInUsd",
        dataIndex: "priceInUsd"
      },
      {
        title: "Action",
        key: 'operation',
        fixed: 'right',
        width: 150,
        render: (item, record) => (
          <>
            <Button type="primary" ghost shape="circle" onClick={() => this.handleEdit(record)}><Icon type='edit' /></Button>
            <Popconfirm
              placement="topLeft"
              title={`Are you sure, want to delete?`}
              onConfirm={() =>
                this.handleDelete(record)
              }
              okText="Yes"
              cancelText="No">
              <Button type="danger" ghost shape="circle" style={{ marginLeft: "10px" }}><Icon type='delete' /></Button>
            </Popconfirm>

            {/* <Button type="danger" ghost shape="circle" style={{ marginLeft: "10px" }}><Icon type='delete' /></Button> */}
          </>
        )
      }
    ]

    const inputSchma = {
      fields: [
        {
          label: 'Year',
          key: 'year',
          placeholder: 'Year',
          required: true,
          type: "select",
          options: ["2015", "2016", "2017", "2018", "2019", "2020", "2021", "2022", "2023", "2024", "2025", "2026", "2027", "2028", "2029", "2030"],
          onChange: (val) => {
            this.props.form.setFieldsValue({ year: val })
          }
        },
        {
          key: 'priceInInr',
          label: 'Price in INR',
          placeholder: "Price in Indian Currency",
          required: true,
          type: "number"
        },
        {
          key: 'priceInUsd',
          label: 'Price in USD',
          placeholder: "Price in Foriegn Currency",
          required: true,
          type: "number"
        }
      ]
    }


    //form design layout
    const formItemLayout = {
      labelCol: {
        xs: { span: 2 },
        sm: { span: 3 },
        md: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 22 },
        sm: { span: 22 },
        md: { span: 22 }
      }
    }

    // console.log(...this.state.journalList)

    return (
      <PageHeaderWrapper
        title={'All Subscription'}>
        {/* {
                    this.state.editSubscription ? <EditSubscription subscriptionId={this.state.subscriptionId}
                        onClose={this.closeEditSubscription}
                        onSubmit={this.subscriptionSubmit}
                    /> : null}*/}

        <Card bordered={true} className="subscriptionList">

          {this.state.journalList.map((item, key) => {
            return (
              <>
                <Card title={item.name} key={key} size='small'
                      className={'mb10'}
                      extra={<Button size='small' type='primary'
                                     onClick={() => this.showDrawer(item._id)}> Add </Button>} block>
                  {subscriptionData && item._id && subscriptionData[item._id] ? <>
                    <Table pagination={false} size='small' dataSource={subscriptionData[item._id]} columns={columns}
                           style={{ fontSize: '18px' }}></Table>
                  </> : null}
                </Card>
              </>
            )
          })}
        </Card>
        {/* <subscriptionDrawer /> */}
        <Drawer
          title="Add Subscription"
          placement="right"
          onClose={this.onClose}
          visible={this.state.visible}
          width={380}>
          <Card>
            <Col span={24}>
              <Form onSubmit={this.handleSubmit}>
                <FormUtils
                  inputSchema={inputSchma}
                  formItemLayout={formItemLayout} // optional //for the design part
                  getFieldDecorator={getFieldDecorator} //input validation
                />
                <Button block type='primary' htmlType='submit'>Submit</Button>
              </Form>
            </Col>
          </Card>
        </Drawer>


        {/* Edit Subscription */}
        {this.state.open && this.state.sendData && <EditSubscriptionComponent
          onClose={() => this.onEditClose()}
          visible={this.state.open}
          data={this.state.sendData}
        />}

      </PageHeaderWrapper>)

  }
}


const mapStateToProps = ({ global }) => ({
  categories: global.categories
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllSubscription)
