import React, { useEffect, useRef, useState } from 'react'
import { Drawer, Card, Button, Form, Row, Col, notification } from 'antd'
import {
  DisplayDate,
  formItemLayout,
  NotificationBox
} from '../../../components/_utils/appUtils'
import { useDispatch } from 'react-redux'
import { ChooseEmailModal, chooseEmailModalFxn } from '../../../components/_utils/appUtils'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import { DeleteArticle, DeleteReviewer } from '../actions'
import ApprovedArticle from '../views/ApprovedArticle'
import moment from 'moment'

const DeleteReviewerDrawer = (props) => {
  let {
    children,
    form: { getFieldDecorator, setFieldsValue },
    callback = null,
    visible,
    onClose,
    edit,
    reviewerId
  } = props
  const dispatch = useDispatch()

  let [visibleDrawer, setVisibleDrawer] = useState(false)

  const events = {
    show: (event) => {
      setVisibleDrawer(true)
    },
    hide: (event) => {
      setVisibleDrawer(false)
    }
  }

  useEffect(() => {
    console.log('))))))(((', reviewerId)
  }, [])

  const inputSchema = {
    fields: [
      {
        label: 'Reason',
        key: 'deleteReason',
        required: true,
        span: 24,
        type: 'textarea'
      }
    ]
  }


  const handleSubmit = e => {
    const { form, reviewerId } = props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        valData.id = reviewerId
        console.log(valData)
        let { success } = await dispatch(DeleteReviewer(valData))
        if (success) {
          //callback()
          onClose()
          dispatch({ type: 'START_LOAD_ARTICLE' })
          // events.hide()
        }
      } else {
        notification.error({
          message: 'Required',
          description: 'Fill all required fields.'
        })
      }
    })
  }

  return (
    <>
      <Drawer
        title={`Delete Reviewer`}
        width={'40%'}
        visible={visible}
        placement='right'
        onClose={onClose}>
        <Card>
          <Form onSubmit={handleSubmit} hideRequiredMark className={'inputForm'}>
            <Row gutter={16}>
              {inputSchema.fields.map((item, key) => {
                return (
                  <Col span={item.span ? item.span : 12} key={key}>
                    <GetEachFormFields getFieldDecorator={getFieldDecorator}
                                       item={item} formItemLayout={formItemLayout} />
                  </Col>
                )
              })}
              <Col span={24}>
                <Form.Item style={{ marginTop: 40 }}>
                  <Button type='primary' htmlType='submit'>
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </Drawer>
    </>
  )
}


const WrappedForm = Form.create()(DeleteReviewerDrawer)

export default WrappedForm
