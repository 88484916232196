import { apiUrl } from '../../../settings'


export const blogListUrl = () => {
  return apiUrl + '/getBlogList'
}

export const deleteBlogUrl = () => {
  return apiUrl + '/deleteBlog'
}

export const updateBlogUrl = () => {
  return apiUrl + '/updateBlog'
}

export const singleBlogUrl = (blogId) => {
  return `${apiUrl}/getSingleBlog/${blogId}`;
}