import {
  Button,
  Card,
  Col,
  column,
  Drawer,
  Form,
  Input,
  message,
  notification,
  Popconfirm,
  Row,
  Select,
  Table,
  Upload
} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import axios from 'axios'
import _ from 'lodash'
import React from 'react'


const { Option } = Select

@Form.create()
class EditAffiliate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      affiliate: ''
    }
  }

  submitForm = () => {
    if (!this.state.affiliate) {
      notification.warning({
        message: 'Enter affiliate '
      })
      return
    }
    this.props.editVal.affiliate = this.state.affiliate
    this.props.updateAffiliate(this.props.editVal)
    this.setState({
      affiliate: ''
    })
    this.props.onClose()
  }
  componentDidMount() {
    console.log(this.props)
    this.setState({ affiliate: this.props.editVal.affiliate })
  }
  // {  this.setState({ affiliate: this.props.editVal.affiliate })}
  render() {
    console.log(this.props)
    let { visible, onClose, value, onChange } = this.props

    return (
      <>
        <Drawer
          title={'Edit Affilation'}
          width={'60%'}
          visible={visible}
          maskClosable={true}
          placement="right"
          onClose={onClose}>
          <Card>
            <Form hideRequiredMark className={'inputForm'}>
              <Col span={24} className="flexColumn">
                <Form.Item label="Affiliate" name="affiliate" required>
                  <TextArea
                    value={this.state.affiliate}
                    placeholder="Enter affiliate..."
                    onChange={e => {
                      this.setState({ affiliate: e.target.value })
                    }}
                  />
                </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item>
                  <Button
                    type="primary"
                    onClick={() => {
                      this.submitForm()
                    }}>
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Form>
          </Card>
        </Drawer>
      </>
    )
  }
}

export default EditAffiliate
