import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Icon,
  Input,
  Modal,
  notification,
  Row,
  Select,
  Table,
  Tooltip
} from 'antd'
import _ from 'lodash'
import moment from 'moment'
import React, { PureComponent } from 'react'

import { connect } from 'react-redux'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import { getPushPathWrapper, getUrlParams } from '../../../routes'

import { Editor } from 'react-draft-wysiwyg'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
  ArticleTypeList,
  chooseEmailModalFxn,
  Countries,
  CustomCkEditor,
  DesignationList,
  InputBox,
  mentionsFeeds,
  NotificationBox,
  ProcessList
} from '../../../components/_utils/appUtils'
import { CountriesList, JournalList } from '../../journals/actions'
import { singleOnlineSubmission } from '../../onlineSubmission/actions'
import { addArticle, LastArticleNo } from '../actions'
import AddAuthor from '../drawers/addAuthor'

@Form.create()
class AddArticle extends PureComponent {
  state = {
    edit: false,
    journalListArr: [],
    volumeList: [],
    issueList: [],
    visibleAddAuthor: false,
    authors: [],
    reviewerArticleAttachment: {},
    reviewerOtherAttachment: {},
    fileKey: moment(),
    onlineSubmissionId: '',
    lastArticleNo: '',
    abstract: ''
  }

  constructor(props) {
    super(props)
  }

  loadLastArticleNo = async () => {
    let {
      form: { getFieldValue },
      dispatch
    } = this.props
    let obj = {
      journalId: getFieldValue('journalId') ? getFieldValue('journalId') : null,
      country: getFieldValue('country') ? getFieldValue('country') : null
    }
    let resp = await dispatch(LastArticleNo(obj))
    this.setState({ lastArticleNo: resp })
  }

  handleSubmit = e => {
    const { dispatch, form } = this.props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        let {
          authors,
          onlineSubmissionId,
          reviewerArticleAttachment,
          abstract
        } = this.state
        if (
          !reviewerArticleAttachment ||
          (reviewerArticleAttachment && !reviewerArticleAttachment.name)
        ) {
          notification.success({
            message: 'Choose Reviewer Article Attachment'
          })
          return
        }

        chooseEmailModalFxn({
          title: 'Send Acknowledgement',
          callback: async email => {
            let fd = new FormData()
            valData.authors = authors
            valData.onlineSubmissionId = onlineSubmissionId
            valData.fromEmail = email
            valData.abstract = abstract
            valData.template =
              valData.processingCharges > 0
                ? 'acknowledgementWithCharges'
                : 'acknowledgementWithoutCharges'
            fd.append('obj', JSON.stringify(valData))
            fd.append(
              'reviewerArticleAttachment',
              this.state.reviewerArticleAttachment
            )
            fd.append(
              'reviewerOtherAttachment',
              this.state.reviewerOtherAttachment
            )
            let { success, message, data } = await dispatch(addArticle(fd))
            if (success) {
              NotificationBox({
                title: 'Thank you for submitting the manuscript.',
                // message: 'All acknowledgement email has been sent to your email address, please quote the manuscript number for all the future correspondences, You can track progress of your manuscript through author portal.',
                message: '',
                callback: () => {
                  form.resetFields()
                  if (this.state.onlineSubmissionId) {
                    dispatch(getPushPathWrapper('article.add'))
                  }
                  this.setState(
                    {
                      authors: [],
                      fileKey: moment(),
                      lastArticleNo: '',
                      onlineSubmissionId: '',
                      abstract: ''
                    },
                    () => {
                      form.setFieldsValue({ submittedDate: moment() })
                    }
                  )
                }
              })
            }
          }
        })
      }
    })
  }

  setFormValuesFxn = async id => {
    const { dispatch, form } = this.props
    let { error, data } = await dispatch(singleOnlineSubmission(id))
    if (error) {
      notification.error({
        message: 'Error Loading Data'
      })
    } else {
      if (data.status !== 'Proceed') {
        if (data.journalId && data.journalId._id) {
          data.journalId = data.journalId._id
        }
        form.setFieldsValue({
          ...data,
          totalAuthors: data.noOfAuthor
        })
        this.setState({ authors: data.authors, onlineSubmissionId: data._id, abstract: data.abstract })
        this.loadLastArticleNo()
      } else {
        Modal.error({
          content: (
            <div>
              <h3>Online Submission already Proceed</h3>
            </div>
          ),
          onOk: () => {
            dispatch(getPushPathWrapper('article.add'))
          },
          onCancel: () => {
            dispatch(getPushPathWrapper('article.add'))
          }
        })
      }
    }
  }

  componentDidMount() {
    this.loadJournalList()
    let data = getUrlParams('article.onlineSubmission', this.props.pathname)
    if (!!data) {
      let { id } = data
      this.setFormValuesFxn(id)
    }
  }

  loadJournalList = async () => {
    const { dispatch, form } = this.props
    let { data } = await JournalList()
    this.setState({ journalListArr: data })
    form.setFieldsValue({ submittedDate: moment() })
  }

  addAuthorSubmit = async data => {
    const {
      form: { getFieldValue }
    } = this.props
    let cloneAuthors = _.clone(this.state.authors)
    let totalAuthors = getFieldValue('totalAuthors')
    if (parseInt(totalAuthors) > cloneAuthors.length) {
      cloneAuthors.push(data)
      this.setState({ authors: cloneAuthors, visibleAddAuthor: false })
    } else {
      notification.success({
        message: `You can add only ${totalAuthors} Authors!!`
      })
      this.setState({ visibleAddAuthor: false })
    }
  }
  removeAuthorSubmit = async index => {
    let cloneAuthors = _.clone(this.state.authors)
    cloneAuthors.splice(index, 1)
    this.setState({ authors: cloneAuthors, visibleAddAuthor: false })
  }

  addMoreAuthor = () => {
    const {
      form: { getFieldValue }
    } = this.props
    let totalAuthors = getFieldValue('totalAuthors')
    if (totalAuthors) {
      this.setState({ visibleAddAuthor: true })
    } else {
      notification.success({
        message: `Please enter Total Number Of Authors.`
      })
    }
  }

  chooseDocument = e => {
    let { name, files } = e.target
    if (files && files.length) {
      this.setState({ [name]: files[0] })
    }
  }

  render() {
    const {
      form: { getFieldDecorator, getFieldValue, setFieldsValue },
      loading
    } = this.props
    const { edit, journalListArr, lastArticleNo, visibleAddAuthor } = this.state

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 }
      }
    }

    const inputSchma = {
      fields: [
        {
          label: 'Date of Submission',
          key: 'submittedDate',
          required: true,
          placeholder: 'Date of Submission',
          type: 'date'
        },
        {
          label: 'Country',
          key: 'country',
          required: true,
          showSearch: true,
          options: Countries,
          keyAccessor: x => x.name,
          valueAccessor: x => x.name,
          placeholder: 'Country',
          type: 'select',
          onChange: val => {
            setFieldsValue({ country: val })
            console.log('contry Click')
            this.loadLastArticleNo()
          }
        },
        {
          label: 'Journal',
          key: 'journalId',
          required: true,
          showSearch: true,
          options: journalListArr,
          keyAccessor: x => x._id,
          valueAccessor: x => x.name,
          placeholder: 'Journal',
          type: 'select',
          onChange: val => {
            setFieldsValue({ journalId: val })
            console.log('journal Click')
            this.loadLastArticleNo()
          }
        },
        {
          label: 'Process',
          key: 'processType',
          required: true,
          options: ProcessList,
          placeholder: 'Process Type',
          type: 'select',
          onChange: val => {
            setFieldsValue({ processType: val })
          }
        },
        {
          label: 'Article No',
          key: 'articleNo',
          required: true,
          placeholder: 'Article No',
          extraBox: (
            <React.Fragment>
              {lastArticleNo ? (
                <label className={'label label-success'}>{lastArticleNo}</label>
              ) : null}
            </React.Fragment>
          ),
          onChange: val => {
            setFieldsValue({ articleNo: val.target.value.toUpperCase() })
          }
        },

        {
          label: 'Article Type',
          key: 'articleType',
          required: true,
          options: ArticleTypeList,
          placeholder: 'Article Type',
          type: 'select',
          onChange: val => {
            setFieldsValue({ articleType: val })
          }
        },
        {
          label: 'Article Title',
          key: 'manuScriptTitle',
          required: true,
          placeholder: 'Article Title',
          span: 24,
          type: 'editor'
        },
        {
          label: 'Manuscript Area of Research',
          key: 'areaOfResearch',
          required: true,
          placeholder: 'Manuscript Area of Research',
          type: 'textArea',
          rows: 2,
          span: 24
        },
        {
          label: 'Processing Charges',
          key: 'processingCharges',
          required: true,
          placeholder: 'Processing Charges'
          // type: 'number'
        },
        {
          label: 'Publication Charges',
          key: 'publicationCharges',
          required: true,
          placeholder: 'Publication Charges'
          //type: 'number'
        },
        {
          label: 'Abstract',
          key: 'abstract',
          required: true,
          placeholder: 'Abstract',
          rows: 2,
          span: 24,
          customField: (
            <Col>
              <CustomCkEditor
                title={'Abstract'}
                feeds={mentionsFeeds}
                html={this.state.abstract}
                onChange={data => {
                  this.setState({ abstract: data })
                }}
              />
            </Col>
          )
        },
        {
          label: 'Reviewer Article Attachment',
          customField: (
            <InputBox title={`Reviewer Article Attachment*`}>
              <Input
                type={'file'}
                name={'reviewerArticleAttachment'}
                id={'reviewerArticleAttachment'}
                className={'form-control'}
                key={this.state.fileKey}
                onChange={e => {
                  this.chooseDocument(e)
                }}
              />
            </InputBox>
          )
        },
        {
          label: 'Reviewer Other Attachment',
          customField: (
            <InputBox title={`Reviewer Other Attachment`}>
              <Input
                type={'file'}
                name={'reviewerOtherAttachment'}
                id={'reviewerOtherAttachment'}
                className={'form-control'}
                key={this.state.fileKey}
                onChange={e => {
                  this.chooseDocument(e)
                }}
              />
            </InputBox>
          )
        },
        {
          label: 'Special Note',
          key: 'specialNote',
          //required: true,
          placeholder: 'Special Note',
          type: 'textArea',
          rows: 2,
          span: 24
        }
      ],
      authorFields: [
        {
          label: 'Total Number Of Authors',
          key: 'totalAuthors',
          required: true,
          placeholder: 'Total Number Of Authors'
        },
        {
          label: 'Corresponding Author',
          key: 'correspondingAuthor',
          required: true,
          placeholder: 'Corresponding Author'
        },
        {
          label: 'Corresponding Author Email',
          key: 'correspondingAuthorsEmail',
          required: true,
          placeholder: 'Authors Email'
        },
        {
          label: 'Corresponding Author Secondary Email',
          key: 'correspondingAuthorEmail2',
          placeholder: 'Secondary Email'
        },
        {
          label: 'Corresponding Author Contact',
          key: 'correspondingAuthorContact',
          placeholder: 'Corresponding Author Contact'
        },
        {
          label: 'Country',
          key: 'correspondingAuthorCountry',
          required: true,
          showSearch: true,
          placeholder: 'Country',
          options: Countries,
          keyAccessor: x => x.name,
          valueAccessor: x => x.name,
          type: 'select',
          onChange: val => {
            setFieldsValue({ correspondingAuthorCountry: val })
          }
        },
        {
          label: 'Author Designation',
          key: 'correspondingAuthorDesignation',
          required: true,
          placeholder: 'Author Designation',
          options: DesignationList,
          type: 'select',
          onChange: val => {
            setFieldsValue({ correspondingAuthorDesignation: val })
          }
        },
        {
          label: 'Institute Affiliated With',
          key: 'correspondingAuthorAffiliation',
          required: true,
          placeholder: 'Institute Affiliated With',
          type: 'textArea',
          rows: 3
        }
      ]
    }

    let columns = [
      {
        key: 'name',
        title: 'Name',
        dataIndex: 'name',
        render: (item, record) => {
          return (
            <React.Fragment>
              {record.title} {item}
              {record.contactNo ? <div>Mob : {record.contactNo}</div> : null}
              <div>{record.country}</div>
            </React.Fragment>
          )
        }
      },
      {
        title: 'Affiliated',
        key: 'instituteAffiliated',
        dataIndex: 'instituteAffiliated'
      },
      {
        title: 'Designation',
        key: 'designation',
        dataIndex: 'designation'
      },
      {
        title: 'Email',
        key: 'email',
        dataIndex: 'email',
        render: (item, record) => {
          return (
            <React.Fragment>
              {item}
              {record.secondaryEmail ? (
                <div>Sec. {record.secondaryEmail}</div>
              ) : null}
            </React.Fragment>
          )
        }
      },
      {
        title: 'Action',
        key: 'action',
        dataIndex: 'action',
        render: (item, record, index) => {
          return (
            <React.Fragment>
              <Tooltip title="Remove Author">
                <Button
                  size={'small'}
                  icon={'cross'}
                  onClick={() => this.removeAuthorSubmit(index)}
                />
              </Tooltip>
            </React.Fragment>
          )
        }
      }
    ]

    return (
      <PageHeaderWrapper title={'Add Article'}>
        <Card bordered={true}>
          <Form
            onSubmit={this.handleSubmit}
            hideRequiredMark
            className={'inputForm'}>
            <Row gutter={16}>
              <Col span={12}>
                <Card title="Article Details" bordered={true}>
                  <Row gutter={16}>
                    {inputSchma.fields.map((item, key) => {
                      return (
                        <Col
                          span={item.span ? item.span : 12}
                          md={item.span ? item.span : 12}
                          sm={12}
                          xs={24}
                          key={key}>
                          {item.customField ? (
                            item.customField
                          ) : (
                            <GetEachFormFields
                              item={item}
                              getFieldDecorator={getFieldDecorator}
                              formItemLayout={formItemLayout}
                            />
                          )}
                        </Col>
                      )
                    })}
                  </Row>
                </Card>
              </Col>
              <Col span={12}>
                <Card title="Author Details" bordered={true}>
                  <Row gutter={16}>
                    {inputSchma.authorFields.map((item, key) => {
                      return (
                        <Col
                          span={item.span ? item.span : 12}
                          md={item.span ? item.span : 12}
                          sm={12}
                          xs={24}
                          key={key}>
                          <GetEachFormFields
                            item={item}
                            getFieldDecorator={getFieldDecorator}
                            formItemLayout={formItemLayout}
                          />
                        </Col>
                      )
                    })}
                    <Col span={24}>
                      <Button onClick={() => this.addMoreAuthor()}>
                        <Icon type={'plus'} /> Add Author
                      </Button>
                    </Col>
                    <Col span={24} className={'mt10'}>
                      <Table
                        bordered={true}
                        columns={columns}
                        dataSource={this.state.authors}
                        size={'small'}
                        pagination={false}
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>

            <Form.Item style={{ marginTop: 32 }}>
              <Button type="primary" htmlType="submit">
                SUBMIT
              </Button>
            </Form.Item>
          </Form>

          {visibleAddAuthor ? (
            <AddAuthor
              onClose={() => this.setState({ visibleAddAuthor: false })}
              visible={visibleAddAuthor}
              addAuthorSubmit={this.addAuthorSubmit}
            />
          ) : null}
        </Card>
      </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading,
  categories: global.categories,
  search: router.location.search,
  pathname: router.location.pathname
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddArticle)
