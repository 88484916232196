import {
    Button,
    Card,
    Col,
    column,
    Drawer,
    Form,
    notification,
    Row,
    Select,
} from 'antd'
import React from 'react'
import {connect} from 'react-redux'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import {formItemLayout} from '../../../components/_utils/appUtils'
import {updateAdminUser} from '../actions'

const {Option} = Select

@Form.create()
class EditAuthor extends React.Component {
    inputSchema = {
        fields: [
            {
                label: 'Name',
                key: 'name',
                placeholder: 'Enter Your Name',
                required: true
            },

            {
                label: 'Email',
                key: 'local.email',
                placeholder: 'Enter Your Email',
                type: 'email',
                required: true
            },
            {
                label: 'Mobile No',
                key: 'mobileNo',
                placeholder: 'Enter Your Mobile No',
                required: true
            },
            {
                label: 'Address',
                key: 'address',
                placeholder: 'Enter Your Address',
                required: true
            },
            {
                label: 'Designation',
                key: 'designation',
                placeholder: 'Enter Your Designation',
                required: true
            }
        ]
    }

    constructor(props) {
        super(props)

        this.state = {
            name: ''
        }
    }

    handleSubmit = e => {
        const {dispatch, form} = this.props
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                valData.id = this.props.editVal._id
                let resp = await dispatch(
                    updateAdminUser(valData, this.props.editVal._id)
                )

                if (resp && resp.success) {
                    this.props.editSuccessfull()
                    dispatch({type: 'START_LOAD_ARTICLE'})
                    notification.success({
                        message: resp.message
                    })
                }
            } else {
                notification.error({
                    message: 'Please enter all required fields.'
                })
            }
        })
    }

    componentDidMount() {
        setTimeout(() => {
            this.props.form.setFieldsValue(this.props.editVal)
        }, 500)
    }

    render() {
        const {
            form: {getFieldDecorator, getFieldValue, setFieldsValue},
            visible,
            onClose
        } = this.props

        return (
            <>
                <Drawer
                    title={`Edit Author`}
                    width={'60%'}
                    visible={visible}
                    placement="right"
                    onClose={onClose}>
                    <Card>
                        <Form
                            onSubmit={this.handleSubmit}
                            hideRequiredMark
                            className={'inputForm'}>
                            <Row gutter={16}>
                                {this.inputSchema.fields.map((item, key) => {
                                    return (
                                        <Col span={item.span ? item.span : 12} key={key}>
                                            <GetEachFormFields
                                                getFieldDecorator={getFieldDecorator}
                                                item={item}
                                                formItemLayout={formItemLayout}
                                            />
                                        </Col>
                                    )
                                })}
                                <Col span={24}>
                                    <Form.Item style={{marginTop: 40}}>
                                        <Button type="primary" htmlType="submit">
                                            Submit
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Card>
                </Drawer>
            </>
        )
    }
}

const mapStateToProps = ({global}) => ({
    categories: global.categories
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditAuthor)
