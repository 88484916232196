import { Button, Card, Form, notification, Tooltip } from 'antd'
import _ from 'lodash'
import moment from 'moment'
import React, { Component } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { connect } from 'react-redux'
import { FormUtils, TableComp } from 'sz-react-utils-lite'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
  ChooseEmailModal,
  chooseEmailModalFxn,
  DisplayDate,
  formItemLayout
} from '../../../components/_utils/appUtils'
import ChooseEmail from '../../../components/_utils/chooseEmailModal'
import Request from '../../../request'
import { SendTemplateEmail } from '../../email/actions'
import { getAllArticle, ResendComments } from '../actions'
import ArticleActivityList from '../articleView/ArticleActivityList'
import { ArticleFilters } from '../articleView/articlePages'
import ApprovalArticleDrawer from '../drawers/ApprovalArticleDrawer'

const publishArr = [
  { value: true, display: 'Publish', _id: 'publish' },
  { value: false, display: 'Un-Publish', _id: 'unpublish' }
]

@Form.create()
class RevisedArticle extends Component {
  state = {
    edit: false,
    journalList: [],
    volumeList: [],
    issueList: [],
    isPublished: null,
    slugJournal: null,
    slugVolume: null,
    slugIssue: null,
    articleId: null,
    filters: {},
    visibleRevisedArticle: false,
    article: {},
    total: '',
    visibleActivityList: false
  }

  constructor(props) {
    super(props)
    this.table = React.createRef()
  }

  apiRequest = params => {
    return new Promise(async resolve => {
      let {
        dispatch,
        form: { getFieldValue }
      } = this.props
      let obj = {
        ...params,
        ...this.state.filters,
        articleStatus: 'Revised Article'
      }
      let regExpFilters = []

      let data = await dispatch(
        getAllArticle({ ...obj, regExFilters: regExpFilters })
      )
      this.setState({ total: data.total })
      resolve(data)
    })
  }

  reload = () => {
    this.table.current.reload()
  }
  reloadTable = data => {
    if (data.fromDate && data.toDate) {
      data['submittedDate'] = {
        $gte: data.fromDate,
        $lte: data.toDate
      }
      delete data['fromDate']
      delete data['toDate']
    }
    this.setState({ filters: data }, () => {
      this.reload()
    })
  }

  deleteArticle = async data => {
    let resp = await Request.deleteArticle(data)
    this.reload()
  }

  getJournalList = async () => {
    let { data } = await Request.journalName()
    let journalList = []
    _.each(data, item => {
      journalList.push({
        _id: item.slugJournal,
        value: item.slugJournal,
        display: item.name
      })
    })
    this.setState({
      journalList,
      volumeList: [],
      issueList: []
    })
  }

  getVolumeList = async data => {
    let obj = {
      slugJournal: data
    }
    let resp = await Request.journalVolume(obj)
    let volumeList = []
    _.each(resp, item => {
      volumeList.push({
        _id: item.slugVolume,
        value: item.slugVolume,
        display: item.name
      })
    })
    this.setState({
      volumeList,
      issueList: []
    })
  }

  getIssueList = async data => {
    let obj = {
      slugJournal: this.props.form.getFieldValue('slugJournal'),
      slugVolume: data
    }
    let resp = await Request.journalIssue(obj)
    let issueList = []
    _.each(resp, item => {
      issueList.push({
        _id: item.slugIssue,
        value: item.slugIssue,
        display: item.name
      })
    })
    this.setState({
      issueList
    })
  }

  componentDidMount() {
    this.getJournalList()
  }

  handleNotAsPerFormat = (record, e) => {
    let { dispatch } = this.props
    chooseEmailModalFxn({
      callback: async email => {
        let obj = {
          articleId: record._id,
          fromEmail: email,
          emailType: 'notAsPerFormat',
          template: 'notAsPerFormat',
          type: 'article'
        }
        let resp = await dispatch(
          SendTemplateEmail({
            email,
            id: record._id,
            obj,
            template: obj.template
          })
        )
        if (resp && resp.success) {
          notification.success({
            message: resp.message
          })
          this.reload()
        }
      }
    })
  }

  handleToReviewer = (record, e) => {
    let { dispatch } = this.props
    chooseEmailModalFxn({
      callback: async email => {
        let obj = {
          articleId: record._id,
          fromEmail: email,
          emailType: 'revisedArticleReviewer',
          template: 'revisedToReviewer',
          type: 'article',
          reviewerId:
            record.underReviewDetails && record.underReviewDetails.reviewerId
              ? record.underReviewDetails.reviewerId
              : null
        }
        let resp = await dispatch(
          SendTemplateEmail({
            email,
            id: record._id,
            obj,
            reviewerId:
              record.underReviewDetails && record.underReviewDetails.reviewerId
                ? record.underReviewDetails.reviewerId
                : null,
            template: obj.template
          })
        )
        if (resp && resp.success) {
          notification.success({
            message: resp.message
          })
          this.reload()
        }
      }
    })
  }

  sendMailFxn = record => {
    let { dispatch } = this.props
    chooseEmailModalFxn({
      callback: async email => {
        let obj = {
          articleId: record._id,
          fromEmail: email,
          emailType: 'acknowledgement',
          template:
            record.processingCharges > 0
              ? 'acknowledgementWithCharges'
              : 'acknowledgementWithoutCharges',
          type: 'article'
        }
        let resp = await dispatch(
          SendTemplateEmail({
            email,
            id: record._id,
            obj,
            template: obj.template
          })
        )
        if (resp && resp.success) {
          notification.success({
            message: resp.message
          })
          this.reload()
        }
      }
    })
  }

  render() {
    const {
      dispatch,
      form: { getFieldDecorator, getFieldValue, setFieldsValue },
      loading
    } = this.props
    const { journalList, volumeList, issueList } = this.state

    const formProps = {
      getFieldDecorator,
      getFieldValue,
      loading
    }

    const columns = [
      {
        key: 'actions',
        title: 'Article Id',
        width: '85px',
        render: (val, record) => (
          <Tooltip title="Edit Details">
            <a href={`/articleView/${record._id}`}>{record.articleNo}</a>
            {/*<a*/}
            {/*  onClick={() => {*/}
            {/*    dispatch(getPushPathWrapper('articleView', { id: record._id }))*/}
            {/*  }}>*/}
            {/*  {record.articleNo}*/}
            {/*</a>*/}
          </Tooltip>
        )
      },
      {
        title: 'Submitted Date',
        key: 'submittedDate',
        width: 100,
        dataIndex: 'submittedDate',
        render: item => {
          return item ? DisplayDate(item) : null
        }
      },
      {
        title: 'ManuScript Title',
        key: 'manuScriptTitle',
        dataIndex: 'manuScriptTitle',
        render: item => {
          return item ? ReactHtmlParser(item) : null
        }
      },
      {
        title: 'Comments Received',
        key: 'underReviewDetails.receiveDate',
        dataIndex: 'underReviewDetails.receiveDate',
        render: item => {
          return item ? DisplayDate(item) : null
        }
      },
      {
        title: 'Comments Send',
        key: 'underReviewDetails.sendDate',
        width: 100,
        dataIndex: 'underReviewDetails.sendDate',
        render: item => {
          return item ? DisplayDate(item) : null
        }
      },
      {
        title: 'Revised Article ',
        key: 'revisedArticleDate',
        width: 100,
        dataIndex: 'revisedArticleDate',
        render: item => {
          return item ? DisplayDate(item) : null
        }
      },
      {
        title: 'Action',
        key: 'Action',
        render: (item, record) => {
          return (
            <>
              <Button
                onClick={e => this.handleNotAsPerFormat(record, e)}
                className={'roundSmallBtn'}>
                Not as per format
              </Button>
              <Button
                onClick={e => this.handleToReviewer(record, e)}
                className={'roundSmallBtn'}>
                To Reviewer
              </Button>
              <br />
              <Button
                className={'roundSmallBtn'}
                onClick={() =>
                  this.setState({ visibleDrawer: true, article: record })
                }>
                Approval
              </Button>
              <Button
                className={'roundSmallBtn'}
                onClick={() =>
                  this.setState({ visibleActivityList: true, article: record })
                }>
                Article Activities
              </Button>
            </>
          )
        }
      }
    ]

    const fil = {
      labelCol: {
        xs: {
          span: 24
        },
        sm: {
          span: 24
        },
        md: {
          span: 24
        }
      },
      wrapperCol: {
        xs: {
          span: 24
        },
        sm: {
          span: 24
        },
        md: {
          span: 24
        }
      }
    }

    return (
      <PageHeaderWrapper title={`Revised Manuscript (${this.state.total})`}>
        <Card bordered={true}>
          <ArticleFilters
            callback={data => {
              this.reloadTable(data)
            }}
          />
          <TableComp
            ref={this.table}
            columns={columns}
            apiRequest={this.apiRequest}
          />
        </Card>
        {this.state.visibleDrawer ? (
          <ApprovalArticleDrawer
            visible={this.state.visibleDrawer}
            articleId={this.state.article._id}
            article={this.state.article}
            Id={this.state.article._id}
            callback={this.reload}
            edit={false}
            onClose={() => this.setState({ visibleDrawer: false })}
          />
        ) : null}
        {this.state.visibleActivityList ? (
          <ArticleActivityList
            articleId={this.state.article._id}
            articleNo={this.state.article.articleNo}
            visible={this.state.visibleActivityList}
            onClose={() => this.setState({ visibleActivityList: false })}
          />
        ) : null}
      </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global }) => ({
  categories: global.categories
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RevisedArticle)
