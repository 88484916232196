import React, { useEffect, useState, useRef } from 'react'
import { Button, Card, Collapse, notification, Popconfirm, Table, Tooltip } from 'antd'
import { DisplayDate, GetDownloadLink } from '../../../components/_utils/appUtils'
import { TableComp } from 'sz-react-utils-lite'
import _ from 'lodash'
import ReceiveDateDrawer from '../reviewer/editSelectReceiveDate'
import { RowTable } from './RowTable'
import AcceptanceDrawer from '../drawers/AcceptanceDrawer'
import OnlinePublishDrawer from '../drawers/OnlinePublishDrawer'
import { generateAndDepositXml, viewGeneratedXml } from '../actions'
import { API_URL } from '../../../request'
import { useDispatch } from 'react-redux'

const { Panel } = Collapse
const OnlineArticleDetails = (props) => {
  let { article } = props
  let {
    doiProcessDate,
    finalArticleAttachemntWithDoi,
    onlinePublishedDate
  } = article
  let [receiveDateDrawerVisible, setReceiveDateDrawerVisible] = useState(false)
  let [reviewerId, setReviewerId] = useState('')
  let headerTitle = `Online Article ${onlinePublishedDate ? `(${DisplayDate(onlinePublishedDate)})` : ''}`
  let [visibleCommentToAuthorDrawer, setVisibleCommentToAuthorDrawer] = useState(false)
  const table = useRef()
  let dispatch = useDispatch()
  let events = {
    reloadTable: () => {
      table.current.reload()
    },
    showAndHideReceiveDate: (value, id = '') => {
      setReceiveDateDrawerVisible(value)
      setReviewerId(id)
    },
    show: () => {
      setVisibleCommentToAuthorDrawer(true)
    },
    hide: () => {
      setVisibleCommentToAuthorDrawer(false)
    }
  }

  let BtnAction = (event) => {
    events.show()
    event.stopPropagation()
  }
  let doiEvents = {
    viewDoiXml: async (event, article) => {
      event.stopPropagation()
      let { success, message, filePath } = await dispatch(viewGeneratedXml({ articleId: article._id }))
      if (success) {
        /*notification.success({
          message: message
        })*/
        window.open(`${API_URL}${filePath}`, '_blank')
      }
    },
    depositDoiXml: async (event, article) => {
      event.stopPropagation()
      let { success, message } = await dispatch(generateAndDepositXml({ articleId: article._id }))
      if (success) {
        notification.success({
          message: message
        })
        dispatch({ type: 'START_LOAD_ARTICLE' })
      }
    }
  }


  let getExtraButton = () => {
    return (
      <React.Fragment>
        <Button type={'primary'} size={'small'}
                onClick={(event) => doiEvents.viewDoiXml(event, article)}>
          View DOI XML
        </Button> {' '}
        <Popconfirm placement='topLeft' title={`Are you sure you want to Generate XML?`}
                    onConfirm={(event) => doiEvents.depositDoiXml(event, article)} okText='Yes' cancelText='No'>
          <Button type={'primary'} size={'small'}>
            Direct Deposit DOI XML
          </Button>
        </Popconfirm>
        {' '}
        {doiProcessDate && !onlinePublishedDate ?
          <Button type={'primary'} size={'small'} onClick={BtnAction}>Add</Button> : null}
        {onlinePublishedDate ?
          <Button type={'primary'} size={'small'} onClick={BtnAction}>Edit</Button> : null}
      </React.Fragment>
    )
  }

  return (
    <div>
      <Collapse expandIconPosition={'right'} defaultActiveKey={['1']}>
        <Panel header={headerTitle} key='1' extra={getExtraButton()}>
          <div className={'tableBox striped'}>
            <RowTable title={'Date'} value={
              onlinePublishedDate && DisplayDate(onlinePublishedDate)
            } />
            <RowTable title={'Insert DOI'} value={
              article.doiNumber ? article.doiNumber : null
            } />
            <RowTable title={'Article with DOI'} value={
              finalArticleAttachemntWithDoi && finalArticleAttachemntWithDoi.path ?
                <a download className='downloadLink' target={'_blank'}
                   href={finalArticleAttachemntWithDoi.path}>Download</a> : 'No Attachment'
            } />
            <RowTable title={'DOI Deposit Date'} value={
              article.doiDepositDate ? article.doiDepositDate : null
            } />
          </div>
        </Panel>
      </Collapse>
      {visibleCommentToAuthorDrawer ? <OnlinePublishDrawer
        visible={visibleCommentToAuthorDrawer}
        article={article}
        edit={onlinePublishedDate ? true : false}
        onClose={() => events.hide()}
      /> : null}
    </div>
  )
}

export default OnlineArticleDetails
