import { apiUrl } from '../../../settings';

export const addKeywordUrl = () => {
  return apiUrl + '/addKeyword'
}
export const keywordListUrl = () => {
  return apiUrl + '/keywordList'
}
export const removeKeywordUrl = (id) =>{
  return apiUrl + `/removeKeyword/${id}`
}