import React, {useRef, useState} from 'react'
import {TableComp} from 'sz-react-utils-lite'
import {Drawer, Card, Button} from 'antd'
import {DisplayDate, DefaultTablePagination} from '../../../components/_utils/appUtils'
import {useDispatch} from 'react-redux'
import {getMemberHistory} from '../actions'
import ChangeHistory from '../drawers/changeHistory'

const MemberHistory = (props) => {
    let {visible, onClose, articleId, ids, data} = props
    let [changeHistoryVisible, setChangeHistoryVisible] = useState(false)
    let [selectedReviewer, setSelectedReviewer] = useState({})
    const table = useRef()
    const dispatch = useDispatch()
    const columns = [
        {
            title: 'Article No',
            key: 'articleNo',
            dataIndex: 'articleNo'
        },
        {
            title: 'Send Date',
            key: 'selectedReviewer.sendDate',
            dataIndex: 'selectedReviewer.sendDate',
            render: (item) => {
                return item ? DisplayDate(item) : null
            }
        },

        {
            title: 'Remarks',
            key: 'selectedReviewer.remarks',
            dataIndex: 'selectedReviewer.remarks',
            render: (item, record) => {
                return (
                    <div>
                        {item}
                        {record.selectedReviewer && record.selectedReviewer.comments ? <div>
                            Comments :- {record.selectedReviewer.comments}
                        </div> : null}
                    </div>
                )
            }
        },
        {
            title: 'Reminder Date',
            key: 'selectedReviewer.reminderDate',
            dataIndex: 'selectedReviewer.reminderDate',
            render: (item) => {
                return item ? DisplayDate(item) : null
            }
        },
        {
            title: 'Received Date',
            key: 'selectedReviewer.receiveDate',
            dataIndex: 'selectedReviewer.receiveDate',
            render: (item) => {
                return item ? DisplayDate(item) : null
            }
        },

        {
            title: 'Action',
            key: 'actions',
            width: 280,
            render: (val, record) => (
                <React.Fragment>
                    {(record.selectedReviewer.remarks || record.selectedReviewer.comments || record.selectedReviewer.receiveDate) ?
                        <Button className={'roundSmallBtn'} onClick={() => events.showDrawer(record.selectedReviewer)}>Change
                            History</Button> : null}
                </React.Fragment>)

        }


    ]
    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            let obj = {
                ...params,
                id: props.reviewerId
            }
            let regExpFilters = []
            let data = await dispatch(getMemberHistory({...obj, regExFilters: regExpFilters}))
            resolve(data)
        })
    }

    const events = {
        showDrawer: (data) => {
            setChangeHistoryVisible(true)
            setSelectedReviewer({
                articleId: articleId,
                ...data
            })
        },
        hideDrawer: () => {
            setChangeHistoryVisible(false)
        },
        submitCallback: () => {
            setSelectedReviewer({})
            setChangeHistoryVisible(false)
            table.current.reload()
        }

    }

    return (
        <Drawer
            title={`History Details | ${data.name} | ${data.reviewerId}`}
            width={'65%'}
            visible={visible}
            placement="right"
            onClose={onClose}>
            <Card>
                <TableComp ref={table} columns={columns} apiRequest={apiRequest}
                           pagination={DefaultTablePagination({}, 50)}/>
            </Card>

            {changeHistoryVisible ?
                <ChangeHistory visible={changeHistoryVisible} selectedReviewer={selectedReviewer}
                               onClose={events.hideDrawer}
                               onSubmit={events.submitCallback}/>
                : null}

        </Drawer>
    )
}

export default MemberHistory
