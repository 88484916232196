import React, { useState, useEffect } from 'react'
import { Button, Card, Col, Form, Row } from 'antd'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import {
  formItemLayout,
  MonthArr,
  YearArr,
  DateByMonth,
  DateByMonthAndYear,
  DateByYear,
  ProcessList
} from '../../../components/_utils/appUtils'
import Request from '../../../request'
import _ from 'lodash'
import moment from 'moment'

const nationalityList = [
  'Indian',
  'Foreign'
]
const ArticleFilters = (props) => {
  const { dispatch, form: { getFieldDecorator, getFieldValue, setFieldsValue }, callback } = props
  let [journalList, setJournalList] = useState([])
  useEffect(() => {
    getJournalList()
  }, [])

  const getJournalList = async () => {
    let { data } = await Request.journalName()
    let journalList = []
    _.each(data, (item) => {
      journalList.push({
        _id: item.slugJournal,
        value: item.slugJournal,
        display: item.name
      })
    })
    setJournalList(journalList)
  }

  const inputSchema = {
    fields: [
      {
        label: 'Journals',
        key: 'journal',
        type: 'select',
        options: journalList,
        allowClear: true,
        placeholder: 'Journals',
        keyAccessor: x => x.display,
        valueAccessor: x => x.display,
        onChange: (val) => {
          setFieldsValue({ journal: val })
        }
      },
      {
        label: 'Nationality',
        key: 'nationality',
        type: 'select',
        options: nationalityList,
        allowClear: true,
        placeholder: 'Nationality',
        keyAccessor: x => x,
        valueAccessor: x => x,
        onChange: (val) => {
          setFieldsValue({ nationality: val })
        }
      },
      {
        label: 'Month',
        key: 'month',
        type: 'select',
        options: MonthArr,
        allowClear: true,
        placeholder: 'Month',
        keyAccessor: x => x.value,
        valueAccessor: x => x.name,
        onChange: (val) => {
          setFieldsValue({ month: val })
        }
      },
      {
        label: 'Year',
        key: 'year',
        type: 'select',
        options: YearArr(),
        allowClear: true,
        placeholder: 'Year',
        keyAccessor: x => x,
        valueAccessor: x => x,
        onChange: (val) => {
          setFieldsValue({ year: val })
        }
      },
      {
        label: 'Process',
        key: 'processType',
        type: 'select',
        options: ProcessList,
        placeholder: 'Process',
        allowClear: true,
        keyAccessor: x => x,
        valueAccessor: x => x,
        onChange: (val) => {
          setFieldsValue({ processType: val })
        }
      }

    ]

  }

  const filterArticle = () => {
    let year = getFieldValue('year') ? getFieldValue('year') : null
    let month = getFieldValue('month') ? getFieldValue('month') : null
    let nationality = getFieldValue('nationality') ? getFieldValue('nationality') : null
    let obj = {
      journal: getFieldValue('journal') ? getFieldValue('journal') : null,
      processType: getFieldValue('processType') ? getFieldValue('processType') : null
    }
    if (nationality) {
      obj.country = nationality == 'Indian' ? 'India' : { $ne: 'India' }
    }

    if (month && !year) {
      let date = DateByMonth(month)
      obj.fromDate = moment(date).startOf('month')._d
      obj.toDate = moment(date).endOf('month')._d
    }
    if (year && !month) {
      let date = DateByYear(year)
      obj.fromDate = moment(date).startOf('year')._d
      obj.toDate = moment(date).endOf('year')._d
    }
    if (month && year) {
      let date = DateByMonthAndYear(month, year)
      obj.fromDate = moment(date).startOf('month')._d
      obj.toDate = moment(date).endOf('month')._d
    }


    /*createdAt:
      $gte: Wed May 05 2021 00:00:00 GMT+0530 (India Standard Time) {}
    $lt: Thu Jun 24 2021 23:59:59 GMT+0530 (India Standard Time) {}*/

    callback(obj)
  }

  return (
    <Form hideRequiredMark className={'inputForm'}>

      <Row gutter={16}>
        {inputSchema.fields.map((item, key) => {
          return (
            <Col span={4} key={key}>
              <GetEachFormFields getFieldDecorator={getFieldDecorator}
                                 item={item} formItemLayout={formItemLayout} />
            </Col>
          )
        })}
        <Col span={4}>
          <Form.Item style={{ marginTop: 40 }}>
            <Button type='primary' onClick={() => {
              filterArticle()
            }}>
              FILTER
            </Button>
          </Form.Item>
        </Col>
      </Row>


    </Form>
  )
}

const WrappedArticleFilters = Form.create()(ArticleFilters)
export default WrappedArticleFilters
