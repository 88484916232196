import React, { useState, useEffect } from 'react'
import { InputBox, mentionsFeeds } from './appUtils'
import { Form, Input } from 'antd'
import CKEditor from 'react-ckeditor-component'
import moment from 'moment'

const { TextArea } = Input
const config = {
  minHeight: 100,
  autoGrow_bottomSpace: 50
}

class CustomCkEditor extends React.Component {
  constructor(props) {
    super()
    this.state = {
      isLoaded: false
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ isLoaded: true })
    }, 500)
  }

  render() {
    let { title, onChange, feeds = [], html, extraBox = null, customCls = '', height = 200 } = this.props
    let { isLoaded } = this.state
    return (
      <InputBox title={title} extra={extraBox}>
        <CKEditor
          activeClass={customCls}
          // extraPlugins={['mentions']}
          scriptUrl={'/ckeditor/ckeditor.js'}
          content={html}
          config={{ ...config, height }}
          events={{
            change: ({ editor }) => {
              onChange(editor.getData())
            }
          }}
        />
      </InputBox>
    )
  }
}

export default CustomCkEditor

