import React, { useEffect, useState, useRef } from 'react'
import { Button, Card, Collapse, Popconfirm, Table, Tooltip } from 'antd'
import { DisplayDate, GetDownloadLink } from '../../../components/_utils/appUtils'
import { TableComp } from 'sz-react-utils-lite'
import _ from 'lodash'
import ReceiveDateDrawer from '../reviewer/editSelectReceiveDate'
import { ChooseEmailModal } from '../../../components/_utils/appUtils'
import { RowTable } from './RowTable'

const { Panel } = Collapse
const ReviewedArticles = (props) => {
  let { article } = props
  let { underReviewDetails, articleStatus } = article
  let [receiveDateDrawerVisible, setReceiveDateDrawerVisible] = useState(false)
  let [reviewerId, setReviewerId] = useState('')
  let headerTitle = `Reviewed Manuscript ${underReviewDetails && underReviewDetails.receiveDate ? `(${DisplayDate(underReviewDetails.receiveDate)})` : ''}`
  let [ids, setIds] = useState({})
  const table = useRef()
  let events = {
    reloadTable: () => {
      table.current.reload()
    },
    showAndHideReceiveDate: (value, id = '') => {
      setReceiveDateDrawerVisible(value)
      setReviewerId(id)
    }
  }
  let getExtraButton = () => {
    return (
      <React.Fragment>
        {underReviewDetails && underReviewDetails.receiveDate ?
          <Button type={'primary'} size={'small'} onClick={(event) => {
            events.showAndHideReceiveDate(true)
            event.stopPropagation()
          }}>Edit</Button> : null}
      </React.Fragment>
    )
  }


  return (
    <div>
      <Collapse expandIconPosition={'right'} defaultActiveKey={['1']}>
        <Panel header={headerTitle} key="1" extra={getExtraButton()}>
          <div className={'tableBox striped'}>
            <RowTable title={'Comments Attachment'} value={
              underReviewDetails.commentsAttachment && underReviewDetails.commentsAttachment.path ?
                <a download className='downloadLink' target={'_blank'}
                   href={underReviewDetails.commentsAttachment.path}>Download</a> : 'No Attachment'
            } />
            <RowTable title={'Article Attachment'} value={
              underReviewDetails.articleAttachment && underReviewDetails.articleAttachment.path ?
                <a download className='downloadLink' target={'_blank'}
                   href={underReviewDetails.articleAttachment.path}>Download</a> : 'No Attachment'
            } />
            <RowTable title={'See Article Again'} value={
              underReviewDetails ?
                <p>{underReviewDetails.articleSeeAgain ? 'true' : 'false'}</p> : null
            } />
          </div>
        </Panel>
      </Collapse>
      {receiveDateDrawerVisible ? <ReceiveDateDrawer
        visible={receiveDateDrawerVisible}
        reviewerId={reviewerId}
        article={article}
        onClose={() => events.showAndHideReceiveDate(false)}
      /> : null}
    </div>
  )
}

export default ReviewedArticles
