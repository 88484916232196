import React, { Component } from 'react'
import { List, Card, Icon, Col, Row } from 'antd'
import PageHeaderWrapper from '../../components/PageHeaderWrapper'
import { GetDashboardCount } from './actions'
import { connect } from 'react-redux'
import DashboardFilter from './dashboardFilters'
import { getPushPathWrapper } from '../../routes'
import _ from 'lodash'

let articleStatusList = [
  {
    title: 'Online Submissions',
    status: 'Online Submissions',
    icon: 'profile',
    value: 0,
    path: 'onlineSubmissions'
  },
  {
    title: 'Submitted Manuscript',
    status: 'Submitted Article',
    icon: 'laptop',
    value: 0,
    path: 'article.submittedArticles'
  },
  {
    title: 'Under Process',
    status: 'Under Process',
    icon: 'bar-chart',
    value: 0,
    path: 'article.underProcess'
  },
  {
    title: 'Under Review',
    status: 'Under Reviewer',
    icon: 'question-circle',
    value: 0,
    path: 'article.underReviewer'
  },
  {
    title: 'Reviewed Manuscript',
    icon: 'mail',
    value: 0,
    path: 'article.reviewedArticles',
    status: 'Reviewed Comments'
  },
  {
    title: 'Comments To Author',
    status: 'Comments To Author',
    icon: 'upload',
    value: 0,
    path: 'article.commentsToAuthor'

  },
  {
    title: 'Rejected Manuscript',
    status: 'Rejection',
    icon: 'delete',
    value: 0,
    path: 'article.rejection'

  },
  {
    title: 'Revised Manuscript',
    icon: 'message',
    value: 0,
    status: 'Revised Article',
    path: 'article.revisedArticle'

  },
  {
    title: 'Approved',
    status: 'Approved Article',
    icon: 'check-circle',
    value: 0,
    path: 'article.approvedArticle'

  },
  {
    title: 'Accepted',
    status: 'Accepted Article',
    icon: 'star',
    value: 0,
    path: 'article.acceptedArticles'

  },
  {
    title: 'Under Composing',
    icon: 'edit',
    value: 0,
    status: 'Composing Article',
    path: 'article.composingArticle'

  },
  {
    title: 'Galley Proof / Reprints',
    icon: 'form',
    value: 0,
    path: 'article.galleyProof',
    status: 'Galley Proof'

  },
  {
    title: 'DOI Generation',
    icon: 'snippets',
    value: 0,
    status: 'DOI',
    path: 'article.DOIArticle'
  },
  {
    title: 'Online Published',
    icon: 'menu',
    value: 0,
    path: 'article.OnlinePublishArticle',
    status: 'Online Publish'
  },
  {
    title: 'Published Article',
    icon: 'mail',
    value: 0,
    path: 'article.CompletePublication',
    status: 'Published Article'
  },
  {
    title: 'Withdrawal',
    icon: 'logout',
    value: 0,
    path: 'article.WithdrawArticles',
    status: 'Withdrawled'
  },
  {
    title: 'Deleted',
    icon: 'delete',
    value: 0,
    path: 'article.DeletedArticles',
    status: 'Deleted'
  }
]

class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      articleStatus: articleStatusList
    }
  }

  componentDidMount() {
    this.loadDashboardCount()
  }

  loadDashboardCount = async (filter = {}) => {
    let { success, data } = await this.props.dispatch(GetDashboardCount(filter))
    console.log(data)
    if (success) {
      _.each(articleStatusList, (item) => {
        if (item.status) {
          if (data[item.status]) {
            item.value = data[item.status]
          } else {
            item.value = 0
          }
        } else {
          if (data[item.title]) {
            item.value = data[item.title]
          } else {
            item.value = 0
          }
        }

      })
      this.setState({ articleStatus: articleStatusList })
    }
  }


  render() {
    let { dispatch } = this.props
    let { articleStatus } = this.state
    return (
      <PageHeaderWrapper title={'ARTICLES'}>
        <div className={'dashboardCard'}>
          <DashboardFilter callback={(data) => {
            this.loadDashboardCount(data)
          }}/>
          <Row gutter={10} className={'statusRow'}>
            {articleStatus.map((item, key) => {
              return (
                <Col key={key} lg={4} md={8} sm={12} xs={24} className={'statusColumn'}>
                  <Card className={'active'}>
                    <div>
                      <center>
                        <a onClick={() => dispatch(getPushPathWrapper(item.path))}>
                          <div className={'title'}>
                            {item.title}
                          </div>
                          <div className={'iconDiv'}>
                            <Icon type={item.icon} />
                          </div>
                          <div className={'date'}>
                            {item.value}
                          </div>
                        </a>
                      </center>
                    </div>
                  </Card>
                </Col>
              )
            })}
          </Row>
        </div>
      </PageHeaderWrapper>
    )
  }

}


const mapStateToProps = ({ global, router }) => ({
  search: router.location.search,
  pathname: router.location.pathname
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard)


