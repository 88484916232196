import { Button, Card, Form, Icon, Table, Tooltip } from 'antd'
import _ from 'lodash'
import React, { Component } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { connect } from 'react-redux'
import { FormUtils, TableComp } from 'sz-react-utils-lite'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
  DisplayDate,
  formItemLayout
} from '../../../components/_utils/appUtils'

import Request from '../../../request'
import { getAllArticle } from '../actions'
import ArticleActivityList from '../articleView/ArticleActivityList'
import { ArticleFilters } from '../articleView/articlePages'
import ViewMember from '../articleView/viewMember'
import MemberHistory from '../reviewer/memberHistory'
import { SelectReviewer } from '../reviewer/selectReviewer'
import styles from './styles.less'

@Form.create()
class AllArticle extends Component {
  state = {
    edit: false,
    journalList: [],
    filters: {},
    ids: {},
    visibleSelectReview: false,
    article: {},
    memberDrawerVisible: false,
    reviewerId: '',
    total: '',
    reviewerData: {},
    visibleActivityList: false
  }

  constructor(props) {
    super(props)
    this.table = React.createRef()
  }

  apiRequest = params => {
    return new Promise(async resolve => {
      let {
        dispatch,
        form: { getFieldValue }
      } = this.props
      let obj = {
        ...params,
        ...this.state.filters,
        articleStatus: 'Under Reviewer'
      }
      let regExpFilters = []

      let data = await dispatch(
        getAllArticle({ ...obj, regExFilters: regExpFilters })
      )
      this.setState({ total: data.total })
      resolve(data)
    })
  }

  reload = () => {
    this.table.current.reload()
  }

  deleteArticle = async data => {
    let resp = await Request.deleteArticle(data)
    this.reload()
  }

  getJournalList = async () => {
    let { data } = await Request.journalName()
    let journalList = []
    _.each(data, item => {
      journalList.push({
        _id: item.slugJournal,
        value: item.slugJournal,
        display: item.name
      })
    })
    this.setState({
      journalList,
      volumeList: [],
      issueList: []
    })
  }

  getVolumeList = async data => {
    let obj = {
      slugJournal: data
    }
    let resp = await Request.journalVolume(obj)
    let volumeList = []
    _.each(resp, item => {
      volumeList.push({
        _id: item.slugVolume,
        value: item.slugVolume,
        display: item.name
      })
    })
    this.setState({
      volumeList,
      issueList: []
    })
  }

  getIssueList = async data => {
    let obj = {
      slugJournal: this.props.form.getFieldValue('slugJournal'),
      slugVolume: data
    }
    let resp = await Request.journalIssue(obj)
    let issueList = []
    _.each(resp, item => {
      issueList.push({
        _id: item.slugIssue,
        value: item.slugIssue,
        display: item.name
      })
    })
    this.setState({
      issueList
    })
  }
  events = {
    showMemberHistory: record => {
      this.setState({
        memberDrawerVisible: true,
        reviewerId: record.reviewerId._id,
        reviewerData: record.reviewerId
      })
    },
    hideMemberHistory: () => {
      this.setState({ memberDrawerVisible: false, reviewerId: '' })
    },
    showDrawer: article => {
      let ids = {}
      _.each(article.selectedReviewer, value => {
        if (value.reviewerId && value.reviewerId._id) {
          ids[value.reviewerId._id] = true
        }
      })
      this.setState({
        ids,
        visibleSelectReview: true,
        article
      })
    },
    hideDrawer: () => {
      this.setState(
        {
          ids: {},
          visibleSelectReview: false,
          article: {}
        },
        () => {
          this.reload()
        }
      )
    }
  }

  reloadTable = data => {
    if (data.fromDate && data.toDate) {
      data['selectedReviewer.0.sendDate'] = {
        $gte: data.fromDate,
        $lte: data.toDate
      }
      delete data['fromDate']
      delete data['toDate']
    }
    this.setState({ filters: data }, () => {
      this.reload()
    })
  }

  componentDidMount() {}

  render() {
    const {
      dispatch,
      form: { getFieldDecorator, getFieldValue, setFieldsValue },
      loading
    } = this.props
    const { visibleSelectReview, ids, article } = this.state

    const formProps = {
      getFieldDecorator,
      getFieldValue,
      loading
    }

    const innerTableColumns = [
      {
        title: 'Actions',
        key: 'Actions',
        render: (item, record) => {
          return (
            <React.Fragment>
              <Tooltip title="History">
                <Button
                  className={'roundSmallBtn'}
                  onClick={() => this.events.showMemberHistory(record)}>
                  History
                </Button>
              </Tooltip>
              <br />
              <Tooltip title="View Member">
                <ViewMember revData={record} revId={item.reviewerId._id}>
                  <Button className={'roundSmallBtn'}>View Member</Button>
                </ViewMember>
              </Tooltip>
            </React.Fragment>
          )
        }
      },
      {
        title: 'Reviewer',
        key: 'reviewerId',
        dataIndex: 'reviewerId',
        render: item => {
          return item && item.name ? item.name : ''
        }
      },
      {
        title: 'Remark Date',
        key: 'commentsDate',
        dataIndex: 'commentsDate',
        render: (item, record) => {
          return <>{item ? DisplayDate(item) : null}</>
        }
      },
      {
        title: 'Dates',
        key: 'sendDate',
        render: (item, record) => {
          return (
            <div>
              {console.log('ITEM', item)}
              <div>
                Send Date <br />
                {item ? (
                  <label className={'label label-success'}>
                    {DisplayDate(item.sendDate)}
                  </label>
                ) : (
                  ''
                )}
              </div>
              <div>
                Reminder <br />
                {item && item.reminderDate
                  ? DisplayDate(item.reminderDate)
                  : ''}
              </div>
            </div>
          )
        }
      }
    ]

    const columns = [
      {
        key: 'actions',
        width: 150,
        render: (val, record) => (
          <React.Fragment>
            <a className={'roundSmallBtn'} href={`/articleView/${record._id}`}>
              View Details
            </a>
            <Button
              className={'roundSmallBtn'}
              onClick={() => this.events.showDrawer(record)}>
              Another Reviewer
            </Button>
            <Button
              key="1"
              className={'roundSmallBtn'}
              onClick={() => this.setState({ visibleActivityList: true })}>
              <Icon />
              Article Activites
            </Button>
          </React.Fragment>
        )
      },
      {
        title: 'Date',
        key: 'submittedDate',
        dataIndex: 'submittedDate',
        render: (item, record) => {
          return (
            <div>
              <div>
                <strong>S.Date</strong>
                <br />
                {item ? (
                  <label className={'label label-primary'}>
                    {DisplayDate(item)}
                  </label>
                ) : null}
              </div>
              <div>
                <strong>U.R.Date</strong>
                <br />
                {record &&
                record.selectedReviewer.length &&
                record.selectedReviewer[0].sendDate ? (
                  <label className={'label label-success'}>
                    {DisplayDate(record.selectedReviewer[0].sendDate)}
                  </label>
                ) : null}
              </div>
            </div>
          )
        }
      },

      {
        title: 'Article Id',
        key: 'articleNo',
        dataIndex: 'articleNo'
      },
      {
        title: 'Title',
        key: 'manuScriptTitle',
        dataIndex: 'manuScriptTitle',
        render: item => {
          return item ? ReactHtmlParser(item) : null
        }
      },
      {
        title: 'Author Name',
        key: 'correspondingAuthor',
        dataIndex: 'correspondingAuthor'
      },
      {
        title: 'Author Email',
        key: 'correspondingAuthorsEmail',
        dataIndex: 'correspondingAuthorsEmail'
      },
      {
        title: 'Reviewer Details',
        key: 'selectedReviewer',
        render: item => {
          return (
            <Table
              columns={innerTableColumns}
              size={'small'}
              dataSource={item}
              bordered={true}
              pagination={false}
            />
          )
        }
      }
    ]

    const fil = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 }
      }
    }

    return (
      <PageHeaderWrapper title={`Under Reviewer Article (${this.state.total})`}>
        <Card bordered={true}>
          <ArticleFilters
            callback={data => {
              this.reloadTable(data)
            }}
          />
          <TableComp
            ref={this.table}
            columns={columns}
            apiRequest={this.apiRequest}
          />
        </Card>

        {visibleSelectReview ? (
          <SelectReviewer
            article={article}
            ids={ids}
            onClose={() => this.events.hideDrawer()}
            visible={visibleSelectReview}
          />
        ) : null}
        {this.state.memberDrawerVisible ? (
          <MemberHistory
            visible={this.state.memberDrawerVisible}
            articleId={article._id}
            data={this.state.reviewerData}
            reviewerId={this.state.reviewerId}
            onClose={this.events.hideMemberHistory}
          />
        ) : null}
        {this.state.visibleActivityList ? (
          <ArticleActivityList
            articleId={article._id}
            visible={this.state.visibleActivityList}
            onClose={() => this.setState({ visibleActivityList: false })}
          />
        ) : null}
      </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global }) => ({
  categories: global.categories
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllArticle)
