import React, { useEffect, useState } from 'react'
import { Modal, Select, notification } from 'antd'
import { getSingleCompanyEmail } from '../../containers/onlineSubmission/actions'
import { useDispatch } from 'react-redux'
import { InputBox } from './appUtils'
import { SendTemplateEmail } from '../../containers/email/actions'
import { showPageLoad, hidePageLoad } from '../../modules/actions'


let { Option } = Select
const ChooseEmail = (props) => {
  let { children, id, obj, template, journal = '', callback = null, title } = props
  let dispatch = useDispatch()
  let [emails, setEmails] = useState([])
  let [email, setEmail] = useState('')
  let [isModalVisible, setIsModalVisible] = useState(false)
  useEffect(() => {
    loadEmails()
  }, [])

  const loadEmails = async () => {
    let { data } = await dispatch(getSingleCompanyEmail())
    setEmails(data.mailers)
  }

  const emailEvents = {
    showChooseEmail: () => {
      setIsModalVisible(true)
    },
    hideChooseEmail: () => {
      setIsModalVisible(false)
    },
    submitEmailFxn: async () => {
      if (!email) {
        notification.error({
          message: 'Please choose email address'
        })
        return
      }
      setIsModalVisible(false)
      let resp = await dispatch(SendTemplateEmail({ fromEmail: email, email, id, obj, template, journal }))
      if (callback) {
        callback()
      }
    }
  }

  return (
    <React.Fragment>
      <a onClick={() => emailEvents.showChooseEmail()}>
        {children}
      </a>
      {isModalVisible ?
        <Modal title={title ? title : 'Select From Email'}
               visible={isModalVisible}
               onOk={() => emailEvents.submitEmailFxn()}
               onCancel={() => emailEvents.hideChooseEmail()}>

          <InputBox title={title ? 'Select From Email' : ''}>
            <Select onChange={(e) => setEmail(e)}>
              {emails && emails.length ? emails.map((item, key) => {
                return (
                  <Option key={key} value={item.userName}>{item.userName}</Option>
                )
              }) : null}
            </Select>
          </InputBox>
        </Modal> : null}
    </React.Fragment>
  )
}

export default ChooseEmail
