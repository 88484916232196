import {
  Button,
  Card,
  Col,
  Drawer,
  Form,
  Icon,
  notification,
  Row,
  Table,
  Tooltip
} from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { connect } from 'react-redux'
import { FormUtils, TableComp } from 'sz-react-utils-lite'
import {
  chooseEmailModalFxn,
  DisplayDate
} from '../../../components/_utils/appUtils'
import { RowTable } from '../../article/articleView/RowTable'
import EditOnlineSubmission from '../../article/drawers/editOnlineSubmission'
import { SendTemplateEmail } from '../../email/actions'
import { getSingleMember } from '../../members/actions/index'

const OnlineSubmissionDrawer = props => {
  let [revData, setRevData] = useState({})
  let { onClose, visible, data, callBackReload } = props
  let [authorData, setAuthorData] = useState(data.authors)
  let authorTable = useRef()
  const [visibleEdit, setVisibleEdit] = useState(false)
  // useEffect(()=>{
  //   let {data} = props
  //   setAuthorData(data)
  // },[])

  const handleEdit = () => {
    setVisibleEdit(true)
  }
  let attachmentArr = [
    {
      manuscriptAttachment: data.manuscriptAttachment,
      otherAttachment: data.otherAttachment,

      tableAttachment: data.tableAttachment,
      figureAttachment: data.figureAttachment
    }
  ]
  const getMemberDetails = async id => {
    let { dispatch } = props
    let respData = await dispatch(getSingleMember(id))
    setRevData(respData.data)
  }

  let reload = () => {
    if (authorTable.current) {
      authorTable.current.reload()
    }
  }

  const sendMailFxn = valData => {
    let { dispatch } = props
    chooseEmailModalFxn({
      callback: async email => {
        let obj = {
          fromEmail: email,
          emailType: 'reviewerRequest',
          template: 'reviewerRequest',
          type: 'onlineSubmission',
          otherAuthorName: valData.name,
          otherAuthorEmail: valData.email,
          authorId: valData._id
        }
        let resp = await dispatch(
          SendTemplateEmail({
            email,
            id: data._id,
            obj,
            template: obj.template
          })
        )
        if (resp && resp.success) {
          let { data } = resp.respData
          setAuthorData(data)
          reload()
          callBackReload()
        } else {
          notification.error({
            message: resp.message
          })
        }
      }
    })
  }

  const AttachmentColumns = [
    {
      title: 'Manuscript Attachment',
      key: 'manuscriptAttachment',
      dataIndex: 'manuscriptAttachment',
      render: item => {
        return item && item.fileName ? (
          <a href={item.path}>{item.fileName}</a>
        ) : (
          ''
        )
      }
    },
    {
      title: 'Other Attachment',
      key: 'otherAttachment',
      dataIndex: 'otherAttachment',
      render: item => {
        return item && item.fileName ? (
          <a href={item.path}>{item.fileName}</a>
        ) : (
          ''
        )
      }
    },

    {
      title: 'Table Attachment',
      key: 'tableAttachment',
      dataIndex: 'tableAttachment',
      render: item => {
        return item && item.fileName ? (
          <a href={item.path}>{item.fileName}</a>
        ) : (
          ''
        )
      }
    },
    {
      title: 'Figure Attachment',
      key: 'figureAttachment',
      dataIndex: 'figureAttachment',
      render: item => {
        return item && item.fileName ? (
          <a href={item.path}>{item.fileName}</a>
        ) : (
          ''
        )
      }
    }
  ]
  const AuthorColumns = [
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name'
    },
    {
      title: 'Institute Affiliated',
      key: 'instituteAffiliated',
      dataIndex: 'instituteAffiliated'
    },
    {
      title: 'Designation',
      key: 'designation',
      dataIndex: 'designation'
    },
    {
      title: 'Email',
      key: 'email',
      dataIndex: 'email'
    },

    {
      title: 'Address',
      key: 'correspondingAuthorAddress',
      dataIndex: 'correspondingAuthorAddress'
    },
    {
      title: 'Phone Number',
      key: 'phoneNo',
      dataIndex: 'phoneNo'
    },
    {
      title: 'Request Date',
      key: 'requestDate',
      width: 100,
      render: item => {
        return item ? DisplayDate(item) : null
      }
    },
    {
      title: 'Action',
      // key: 'Action',
      key: 'requestSent',
      render: (item, record) => {
        return item ? null : (
          <Button
            className={'roundSmallBtn'}
            onClick={() => sendMailFxn(record)}>
            Reviewer Request
          </Button>
        )
      }
    }
  ]

  return (
    <React.Fragment>
      {visible ? (
        <Drawer
          visible={visible}
          width={'86%'}
          placement="right"
          onClose={onClose}
          title={
            <div>
              Online Submission Details
              <a
                className={'linkIcon'}
                onClick={handleEdit}
                style={{ marginLeft: '15px' }}>
                <Icon type={'edit'} />
              </a>
            </div>
          }>
          <Card>
            <div className={'tableBox striped'}>
              <RowTable title={'Journal'} value={data.journal} />
              <RowTable title={'Country'} value={data.country} />
              <RowTable
                title={'Manuscript Title'}
                value={data.manuScriptTitle}
              />
              <RowTable title={'Article Type'} value={data.articleType} />
              <RowTable
                title={'Area of Research'}
                value={data.areaOfResearch}
              />
              <RowTable
                title={'Year of Research'}
                value={data.yearOfResearch}
              />
              <RowTable
                title={'Duration of Research'}
                value={data.durationOfResearch}
              />
              <RowTable
                title={'Abstract'}
                value={
                  <div style={{ width: '75%', marginLeft: '21%' }}>
                    {ReactHtmlParser(data.abstract)}
                  </div>
                }
              />
              <RowTable
                title={'Subject of Message'}
                value={ReactHtmlParser(data.message)}
              />
              <RowTable
                title={'Total Number of Author'}
                value={data.noOfAuthor}
              />
              <RowTable
                title={'Corresponding Author'}
                value={data.correspondingAuthor}
              />
              <RowTable
                title={'Corresponding Author Affiliation'}
                value={data.correspondingAuthorAffiliation}
              />
              <RowTable
                title={'Corresponding Authors Email'}
                value={data.correspondingAuthorsEmail}
              />
              <RowTable
                title={'Corresponding Author Contact'}
                value={data.correspondingAuthorContact}
              />
              <RowTable
                title={'Corresponding Author Address'}
                value={data.correspondingAuthorAddress}
              />

              {/*<RowTable title={'Description'} value={ReactHtmlParser(revData.description)}/>*/}
            </div>
            <br />
            <Table
              columns={AttachmentColumns}
              dataSource={attachmentArr}
              size={'small'}
              pagination={false}
            />
            <br />
            <TableComp
              columns={AuthorColumns}
              ref={authorTable}
              dataSource={authorData}
              size={'small'}
              pagination={false}
            />
          </Card>
        </Drawer>
      ) : null}
      {visibleEdit ? (
        <EditOnlineSubmission
          visible={visibleEdit}
          onClose={() => setVisibleEdit(false)}
          article={data}
          reload={callBackReload}
        />
      ) : null}
    </React.Fragment>
  )
}
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(mapDispatchToProps)(OnlineSubmissionDrawer)

// export default ViewMember
