import {
  Button,
  Card,
  Col,
  column,
  Drawer,
  Form,
  Input,
  message,
  notification,
  Popconfirm,
  Row,
  Select,
  Table,
  Upload
} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import axios from 'axios'
import _ from 'lodash'
import React from 'react'
import { CkEditor2013 } from '../../components/_utils/appUtils'
const { Option } = Select
@Form.create()
class AddAffilate extends React.Component {
  // formRef = React.createRef
  constructor(props) {
    super(props)
    this.state = {
      affilate: '',
      loading: false
    }
  }

  onClose = () => {
    this.setState({
      ['open']: false
    })
  }
  submitForm = () => {
    if (!this.state.affilate) {
      notification.warning({
        message: 'Enter affilate '
      })
      return
    }

    this.props.addAffilateData(this.state)
    this.setState({
      affilate: ''
    })
    this.props.onClose()
  }

  render() {
    let { visible, onClose, value, onChange } = this.props
    return (
      <>
        <Drawer
          title={'Add Affilation'}
          width={'70%'}
          visible={visible}
          maskClosable={true}
          placement="right"
          onClose={onClose}>
          <Card>
            <Form hideRequiredMark className={'inputForm'}>
              <Col span={24} className="flexColumn">
                <Form.Item label="Add Affilation" name="affilate" required>
                  <TextArea
                    value={this.state.affilate}
                    placeholder="Enter affilation..."
                    onChange={e => {
                      this.setState({
                        affilate: e.target.value
                      })
                    }}
                  />
                </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item>
                  <Button
                    type="primary"
                    onClick={() => {
                      this.submitForm()
                    }}>
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Form>
          </Card>
        </Drawer>
      </>
    )
  }
}

export default AddAffilate
