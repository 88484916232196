import {
  Card,
  Collapse,
  Drawer,
  notification,
  Table,
} from 'antd'
import React, {useEffect, useRef, useState} from 'react'
import ReactHtmlParser from 'react-html-parser'
import {connect, useDispatch} from 'react-redux'
import PageHeaderWrapper from "../../components/PageHeaderWrapper";
import {DisplayDate} from "../../components/_utils/appUtils";
import {singleBlog} from "./actions";

const {Panel} = Collapse
const BlogDetailDrawer = props => {
  let [articleData, setArticleData] = useState({})
  const RowTable = props => {
    let {title, value} = props
    return (
        <div className={'tableRow'}>
          <div className={'title'}>{title}:</div>
          <div>{value}</div>
        </div>
    )
  }

  const loadArticleData = async id => {
    const {dispatch, form} = props
    let {error, data} = await dispatch(singleBlog(props.blogId))
    if (error) {
      notification.error({
        message: 'Error Loading Data'
      })
    } else {
      console.log(data)
      setArticleData(data)
      console.log(articleData, 'shdhs')
    }
  }

  let tableColumn = [
    {
      title: ' Image ',
      name: 'image',
      key: 'image',
      dataIndex: 'image',
      width: 400,
      render: image => {
        return <img src={image.path} style={{height: 120}}/>
      }
    },
    {
      title: 'Description',
      name: 'description',
      key: 'description',
      dataIndex: 'description'
    }
  ]

  useEffect(
      () => {
        loadArticleData()
        console.log(props)
      },
      [props.visible]
  )

  // const apiRequest = params => {
  //   return new Promise(async resolve => {
  //     let regExpFilters = ['keyword']
  //     let result = await dispatch(
  //       keywordList({ ...params, regExFilters: regExpFilters })
  //     )
  //     resolve(result)
  //   })
  // }

  let authColumns = [
    {
      title: 'Sr.',
      dataIndex: 'index',
      key: 'index',
      render: (item, record, key) => {
        return key + 1
      }
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'Super Script',
      dataIndex: 'superScript',
      key: 'superScript'
    }
  ]

  return (
      <>
        <Drawer
            visible={props.visible}
            title='Blog Details'
            onClose={props.onClose}
            width='80%'>
          <Card bordered={true}>
            <PageHeaderWrapper>
              <div className={'tableBox striped'}>
                <RowTable
                    title={'Date'}
                    value={
                      articleData.createdAt
                          ? DisplayDate(articleData.createdAt)
                          : null
                    }
                />
                <RowTable
                    title={'Title'}
                    value={
                      articleData.title
                          ? ReactHtmlParser(articleData.title)
                          : null
                    }
                />


                <RowTable
                    title={'Description'}
                    value={
                      articleData.description ? (
                          <div style={{marginLeft: '10.8%'}}>
                            {ReactHtmlParser(articleData.description)}
                          </div>
                      ) : null
                    }
                />

                <RowTable
                    title={'Keyword List'}
                    value={
                      articleData.blogKeywordList
                          ? articleData.blogKeywordList.map((item, i) => {
                            return (
                                <>
                                  <div
                                      key={i}
                                      style={{
                                        width: '100%'
                                      }}>
                                    {i + 1}. {item.keyword}
                                  </div>
                                </>
                            )
                          })
                          : null
                    }
                />

                <RowTable
                    title={'Meta Title'}
                    value={
                      articleData.metaTitle ? (
                          <div style={{marginLeft: '10.8%'}}>
                            {ReactHtmlParser(articleData.metaTitle)}
                          </div>
                      ) : null
                    }
                />
                <RowTable
                    title={'Meta Description'}
                    value={
                      articleData.metaDescription ? (
                          <div style={{marginLeft: '10.8%'}}>
                            {ReactHtmlParser(articleData.metaDescription)}
                          </div>
                      ) : null
                    }
                />
                <RowTable
                    title={'Meta Keywords'}
                    value={
                      articleData.metaKeywords ? (
                          <div style={{marginLeft: '10.8%'}}>
                            {ReactHtmlParser(articleData.metaKeywords)}
                          </div>
                      ) : null
                    }
                />
                <div className={'doi-form'}>

                  <Card title='Information' className='p10'>
                    {articleData.infoData
                        ? articleData.infoData.map((item, i) => {
                          return (
                              <>
                                <div style={{marginTop: '8px'}}>
                                  <Collapse>
                                    <Panel
                                        header={`${item.title} (${
                                            item.menuHeading
                                        })`}>
                                      <p>{ReactHtmlParser(item.description)}</p>
                                    </Panel>
                                  </Collapse>
                                </div>
                              </>
                          )
                        })
                        : null}
                  </Card>
                  <Card title='Table'>
                    {articleData.table ? (
                        <Table
                            className='figureTable'
                            bordered={true}
                            dataSource={articleData.table}
                            columns={tableColumn}
                            width={'100%'}
                            pagination={false}
                        />
                    ) : null}
                  </Card>

                  <Card title={'Figures '}>
                    {articleData.figure ? (
                        <Table
                            className='figureTable'
                            bordered={true}
                            dataSource={articleData.figure}
                            columns={tableColumn}
                            width={'100%'}
                            pagination={false}
                        />
                    ) : null}
                  </Card>
                  <Card title={'Supplementary Tables'}>
                    {articleData.supplementaryTable ? (
                        <Table
                            className='figureTable'
                            bordered={true}
                            dataSource={articleData.supplementaryTable}
                            columns={tableColumn}
                            width={'100%'}
                            pagination={false}
                        />
                    ) : null}
                  </Card>

                </div>
              </div>
            </PageHeaderWrapper>
          </Card>
        </Drawer>
      </>
  )
}
const mapStateToProps = ({global, router}) => ({
  loading: global.buttonLoading,
  categories: global.categories,
  search: router.location.search,
  pathname: router.location.pathname
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
// export default keyword;
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BlogDetailDrawer)
