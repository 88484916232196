import React, { useEffect, useState } from 'react'
import { Row, Col, Card, Drawer, Form, Button, Icon, Avatar } from 'antd'
import { DisplayDate, formItemLayout, GetDownloadLink } from '../../components/_utils/appUtils'
import ReactHtmlParser from 'react-html-parser'
import { RowTable, CustomCardTitle } from '../article/articleView/RowTable'
import moment from 'moment'
import GetEachFormFields from '../../components/_utils/appFormUtils'
import { connect } from 'react-redux'
import PageHeaderWrapper from '../../components/PageHeaderWrapper'
import { getPushPathWrapper } from '../../routes'
import { useDispatch } from 'react-redux'
import { getSingleMember } from '../ReviewerContainer/actions'
import { PendingArticlesComponent } from '../ReviewerContainer/PendingArticles'
import DescriptionDrawer from '../ReviewerContainer/DescriptionDrawer'

const ReviewerDashboard = (props) => {

  let [memberInfo, setMemberInfo] = useState(false)
  let [visibleDrawer, setVisibleDrawer] = useState(false)
  let dispatch = useDispatch()

  useEffect(() => {
    getMemberInfo()
  }, [])

  const getMemberInfo = async () => {
    let user = JSON.parse(localStorage.getItem('user'))
    let { data } = await dispatch(getSingleMember(user.reviewerId))
    setMemberInfo(data)
    console.log(data)
  }

  let { children } = props
  return (
    <PageHeaderWrapper
      title={`Reviewer Id - ${memberInfo.reviewerId}`}
      content={[
        <Button key='1' type='primary' onClick={() => {
          dispatch(getPushPathWrapper('editReviewer'))
        }}>
          <Icon type={'edit'} />
          Edit
        </Button>
      ]}
    >
      <React.Fragment>
        <Card>
          <Row>
            <Col span={5}>
              <center>
                {memberInfo && (memberInfo.memberImage && memberInfo.memberImage.path) ?
                  <Avatar src={`${memberInfo.memberImage.path}`} shape='square' size={120} icon='user'
                          style={{ marginBottom: '5px' }} />
                  : <Avatar shape='circle' size={100} icon='user' style={{ marginBottom: '10px' }} />}
                <h3>{`Welcome ${memberInfo.name}`}</h3>
              </center>
            </Col>
            <Col span={19}>
              <div className={'tableBox striped'}>
                <RowTable title={'Welcome'} value={`${memberInfo.title} ${memberInfo.name}`} />
                <RowTable title={'Date of membership'} value={memberInfo.availableDate} />
                <RowTable title={'Area of interest'} value={memberInfo.areaOfInterest} />
                <RowTable title={'phone No.'} value={memberInfo.contactNo} />
                <RowTable title={'Department'} value={memberInfo.department} />
                <RowTable title={'journals'} value={memberInfo.journals} />
                <RowTable title={'Description'}
                          value={(
                            memberInfo.description ? <div style={{ padding: 0 }}>
                              <div className={'dotsReplace'}>
                                {ReactHtmlParser(memberInfo.description)}
                              </div>
                              <a className={'readMoreBtn'} onClick={() =>
                                setVisibleDrawer(true)
                              }>read more</a>
                            </div> : null
                          )} />
                <RowTable title={'ReviewerId'} value={memberInfo.reviewerId} />
                <RowTable title={'Address'} value={memberInfo.address} />
                <RowTable title={'Email'} value={memberInfo.email} />
                <RowTable title={'Designation'} value={memberInfo.designation} />
                <RowTable title={'Institute Affiliated With'} value={memberInfo.instituteAffiliatedWith} />
              </div>
            </Col>
          </Row>
        </Card>
        {
          visibleDrawer && <DescriptionDrawer visible={visibleDrawer} onClose={() => setVisibleDrawer(false)}
                                              data={memberInfo.description} />
        }
      </React.Fragment>
      <div style={{ marginTop: 25 }}>
        <PendingArticlesComponent {...props} showTitle={true} />
      </div>
    </PageHeaderWrapper>
  )
}
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapDispatchToProps
)(ReviewerDashboard)
