import {
    Button,
    Card,
    notification,
    Tooltip
} from 'antd'
import React, {Component} from 'react'
import {connect} from 'react-redux'
import {FormUtils, TableComp} from 'sz-react-utils-lite'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import Request from '../../../request'
import styles from './styles.less'
import {getAllUsersFxn} from '../actions'
import EditUser from "../drawers/editUser"
import MemberHistory from "../../article/reviewer/memberHistory";

const regExpFilters = ['name', 'local.email', 'userType']

class AllUsers extends Component {
    events = {
        showEdit: (data) => {
            this.setState({
                visible: true, userId: data._id,
                userType: data.userType
            })
        },
        hideEdit: () => {
            this.setState({
                visible: false, userId: "", userType: ""
            })
        },
        onSubmit: () => {
            this.setState({
                visible: false, userId: "", userType: ""
            }, () => {
                this.table.current.reload()
            })
        },
        showHistory: (data) => {
            this.setState({
                reviewerState: {
                    visible: true,
                    reviewerId: data.reviewer_Id,
                    data: {
                        reviewerId: data.reviewerId,
                        name: data.name,
                    }
                }
            })
        },
        hideHistory: () => {
            this.setState({
                reviewerState: {
                    visible: false,
                    reviewerId: "",
                    data: {}
                }
            })
        }
    }

    constructor(props) {
        super(props)
        this.table = React.createRef();
        this.state = {
            userId: "",
            visible: false,
            userType: "",
            reviewerState: {
                visible: false,
                data: {}
            }
        }
    }

    apiRequest = params => {
        let {dispatch} = this.props
        return new Promise(async resolve => {
            let data = await dispatch(
                getAllUsersFxn({
                    ...params,
                    regExFilters: regExpFilters
                })
            )
            resolve(data)
        })
    }

    deleteUser = params => {
        Request.deleteUserApi(params).then(data => {
            if (data.type) {
                notification.success({
                    message: data.msg
                })
                this.reload()
            } else {
                notification.error({
                    message: data.msg
                })
            }
        })
    }

    reload = () => {
        this.table.current.reload()
    }

    componentDidMount() {
    }

    render() {
        let {state: {visible, userId, userType, reviewerState}, events} = this
        const columns = [
            {
                title: 'Name',
                key: 'name',
                sorter: true,
                dataIndex: 'name',
                searchTextName: 'name',
                filterByRegex: true,
                fixed: 'left',
                width: 130
            },
            {
                title: 'Email',
                dataIndex: 'local.email',
                key: 'local.email',
                filterByRegex: true,
                searchTextName: 'local.email',
                fixed: 'left',
                width: 150
            },
            {
                title: 'User Type',
                dataIndex: 'userType',
                key: 'userType',
                searchTextName: 'userType',
                filterByRegex: true
            },
            {
                title: 'Reviewer Id',
                dataIndex: 'reviewerId',
                key: 'reviewerId',
                searchTextName: 'userType',
                filterByRegex: true
            },
            {
                title: 'Password',
                dataIndex: 'displayPassword',
                key: 'displayPassword'
            },
            {
                title: 'Address',
                dataIndex: 'address',
                key: 'address'
            },
            {
                title: 'Institute',
                dataIndex: 'instituteAddress',
                key: 'instituteAddress'
            },
            {
                title: 'Designation',
                dataIndex: 'designation',
                key: 'designation'
            },
            {
                title: 'MobileNo',
                dataIndex: 'mobileNo',
                key: 'mobileNo'
            },

            {
                title: 'userName',
                dataIndex: 'userName',
                key: 'userName'
            },
            {
                title: 'Country',
                dataIndex: 'country',
                key: 'country'
            },
            {
                key: 'actions',
                width: 150,
                render: (item, record) => {
                    return (
                        <div className={'wrapRow'}>
                            {item.userType == 'User' ? (
                                <React.Fragment>
                                    <Tooltip title='Show Details'>
                                        <Button
                                            className={styles.btn}
                                            shape='circle'
                                            type='danger'
                                            onClick={() => {
                                                this.deleteUser(item)
                                            }}
                                            icon='delete'
                                        />
                                    </Tooltip>
                                </React.Fragment>
                            ) : null}

                            {['User', 'Author'].includes(item.userType) ?
                                <a className={'btn round btn-default'} onClick={() => {
                                    this.events.showEdit(record)
                                }}>Edit</a> : null}

                            {item.userType == "Reviewer" ?
                                <a className={'btn round btn-default'} onClick={() => {
                                    this.events.showHistory(record)
                                }}>History</a> : null}

                        </div>
                    )
                }
            }
        ]
        return (
            <PageHeaderWrapper title={'All Users'}>
                <Card bordered={true}>
                    <TableComp
                        ref={this.table}
                        extraProps={{scroll: {x: 1350}}}
                        columns={columns}
                        apiRequest={this.apiRequest}
                    />
                </Card>

                {visible ? <EditUser
                    visible={visible} userId={userId}
                    userType={userType}
                    onSubmit={events.onSubmit}
                    onClose={events.hideEdit}/> : ""}


                {reviewerState.visible ? (
                    <MemberHistory
                        {...reviewerState}
                        onClose={events.hideHistory}
                    />
                ) : null}

            </PageHeaderWrapper>
        )
    }
}

const mapStateToProps = (
    {
        global
    }
) => (
    {
        categories: global.categories
    }
)
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AllUsers)
