import React, { useEffect, useState, useRef } from 'react'
import { Button, Card, Collapse, Popconfirm, Table, Tooltip } from 'antd'
import { chooseEmailModalFxn, DisplayDate, GetDownloadLink } from '../../../components/_utils/appUtils'
import { RowTable } from './RowTable'
import RevisedArticleDrawer from '../drawers/RevisedArticleDrawer'
import { SendTemplateEmail } from '../../email/actions'
import { useDispatch } from 'react-redux'

const { Panel } = Collapse
const RevisedArticleTable = (props) => {
  let { article } = props
  let dispatch = useDispatch()
  let {
    underReviewDetails,
    revisedArticleAttachment,
    sendToAuthorDate,
    revisedArticleDate,
    responseFromAuthorAttachment
  } = article
  let [receiveDateDrawerVisible, setReceiveDateDrawerVisible] = useState(false)
  let [reviewerId, setReviewerId] = useState('')
  let headerTitle = `Revised Article ${revisedArticleDate ? `(${DisplayDate(revisedArticleDate)})` : ''}`
  let [visibleRevisedArticle, setVisibleRevisedArticle] = useState(false)
  const table = useRef()
  let events = {
    reloadTable: () => {
      table.current.reload()
    },
    showAndHideReceiveDate: (value, id = '') => {
      setReceiveDateDrawerVisible(value)
      setReviewerId(id)
    },
    show: () => {
      console.log('show drawer')
      setVisibleRevisedArticle(true)
    },
    hide: () => {
      setVisibleRevisedArticle(false)
    }
  }

  let BtnAction = (event) => {
    events.show()
    event.stopPropagation()
  }

  let sendMailToReviewer = () => {
    chooseEmailModalFxn({
      callback: async (email) => {
        let obj = {
          id: article._id,
          fromEmail: email,
          emailType: 'revisedArticleReviewer',
          template: 'revisedToReviewer',
          type: 'article',
          reviewerId: article.underReviewDetails && article.underReviewDetails.reviewerId ? article.underReviewDetails.reviewerId : null
        }
        let resp = await dispatch(SendTemplateEmail({
          email: obj.fromEmail,
          id: article._id,
          obj,
          template: obj.template,
          reviewerId: obj.reviewerId
        }))
        if (resp && resp.success) {
          dispatch({ type: 'START_LOAD_ARTICLE' })
        }
      }
    })
  }

  const sendMailNotPerFormat = () => {
    chooseEmailModalFxn({
      callback: async (email) => {
        let obj = {
          id: article._id,
          fromEmail: email,
          emailType: 'notAsPerFormat',
          template: 'notAsPerFormat',
          type: 'article'
        }
        let resp = await dispatch(SendTemplateEmail({
          email: obj.fromEmail,
          id: article._id,
          obj,
          template: obj.template
        }))
        if (resp && resp.success) {
          dispatch({ type: 'START_LOAD_ARTICLE' })
        }
      }
    })
  }


  let getExtraButton = () => {
    return (
      <React.Fragment>
        {revisedArticleDate ?
          <Button type={'primary'} size={'small'} onClick={(events) => {
            events.stopPropagation()
            sendMailNotPerFormat()
          }}>Not as per format</Button> : null}
        {' '}
        {revisedArticleDate ?
          <Button type={'primary'} size={'small'}
                  onClick={(events) => {
                    events.stopPropagation()
                    sendMailToReviewer()
                  }}
          >To Reviewer</Button>
          : null}
        {' '}
        {sendToAuthorDate && !revisedArticleDate ?
          <Button type={'primary'} size={'small'} onClick={BtnAction}>Add</Button> : null}
        {revisedArticleDate ?
          <Button type={'primary'} size={'small'} onClick={BtnAction}>Edit</Button> : null}
      </React.Fragment>
    )
  }

  return (
    <div>
      <Collapse expandIconPosition={'right'} defaultActiveKey={['1']}>
        <Panel header={headerTitle} key='1' extra={getExtraButton()}>
          <div className={'tableBox striped'}>
            <RowTable title={'Date'} value={
              revisedArticleDate && DisplayDate(revisedArticleDate)
            } />
            <RowTable title={'Revised Article Attachment'} value={
              revisedArticleAttachment && revisedArticleAttachment.path ?
                <a download className='downloadLink' target={'_blank'}
                   href={revisedArticleAttachment.path}>Download</a> : 'No Attachment'
            } />
            <RowTable title={'Response from Author Attachment'} value={
              responseFromAuthorAttachment && responseFromAuthorAttachment.path ?
                <a download className='downloadLink' target={'_blank'}
                   href={responseFromAuthorAttachment.path}>Download</a> : 'No Attachment'
            } />
          </div>
        </Panel>
      </Collapse>
      {visibleRevisedArticle ? <RevisedArticleDrawer
        visible={visibleRevisedArticle}
        articleId={article._id}
        article={article}
        edit={revisedArticleDate ? true : false}
        onClose={() => events.hide()}
      /> : null}
    </div>
  )
}

export default RevisedArticleTable
