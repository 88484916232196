import { Button, Card, Collapse, Popconfirm, Table, Tooltip } from 'antd'
import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { useDispatch } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import {
  DisplayDate,
  GetDownloadLink
} from '../../../components/_utils/appUtils'
import { getPushPathWrapper } from '../../../routes'
import DOIDrawer from '../drawers/DOIDrawer'
import { RowTable } from './RowTable'
import Viewdetaildrawer from './viewDetailDrawer'
const { Panel } = Collapse
const DIODetails = props => {
  let { article } = props
  let dispatch = useDispatch()
  let { sendgalleyProofDate, doiProcessDate } = article
  let [receiveDateDrawerVisible, setReceiveDateDrawerVisible] = useState(false)
  let [reviewerId, setReviewerId] = useState('')
  let headerTitle = `DOI Generated ${
    doiProcessDate ? `(${DisplayDate(doiProcessDate)})` : ''
  }`
  let [open, setOpen] = useState(false)
  let [
    visibleCommentToAuthorDrawer,
    setVisibleCommentToAuthorDrawer
  ] = useState(false)

  const table = useRef()
  let events = {
    reloadTable: () => {
      table.current.reload()
    },

    showAndHideReceiveDate: (value, id = '') => {
      setReceiveDateDrawerVisible(value)
      setReviewerId(id)
    },
    show: () => {
      setVisibleCommentToAuthorDrawer(true)
    },
    hide: () => {
      setVisibleCommentToAuthorDrawer(false)
    },

    closeDoiDetailDrawer: () => {
      setOpen(false)
    }
  }

  let BtnAction = event => {
    // events.show()
    dispatch(getPushPathWrapper('article.addDoi', { id: article._id }))
    event.stopPropagation()
  }

  let getExtraButton = () => {
    return (
      <React.Fragment>
        {sendgalleyProofDate && !doiProcessDate ? (
          <Button type={'primary'} size={'small'} onClick={BtnAction}>
            Add
          </Button>
        ) : null}
        {doiProcessDate ? (
          <Button type={'primary'} size={'small'} onClick={BtnAction}>
            Edit
          </Button>
        ) : null}

        <Button
          type={'primary '}
          size={'small'}
          onClick={() => {
            setOpen(true)
          }}
          style={{ marginLeft: '6px' }}>
          View Details
        </Button>
      </React.Fragment>
    )
  }

  return (
    <div>
      <Collapse expandIconPosition={'right'} defaultActiveKey={['1']}>
        <Panel header={headerTitle} key="1" extra={getExtraButton()}>
          <div className={'tableBox striped'}>
            <RowTable
              title={'Date'}
              value={doiProcessDate && DisplayDate(doiProcessDate)}
            />

            <RowTable
              title={'Article Title'}
              value={
                article.doiArticleTitle
                  ? ReactHtmlParser(article.doiArticleTitle)
                  : null
              }
            />

          {/*  <RowTable
              title={'Author Name'}
              value={article.doiAuthorName ? article.doiAuthorName : null}
            />

            <RowTable
              title={'Author Address'}
              value={article.doiAuthorAddress ? article.doiAuthorAddress : null}
            />*/}

            <RowTable
              title={'Abstract'}
              value={
                article.doiAbstract ? (
                  <div style={{ width: '75%', marginLeft: '21%' }}>
                    {ReactHtmlParser(article.doiAbstract)}
                  </div>
                ) : null
              }
            />

            {/* <RowTable
              title={'Keywords'}
              value={
                article.doiKeywords
                  ? ReactHtmlParser(article.doiKeywords)
                  : null
              }
            />*/}
            <RowTable
              title={'Reference'}
              value={
                article.doiReferences
                  ? ReactHtmlParser(article.doiReferences)
                  : null
              }
            />
          </div>
        </Panel>
      </Collapse>
      <Viewdetaildrawer
        visible={open}
        onClose={() => events.closeDoiDetailDrawer()}
        sendId={article._id}
      />
      {visibleCommentToAuthorDrawer ? (
        <DOIDrawer
          visible={visibleCommentToAuthorDrawer}
          article={article}
          edit={doiProcessDate ? true : false}
          onClose={() => events.hide()}
        />
      ) : null}
    </div>
  )
}

export default DIODetails
