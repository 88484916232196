import {
  Button,
  Card,
  Col,
  Drawer,
  Form,
  Input,
  message,
  notification,
  Row,
  Select
} from 'antd'

import TextArea from 'antd/lib/input/TextArea'
import React from 'react'
import moment from 'moment'


const { Option } = Select

@Form.create()
class AddTable extends React.Component {
  // formRef = React.createRef
  constructor(props) {
    super(props)
    this.state = {
      image: '',
      description: '',

      loading: false
    }
  }

  submitForm = e => {
    let { tableType, addTableData, onClose } = this.props
    let { image } = this.state
    if (!this.state.description) {
      notification.warning({
        message: 'Enter description '
      })
      return
    }
    if (!this.state.image || (this.state.image && !this.state.image.name)) {
      notification.warning({
        message: 'Choose Image'
      })
      return
    }
    let newObj = {
      tableType,
      ...this.state,
      fileName: image.name
    }
    addTableData(newObj)

    this.setState({
      description: ''
    })
    onClose()
  }

  render() {
    let { visible, onClose, title, onChange } = this.props
    return (
      <>
        <Drawer
          title={`Add ${title}`}
          width={'70%'}
          visible={visible}
          maskClosable={true}
          placement='right'
          onClose={onClose}>
          <Card>
            <Form hideRequiredMark className={'inputForm'}>
              <Row>
                <Col span={24}>
                  <Form.Item label='Description' name='description' required>
                    <TextArea
                      value={this.state.description}
                      placeholder='Enter Description...'
                      onChange={e => {
                        this.setState({
                          description: e.target.value
                        })
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label='Image'>
                    <Input
                      type='file'
                      className='form-control'
                      accept='image/png, image/gif, image/jpeg'
                      onChange={e => {
                        let file = e.target.files[0]
                        file.path = URL.createObjectURL(file)
                        this.setState({ image: file })
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item>
                    <Button
                      type='primary'
                      onClick={() => {
                        this.submitForm()
                      }}>
                      Submit
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Drawer>
      </>
    )
  }
}

export default AddTable
